import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {ReduxStore, SidebarData} from "@interfaces/store";
import {useCallback} from "react";
import {sidebarActions} from "@store/sidebarStore";

export function useReCAPTCHAKey() {
    return useSelector(({ panel }: ReduxStore) => panel.settings.data.recaptchaPublic);
}

export function useSidebar() {
    const store = useSelector(({ sidebar }: ReduxStore) => sidebar);
    const dispatch = useDispatch();
    
    const setSidebar = useCallback((state: Partial<SidebarData>) => dispatch(sidebarActions.setSidebar(state)), [dispatch]);

    const changeTheme = useCallback((theme: string | null) => {
        const isDark =
            theme === 'auto'
                ? window.matchMedia('(prefers-color-scheme: dark)').matches
                : theme === 'dark';

        document.documentElement.setAttribute(
            'data-bs-theme',
            isDark ? 'dark' : 'light'
        );

        setSidebar({ 
            theme: theme || 'auto', 
            isDark 
        });
    }, [setSidebar]);

    return {
        ...store,
        setSidebar,
        changeTheme
    }
}

export function useProjectName() {
    return useSelector(({ panel }: ReduxStore) => panel.settings.data.projectName);
}

export function useSettings() {
    return useSelector(({ panel }: ReduxStore) => panel.settings.data);
}

export function usePaymentSettings() {
    return useSelector(({ panel }: ReduxStore) => panel.paymentSettings);
}

export function usePaymentCurrency() {
    return useSelector(({ panel }: ReduxStore) => panel.settings.data.paymentCurrency);
}

export function useGlobalVariable(name: string, defaultValue?: string | number) : string | number | undefined {
    return useSelector(({ panel }: ReduxStore) => panel.vars[name]) || defaultValue;
}

export function useGlobalVariables() {
    return useSelector(({ panel }: ReduxStore) => panel.vars, shallowEqual);
}
import { useSelector } from 'react-redux';
import { ReduxStore } from '@interfaces/store';
import { KeyValueSet } from '@interfaces/helpers';
import {ReactText, useMemo} from "react";

export function useCurrentLanguage() {
    const deflang = useSelector(({ panel }: ReduxStore) => panel.settings.data.defaultLang);
    const lang = useSelector(({ user: { user } }: ReduxStore) => (user.loaded && user.data.lang) || deflang);
    return useSelector(({panel}: ReduxStore) => panel.languages[lang]);
}

/**
 * Локализация строки
 * @param text 
 * @param replaces 
 */
export function useLocalizedText(text: ReactText, replaces: any = {}) {
    
    const lang = useCurrentLanguage();

    let template = (lang && lang.data?.strings[text]) || text?.toString();//useSelector(({ panel: { languages } }: ReduxStore) => (languages[lang] && languages[lang].loaded && languages[lang].data?.strings[text]) || text);

    if(typeof replaces === 'object') {
        Object.entries<string>(replaces).forEach((val) => {
            if(val[1]) {
                template = template.replace(`{${val[0]}}`, val[1].toString());
            }
        });
    }

    return template;
}

/**
 * Локализация строк из массива
 * @param texts 
 * @param replaces 
 */
export function useLocalizedTexts(texts: string[], replaces: any = {}){

    const lang = useCurrentLanguage();

    return useMemo(() => {
        const result: KeyValueSet<string> = {};

        texts.forEach(text => {
            let template = lang.data?.strings[text] || text;
            Object.entries<string>(replaces).forEach((val) => {
                template = template.replace(`{${val[0]}}`, val[1]);
            });
            result[text] = template;
        });

        return result;
    }, [texts, replaces]);
}

/**
 * Локализация всех строк, начинающихся с block
 * @param block 
 * @param replaces 
 */
export function useLocalizedBlock(block: string, replaces: any = {}) {

    const lang = useCurrentLanguage();
    return useMemo(() => {

        if(!lang.data){
            return {};
        }

        const result: KeyValueSet<string> = {};

        const entries = Object.entries(lang.data.strings).filter(e => e[0].startsWith(block));
        entries.forEach(e => {
            let template = e[1];
            Object.entries<string>(replaces).forEach((val) => {
                template = template.replace(`{${val[0]}}`, val[1]);
            });
            result[e[0].replace(`${block}.`, '')] = template;
        });

        return result;
    }, [lang.data, block, replaces]);
}
import ConsumerDailyService from "@api/consumer/consumerDailyService";
import { EditDailyGiftForm } from "@components/consumer/daily";
import withPage from "@components/hoc/withPage";
import { useBreadcrumbs, useStateLoader } from "@hooks/panelHooks";
import { IConsumerGift } from "@interfaces/consumer/daily";
import { FC, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, CardTitle, Spinner } from "reactstrap";

const EditDailyGiftPage: FC = () => {

    const { gift_id } = useParams<{ gift_id: string }>();

    const loadGift = useCallback(async () => {

        const api = new ConsumerDailyService();
        return await api.getGift(gift_id);
    }, [gift_id]);

    const { data: gift, loading } = useStateLoader<IConsumerGift>(loadGift);
    useBreadcrumbs('consumer.daily.current.name', true, gift?.name);

    if (!gift) {
        return (
            <Card>
                <CardBody className="text-center">
                    {loading ? <Spinner className="mr-2" color="primary" /> : (
                        "Подарок не найден"
                    )}
                </CardBody>
            </Card>
        )
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h5">
                    Редактирование подарка {gift.name}
                </CardTitle>
            </CardHeader>
            <CardBody>
                <EditDailyGiftForm gift={gift} />
            </CardBody>
        </Card>
    )
}

export default withPage(EditDailyGiftPage);
import { fromUtcDate } from "@services/helpers";
import { FC } from "react";
import { useNewsContext } from "./context";
import { Text } from '@components/constructor/components';

interface NewsDateProps {
    format?: string;
}

const NewsDate: FC<NewsDateProps> = ({ format = 'lll', ...props }) => {
    const news = useNewsContext();

    if (!news) {
        return null;
    }

    return (
        <Text text={fromUtcDate(news.beginTime).format(format)} {...props} />
    )
}

export default NewsDate;
import {
    IConsumerDashboard,
    IOnlineWidget, IPaymentChartsWidget,
    IPaymentsWidget,
    ISignUpsWidget,
    ISupportWidget
} from '@interfaces/consumer/dashboard';
import { ConsumerProjectInfo } from '@interfaces/consumer/project';
import { PutConsumerBucketModel } from '@models/consumer/storages';
import IService from './iService';

export default class ConsumerService extends IService {

    async fetchConsumerDashboard(): Promise<IConsumerDashboard> {
        return await this.getAndThrowApi<IConsumerDashboard>('/consumer/dashboard/');
    }

    async fetchConsumerProjectInfo() {
        return await this.getAndThrowApi<ConsumerProjectInfo>('/consumer/project/');
    }

    async putConsumerBucket(bucket: PutConsumerBucketModel) {
        return await this.putApi('/admin/projects/bucket/', bucket);
    }

    fetchOnlineWidget = async () => {
        return await this.getApi<IOnlineWidget>('/consumer/dashboard/online/');
    }

    fetchSignUpsWidget = async () => {
        return await this.getApi<ISignUpsWidget>('/consumer/dashboard/signups/');
    }

    fetchSupportWidget = async () => {
        return await this.getApi<ISupportWidget>('/consumer/dashboard/support/');
    }

    fetchPaymentsWidget = async () => {
        return await this.getApi<IPaymentsWidget>('/consumer/dashboard/payments/');
    }

    fetchPaymentChartsWidget = async () => {
        return await this.getApi<IPaymentChartsWidget>('/consumer/dashboard/payments/charts/');
    }
}
import ConsumerRefService from "@api/consumer/consumerRefService";
import { EditGoalRewardForm } from "@components/consumer/goals";
import withPage from "@components/hoc/withPage";
import { useBreadcrumbs, useStateLoader } from "@hooks/panelHooks";
import { IGoalReward } from "@interfaces/consumer/refs";
import { FC, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Card, CardHeader, CardTitle } from "reactstrap";


const EditGoalRewardPage: FC = () => {

    const { goal_id, reward_id } = useParams<{ goal_id: string, reward_id: string }>();

    const loadGoal = useCallback((goal_id: string, reward_id: string) => {
        const api = new ConsumerRefService();
        return api.getGoalReward(goal_id, reward_id);
    }, []);

    const { data: reward } = useStateLoader<IGoalReward>(loadGoal, goal_id, reward_id);

    useBreadcrumbs('consumer.goals.reward.current.name', true, reward?.name);
    useBreadcrumbs('consumer.goals.current.name', true, reward?.goalName, `/consumer/goals/${reward?.goalId}/`);
    useBreadcrumbs('consumer.goals.rewards', true, null, `/consumer/goals/${reward?.goalId}/rewards/`);

    if (!reward) {
        return null;
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h5">
                    Редактирование награды {reward?.name}
                </CardTitle>
            </CardHeader>
            <EditGoalRewardForm reward={reward}/>
        </Card>
    )
}

export default withPage(EditGoalRewardPage);
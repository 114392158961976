import { useFile } from "@hooks/filesHooks";
import { FC } from "react";
import { useItemContext } from "./context";

const ItemIcon: FC = (props) => {

    const item = useItemContext();
    const file = useFile(item?.iconId);

    if (!file?.data) {
        return null;
    }

    return (
        <img src={file.data.url} alt={item?.name || ''} {...props} />
    )
}

export default ItemIcon;
import React, {FC} from "react";
import {useGameServers, useLoginServers} from "@hooks/selectors/game.selectors";
import {Card, Form} from "react-bootstrap";
import {useFormikContext} from "formik";

const ServersBlock: FC = () => {
    const gameServers = useGameServers();
    const loginServers = useLoginServers();

    const {
        values,
        handleChange,
        touched,
        errors
    } = useFormikContext<{servers: string[]}>();

    return (
        <Card className="mb-3">
            <Card.Header as="h6" className="bg-body-tertiary">
                Доступные сервера
            </Card.Header>
            <Card.Body>
                <Form.Select
                    id="servers"
                    name="servers"
                    value={values.servers}
                    onChange={handleChange}
                    multiple
                    isInvalid={Boolean(touched.servers && errors.servers)}>
                    {
                        loginServers.map(l => (
                            <option key={l.id} value={l.id}>{l.name} (логин)</option>
                        ))
                    }
                    {
                        gameServers.map(g => (
                            <option key={g.id} value={g.id}>{g.name} (гейм)</option>
                        ))
                    }
                </Form.Select>
            </Card.Body>
        </Card>
    )
}

export default ServersBlock;
import {EModifyType, EPaymentBonusType, EPaymentCurrency, EThresholdType} from "../user/payments";

export interface ConsumerPayment {
    id: string;
    orderId: number;
    userId: string;
    userName: string;
    sum: number;
    normalizedSum: number;
    profit: number;
    normalizedProfit: number;
    finishTime: string;
    state: PaymentState;
    aggregator: PaymentAgregator;
    currency: EPaymentCurrency;
}

export interface ConsumerPaymentFull {
    id: string;
    orderId: number;
    userId: string;
    userName: string;
    sum: number;
    paidSum: number;
    normalizedPaidSum: number;
    replenishmentSum: number;
    profit: number;
    normalizedProfit: number;
    state: PaymentState;
    aggregator: PaymentAgregator;
    createTime: string;
    finishTime?: string;
    currency: EPaymentCurrency;

    payments: ConsumerPaymentShort[];
}

export interface ConsumerPaymentShort {
    id: string;
    orderId: number;
    sum: number;
    normalizedSum: number;
    currency: EPaymentCurrency;
    finishTime: string;
}

export enum PaymentState
{
    Created,
    Finished,
    Error
}

export enum PaymentAgregator
{
    Unitpay = 'UNITPAY',
    Enot = 'ENOT',
    Prime = 'PRIME',
    PayPalych = 'PAYPALYCH',
    Antilopay = 'ANTILOPAY',
    StreamPay = 'STREAMPAY'
}

export interface ICPaymentBonus {
    id: string;
    name: string;
    isActive: boolean;
    value: number;
    type: EPaymentBonusType;
    threshold: number;
    thresholdType: EThresholdType;
    createTime: string;
    startDate?: string;
    startTime?: string;
    endDate?: string;
    endTime?: string;
    daysOfWeek: number[];
    aggregators: PaymentAgregator[],
    modifyType: EModifyType;
    hidden: boolean;
}
import {FC, useEffect, useRef, useState} from "react";
import {Navbar} from "react-bootstrap";
import classNames from "classnames";
import NavbarTopElements from "./navbarTopElements";

const NavbarTop: FC = () => {

    const [showDropShadow, setShowDropShadow] = useState(false);

    const setDropShadow = () => {
        const el = document.documentElement;
        if (el.scrollTop > 0) {
            setShowDropShadow(true);
        } else {
            setShowDropShadow(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', setDropShadow);
        return () => window.removeEventListener('scroll', setDropShadow);
    }, []);

    return (
        <Navbar className={classNames('navbar-glass fs-10 navbar-top sticky-kit', {
            // 'navbar-glass-shadow': showDropShadow
            'navbar-glass-shadow': showDropShadow// && !isChat
        })}
        expand={true}>
            <NavbarTopElements
                navbarCollapsed={false}
                navbarPosition={'vertical'}
                handleBurgerMenu={() => {}}
            />
        </Navbar>
    )
}

export default NavbarTop;
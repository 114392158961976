import { ConstructionComponentInfo } from '@interfaces/constructor/constructor';
import LoginServerChargeRate from './loginServerChargeRate';
import LoginServersList from './loginServerList';
import LoginServerName from './loginServerName';
import PanelLoginServerContext from './panelLoginServerContext';

export interface LoginIdProps {
    login_id?: string;
}

const LoginServersListInfo: ConstructionComponentInfo = {
    component: LoginServersList,
    addedChildProps: [
        'login_id'
    ]
}

const LoginServerContextInfo: ConstructionComponentInfo = {
    component: PanelLoginServerContext
}

const LoginServerNameInfo: ConstructionComponentInfo = {
    component: LoginServerName,
    requiredParent: [
        'LoginServerContext'
    ],
    props: [
        'login_id'
    ],
    injectChild: [
        'login_id'
    ]
}

const LoginServerChargeRateInfo: ConstructionComponentInfo = {
    component: LoginServerChargeRate
}

export {
    LoginServersListInfo,
    LoginServerChargeRateInfo,
    LoginServerNameInfo,
    LoginServerContextInfo
}
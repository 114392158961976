import {FC, PropsWithChildren, ReactNode, Suspense, useEffect, useMemo} from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { ScrollToTop, LoadingPage } from '../components/panel';
import DashboardLayout from '../layouts/Dashboard';
import GuestLayout from '../layouts/Auth';
import { RouterItem, RouterItemInfo } from '@interfaces/control-panel';
import Page404 from '../pages/guest/page404';
import { useAuth, useAllRoles, useRole, usePanel } from '@services/hooks';
import { useTheme } from '@hooks/panelHooks';
import ConstructionComponent from '../components/constructor/constructionComponent';
import { PageRouting } from '@interfaces/panel/routing';
import { ThemeStyles } from '@interfaces/panel/themes';

import ConsumerRoutes from './consumerRoutes';
import { Logout } from '../pages';
import ym from 'react-yandex-metrika';
//import infos from '@components/constructor/infos/forms';
//const ConsumerRoutes = lazy(() => import('./consumerRoutes'));

export function childRoutes(routes: RouterItem, roles: string[], isAuthed: boolean, items: RouterItemInfo[] = []) {
    const { path, children = null, exact } = routes;
    const Component = routes.component;

    let elems: React.ReactNode[] = [];
    let itArr = items;

    const route_info: PageRouting = {
        code: routes.code,
        name: routes.name,
        title: routes.title,
        items: itArr
    };

    const child = Component ? (typeof Component === 'string' ? <ConstructionComponent name={Component} childProps={{ route_info }} /> : <Component route_info={route_info} />) : null;

    if (routes.needAuth === undefined || routes.needAuth === isAuthed) {
        if (routes.authRole === undefined || roles.includes(routes.authRole)) {
            if (Component) {
                elems = elems.concat(
                    <Route key={Array.isArray(routes.path) ? routes.path[0] : routes.path} path={path} exact={exact === undefined || exact}>
                        {child}
                    </Route>
                )
            }
            if (children) {
                itArr = items.concat({ 
                    name: routes.name, 
                    link: Array.isArray(routes.path) ? routes.path[0] : routes.path, 
                    useLink: Boolean(Component !== undefined || routes.useLink), 
                    code: routes.code 
                });
                children.forEach((item, idx) => {
                    const r = childRoutes(item, roles, isAuthed, itArr);
                    elems = elems.concat(r);
                });
            }
        }
    }

    return elems;
}

const defaultTheme: ThemeStyles = {
    css: [
        '/css/theme.css',
        '/themes/immortals/css/loading.css'
    ]
}

function insertFont(url: string) {
    const link = document.createElement("link");
    link.href = url;
    link.rel = "stylesheet";
    document.getElementsByTagName("head")[0].appendChild(link);

    //console.log('добавлен шрифт ' + url);
}

function validateStyles(styles: string[]) {

    let cssList = [...styles];

    Array.from(document.querySelectorAll<HTMLLinkElement>('link[rel="stylesheet"]')).forEach(
        style => {
            const pathname = new URL(style.href).pathname;
            if(pathname.startsWith("/static/")) {
                return;
            }

            if(!cssList.includes(pathname)) {
                style.parentNode?.removeChild(style);
            }
            else {
                cssList = cssList.filter(css => css !== pathname);
            }
        }
    );
    cssList.forEach(css => {
        const link = document.createElement("link");
        link.href = css;
        link.type = "text/css";
        link.rel = "stylesheet";
        document.getElementsByTagName("head")[0].appendChild(link);

        //console.log('добавлен стиль ' + css);
    })
}

interface ThemeSwitcherProps {
    theme?: ThemeStyles;
    useDefault?: boolean
}
const ThemeSwitcher: FC<PropsWithChildren<ThemeSwitcherProps>> = ({ children, theme }) => {

    useEffect(() => {

        if (!theme || (!theme?.css?.length && !theme.fonts?.length)) {
            return;
        }

        /*detachStylesheets();
        theme.css?.forEach(c => {
            insertStylesheet(c);
        });*/
        if(theme.css) {
            validateStyles(theme.css)
        }

        theme.fonts?.forEach(f => {
            insertFont(f);
        });
    }, [theme]);

    return (
        <>{children}</>
    )
}

const PathHandler: FC = () => {

    const { ymId } = usePanel();
    const history = useHistory();

    useEffect(() => history.listen(location => {
        if(ymId) {
            ym('hit', location.pathname);
        }
    }), [history, ymId]);

    return null;
}

const Routes: FC = () => {

    const { isAuthed, tokenLoaded } = useAuth();
    const roles = useAllRoles();
    const theme = useTheme();

    const isConsumer = useRole('consumer');

    const themedElems: ReactNode[] = useMemo(() => {

        let e: ReactNode[] = []

        if (theme?.routes) {
            theme.routes.forEach(route => {
                e.push(childRoutes(route, roles, isAuthed));
            })
        }

        return e;
    }, [isAuthed, roles, theme]);

    //useEffect(() => {
    //    console.log(JSON.stringify(infos));
    //}, []);
    return (
        <Router>
            <PathHandler />
            <ScrollToTop>
                <Suspense fallback={<DashboardLayout><LoadingPage color='primary' /></DashboardLayout>}>
                    {tokenLoaded &&
                        <Switch>
                            {isAuthed && <Redirect key="redtomain" from={theme?.urls.signin || "/auth/"} to="/" exact />}
                            {isAuthed && <Redirect key="redtomain" from={theme?.urls.signup || "/register/"} to="/" exact />}
                            {isAuthed && <Redirect key="redtomain" from={theme?.urls.forgot || "/forgot/"} to="/" exact />}
                            {isAuthed && (
                                <Route path='/logout'>
                                    <Logout />
                                </Route>
                            )}

                            {
                                isConsumer && (
                                    <Route path="/consumer/">
                                        <ThemeSwitcher theme={defaultTheme}>
                                            <ConsumerRoutes />
                                        </ThemeSwitcher>
                                    </Route>
                                )
                            }

                            <Route>
                                <ThemeSwitcher theme={theme?.theme}>
                                    <Switch>
                                        {themedElems}
                                        {!isAuthed && <Redirect key="redtoauth" to={theme?.urls.signin || "/auth/"} />}
                                        <Route>
                                            <GuestLayout>
                                                <Page404 /> {/* //TODO сделать нормальную страницу 404 */}
                                            </GuestLayout>
                                        </Route>
                                    </Switch>
                                </ThemeSwitcher>
                            </Route>
                        </Switch>}
                </Suspense>
            </ScrollToTop>
        </Router>
    );

}

export default Routes;
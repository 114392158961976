import { FC } from "react";
import { useGoalContext } from "./context";
import { Text } from '@constructor/components';

const GoalName: FC = (props) => {
    const goal = useGoalContext();

    if (!goal) {
        return null;
    }

    return (
        <Text text={goal.name} {...props} />
    )
}

export default GoalName;
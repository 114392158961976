import { ConstructionComponentInfo } from "@interfaces/constructor/constructor"
import DonateLogItemDate from "./donateLogItemDate"
import DonateLogItemIndex from "./donateLogItemIndex"
import DonateLogItemSum from "./donateLogItemSum"
import DonateLogs from "./donateLogs"
import DonateLogsList from "./donateLogsList"

const DonateLogsInfo: ConstructionComponentInfo = {
    component: DonateLogs,
    props: [
        'donate_logs_per_page'
    ],
    addedChildProps: [
        'donate_logs_loading',
        'donate_logs_count',
        'pagination_current_page',
        'pagination_total_pages',
        'pagination_per_page',
        'pagination_set_page',
        'pagination_next_page',
        'pagination_prev_page',
    ]
}

const DonateLogsListInfo: ConstructionComponentInfo = {
    component: DonateLogsList,
    props: [
        'pagination_current_page',
        'pagination_per_page'
    ],
    addedChildProps: [
        'donate_log_item_index'
    ],
    injectChild: [
        'pagination_current_page',
        'pagination_per_page'
    ]
}

const DonateLogItemSumInfo: ConstructionComponentInfo = {
    component: DonateLogItemSum,
    requiredParent: [
        'DonateLogsList'
    ]
}

const DonateLogItemDateInfo: ConstructionComponentInfo = {
    component: DonateLogItemDate,
    requiredParent: [
        'DonateLogsList'
    ],
    props: [
        'format'
    ]
}

const DonateLogItemIndexInfo: ConstructionComponentInfo = {
    component: DonateLogItemIndex,
    requiredParent: [
        'DonateLogsList'
    ],
    injectChild: [
        'donate_log_item_index'
    ]
}

export {
    DonateLogItemDateInfo,
    DonateLogItemIndexInfo,
    DonateLogItemSumInfo,
    DonateLogsInfo,
    DonateLogsListInfo
}
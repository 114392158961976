import {FC, PropsWithChildren, useState} from "react";
import Lightbox from "react-18-image-lightbox";
import 'react-18-image-lightbox/style.css';
import {useFileUrl} from "@hooks/filesHooks";

interface LightBoxProps {
    image?: string;
    fileId?: string;
}

const LightBox: FC<PropsWithChildren<LightBoxProps>> = ({image, fileId, children}) => {

    const [isOpen, setIsOpen] = useState(false);
    const src = useFileUrl(fileId, image);

    return (
        <>
            <div className="cursor-pointer" onClick={() => setIsOpen(true)}>
                {children}
            </div>
            {isOpen && (
                <Lightbox
                    mainSrc={src}
                    onCloseRequest={() => setIsOpen(false)}
                    reactModalStyle={{ overlay: { zIndex: 999999 } }}
                    onImageLoad={() => {
                        window.dispatchEvent(new Event('resize'));
                    }}
                />
            )}
        </>
    )
}

export default LightBox;
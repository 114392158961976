import { panelActions } from '@store/panelStore';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { auth } from '@services/auth';

const Logout: React.FC = () => {
    
    const dispatch = useDispatch();
    useEffect(() => {
        auth().signOut();
        dispatch(panelActions.clearVariables());
    }, [dispatch]);

    return (
        <Redirect to="/auth/" />
    )
}

export default Logout;
import {FC, useCallback, useState} from 'react';
import { Card } from 'react-bootstrap';
import {IUserSecurityLog, ESecurityLogType} from '@interfaces/consumer/users';
import {dateFormatter} from '@services/helpers';
import {useParams} from "react-router-dom";
import ConsumerUsersService from "@api/consumer/consumerUsersService";
import {PanelTableDataModel} from "@models/panel";
import {ColumnDef} from "@tanstack/react-table";
import {useRemoteTable} from "@hooks/tableHooks";
import {Flex} from "@components/falcon/common";
import {AdvanceTable, AdvanceTableFooter} from "@components/falcon/common/tables";
import AdvanceTableProvider from "@components/falcon/common/tables/context";

const columns: ColumnDef<IUserSecurityLog>[] = [
    {
        accessorKey: 'date',
        header: 'Дата',
        enableSorting: true,
        cell: ({row: {original: {date}}}) => dateFormatter(date),
        meta: {
            headerProps: { className: 'text-900' }
        }
    },
    {
        accessorKey: 'ip',
        header: 'IP',
        meta: {
            headerProps: { className: 'text-900' }
        }
    },
    {
        accessorKey: 'type',
        header: 'Действие',
        cell: ({row: {original: {type}}}) => {
            switch (type) {
                case ESecurityLogType.SignIn:
                    return 'Авторизация';
                case ESecurityLogType.SignUp:
                    return 'Регистрация';
                case ESecurityLogType.ChangePassword:
                    return 'Смена пароля';
                case ESecurityLogType.ResetPassword:
                    return 'Восстановление пароля';
                default:
                    return 'Неизвестно';
            }
        },
        meta: {
            headerProps: { className: 'text-900' }
        }
    }
]

const ConsumerUserSafety: FC = () => {

    const { id } = useParams<{ id: string }>();
    const [api] = useState(() => new ConsumerUsersService());
    const fetchApi = useCallback((model: PanelTableDataModel) => api.fetchSecurityLogs(id, model), [api, id]);

    const table = useRemoteTable({
        name: 'consumer.users.security.logs',
        columns,
        defaultState: {
            sorting: [
                {
                    id: 'date',
                    desc: true
                }
            ]
        }
    }, fetchApi);

    return (
        <AdvanceTableProvider table={table}>
            <Card className="mb-3">
                <Card.Header>
                    <Flex className='align-items-center justify-content-between'>
                        <Flex alignItems="center" className="fs-10">
                            <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">История активности</h5>
                        </Flex>
                        {/*<Flex>
                            <AdvanceTableSearchBox/>
                        </Flex>*/}
                    </Flex>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowInfo
                        rowsPerPageSelection
                        paginationButtons
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableProvider>
    )
}

export default ConsumerUserSafety;
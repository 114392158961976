import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";
import ConfirmEmail from "./confirmEmail";
import ConfirmEmailMessage from "./confirmEmailMessage";
import ForgotPasswordForm from "./forgotPasswordForm";
import ResetPasswordForm from "./resetPasswordForm";
import SignInForm from "./signInForm";
import SignUpForm from "./signUpForm";

const SignInFormInfo: ConstructionComponentInfo = {
    component: SignInForm
}

const SignUpFormInfo: ConstructionComponentInfo = {
    component: SignUpForm
}

const ResetPasswordFormInfo: ConstructionComponentInfo = {
    component: ResetPasswordForm
}

const ForgotPasswordFormInfo: ConstructionComponentInfo = {
    component: ForgotPasswordForm
}

const ConfirmEmailBlockInfo: ConstructionComponentInfo = {
    component: ConfirmEmail
}

const ConfirmEmailMessageInfo: ConstructionComponentInfo = {
    component: ConfirmEmailMessage,
    requiredParent: [ 'ConfirmEmail' ]
}

export {
    SignInFormInfo,
    SignUpFormInfo,
    ResetPasswordFormInfo,
    ForgotPasswordFormInfo,
    ConfirmEmailBlockInfo,
    ConfirmEmailMessageInfo
}
import {FC, useCallback, useState} from "react";
import ConsumerUsersService from "@api/consumer/consumerUsersService";
import {PanelTableDataModel} from "@models/panel";
import {useRemoteTable} from "@hooks/tableHooks";
import {ColumnDef} from "@tanstack/react-table";
import {Link} from "react-router-dom";
import {dateFormatter} from "@services/helpers";
import {ConsumerPaymentShort} from "@interfaces/consumer/payments";
import {Card} from "react-bootstrap";
import {Flex} from "@components/falcon/common";
import {AdvanceTable, AdvanceTableFooter} from "@components/falcon/common/tables";
import AdvanceTableProvider from "@components/falcon/common/tables/context";

interface PaymentLogsProps {
    userId: string;
}

const columns: ColumnDef<ConsumerPaymentShort>[] = [
    {
        accessorKey: 'orderId',
        header: '№',
        enableSorting: true,
        meta: {
            headerProps: { className: 'pe-1 text-900' },
            cellProps: { className: 'py-2' }
        },
        cell: ({row: {original: {orderId}}}) => (
            <Link to={`/consumer/payments/${orderId}/`}>
                <strong>{orderId}</strong>
            </Link>
        )
    },
    {
        accessorKey: 'finishTime',
        header: 'Дата',
        enableSorting: true,
        meta: {
            headerProps: { className: 'text-900' }
        },
        cell: ({row: {original: {finishTime}}}) => dateFormatter(finishTime),
    },
    {
        accessorKey: 'sum',
        header: 'Сумма',
        meta: {
            headerProps: { className: 'text-end text-900' },
            cellProps: {
                className: 'text-end fs-9 fw-medium py-2'
            }
        },
        cell: ({row: {original: {sum, currency}}}) => `${sum} ${currency}`,
        enableSorting: true,
    }
]

const PaymentLogs: FC<PaymentLogsProps> = ({userId}) => {

    const [api] = useState(() => new ConsumerUsersService());
    const fetchApi = useCallback((model: PanelTableDataModel) => api.fetchPaymentLogs(userId, model), [api, userId]);

    const table = useRemoteTable({
        name: 'consumer.user.payments.log',
        columns,
        defaultState: {
            pagination: {
                pageSize: 10,
                pageIndex: 0
            },
            sorting: [
                {
                    id: 'orderId',
                    desc: true
                }
            ]
        }
    }, fetchApi);

    return (
        <AdvanceTableProvider table={table}>
            <Card className="mb-3">
                <Card.Header>
                    <Flex className='align-items-center justify-content-between'>
                        <Flex alignItems="center" className="fs-10">
                            <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">Другие платежи пользователя</h5>
                        </Flex>
                    </Flex>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        paginationButtons
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableProvider>
    )
}

export default PaymentLogs;
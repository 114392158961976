import { FC } from "react";
import { useGameServer, useLoginServer } from "@hooks/selectors/game.selectors";
import { useQueryContext } from "./context";
import { Text } from '@components/constructor/components';

const QueryServerName: FC = (props) => {

    const query = useQueryContext();
    const gs = useGameServer(query?.serverId);
    const ls = useLoginServer(query?.serverId);

    if (!gs?.name && !ls?.name) {
        return null;
    }

    return (
        <Text text={gs?.name || ls?.name || ''} {...props} />
    )
}

export default QueryServerName;
import { FC } from "react";
import { useItemContext } from "./context";
import { Text } from '@constructor/components';

const ItemName: FC = (props) => {
    const item = useItemContext();

    if (!item) {
        return null;
    }

    return (
        <Text text={item.name} {...props} />
    )
}

export default ItemName;
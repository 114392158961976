
import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";
import Slider from "./slider";
import Slides from "./slides";

const SliderInfo: ConstructionComponentInfo = {
    component: Slider,
    addedChildProps: [
        'slider_next',
        'slider_prev',
        'slider_pause',
        'slider_play',
        'slider_ref'
    ]
}

const SlidesInfo: ConstructionComponentInfo = {
    component: Slides,
    requiredParent: [
        'Slider'
    ],
    injectChild: [
        'slider_ref'
    ]
}

export {
    SliderInfo,
    SlidesInfo
}
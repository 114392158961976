import { useLoginServerAuto } from "@hooks/selectors/game.selectors";

const LoginServerChargeRate: React.FC = () => {
    
    const loginServer = useLoginServerAuto();

    return (
        <>
            {loginServer && loginServer.chargePower}
        </>
    )
}

export default LoginServerChargeRate;
import {FC, useEffect} from "react";
import {useSupportTopicContext} from "@components/consumer/support/context";
import classNames from "classnames";
import SimpleBarReact from 'simplebar-react';
import {Nav} from "react-bootstrap";
import {useSortedTopics} from "@hooks/selectors/consumer/support.selectors";
import {ChatThread} from ".";
import {usePromisedDispatch} from "@hooks/helperHooks";
import {consumerActions} from "@store/consumer";

const ChatSidebar: FC = () => {

    const dispatch = usePromisedDispatch();
    
    const {
        sidebar: {
            hideSidebar
        }
    } = useSupportTopicContext();

    const topics = useSortedTopics();

    useEffect(() => {
        dispatch(consumerActions.support.loadTopics(0, false));
    }, [dispatch]);

    return (
        <div className={classNames('chat-sidebar', { 'start-0': hideSidebar })}>
            <div className="contacts-list">
                <SimpleBarReact style={{ height: '100%', minWidth: '65px' }}>
                    <Nav className="border-0">
                        {topics.map(topic => (
                            <ChatThread key={topic.id} topic={topic} />
                        ))}
                    </Nav>
                </SimpleBarReact>
            </div>
        </div>
    )
}

export default ChatSidebar;
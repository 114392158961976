import { FC } from "react";
import { useRefStatContext } from "./context";
import { Text } from '@constructor/components';
import { fromUtcDate } from "@services/helpers";

interface RefStatReceiveTimeProps {
    format?: string;
}

const RefStatReceiveTime: FC<RefStatReceiveTimeProps> = ({ format = 'lll', ...props }) => {

    const stat = useRefStatContext();
    if (!stat?.receiveTime) {
        return null;
    }

    return (
        <Text text={fromUtcDate(stat.receiveTime).format(format)} {...props} />
    )
}

export default RefStatReceiveTime;
import { AddRefTargetForm } from "@components/consumer/refs";
import withPage from "@components/hoc/withPage";
import {FC, useCallback} from "react";
import { Card, CardHeader, CardTitle } from "reactstrap";
import {useParams} from "react-router-dom";
import ConsumerRefService from "@api/consumer/consumerRefService";
import {useBreadcrumbs} from "@services/hooks";
import {useStateLoader} from "@hooks/panelHooks";

const AddRefTargetPage: FC = () => {

    const { ref_id } = useParams<{ ref_id: string }>();
    const loadRef = useCallback((ref_id: string) => {
        const api = new ConsumerRefService();
        return api.getRef(ref_id);
    }, []);

    const { data: ref } = useStateLoader(loadRef, ref_id);
    useBreadcrumbs('consumer.refs.current.name', true, ref?.name, `/consumer/refs/${ref_id}/`);
    useBreadcrumbs('consumer.refs.targets', true, null, `/consumer/refs/${ref_id}/targets/`);

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h5">
                    Создание цели реферального бонуса
                </CardTitle>
            </CardHeader>
            <AddRefTargetForm />
        </Card>
    )
}

export default withPage(AddRefTargetPage);
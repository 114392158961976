import { ConstructionComponentInfo } from "@interfaces/constructor/constructor"
import DonateForm from "./donateForm"
import DonateRateGames from "./donateRateGames"
import DonateRateLogins from "./donateRateLogins"

const DonateFormInfo: ConstructionComponentInfo = {
    component: DonateForm
}

const DonateRateLoginsInfo: ConstructionComponentInfo = {
    component: DonateRateLogins
}

const DonateRateGamesInfo: ConstructionComponentInfo = {
    component: DonateRateGames
}

export {
    DonateFormInfo,
    DonateRateGamesInfo,
    DonateRateLoginsInfo
}
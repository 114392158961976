import IService from "@api/iService";
import { CustomQuery, IQueryResult } from "@interfaces/query";
import { CustomQueryModel } from "@models/query/queries";

export default class QueryService extends IService {

    async executeCustomQuery(query: CustomQueryModel) {
        return await this.postApi<IQueryResult>('/query/', query);
    }
    
    async fetchQueries(): Promise<CustomQuery[]> {
        return await this.getAndThrowApi<CustomQuery[]>('/query/');
    }
}
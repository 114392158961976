import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import {Avatar} from "@components/falcon/common";
import {useAvatarData, usePublicName, useUserName} from "@hooks/selectors/user.selectors";

const ProfileDropdown = () => {

    const { avatar, avatarId } = useAvatarData();
    const publicName = usePublicName();
    const userName = useUserName();

    return (
        <Dropdown navbar={true} as="li">
            <Dropdown.Toggle
                bsPrefix="toggle"
                as={Link}
                to="#!"
                className="pe-0 ps-2 nav-link"
            >
                <Avatar src={avatar} fileId={avatarId} name={publicName || userName} rounded="circle"/>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
                <div className="bg-white rounded-2 py-2 dark__bg-1000">
                    <Dropdown.Item as={Link} to='/settings/'>
                        Настройки
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to='/logout/'>
                        Выход
                    </Dropdown.Item>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default ProfileDropdown;
import {FC, PropsWithChildren, useMemo} from "react";
import {useSupportMessageContext} from "@constructor/components/features/support/context";
import {AttachmentState, AttachmentType} from "@interfaces/user/support";
import {injectChildArray} from "@constructor/functions";

interface MessageAttachmentsListChildProps {
    attachment_id: string;
    attachment_file_id: string;
}

const MessageAttachmentsList: FC<PropsWithChildren> = ({children}) => {

    const message = useSupportMessageContext();

    const data: MessageAttachmentsListChildProps[] = useMemo(() => message?.attachments?.filter(attachment => 
        attachment.type === AttachmentType.Image && attachment.state === AttachmentState.Uploaded)
        .map(attachment => ({
            attachment_id: attachment.id,
            attachment_file_id: attachment.fileId
    })), [message?.attachments]) || [];

    if (data.length === 0) {
        return null;
    }

    return injectChildArray(data, children, 'attachment_id');
}

export default MessageAttachmentsList;
import {FC} from "react";
import {Card, Tab} from "react-bootstrap";
import {useSortedTopics} from "@hooks/selectors/consumer/support.selectors";
import {useParams} from "react-router-dom";
import {Flex} from "@components/falcon/common";
import {ChatContent} from "./content";
import {useSupportTopicContext} from "@components/consumer/support/context";
import ChatProvider from "@components/consumer/support/chatProvider";
import {ChatSidebar} from "./sidebar";

const ChatTabs: FC = () => {

    const { id } = useParams<{id: string}>();
    const tasks = useSortedTopics();

    const {
        setCurrentTopic,
        sidebar: {
            setHideSidebar,
        },
        topicInfo: {
            setThreadInfoOpened
        },
        scroll: {
            setScrollToBottom
        }
    } = useSupportTopicContext();

    const handleSelect = (key: string | null) => {
        setHideSidebar(false);
        setThreadInfoOpened(false);
        const topic = tasks.find(task => task.id === key);
        setCurrentTopic(topic);
        setScrollToBottom(true);
    }

    return (
        <Tab.Container
            id="chat-tabs"
            defaultActiveKey={id}
            onSelect={handleSelect}
        >
            <Card className="card-chat overflow-hidden">
                <Card.Body as={Flex} className="p-0 h-100">
                    <ChatSidebar />
                    <ChatContent />
                </Card.Body>
            </Card>
        </Tab.Container>
    )
}

const Chat: FC = () => (
    <ChatProvider>
        <ChatTabs />
    </ChatProvider>
)

export default Chat;
import { injectContextArray } from "@components/constructor";
import { PaginationProps } from "@constructor/components/core/pagination";
import { useDonateBonuses } from "@hooks/selectors/user/payments.selectors";
import { IDonateBonus } from "@interfaces/user/payments";
import {FC, PropsWithChildren, useMemo} from "react";
import { DonateBonusProvider } from "./context";
import {InjectContextType} from "@interfaces/constructor/constructor";

interface DonateBonusListChildProps {
    donate_bonus_index: number;
}

const DonateBonusList: FC<PropsWithChildren<PaginationProps>> = ({ pagination_current_page = 0, pagination_per_page = 10, children }) => {

    const bonuses = useDonateBonuses();
    const page = bonuses.slice(pagination_current_page * pagination_per_page, (pagination_current_page + 1) * pagination_per_page);

    const data = useMemo<InjectContextType<IDonateBonus, DonateBonusListChildProps>[]>(() => page.map((bonus, idx) => {
        return {
            key: bonus.id,
            value: bonus,
            childData: {
                donate_bonus_index: pagination_current_page * pagination_per_page + 1 + idx
            }
        }
    }), [page, pagination_current_page, pagination_per_page]);

    return injectContextArray(data, DonateBonusProvider, children);
}

export default DonateBonusList;
import ConsumerRefService from "@api/consumer/consumerRefService";
import { IBalanceRefReward, IItemRefReward, IPercentRefReward, IRefReward, RefRewardType } from "@interfaces/consumer/refs";
import { PatchRefRewardModel } from "@models/consumer/refs";
import { FormikHelpers } from "formik";
import { FC, useCallback } from "react";
import { toastr } from "react-redux-toastr";
import { CardBody } from "reactstrap";
import RefRewardForm, { RefRewardFormValues } from "./refRewardForm";

interface EditRefRewardFormProps {
    reward: IRefReward;
}

const EditRefRewardForm: FC<EditRefRewardFormProps> = ({reward}) => {

    const initialValues: RefRewardFormValues = {
        name: reward.name,
        order: reward.order,
        isActive: reward.isActive,
        type: reward.type,

        balance: (reward as IBalanceRefReward).balance || 0,

        itemId: (reward as IItemRefReward).itemId || '',
        count: (reward as IItemRefReward).count || 0,

        percent: (reward as IPercentRefReward).percent || 0,
        minSum: (reward as IPercentRefReward).minSum || 0,
        maxSum: (reward as IPercentRefReward).maxSum || 0
    }

    const onSubmit = useCallback(async (values: RefRewardFormValues, actions: FormikHelpers<RefRewardFormValues>) => {
        const api = new ConsumerRefService();

        const model: PatchRefRewardModel = {
            id: reward.id,
            name: values.name,
            isActive: values.isActive,
            order: values.order
        }
        //Чтоб не гонять мусор
        if(values.type === RefRewardType.Balance) {
            model.balance = values.balance
        }
        else if(values.type === RefRewardType.Item) {
            model.itemId = values.itemId;
            model.count = values.count;
        }
        else {
            model.percent = values.percent;
            model.minSum = values.minSum;
            model.maxSum = values.maxSum;
        }

        const result = await api.patchRefReward(reward.refId, model);

        if (result.success) {
            toastr.success("Изменение реферального бонуса", "Награда реферального бонуса успешно добавлена");
        }
        else {
            if (result.errorCode === 'RefNotFound') {
                toastr.error('Изменение реферального бонуса', 'Бонус не найден');
            }
            else if (result.errorCode === 'RewardNotFound') {
                toastr.error('Изменение реферального бонуса', 'Награда не найдена');
            }
            else if (result.errorCode === 'InvalidRequest') {
                toastr.error('Изменение реферального бонуса', 'Некорректные параметры награды');
            }
            else if (result.errorCode === 'DisabledItem') {
                actions.setFieldError('itemId', 'Предмет отключен');
            }
            else if (result.errorCode === 'InvalidType') {
                toastr.error('Изменение реферального бонуса', 'Некорректный тип награды');
            }
            else {
                toastr.error('Изменение реферального бонуса', 'Неизвестная ошибка');
            }
        }
    }, [reward.id, reward.refId]);

    return (
        <CardBody>
            <RefRewardForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                editForm />
        </CardBody>
    )
}

export default EditRefRewardForm;
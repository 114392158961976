import { injectChild } from "@components/constructor";
import { useNotifications } from "@hooks/selectors/user.selectors";
import {FC, PropsWithChildren, useMemo} from "react";

interface NotificationsChildProps {
    notifications_count: number;
    notifications_unreaded_count: number;
    notifications_has_unreaded: boolean;
}

const Notifications: FC<PropsWithChildren> = ({ children }) => {

    const notifications = useNotifications();

    const data = useMemo<NotificationsChildProps>(() => {

        const unreaded = notifications.filter(n => !n.isReaded).length;

        return {
            notifications_count: notifications.length,
            notifications_unreaded_count: unreaded,
            notifications_has_unreaded: unreaded > 0
        }
    }, [notifications]);

    return injectChild(data, children);
}

export default Notifications;
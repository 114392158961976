import {FC, useCallback, useState} from "react";
import {Card, Col, Row} from "react-bootstrap";
import CountUp from 'react-countup';
import ConsumerService from "@api/consumerService";
import {useStateLoader} from "@hooks/panelHooks";
import {HalfDoughnutChart} from "@components/falcon/common";

const SignUpsWidget: FC = () => {

    const [api] = useState(() => new ConsumerService());
    const loadOnline = useCallback(() => api.fetchSignUpsWidget(), [api]);

    const {
        data
    } = useStateLoader(loadOnline);

    const confirmed = data ? data.confirmed : 0;
    const notConfirmed = data ? (data.total - data.confirmed) : 0;
    const confirmPercents = data?.total ? Math.round((confirmed / data.total) * 100) : 0;

    const items = [
        {
            label: 'Подтверждено',
            value: confirmed
        },
        {
            label: 'Не подтверждено',
            value: notConfirmed
        }
    ];

    return (
        <Card className="h-md-100">
            <Card.Header className="pb-0">
                <h6 className="mb-0">Регистрации</h6>
            </Card.Header>
            <Card.Body className="pt-0">
                <Row className="align-items-end h-100 mb-n1 mt-n3">
                    <Col xs={5} className="pe-0 pe-lg-3">
                        <Row className="g-0">
                            <Col xs={7}>
                                <h6 className="text-600">Подтверждено: </h6>
                            </Col>
                            <Col xs={5}>
                                <h6 className="text-800">{data?.confirmed} </h6>
                            </Col>
                        </Row>
                        <Row className="g-0">
                            <Col xs={7}>
                                <h6 className="mb-0 text-600">Зарегистрировано: </h6>
                            </Col>
                            <Col xs={5}>
                                <h6 className="mb-0 text-800">{data?.total} </h6>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={7}>
                        <div
                            className="ms-auto text-center"
                            style={{ maxWidth: '10.625rem' }}
                        >
                            <div className="mb-n6">
                                <HalfDoughnutChart
                                    color="primary"
                                    items={items}
                                />
                            </div>
                            <CountUp
                                start={0}
                                end={confirmPercents}
                                duration={2.75}
                                className="d-inline-block mb-0 mt-n6 fs-8 fw-medium font-sans-serif"
                                suffix="%"
                                separator=","
                            />
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default SignUpsWidget;
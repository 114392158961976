import {FC} from "react";
import {cPaymentsActions} from "@store/consumer/consumerPaymentsStore";
import {toastr} from "react-redux-toastr";
import {EditBonusFormValues} from "@components/consumer/payments/bonuses/editBonusForm";
import {usePromisedDispatch} from "@hooks/helperHooks";
import {useHistory} from "react-router-dom";
import {EditBonusForm} from "@components/consumer/payments/bonuses";
import {EModifyType, EPaymentBonusType, EThresholdType} from "@interfaces/user/payments";
import withPage from "@components/hoc/withPage";

const CreatePaymentBonusPage: FC = () => {

    const history = useHistory();
    const dispatch = usePromisedDispatch();

    const onSubmit = async (values: EditBonusFormValues) => {
        const result = await dispatch(cPaymentsActions.createBonus(values));

        if(result.success) {
            toastr.success('Бонусы за пополнение', 'Бонус успешно создан');
            history.replace(`/consumer/payments/bonuses/${result.data.id}/`);
        }
        else {
            toastr.error('Бонусы за пополнение', 'Что-то пошло не так');
        }
    }

    const initial: EditBonusFormValues = {
        name:'',
        isActive: false,
        value: 0,
        type: EPaymentBonusType.Fixed,
        threshold: 1000,
        thresholdType: EThresholdType.OneTime,
        daysOfWeek: [],
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        aggregators: [],
        hidden: false,
        modifyType: EModifyType.Add
    }

    return (
        <EditBonusForm
            title={`Создание нового бонуса`}
            bonus={initial}
            onSubmit={onSubmit}
        />
    )
}

export default withPage(CreatePaymentBonusPage);
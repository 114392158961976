import { Loadable } from '@interfaces/helpers';
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { IUserTaskMessage } from '@interfaces/user/support';
import {PatchUserTaskMessageModel, UserTaskMessageModel} from '@models/user/support';
import SupportService from '@api/user/supportService';

interface TaskMessages {
    taskId: string;
    messages: IUserTaskMessage[];
}

interface TaskMessage {
    taskId: string;
    message: IUserTaskMessage;
}

const slice = createSlice({
    initialState: {
        loaded: false,
        loading: false,
        error: null,
        data: []
    } as Loadable<IUserTaskMessage[]>,
    name: 'userTaskMessagesStore',
    reducers: {
        setLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        setMessages: (state, action: PayloadAction<TaskMessages>) => {
            const { taskId, messages } = action.payload;
            state.data = state.data.filter(m => m.task !== taskId).concat(messages);
            state.loading = false;
            state.error = null;
        },
        setMessage: (state, action: PayloadAction<TaskMessage>) => {
            const { taskId, message } = action.payload;
            const idx = state.data.findIndex(m => m.task === taskId && m.id === message.id);
            if(idx !== -1) {
                state.data[idx] = message;
            }
            else {
                state.data.push(message);
            }
        },
        deleteMessage: (state, action: PayloadAction<string>) => {
            state.data = state.data.filter(m => m.id !== action.payload);
        }
    }
})

export const { reducer: userTaskMessagesReducer } = slice;

const {
    setError,
    setLoading,
    setMessages,
    setMessage,
    deleteMessage
} = slice.actions;

export const loadTaskMessages = (taskId: string) => async (dispatch: Dispatch) => {
    dispatch(setLoading());
    try {
        const api = new SupportService();
        const messages = await api.fetchUserTaskMessages(taskId);
        dispatch(setMessages({
            taskId,
            messages
        }));
        return messages;
    }
    catch(e) {
        dispatch(setError(e));
    }
}

export const addTaskMessage = (message: UserTaskMessageModel) => async (dispatch: Dispatch) => {
    const api = new SupportService();
    const result = await api.putUserTaskMessage(message);
    if(result.success) {
        dispatch(setMessage({
            taskId: result.data.task,
            message: result.data
        }));
    }
    return result;
}

export const editTaskMessage = (taskId: string, messageId: string, message: PatchUserTaskMessageModel) => async (dispatch: Dispatch) => {
    const api = new SupportService();
    const result = await api.patchUserTaskMessage(taskId, messageId, message);
    if(result.success) {
        dispatch(setMessage({
            taskId: result.data.task,
            message: result.data
        }));
    }
    return result;
}

export const deleteTaskMessage = (taskId: string, messageId: string) => async (dispatch: Dispatch) => {
    const api = new SupportService();
    const result = await api.deleteUserTaskMessage(taskId, messageId);
    if(result.success) {
        dispatch(deleteMessage(messageId));
    }
    return result;
}
import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";
import BonusCodeBuyForm from "./bonusCodeBuyForm";
import PersonalBonusCode from "./personalBonusCode";
import PersonalBonusCodes from "./personalBonusCodes";
import PersonalBonusCodesList from "./personalBonusCodesList";
import PersonalBonusCodeUseTime from "./personalBonusCodeUseTime";
import PersonalBonusCodeValue from "./personalBonusCodeValue";

const BonusCodeBuyFormInfo: ConstructionComponentInfo = {
    component: BonusCodeBuyForm
}

const PersonalBonusCodeInfo: ConstructionComponentInfo = {
    component: PersonalBonusCode
}

const PersonalBonusCodesInfo: ConstructionComponentInfo = {
    component: PersonalBonusCodes,
    props: [
        'personal_codes_per_page'
    ],
    addedChildProps: [
        'pagination_current_page',
        'pagination_total_pages',
        'pagination_per_page',
        'pagination_set_page',
        'pagination_next_page',
        'pagination_prev_page',
        'personal_codes_count',
        'personal_codes_loading'
    ]
}

const PersonalBonusCodesListInfo: ConstructionComponentInfo = {
    component: PersonalBonusCodesList,
    props: [
        'pagination_per_page'
    ],
    addedChildProps: [
        'personal_codes_index',
        'personal_code_used'
    ],
    injectChild: [
        'pagination_current_page',
        'pagination_per_page'
    ]
}

const PersonalBonusCodeUseTimeInfo: ConstructionComponentInfo = {
    component: PersonalBonusCodeUseTime,
    props: [
        'format',
        'default_value'
    ],
}

const PersonalBonusCodeValueInfo: ConstructionComponentInfo = {
    component: PersonalBonusCodeValue
}

export {
    BonusCodeBuyFormInfo,
    PersonalBonusCodeInfo,
    PersonalBonusCodesInfo,
    PersonalBonusCodesListInfo,
    PersonalBonusCodeUseTimeInfo,
    PersonalBonusCodeValueInfo
}
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { ConsumerSupportStore } from "@interfaces/consumer/store";
import { ConsumerTicket, ConsumerTicketMessage } from "@interfaces/consumer/support";
import {PatchMessageModel, PutMessageModel} from "@models/consumer/support";
import CSupportService from "@api/consumer/CSupportService";
import FetchResult from "@models/fetchResult";

interface TopicMessages {
    topicId: string;
    messages: ConsumerTicketMessage[];
}

const slice = createSlice({
    initialState: {
        topics: {
            loaded: false,
            loading: false,
            error: null,
            data: []
        },
        messages: {
            loaded: false,
            loading: false,
            error: null,
            data: []
        }
    } as ConsumerSupportStore,
    name: 'consumerSupportStore',
    reducers: {
        setTopicsLoading: (state) => {
            state.topics = {
                ...state.topics,
                loading: true,
                error: null
            }
        },
        setTopicsError: (state, action) => {
            state.topics = {
                ...state.topics,
                error: action.payload,
                loading: false
            }
        },
        setTopics: (state, action: PayloadAction<ConsumerTicket[]>) => {
            state.topics = {
                loaded: true,
                loading: false,
                error: null,
                data: action.payload
            }
        },
        setTopic: (state, action: PayloadAction<ConsumerTicket>) => {
            const topic = action.payload;
            state.topics = {
                loaded: true,
                loading: false,
                error: null,
                data: state.topics.data.filter(t => t.id !== topic.id).concat(topic)
            }
        },
        removeTopic: (state, action: PayloadAction<string>) => {
            state.topics = {
                ...state.topics,
                data: state.topics.data.filter(t => t.id !== action.payload)
            }
        },

        setMessagesLoading: (state) => {
            state.messages = {
                ...state.messages,
                loading: true,
                error: null
            }
        },
        setMessagesError: (state, action) => {
            state.messages = {
                ...state.messages,
                error: action.payload,
                loading: false
            }
        },
        setMessages: (state, {payload: {topicId, messages}}: PayloadAction<TopicMessages>) => {
            state.messages = {
                loaded: true,
                loading: false,
                error: null,
                data: state.messages.data
                    .filter(m => m.task !== topicId)
                    .concat(messages)
            }
        },
        setMessage: (state, {payload: message}: PayloadAction<ConsumerTicketMessage>) => {
            state.messages = {
                ...state.messages,
                data: state.messages.data.filter(m => m.id !== message.id).concat(message)
            }
        },
        clearMessages: (state) => {
            state.messages = {
                loaded: false,
                loading: false,
                error: null,
                data: []
            }
        },
        deleteMessage: (state, {payload: messageId}: PayloadAction<string>) => {
            state.messages = {
                ...state.messages,
                data: state.messages.data.filter(m => m.id !== messageId)
            }
        },
        deleteMessages: (state, {payload: topicId}: PayloadAction<string>) => {
            state.messages = {
                ...state.messages,
                data: state.messages.data.filter(m => m.task !== topicId)
            }
        }
    }
})

export const { reducer: consumerSupportReducer } = slice;

const {
    setMessage,
    setMessages,
    deleteMessage,
    deleteMessages,
    setMessagesLoading,
    setMessagesError,
    clearMessages,

    setTopic,
    setTopics,
    removeTopic,
    setTopicsError,
    setTopicsLoading
} = slice.actions;

export const cSupportActions = {
    clearMessages,
    removeMessage: deleteMessage,
    removeMessages: deleteMessages,
    patchMessage: (topicId: string, messageId: string, message: PatchMessageModel) => async (dispatch: Dispatch) => {
        try {
            const api = new CSupportService();
            const result = await api.patchMessage(topicId, messageId, message);
            if(result.success) {
                dispatch(setMessage(result.data));
            }

            return result;
        }
        catch (e) {
            return e as FetchResult<ConsumerTicketMessage>;
        }
    },
    putMessage: (topicId: string, message: PutMessageModel) => async (dispatch: Dispatch) => {
        try {
            const api = new CSupportService();
            const result = await api.putMessage(topicId, message);
            if(result.success) {
                dispatch(setMessage(result.data));
            }

            return result;
        }
        catch (e) {
            return e as FetchResult<ConsumerTicketMessage>;
        }
    },
    loadMessage: (topicId: string, messageId: string) => async (dispatch: Dispatch) => {
        try {
            const api = new CSupportService();
            const result = await api.getMessage(topicId, messageId);
            if(result.success) {
                dispatch(setMessage(result.data));
            }
            else if(result.errorCode === "MessageNotFound") {
                dispatch(deleteMessage(messageId));
            }

            return result;
        }
        catch (e) {
            return e as FetchResult<ConsumerTicketMessage>;
        }
    },
    loadMessages: (topicId: string, silent: boolean = false) => async (dispatch: Dispatch) => {

        if(!silent) {
            dispatch(setMessagesLoading());
        }

        try {
            const api = new CSupportService();
            const result = await api.getMessages(topicId);
            if(result.success) {
                dispatch(setMessages({
                    topicId,
                    messages: result.data
                }));
            }
            else {
                dispatch(setMessagesError(result));
            }
            return result;
        }
        catch(e) {
            dispatch(setMessagesError(e));
            return e as FetchResult<ConsumerTicketMessage[]>;
        }
    },
    deleteMessage: (topicId: string, messageId: string) => async (dispatch: Dispatch) => {
        try {
            const api = new CSupportService();
            const result = await api.deleteMessage(topicId, messageId);
            if(result.success) {
                dispatch(deleteMessage(messageId));
            }

            return result;
        }
        catch (e) {
            return e as FetchResult<any>;
        }
    },

    /*
     * Удалить топик из стора
     */
    removeTopic,
    loadTopic: (topicId: string, silent: boolean = false) => async (dispatch: Dispatch) => {
        if(!silent) {
            dispatch(setTopicsLoading());
        }

        try {
            const api = new CSupportService();
            const result = await api.getTopic(topicId);
            if(result.success) {
                dispatch(setTopic(result.data));
            }
            else {
                dispatch(setTopicsError(result));
            }

            return result;
        }
        catch (e) {
            dispatch(setTopicsError(e));
            return e as FetchResult<ConsumerTicket>;
        }
    },
    loadTopics: (page: number, closed: boolean) => async (dispatch: Dispatch) => {
        dispatch(setTopicsLoading());
        try {
            const api = new CSupportService();
            const result = await api.getTopicsList(page, closed);
            if(result.success) {
                dispatch(setTopics(result.data));
            }
            else {
                dispatch(setTopicsError(result));
            }
            return result;
        }
        catch(e) {
            dispatch(setTopicsError(e));
            return e as FetchResult<ConsumerTicket[]>;
        }
    },
    changeTopicStatus: (topicId: string, isClosed: boolean) => async (dispatch: Dispatch) => {
        try {
            const api = new CSupportService();
            const result = await api.changeTopicStatus(topicId, isClosed);
            if(result.success) {
                dispatch(setTopic(result.data));
            }

            return result;
        }
        catch (e) {
            return e as FetchResult<ConsumerTicket>;
        }
    },
    /*
     * Удалить топик из базы
     */
    deleteTopic: (topicId: string) => async (dispatch: Dispatch) => {
        try {
            const api = new CSupportService();
            const result = await api.deleteTopic(topicId);
            if(result.ok) {
                dispatch(removeTopic(topicId));
                dispatch(deleteMessages(topicId));
            }

            return result;
        }
        catch (e) {
            return e as FetchResult<any>;
        }
    }
}
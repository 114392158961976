import {useCallback, useEffect, useMemo, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {ReduxStore} from "@interfaces/store";
import {PanelTableState} from "@interfaces/panel";
import FetchResult from "@models/fetchResult";
import {panelActions} from '@store/panelStore';
import {ThemeGoals, ThemeUrls} from "@interfaces/panel/themes";

export function useBreadcrumbs(name: string, clear: boolean, text?: string | null, link?: string) {

    const dispatch = useDispatch();

    useEffect(() => {
        if (text || link) {
            dispatch(panelActions.setBreadcrumb({
                code: name,
                name: text,
                link
            }));
        }

        if (clear) {
            return () => {
                dispatch(panelActions.deleteBreadcrumb(name));
            }
        }
    }, [clear, dispatch, link, name, text]);
}

export function useTableState(table: string, defaultState: Partial<PanelTableState> = {}): PanelTableState {

    const state = useSelector(({ panel }: ReduxStore) => panel.tableStates[table], shallowEqual);
    return state || {
        page: 0,
        sizePerPage: 20,
        ...defaultState
    } as PanelTableState;
}

export function useStateLoader<T>(loadState: (...params: any) => Promise<FetchResult<T>>, ...params: any[]) {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<T>();
    const [error, setError] = useState<FetchResult<T>>();

    const reload = useCallback(() => {
        setLoading(true);
    }, [setLoading]);

    useEffect(() => {

        const load = async () => {

            setLoading(true);
            const result = await loadState(...params);

            if (result.ok) {
                setData(result.result);
            }
            else {
                setError(result);
            }
            setLoading(false);
        }

        //if (loading) {
            load();
        //}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadState, /*loading,*/ ...params]);

    return useMemo(() => {
        return {
            loading,
            data,
            reload,
            error
        }
    }, [data, loading, reload, error]);
}

interface ModalState {
    isOpen: boolean;
    toggleModal: () => void;
    closeModal: () => void;
    openModal: () => void;
    fadeIn: boolean;
    fadeOut: boolean;
    unmounted: boolean;
}

interface ModalCfg {
    fadeInTime?: number;
    fadeOutTime?: number;
    clearFadeIn?: boolean;
    closeOnFadeIn?: boolean;
    openOnFadeOut?: boolean;
    unmount?: boolean;
}

export function useModal(name: string, cfg: ModalCfg = {}): ModalState {
    const dispatch = useDispatch();
    const storedState = useSelector(({ panel }: ReduxStore) => panel.modalStates[name]);

    const [fadeIn, setFadeIn] = useState(false);
    const [fadeOut, setFadeOut] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {

        setIsOpen(!!storedState);

        if (storedState === true) {
            setFadeOut(false);
        }
        else if (storedState === false) {
            setFadeIn(false);
        }

        if (storedState === true && cfg.fadeInTime) {
            setFadeIn(true);

            if (cfg.clearFadeIn) {
                const clearFadeIn = () => {
                    setFadeIn(false);
                }

                const timeOut = setTimeout(clearFadeIn, cfg.fadeInTime)
                return () => {
                    clearTimeout(timeOut);
                    clearFadeIn();
                }
            }
        }
        else if (storedState === false && cfg.fadeOutTime) {
            setFadeOut(true);

            const clearFadeOut = () => {
                setFadeOut(false);
            }

            const timeOut = setTimeout(clearFadeOut, cfg.fadeOutTime);
            return () => {
                clearTimeout(timeOut);
                setFadeOut(false);
            }
        }



    }, [cfg.clearFadeIn, cfg.fadeInTime, cfg.fadeOutTime, cfg.openOnFadeOut, name, storedState]);

    const toggleModal = useCallback(() => {
        dispatch(panelActions.toggleModalState(name));
    }, [dispatch, name]);

    const closeModal = useCallback(() => {
        dispatch(panelActions.setModalState({
            id: name,
            state: false
        }));
    }, [dispatch, name]);

    const openModal = useCallback(() => {
        dispatch(panelActions.setModalState({
            id: name,
            state: true
        }));
    }, [dispatch, name]);

    return useMemo<ModalState>(() => {
        return {
            isOpen: (cfg.closeOnFadeIn && fadeIn) ? false : (isOpen || Boolean(cfg.openOnFadeOut && fadeOut)),
            toggleModal,
            closeModal,
            openModal,
            fadeIn,
            fadeOut,
            unmounted: Boolean(cfg.unmount && !isOpen && !fadeOut)
        }
    }, [cfg.closeOnFadeIn, cfg.openOnFadeOut, cfg.unmount, closeModal, fadeIn, fadeOut, isOpen, openModal, toggleModal]);
}

export function useSimpleModal() {
    const [isOpened, setOpened] = useState(false);

    const toggleModal = useCallback(() => setOpened(!isOpened), [isOpened]);
    const openModal = useCallback(() => setOpened(true), []);
    const closeModal = useCallback(() => setOpened(false), []);

    return {
        isOpened,
        setOpened,
        toggleModal,
        closeModal,
        openModal
    }
}

export function useTheme() {
    return useSelector(({ panel }: ReduxStore) => panel.theme.data);
}

export function useThemeUrl(url: keyof ThemeUrls) {
    return useSelector(({ panel: { theme: { data } } }: ReduxStore) => data && data.urls[url]);
}

export function useThemeUrls() {
    return useSelector(({ panel: { theme: { data } } }: ReduxStore) => data?.urls);
}

export function useThemeGoal(goal: keyof ThemeGoals) {
    return useSelector(({ panel: { theme: { data } } }: ReduxStore) => data && data.goals && data.goals[goal]);
}

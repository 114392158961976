import { FC } from "react";
import { useGoalRewardContext } from "./context";
import { Text } from '@constructor/components';

const GoalRewardName: FC = (props) => {
    const reward = useGoalRewardContext();
    if (!reward) {
        return null;
    }

    return (
        <Text text={reward.name} {...props} />
    )
}

export default GoalRewardName;
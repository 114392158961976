import { useLoginServers } from "@hooks/selectors/game.selectors";
import classNames from "classnames";
import { Field, useFormikContext } from "formik";
import { FC, useCallback } from "react";
import { SelectorsFormsValues } from ".";
import { FormFieldProps } from "..";


const LoginServerSelector: FC<FormFieldProps> = ({ className, invalidClassName, ...props }) => {

    const { values, touched, errors, setFieldValue, handleChange, setFieldTouched } = useFormikContext<SelectorsFormsValues>();
    const servers = useLoginServers();

    const onChange = useCallback((e: any) => {
        handleChange(e);
        if (values.selected_game_server) {
            setFieldValue('selected_game_server', '');
            setFieldTouched('selected_game_server', false);
        }
        if (values.selected_account) {
            setFieldValue('selected_account', '');
            setFieldTouched('selected_account', false);
        }
        if (values.selected_character) {
            setFieldValue('selected_character', '');
            setFieldTouched('selected_character', false);
        }
    }, [handleChange, setFieldTouched, setFieldValue, values.selected_account, values.selected_character, values.selected_game_server]);

    const invalid = Boolean(errors['selected_login_server'] && touched['selected_login_server']);

    const cn = classNames(className, invalidClassName && {
        [invalidClassName]: invalid
    });

    return (
        <Field
            id="selected_login_server"
            name="selected_login_server"
            as="select"
            onChange={onChange}
            invalid={invalid || undefined}
            className={cn}
            {...props}>
            <option value='' key={'empty'}>Выберите сервер авторизации</option>
            {
                servers.map(server => <option value={server.id} key={server.id}>{server.name}</option>)
            }
        </Field>
    )
}

export default LoginServerSelector;
import { useFormik } from "formik";
import { FC, useMemo } from "react";
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import * as Yup from 'yup';

interface GoalFormProps {
    initialValues: GoalFormValues;
    onSubmit: (values: GoalFormValues) => Promise<any>;
    editForm?: boolean;
}

export interface GoalFormValues {
    name: string;
    description: string;
    isActive: boolean;
    hidden: boolean;
    order: number;
    conditionType: ConditionType;
}

export enum ConditionType {
    All = 'all',
    One = 'one'
}

const GoalForm: FC<GoalFormProps> = ({ initialValues, onSubmit, editForm }) => {

    const validationSchema = useMemo(() => Yup.object().shape({
        name: Yup.string()
            .max(256)
            .required(),
        description: Yup.string()
            .max(10000),
        isActive: Yup.boolean()
            .required(),
        hidden: Yup.boolean()
            .required(),
        order: Yup.number()
    }), []);

    const {
        values,
        isSubmitting,
        isValid,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
        errors
    } = useFormik({
        onSubmit,
        initialValues,
        validationSchema
    });

    return (
        <Form onSubmit={handleSubmit} onBlur={handleBlur}>
            <FormGroup row>
                <Label sm="2">Название</Label>
                <Col sm="10">
                    <Input
                        id="name"
                        name="name"
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        invalid={Boolean(touched.name && errors.name)} />
                    <FormFeedback valid={false}>{errors.name}</FormFeedback>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm="2">Описание</Label>
                <Col sm="10">
                    <Input
                        id="description"
                        name="description"
                        type="text"
                        value={values.description}
                        onChange={handleChange}
                        invalid={Boolean(touched.description && errors.description)} />
                    <FormFeedback valid={false}>{errors.description}</FormFeedback>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={2}>Скрыто</Label>
                <Col sm={10}>
                    <Input
                        type="switch"
                        id="hidden"
                        name="hidden"
                        checked={values.hidden}
                        onChange={e => setFieldValue('hidden', e.target.checked)} />
                </Col>
            </FormGroup>
            {
                editForm && (
                    <>
                        <FormGroup row>
                            <Label sm={2}>Активно</Label>
                            <Col sm={10}>
                                <FormGroup check>
                                    <Input
                                        type="switch"
                                        id="isActive"
                                        name="isActive"
                                        checked={values.isActive}
                                        onChange={e => setFieldValue('isActive', e.target.checked)} />
                                </FormGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Условия</Label>
                            <Col sm={10} role='group'>
                                <FormGroup check>
                                    <Input
                                        type='radio'
                                        name='type'
                                        id='goal-condition-one'
                                        value={ConditionType.One}
                                        checked={values.conditionType === ConditionType.One}
                                        onChange={() => setFieldValue('conditionType', ConditionType.One)} />
                                    <Label check>
                                        Хотя-бы одно
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Input
                                        type='radio'
                                        name='type'
                                        id='goal-condition-all'
                                        value={ConditionType.All}
                                        checked={values.conditionType === ConditionType.All}
                                        onChange={() => setFieldValue('conditionType', ConditionType.All)} />
                                    <Label check>
                                        Все
                                    </Label>
                                </FormGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm="2">Порядковый номер</Label>
                            <Col sm="10">
                                <Input
                                    id="order"
                                    name="order"
                                    type="number"
                                    value={values.order}
                                    onChange={handleChange}
                                    invalid={Boolean(touched.order && errors.order)} />
                                <FormFeedback valid={false}>{errors.order}</FormFeedback>
                            </Col>
                        </FormGroup>
                    </>
                )
            }
            <div className="text-center mt-3">
                <Button type="submit" color="primary" disabled={isSubmitting || !isValid}>
                    Сохранить
                </Button>
            </div>
        </Form>
    )
}

export default GoalForm;
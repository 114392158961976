import UserService from "@api/userService";
import { injectChild } from "@components/constructor";
import { usePromisedDispatch } from "@hooks/helperHooks";
import { FileUploader } from "@services/index";
import { panelActions } from "@store/panelStore";
import { userActions } from "@store/user";
import {ChangeEvent, FC, PropsWithChildren, useCallback, useMemo, useRef, useState} from "react";
import { ReactCropperElement } from "react-cropper";
import { toastr } from "react-redux-toastr";
import { AvatarProvider, IAvatarContext } from "./context";

interface AvatarUploaderChildProps {
    on_avatar_selected: (event: ChangeEvent<HTMLInputElement>) => any;
    upload_avatar: () => Promise<any>;
    avatar_selected: boolean;
    avatar_uploading: boolean;
    avatar_upload_progress: number;
}

interface AvatarUploaderProps {
    modalToggle?: () => void;
    open_modal_id?: string;
}

const AvatarUploader: FC<PropsWithChildren<AvatarUploaderProps>> = ({children, modalToggle, open_modal_id}) => {

    const cropperRef = useRef<ReactCropperElement>(null);
    const dispatch = usePromisedDispatch();

    const [imageUrl, setUrl] = useState<string | null>(null);
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);

    const state = useMemo<IAvatarContext>(() => ({
        cropper_ref: cropperRef,
        imageUrl
    }), [imageUrl]);


    const on_avatar_selected = useCallback(({ currentTarget: { files } }: ChangeEvent<HTMLInputElement>) => {
        const file = (files && files.length > 0) ? files[0] : null;
        if (file) {
            setUrl((lastUrl) => {
                if (lastUrl) {
                    URL.revokeObjectURL(lastUrl);
                }
                return URL.createObjectURL(file);
            });

            if(open_modal_id) {
                dispatch(panelActions.setModalState({
                    id: open_modal_id,
                    state: true
                }));
            }
            if(modalToggle) {
                modalToggle();
            }
        }
        else {
            setUrl(null)
        }
    }, [dispatch, modalToggle, open_modal_id]);

    const upload_avatar = useCallback(async () => {

        const cropper = cropperRef.current?.cropper;
        if(!cropper) {
            return;
        }
        
        setUploading(true);
        cropper.getCroppedCanvas().toBlob(async blob => {

            if(!blob) {
                return;
            }

            const api = new UserService();

            const result = await api.prepareAvatarUpload();
            if(!result.success) {
                setUploading(false);
                return;
            }
    
            const uploader = new FileUploader(result.data);
            uploader.onProgress = (event: ProgressEvent<EventTarget>) => setProgress(event.loaded / event.total * 100);
    
            try {
                await uploader.uploadBlob(blob, 'avatar.png');
                toastr.success('Загрузка аватара', 'Аватар успешно загружен');
                await dispatch(userActions.data.loadUserData());
    
                if(modalToggle) {
                    modalToggle();
                }

                setUrl(imageUrl => {
                    if (imageUrl) {
                        URL.revokeObjectURL(imageUrl);
                    }
                    return null;
                })
            }
            catch(e) {
                toastr.error('Загрузка аватара', 'Не удалось загрузить аватар');
            }
            finally {
                setProgress(0);
                setUploading(false);
            }
        });
    }, [dispatch, modalToggle]);

    const data = useMemo<AvatarUploaderChildProps>(() => ({
        on_avatar_selected,
        upload_avatar,
        avatar_selected: Boolean(imageUrl),
        avatar_upload_progress: progress,
        avatar_uploading: uploading
    }), [imageUrl, on_avatar_selected, progress, upload_avatar, uploading]);

    return (
        <AvatarProvider value={state}>
            {injectChild(data, children)}
        </AvatarProvider>
    )
}

export default AvatarUploader;
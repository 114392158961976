import ConsumerRefService from "@api/consumer/consumerRefService";
import { IGoalBalanceReward, IGoalItemReward, IGoalReward } from "@interfaces/consumer/refs";
import { FormikHelpers } from "formik";
import { FC, useCallback } from "react";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import { CardBody } from "reactstrap";
import GoalRewardForm, { GoalRewardFormValues } from "./goalRewardForm";

interface EditGoalRewardFormProps {
    reward: IGoalReward;
}

const EditGoalRewardForm: FC<EditGoalRewardFormProps> = ({reward}) => {

    const history = useHistory();
    
    const initialValues: GoalRewardFormValues = {
        name: reward.name,
        isActive: reward.isActive,
        type: reward.type,
        order: reward.order,

        balance: (reward as IGoalBalanceReward).balance || 0,

        itemId: (reward as IGoalItemReward).itemId || '',
        count: (reward as IGoalItemReward).count || 1
    }

    const onSubmit = useCallback(async (values: GoalRewardFormValues, actions: FormikHelpers<GoalRewardFormValues>) => {
        const api = new ConsumerRefService();
        const result = await api.patchGoalReward(reward.goalId, {
            id: reward.id,
            isActive: values.isActive,
            name: values.name,
            order: values.order,
            
            balance: values.balance,
        
            itemId: values.itemId,
            count: values.count
        });

        if (result.success) {
            toastr.success("Изменение достижения", "Награда достижения успешно добавлена");
            history.replace(`/consumer/goals/${result.data.goalId}/rewards/${result.data.id}/`);
        }
        else {
            if (result.errorCode === 'GoalNotFound') {
                toastr.error('Изменение достижения', 'Достижение не найдено');
            }
            else if (result.errorCode === 'RewardNotFound') {
                toastr.error('Изменение достижения', 'Награда не найдена');
            }
            else if (result.errorCode === 'InvalidRequest') {
                toastr.error('Изменение достижения', 'Некорректные параметры награды');
            }
            else if (result.errorCode === 'DisabledItem') {
                actions.setFieldError('itemId', 'Предмет отключен');
            }
            else if (result.errorCode === 'InvalidType') {
                toastr.error('Изменение достижения', 'Некорректный тип награды');
            }
            else {
                toastr.error('Изменение достижения', 'Неизвестная ошибка');
            }
        }
    }, [history, reward.goalId, reward.id]);

    return (
        <CardBody>
            <GoalRewardForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                editForm />
        </CardBody>
    )
}

export default EditGoalRewardForm;
import {useState, useCallback, FC} from 'react';
import { Card} from 'react-bootstrap';
import withPage from '../../../hoc/withPage';
import {ColumnDef} from "@tanstack/react-table";
import {ConsumerTicket} from "@interfaces/consumer/support";
import {Link} from "react-router-dom";
import {Avatar, Flex, SubtleBadge} from "@components/falcon/common";
import {dateFormatter} from "@services/helpers";
import {useRemoteTable} from "@hooks/tableHooks";
import CSupportService from "@api/consumer/CSupportService";
import {PanelTableDataModel} from "@models/panel";
import AdvanceTableProvider from "@components/falcon/common/tables/context";
import {AdvanceTable, AdvanceTableFooter} from "@components/falcon/common/tables";
import {TicketsHeader} from "@components/consumer/support";

const columns: ColumnDef<ConsumerTicket>[] = [
    {
        accessorKey: "ownerUserName",
        header: "Пользователь",
        enableSorting: true,
        meta: {
            headerProps: { className: 'ps-2 text-900', style: { height: '46px' } },
            cellProps: {
                className: 'py-2 white-space-nowrap pe-3 pe-xxl-4 ps-2'
            }
        },
        cell: ({row: {original: { ownerUserName, ownerId}}}) => (
            <Flex alignItems="center" className="position-relative py-1">
                <Avatar userId={ownerId} name={ownerUserName} size="xl" className="me-2" rounded="circle"/>
                <h6 className="mb-0">
                    <Link to={`/consumer/users/${ownerId}/`} className="stretched-link text-900">
                        {ownerUserName}
                    </Link>
                </h6>
            </Flex>
        )
    },
    {
        accessorKey: "name",
        header: "Тема",
        enableSorting: true,
        meta: {
            headerProps: {
                style: { minWidth: '14.625rem' },
                className: 'text-900'
            },
            cellProps: {
                className: 'py-2 pe-4'
            }
        },
        cell: ({row: {original: {id, name}}}) => (
            <Link to={`/consumer/support/${id}/`}>
                {name}
            </Link>
        )
    },
    {
        accessorKey: "lastUserName",
        header: "Статус",
        enableSorting: false,
        meta: {
            headerProps: { className: 'text-900' },
            cellProps: {
                className: 'fs-9 pe-4'
            }
        },
        cell: ({row: {original: {lastUserName, ownerUserName}}}) => {
            const waitForAnswer = lastUserName === ownerUserName;
            return (
                <SubtleBadge bg={waitForAnswer? "warning" : "success"} className="me-2">
                    {waitForAnswer ? "Ожидает" : "Есть ответ"}
                </SubtleBadge>
            )
        }
    },
    {
        accessorKey: "updateTime",
        header: "Обновлено",
        enableSorting: true,
        cell: ({row: {original: {updateTime}}}) => dateFormatter(updateTime),
    },
    {
        accessorKey: "createTime",
        header: "Создано",
        enableSorting: true,
        cell: ({row: {original: {createTime}}}) => dateFormatter(createTime),
    }
]

const ConsumerTicketsPage: FC = () => {

    const [closed, setClosed] = useState(false);
    const [api] = useState(() => new CSupportService());
    const getTopics = useCallback((model: PanelTableDataModel) => api.getTopics(model, closed), [api, closed]);

    const table = useRemoteTable({
        name: 'consumer.support.list',
        columns,
        selection: true,
        defaultState: {
            sorting: [
                {
                    id: 'updateTime',
                    desc: true
                }
            ]
        }
    }, getTopics);

    return (
        <AdvanceTableProvider table={table}>
            <Card>
                <Card.Header className="border-bottom border-200 px-0">
                    <TicketsHeader
                        closed={closed}
                        setClosed={setClosed}
                    />
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        headerClassName="bg-body-tertiary align-middle"
                        rowClassName="btn-reveal-trigger align-middle"
                        tableProps={{
                            size: 'sm',
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowInfo
                        rowsPerPageSelection
                        paginationButtons
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableProvider>
    )

    /*const [tab, setTab] = useState(0);
    const [page] = useState(0);

    const tasks = useSelector((state: ReduxStore) => state.consumer.support.topics.data);
    const loading = useSelector((state: ReduxStore) => state.consumer.support.topics.loading);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(consumerActions.support.loadTopics(page, tab === 1));
    }, [dispatch, tab, page]);

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h5">
                    Заявки пользователей
                    </CardTitle>
            </CardHeader>
            <CardBody>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classNames({ active: tab === 0 })}
                            onClick={() => setTab(0)}>
                            Открытые
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classNames({ active: tab === 1 })}
                            onClick={() => setTab(1)}>
                            Закрытые
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent>
                    <ConsumerTicketsList
                        loading={loading}
                        tasks={tasks} />
                </TabContent>
            </CardBody>
        </Card>
    )*/
}

export default withPage(ConsumerTicketsPage);
import {FC} from "react";
import {Card, Form as BForm} from "react-bootstrap";
import {useFormikContext} from "formik";

enum ConditionType {
    All = 'all',
    One = 'one'
}

const RefTypeBlock: FC = () => {

    const {
        values,
        setFieldValue
    } = useFormikContext<{conditionType: ConditionType}>();

    return (
        <Card className="mb-3">
            <Card.Header as="h6" className="bg-body-tertiary">
                Условия получения
            </Card.Header>
            <Card.Body>
                <BForm.Check
                    type='radio'
                    name='type'
                    id='ref-condition-one'
                    value={ConditionType.One}
                    checked={values.conditionType === ConditionType.One}
                    onChange={() => setFieldValue('conditionType', ConditionType.One)}
                    label='Выполнено хотя-бы одно'
                />
                <BForm.Check
                    type='radio'
                    name='type'
                    id='ref-condition-all'
                    value={ConditionType.All}
                    checked={values.conditionType === ConditionType.All}
                    onChange={() => setFieldValue('conditionType', ConditionType.All)}
                    label='Выполнены все'
                />
            </Card.Body>
        </Card>
    )
}

export default RefTypeBlock;
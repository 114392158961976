import { injectChildArray } from "@components/constructor";
import { useGameServers } from "@hooks/selectors/game.selectors";
import { useFormikContext } from "formik";
import {FC, PropsWithChildren, useEffect, useMemo} from "react";
import { SelectorsFormsValues } from "../selectors";

interface GameServersListProps {
    clear_on_change?: boolean;
}

interface GameServersListChildProps {
    game_server_id: string;
    game_server_name: string;
}

const GameServersList: FC<PropsWithChildren<GameServersListProps>> = ({ clear_on_change, children }) => {

    const {
        values: { selected_login_server, selected_game_server },
        touched,
        setFieldValue,
        setFieldTouched
    } = useFormikContext<SelectorsFormsValues>();

    const servers = useGameServers(selected_login_server || undefined);

    useEffect(() => {
        if (touched.selected_game_server && clear_on_change) {
            setFieldValue('selected_character', '');
            setFieldTouched('selected_character', false);
        }
    }, [clear_on_change, setFieldTouched, setFieldValue, touched.selected_game_server, selected_game_server]);

    const data = useMemo<GameServersListChildProps[]>(() => servers.map(server => {
        return {
            game_server_id: server.id,
            game_server_name: server.name
        }
    }), [servers]);

    if(selected_login_server) {
        return null;
    }
    
    return injectChildArray(data, children, 'game_server_id');
}

export default GameServersList;
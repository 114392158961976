import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";
import CreateSupportTopicForm from "./createSupportTopicForm";
import Support from "./support";
import SupportTopic from "./supportTopic";
import SupportTopicCreateTime from "./supportTopicCreateTime";
import SupportTopicInputForm from "./supportTopicInputForm";
import SupportTopicLastAnswer from "./supportTopicLastAnswer";
import SupportTopicName from "./supportTopicName";
import SupportTopicsList from "./supportTopicsList";
import SupportTopicStatus from "./supportTopicStatus";
import SupportTopicUpdateTime from "./supportTopicUpdateTime";

const CreateSupportTopicFormInfo: ConstructionComponentInfo = {
    component: CreateSupportTopicForm
}

const SupportInfo: ConstructionComponentInfo = {
    component: Support,
    props: [
        'support_topics_per_page'
    ],
    addedChildProps: [
        'support_loaded',
        'support_loading',
        'support_error',
        'support_topics_count',
        'pagination_current_page',
        'pagination_total_pages',
        'pagination_per_page',
        'pagination_set_page',
        'pagination_next_page',
        'pagination_prev_page',
    ]
}

const SupportTopicInfo: ConstructionComponentInfo = {
    component: SupportTopic,
    addedChildProps: [
        'support_loading',
        'topic_messages_count',
        'topic_closed'
    ]
}

const SupportTopicCreateTimeInfo: ConstructionComponentInfo = {
    component: SupportTopicCreateTime,
    props: [
        'format'
    ]
}

const SupportTopicInputFormInfo: ConstructionComponentInfo = {
    component: SupportTopicInputForm,
    injectChild: [
        'cancel_edit_message'
    ]
}

const SupportTopicLastAnswerInfo: ConstructionComponentInfo = {
    component: SupportTopicLastAnswer
}

const SupportTopicNameInfo: ConstructionComponentInfo = {
    component: SupportTopicName
}

const SupportTopicsListInfo: ConstructionComponentInfo = {
    component: SupportTopicsList,
    addedChildProps: [
        'topic_closed'
    ],
    injectChild: [
        'pagination_current_page',
        'pagination_per_page'
    ]
}

const SupportTopicStatusInfo: ConstructionComponentInfo = {
    component: SupportTopicStatus
}

const SupportTopicUpdateTimeInfo: ConstructionComponentInfo = {
    component: SupportTopicUpdateTime,
    props: [
        'format'
    ]
}

export {
    SupportInfo,
    SupportTopicCreateTimeInfo,
    SupportTopicInputFormInfo,
    SupportTopicLastAnswerInfo,
    SupportTopicInfo,
    SupportTopicNameInfo,
    SupportTopicUpdateTimeInfo,
    SupportTopicStatusInfo,
    SupportTopicsListInfo,
    CreateSupportTopicFormInfo
}
import { connect } from 'react-redux';
import { Wrapper, Sidebar, Main, Content, Footer } from '../components/panel';
import { ReduxStore } from '@interfaces/store';
import { Navbar } from '../components/panel/Navbar';
import {FC, Fragment, PropsWithChildren} from "react";

interface DashboardStateProps {
  sidebarOnRight: boolean
}

const Dashboard: FC<PropsWithChildren<DashboardStateProps>> = ({ sidebarOnRight, children }) => (
  <Fragment>
    <Wrapper>
      {!sidebarOnRight && <Sidebar />}
      <Main>
        <Navbar />
        <Content>{children}</Content>
        <Footer />
      </Main>
      {sidebarOnRight && <Sidebar />}
    </Wrapper>
  </Fragment>
);

function mapStateToProps(store: ReduxStore): DashboardStateProps {
  return {
    sidebarOnRight: store.sidebar.isOnRight
  }
}
export default connect(mapStateToProps)(Dashboard);
import BaseInfoBlock from "./baseInfoBlock";
import RewardBlock from "./rewardBlock";
import {BonusCodeAccess, BonusCodeType} from "@interfaces/consumer/bonusCodes";
import * as Yup from "yup";
export {
    BaseInfoBlock,
    RewardBlock
}

export interface BonusCodeFormValues {
    code: string;
    count: number;
    maxUseCount: number;

    timeLimited: boolean;
    beginTime?: Date;
    endTime?: Date;

    isActive: boolean;
    access: BonusCodeAccess;

    type: BonusCodeType;
    itemId: string;
    itemCount: number,
    money: number;
}

export const bonusFormValidation = Yup.object().shape<BonusCodeFormValues>({
    code: Yup.string()
        .min(3)
        .max(36),
    count: Yup.number()
        .min(1)
        .max(100)
        .required(),
    maxUseCount: Yup.number()
        .min(0)
        .max(10000),

    timeLimited: Yup.boolean(),
    beginTime: Yup.date(),
    endTime: Yup.date(),

    isActive: Yup.boolean(),
    access: Yup.string(),

    type: Yup.string(),
    itemId: Yup.string()
        .when("type", {
            is: BonusCodeType.Item,
            then: Yup.string().required()
        }),
    itemCount: Yup.number()
        .when("type", {
            is: BonusCodeType.Item,
            then: Yup.number()
                .min(1)
                .max(2000000000)
                .required()
        }),
    money: Yup.number()
        .when("type", {
            is: BonusCodeType.Balance,
            then: Yup.number()
                .min(1)
                .max(100000)
                .required()
        })
})
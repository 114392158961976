import classNames from "classnames";
import { FC } from "react";
import { useNewsContext } from "./context";

interface NewsBodyProps {
    className?: string;
}

const NewsBody: FC<NewsBodyProps> = ({ className, ...props }) => {
    const news = useNewsContext();

    if (!news) {
        return null;
    }

    return (
        <div 
            className={classNames("ql-container ql-editor", className)} 
            dangerouslySetInnerHTML={{ __html: news.text }} 
            {...props} />
    )
}

export default NewsBody;
import { FC } from "react";
import { useDonateLogItemContext } from "./context";
import { Text } from '@constructor/components';

const DonateLogItemSum: FC = (props) => {
    const item = useDonateLogItemContext();

    if (!item) {
        return null;
    }

    return <Text text={item.summ} {...props} />
}

export default DonateLogItemSum;
import IService from "@api/iService";
import { IDonateBonus } from "@interfaces/user/payments";

export default class DonateBonusService extends IService {

    async getBonuses() {
        return await this.getApi<IDonateBonus[]>('/user/payment/bonus/');
    }

    async getBonus(id: string) {
        return await this.getApi<IDonateBonus>(`/user/payment/bonus/${id}/`);
    }
    
}
import IService from "@api/iService";
import {ConsumerTicketStatusModel, PatchMessageModel, PutMessageModel} from "@models/consumer/support";
import {ConsumerTicket, ConsumerTicketMessage} from "@interfaces/consumer/support";
import FetchResult from "@models/fetchResult";
import {PanelTableDataModel} from "@models/panel";
import {PanelTableData} from "@interfaces/panel";

export default class CSupportService extends IService {

    getTopic = (topicId: string) : Promise<FetchResult<ConsumerTicket>> =>
        this.getApi<ConsumerTicket>(`/consumer/support/${topicId}/`);

    getTopicsList = (page: number, closed: boolean) : Promise<FetchResult<ConsumerTicket[]>> =>
        this.getApi<ConsumerTicket[]>(`/consumer/support/?page=${page}&closed=${closed}`);

    getTopics = (model: PanelTableDataModel, closed: boolean) : Promise<FetchResult<PanelTableData<ConsumerTicket[]>>> =>
        this.postApi<PanelTableData<ConsumerTicket[]>>(`/consumer/support/?closed=${closed}`, model);

    changeTopicStatus = (topicId: string, isClosed: boolean) : Promise<FetchResult<ConsumerTicket>> =>
        this.patchApi<ConsumerTicket>(`/consumer/support/${topicId}/`, {
            isClosed
        } as ConsumerTicketStatusModel);

    deleteTopic = (topicId: string) : Promise<FetchResult<any>> =>
        this.deleteApi(`/consumer/support/${topicId}/`);

    deleteTopics = (topicIds: string[]) : Promise<FetchResult<any>> =>
        this.deleteApi(`/consumer/support/`, topicIds);

    getMessages = (topicId: string) : Promise<FetchResult<ConsumerTicketMessage[]>> =>
        this.getApi<ConsumerTicketMessage[]>(`/consumer/support/${topicId}/messages/`);

    getMessage = (topicId: string, messageId: string) : Promise<FetchResult<ConsumerTicketMessage>> =>
        this.getApi<ConsumerTicketMessage>(`/consumer/support/${topicId}/messages/${messageId}/`);

    putMessage = (topicId: string, message: PutMessageModel) : Promise<FetchResult<ConsumerTicketMessage>> =>
        this.putApi<ConsumerTicketMessage>(`/consumer/support/${topicId}/messages/`, message);

    patchMessage = (topicId: string, messageId: string, message: PatchMessageModel) : Promise<FetchResult<ConsumerTicketMessage>> =>
        this.patchApi<ConsumerTicketMessage>(`/consumer/support/${topicId}/messages/${messageId}/`, message);

    deleteMessage = (topicId: string, messageId: string) : Promise<FetchResult<any>> =>
        this.deleteApi(`/consumer/support/${topicId}/messages/${messageId}/`);
}
import { injectChildArray } from "@components/constructor";
import {FC, PropsWithChildren, useMemo} from "react";
import { PaginationProps, PaginationItemProps } from ".";

const PaginationPages: FC<PropsWithChildren<PaginationProps>> = ({ pagination_total_pages, pagination_set_page, children }) => {

    const data = useMemo<PaginationItemProps[]>(() => new Array(pagination_total_pages).fill(0).map((_, idx) => {
        return {
            pagination_page: idx,
            pagination_text_page: idx + 1,
            pagination_open_page: () => pagination_set_page(idx)
        }
    }), [pagination_set_page, pagination_total_pages]);

    return injectChildArray(data, children, "pagination_page");
}

export default PaginationPages;
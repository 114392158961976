import { useSelector } from "react-redux";
import { ReduxStore } from "../../../interfaces/store";
import { useMemo } from "react";

export function useMarketItems(category?: string) {
    let {
        loaded,
        loading,
        error,
        data: { items }
    } = useSelector(({ user: { market } }: ReduxStore) => market);

    items = useMemo(() => {
        if(category) {
            return items.filter(i => i.categories.includes(category));
        }
        else {
            return items;
        }
    }, [category, items]);
    

    return {
        loaded,
        loading,
        error,
        items
    }
}

export function useMarketCategories() {
    const {
        loaded,
        loading,
        error,
        data: { categories }
    } = useSelector(({ user: { market } }: ReduxStore) => market);

    return {
        loaded,
        loading,
        error,
        categories
    }
}
import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";
import DonateBonuses from "./donateBonuses";
import DonateBonusList from "./donateBonusList";
import DonateBonusThreshold from "./donateBonusThreshold";
import DonateBonusValue from "./donateBonusValue";

const DonateBonusesInfo: ConstructionComponentInfo = {
    component: DonateBonuses,
    props: [
        'donate_bonuses_per_page'
    ],
    addedChildProps: [
        'donate_bonuses_count',
        'pagination_current_page',
        'pagination_total_pages',
        'pagination_per_page',
        'pagination_set_page',
        'pagination_next_page',
        'pagination_prev_page'
    ]
}

const DonateBonusListInfo: ConstructionComponentInfo = {
    component: DonateBonusList,
    props: [
        'pagination_per_page'
    ],
    addedChildProps: [
        'donate_bonus_index'
    ],
    injectChild: [
        'pagination_current_page',
        'pagination_per_page'
    ]
}

const DonateBonusThresholdInfo: ConstructionComponentInfo = {
    component: DonateBonusThreshold,
    requiredParent: [
        'DonateBonusList'
    ]
}

const DonateBonusValueInfo: ConstructionComponentInfo = {
    component: DonateBonusValue,
    requiredParent: [
        'DonateBonusList'
    ]
}

export {
    DonateBonusListInfo,
    DonateBonusThresholdInfo,
    DonateBonusValueInfo,
    DonateBonusesInfo
}
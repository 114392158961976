import { FC } from "react";
import { useQueryContext } from "./context";
import { Text } from '@components/constructor/components';
import { fromUtcDate } from "@services/helpers";

interface QueryNameProps {
    format?: string;
}

const QueryName: FC<QueryNameProps> = ({ format = 'lll', ...props }) => {
    const query = useQueryContext();

    if (!query?.name) {
        return null;
    }

    return (
        <Text text={fromUtcDate(query.name).format(format)} {...props} />
    )
}

export default QueryName;
import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";
import ChangePasswordForm from "./changePasswordForm";
import ChangeProfileForm from "./changeProfileForm";

import AvatarUploader from "./avatar/avatarUploader";
import AvatarCropper from "./avatar/cropper";

const ChangePasswordFormInfo: ConstructionComponentInfo = {
    component: ChangePasswordForm
}

const ChangeProfileFormInfo: ConstructionComponentInfo = {
    component: ChangeProfileForm
}

const AvatarUploaderInfo: ConstructionComponentInfo = {
    component: AvatarUploader,
    injectChild: [
        'modalToggle'
    ]
}

const AvatarCropperInfo: ConstructionComponentInfo = {
    component: AvatarCropper,
    requiredParent: [
        'AvatarUploader'
    ]
}

export {
    ChangePasswordFormInfo,
    ChangeProfileFormInfo,

    AvatarCropperInfo,
    AvatarUploaderInfo
}

export interface IDailyStat {
    id: string;
    date: string;
}

export interface IGift {
    id: string;
    name: string;
    days: number;
    repeat: boolean;
    oneTime: boolean;
    giftType: EGiftType;
    isAssigned: boolean;
    giftInfo: IItemGiftInfo | IBalanceGiftInfo;
}

export interface ICheckGift {
    newGifts: IGift[];
    updatedGifts: IGift[];
}

export interface IItemGiftInfo {
    itemId: string;
    count: number;
}

export interface IBalanceGiftInfo {
    balance: number;
}

export enum EGiftType {
    ItemGift = "Item",
    BalanceGift = "Balance"
}
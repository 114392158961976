import { FC } from "react";
import { useNotificationContext } from "./context";
import { Text } from '@constructor/components';

const NotificantionTitle: FC = (props) => {

    const not = useNotificationContext();

    if (!not?.title) {
        return null;
    }

    return (
        <Text text={not.title} {...props} replaces={not.replaces}/>
    )
}

export default NotificantionTitle;
import {FC} from "react";
import {PaymentState} from "@interfaces/consumer/payments";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SubtleBadge} from "@components/falcon/common";

interface PaymentStateBadgeProps {
    state: PaymentState;
    className?: string;
}

const PaymentStateBadge: FC<PaymentStateBadgeProps> = ({state, className}) => (
    <SubtleBadge
        pill
        bg={classNames({
            success: state === 1,
            primary: state === 0,
            warning: state === 2
        }) as 'success' | 'primary' | 'warning'}
        className={className}
    >
        {state === 1 && 'Завершен'}
        {state === 0 && 'Ожидается'}
        {state === 2 && 'Отменен'}
        <FontAwesomeIcon
            icon={classNames({
                check: state === 1,
                redo: state === 0,
                ban: state === 2
            }) as 'check' | 'redo' | 'ban'}
            transform="shrink-2"
            className="ms-1"
        />
    </SubtleBadge>
)

export default PaymentStateBadge;
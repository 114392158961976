import { GoalsList } from "@components/consumer/goals";
import withPage from "@components/hoc/withPage";
import { FC } from "react";

const GoalsListPage: FC = () => {

    return (
        <GoalsList />
    )
}

export default withPage(GoalsListPage);
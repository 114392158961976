import {ChangeEvent, FC, PropsWithChildren, RefObject, useCallback, useEffect, useMemo, useRef} from "react";
import {useTaskMessage, useTopicAuto} from "@hooks/selectors/user/support.selectors";
import * as Yup from 'yup';
import {Form, Formik, FormikHelpers, useFormikContext} from "formik";
import {usePromisedDispatch} from "@hooks/helperHooks";
import {userActions} from "@store/user";
import {toastr} from "react-redux-toastr";
import {injectChild} from "@components/constructor";
import {AttachmentType} from "@interfaces/user/support";
import {useSupportTopicContext} from "@constructor/components/features/support/context";

export interface SupportTopicInputFormValues {
    message: string;
    images: File[];
}

interface SupportTopicInputFormProps {
    cancel_edit_message?: () => void;
    max_attachments_count: number;
}

const SupportTopicInputForm: FC<PropsWithChildren<SupportTopicInputFormProps>> = ({ cancel_edit_message, children, max_attachments_count = 1, ...props }) => {

    const dispatch = usePromisedDispatch();

    const context = useSupportTopicContext();
    const topic = useTopicAuto();
    const editableMessage = useTaskMessage(topic?.id, context?.editableMessageId);

    const initialValues: SupportTopicInputFormValues = {
        message: "",
        images: []
    }

    const validation = useMemo(() => Yup.object().shape({
        message: Yup.string()
            .max(1000, "Не более 1000 символов")
            .when('images', (images, schema) => {
                return images.length === 0 ? schema.required("Введите сообщение или прикрепите изображение") : schema;
            })
    }), []);

    const onSubmit = useCallback(async ({ message, images }: SupportTopicInputFormValues, actions: FormikHelpers<SupportTopicInputFormValues>) => {
        
        if(!topic?.id){
            return;
        }

        const id = topic.id;
        const result = editableMessage?.id ?
            await dispatch(userActions.editTaskMessage(topic.id, editableMessage.id, {
                message
            })) :
            await dispatch(userActions.addTaskMessage({
                task: id,
                message,
                attachments: images?.map(file => ({
                    name: file.name,
                    type: AttachmentType.Image
                }))
            }));

        if (result.success) {
            cancel_edit_message && cancel_edit_message();

            dispatch(userActions.loadTask(id));
            dispatch(userActions.loadTaskMessages(id));

            const attaches = result.data.attachments.filter(a => a.uploadFile);
            if(attaches.length > 0 && images.length > 0 && context?.uploadAttachedImages) {
                await context.uploadAttachedImages(attaches.map(a => a.uploadFile!));
            }

            actions.resetForm();
        }
        else {
            if(result.errorCode === "EmptyMessage"){
                toastr.error("Поддержка", "Нельзя отправить пустое сообщение");
            }
            else if(result.errorCode === 'TopicNotFound') {
                toastr.error("Поддержка", "Заявка не найдена");
                dispatch(userActions.loadTask(id));
            }
            else if(result.errorCode === 'ClosedTopic') {
                toastr.error("Поддержка", "Нельзя отправить сообщение в закрытую заявку");
                dispatch(userActions.loadTask(id));
            }
            else if(result.errorCode === 'MessageNotFound') {
                toastr.error("Поддержка", "Сообщение не найдено");
                dispatch(userActions.loadTask(id));
            }
            else if(result.errorCode === 'NotOwner') {
                toastr.error("Поддержка", "Вы не можете редактировать это сообщение");
                dispatch(userActions.loadTask(id));
            }
            else if(result.errorCode === "TooFast"){
                toastr.error("Поддержка", "Слишком быстро отправляете сообщения");
            }
            else if(result.errorCode === 'TooLate') {
                toastr.error("Поддержка", "Нельзя редактировать сообщения старше 5 минут");
            }
            else {
                toastr.error("Поддержка", "Что-то пошло не так");
            }
        }
    }, [cancel_edit_message, context, dispatch, editableMessage?.id, topic?.id]);

    if(!topic) {
        return null;
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={onSubmit}>
            <Form {...props}>
                <EditMessageHelper 
                    topic_id={topic?.id} 
                    message_id={context?.editableMessageId}
                    images_max_count={max_attachments_count}>
                    {children}
                </EditMessageHelper>
            </Form>
        </Formik>
    )
}

interface EditMessageHelperProps {
    topic_id?: string;
    message_id?: string;
    images_max_count: number;
}

interface EditMessageHelperChildValues {
    image_input_ref: RefObject<HTMLInputElement>;
    image_input_click: () => void;
    image_input_changed: (event: ChangeEvent<HTMLInputElement>) => any;
    images_attached: boolean;
}

const EditMessageHelper: FC<PropsWithChildren<EditMessageHelperProps>> = ({ topic_id, message_id, images_max_count, children }) => {

    const {
        setAttachedImages
    } = useSupportTopicContext() ?? {};
    const message = useTaskMessage(topic_id, message_id);
    const {
        setFieldValue,
        values: { images }
    } = useFormikContext<SupportTopicInputFormValues>();

    useEffect(() => {
        setFieldValue('message', message?.message ?? '');
    }, [message?.message, setFieldValue]);

    useEffect(() => {
        if(images.length > images_max_count) {
            setFieldValue('images', images.slice(0, images_max_count));
        }
    }, [images, images_max_count, setFieldValue]);

    const imageInputRef = useRef<HTMLInputElement>(null);
    const clickImageInput = useCallback(() => {
        imageInputRef.current?.click();
    }, []);
    
    const imageInputChanged = useCallback(({currentTarget: {files}}: ChangeEvent<HTMLInputElement>) => {
        const array = files ? Array.from(files) : [];
        setFieldValue('images', array);
        setAttachedImages && setAttachedImages(array);
    }, [setFieldValue, setAttachedImages]);

    const data = useMemo<EditMessageHelperChildValues>(() => ({
        image_input_ref: imageInputRef,
        image_input_click: clickImageInput,
        images_attached : images.length > 0,
        image_input_changed: imageInputChanged
    }), [clickImageInput, imageInputChanged, images.length]);

    return injectChild(data, children);
}

export default SupportTopicInputForm;
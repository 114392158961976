import { useInventoryItemContext } from "./context";
import { Text } from '@constructor/components';
import { FC } from "react";

const InventoryItemCount: FC = (props) => {

    const item = useInventoryItemContext();
    if(!item) {
        return null;
    }

    return (
        <Text text={item.count} {...props} />
    )
}

export default InventoryItemCount;
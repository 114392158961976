import { PaginationProps } from "@constructor/components/core/pagination";
import { useCallback, useMemo, useState } from "react";

export function usePagination(sizePerPage: number, totalSize: number): PaginationProps {
    const [page, setPage] = useState(0);

    const maxPage = Math.floor(totalSize / sizePerPage);

    const prevPage = useCallback(() => setPage(Math.max(0, page - 1)), [page]);
    const nextPage = useCallback(() => setPage(Math.min(maxPage, page + 1)), [maxPage, page]);

    return useMemo<PaginationProps>(() => {
        return {
            pagination_page_name: page + 1,
            pagination_current_page: page,
            pagination_next_page: nextPage,
            pagination_per_page: sizePerPage,
            pagination_prev_page: prevPage,
            pagination_set_page: setPage,
            pagination_total_pages: maxPage + 1
        }
    }, [maxPage, nextPage, page, prevPage, sizePerPage]);
}
import { FC } from "react";
import { useDonateLogItemContext } from "./context";
import { Text } from '@constructor/components';
import { fromUtcDate } from "@services/helpers";

interface DonateLogItemDatePros {
    format?: string;
}

const DonateLogItemDate: FC<DonateLogItemDatePros> = ({ format = 'lll', ...props }) => {
    const item = useDonateLogItemContext();

    if (!item) {
        return null;
    }

    return <Text text={fromUtcDate(item.date).format(format)} {...props} />
}

export default DonateLogItemDate;
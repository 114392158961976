import { ReduxStore } from "@interfaces/store";
import { shallowEqual, useSelector } from "react-redux";
import {sortByDate} from "@services/helpers";

export function useChatMessages(topicId: string) {
    return useSelector((state: ReduxStore) => state.consumer.support.messages.data.filter(m => m.task === topicId), shallowEqual);
}

export function useChatMessage(topicId?: string, messageId?: string) {
    return useSelector((state: ReduxStore) => state.consumer.support.messages.data.find(m => m.task === topicId && m.id === messageId));
}

export function useSortedChatMessages(topicId: string, order: 'desc' | 'asc' = "desc") {
    const messages = useChatMessages(topicId);
    return [...messages].sort(sortByDate(m => m.createTime, order));
}

export function useTopic(topicId: string) {
    return useSelector((state: ReduxStore) => state.consumer.support.topics.data.find(t => t.id === topicId));
}

export function useTopics() {
    return useSelector((state: ReduxStore) => state.consumer.support.topics.data)
}

export function useSortedTopics(order: 'desc' | 'asc' = "desc") {
    const tasks = useTopics();
    return [...tasks].sort(sortByDate(t => t.updateTime, order));
}
import {FC, useEffect, useState} from "react";
import {Button, Col, Dropdown, Row, Spinner} from "react-bootstrap";
import {AdvanceTableSearchBox} from "@components/falcon/common/tables";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAdvanceTableContext} from "@components/falcon/common/tables/context";
import {ConsumerTicket} from "@interfaces/consumer/support";
import CSupportService from "@api/consumer/CSupportService";
import {toastr} from "react-redux-toastr";

interface TicketsHeaderProps {
    closed: boolean;
    setClosed: (closed: boolean) => void;
}

const TicketsHeader: FC<TicketsHeaderProps> = ({closed, setClosed}) => {

    const [action, setAction] = useState(0);
    const [executing, setExecuting] = useState(false);
    const {
        resetRowSelection,
        getSelectedRowModel,
        options: {meta}
    } = useAdvanceTableContext<ConsumerTicket>();

    const refreshData = (meta as any).refreshData;

    useEffect(() => {
        setAction(value => {
            if(value === 1 && closed) {
                return 2;
            }
            else if(value === 2 && !closed) {
                return 1;
            }
            return value;
        })
    }, [closed]);

    let actionText = "Действия";
    if(action === 1) {
        actionText = "Открыть";
    }
    else if(action === 2) {
        actionText = "Закрыть";
    }
    else if(action === 3) {
        actionText = "Удалить";
    }

    const deleteTickets = async () => {
        const ticketIds = getSelectedRowModel().rows.map(x => x.original.id);
        if(ticketIds.length === 0) {
            return;
        }

        const api = new CSupportService();
        const result = await api.deleteTopics(ticketIds);
        await refreshData(true);
        resetRowSelection();

        if(result.ok) {
            toastr.success("Поддержка", "Заявки удалены");
        }
    }

    const closeTickets = () => {
        toastr.warning("Поддержка", "Закрытие заявок пока не реализовано");
    }

    const openTickets = () => {
        toastr.warning("Поддержка", "Открытие заявок пока не реализовано");
    }

    const handleAction = async () => {
        if(executing) {
            return;
        }

        setExecuting(true);
        if(action === 1) {
            openTickets();
        }
        else if(action === 2) {
            closeTickets();
        }
        else if(action === 3) {
            await deleteTickets();
        }

        setExecuting(false);
    }

    return (
        <div className="d-lg-flex justify-content-between">
            <Row className="flex-between-center gy-2 px-x1">
                <Col xs="auto" className="pe-0">
                    <h6 className="mb-0">Заявки</h6>
                </Col>
                <Col xs="auto">
                    <AdvanceTableSearchBox
                        className="input-search-width"
                    />
                </Col>
            </Row>
            <div className="border-bottom border-200 my-3" />
            <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
                <div
                    className="bg-300 mx-3 d-none d-lg-block d-xl-none"
                    style={{width: '1px', height: '29px'}}
                />
                {getSelectedRowModel().rows.length > 0 ? (
                    <div className="d-flex">
                        <div id="orders-actions">
                            <Dropdown
                                align="end"
                                className="btn-reveal-trigger d-inline-block me-2"
                            >
                                <Dropdown.Toggle split variant="falcon-default" size="sm">
                                    <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block me-1">
                                        {actionText}
                                    </span>
                                    <FontAwesomeIcon icon="chevron-down" transform="shrink-2"/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="border py-0">
                                    {closed ? (
                                        <Dropdown.Item
                                            active={action === 1}
                                            onClick={() => setAction(1)}
                                        >
                                            Открыть
                                        </Dropdown.Item>
                                    ) : (
                                        <Dropdown.Item
                                            active={action === 2}
                                            onClick={() => setAction(2)}
                                        >
                                            Закрыть
                                        </Dropdown.Item>
                                    )}
                                    <Dropdown.Item
                                        active={action === 3}
                                        onClick={() => setAction(3)}
                                    >
                                        Удалить
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <Button
                            type="button"
                            variant="falcon-default"
                            size="sm"
                            className="d-inline-flex flex-center gap-1 ms-2"
                            disabled={executing}
                            onClick={handleAction}
                        >
                            {executing && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                            Применить
                        </Button>
                    </div>
                ) : (
                    <div id="orders-actions">
                        <Dropdown
                            align="end"
                            className="btn-reveal-trigger d-inline-block me-2"
                        >
                            <Dropdown.Toggle split variant="falcon-default" size="sm">
                            <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block me-1">
                              {closed ? "Закрытые" : "Открытые"}
                            </span>
                                <FontAwesomeIcon icon="chevron-down" transform="shrink-2"/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="border py-0">
                                <Dropdown.Item
                                    active={!closed}
                                    onClick={() => setClosed(false)}
                                >
                                    Открытые
                                </Dropdown.Item>
                                <Dropdown.Item
                                    active={closed}
                                    onClick={() => setClosed(true)}
                                >
                                    Закрытые
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )}
            </div>
        </div>
    )
}

export default TicketsHeader;
import { FC } from "react";
import { fromUtcDate } from "@services/helpers";
import { useNotificationContext } from "./context";
import { Text } from '@constructor/components';

const NotificationTime: FC = (props) => {
    const not = useNotificationContext();

    if(!not?.createTime) {
        return null;
    }

    return (
        <Text text={fromUtcDate(not.createTime).calendar()} {...props} />
    )
}

export default NotificationTime;
import * as React from 'react';
import { Collapse, Nav } from 'reactstrap';
import { ReduxStore } from '@interfaces/store';
import { connect } from 'react-redux';
import { NavbarNotifications } from './dropdowns';
import { NavbarSettings } from './dropdowns';
import {FC} from "react";




interface NavbarDropdownsStateProps {
    isOnRight: boolean;
}

const NavbarDropdowns: FC<NavbarDropdownsStateProps> = ({ isOnRight }) => {
    return (
        <Collapse navbar>
            <Nav className={/*!isOnRight ? "ml-auto" : "mr-auto"*/'ms-auto'} navbar>

                
                <NavbarNotifications />
                <NavbarSettings />

            </Nav>
        </Collapse>
    );
};

function mapStateToProps(state: ReduxStore): NavbarDropdownsStateProps {
    return {
        isOnRight: state.sidebar.isOnRight
    }
}

export default connect(mapStateToProps)(NavbarDropdowns);
import { useDataLoading } from "@hooks/loadingHooks";
import { ReduxStore } from "@interfaces/store";
import { userActions } from "@store/user";
import { useSelector } from "react-redux";

export function useRefCode() {
    const data = useSelector((store: ReduxStore) => store.user.refs.code);
    return useDataLoading(userActions.refs.loadCode, data);
}

export function useRefStats() {
    const data = useSelector((store: ReduxStore) => store.user.refs.stats);
    return useDataLoading(userActions.refs.loadStats, data);
}
import {FC} from "react";
import {Button, Card, Col, Row} from "react-bootstrap";
import {useHistory} from "react-router-dom";

const FooterBlock: FC = () => {

    const history = useHistory();
    const back = () => history.replace('/consumer/payments/bonuses/');

    return (
        <Card>
            <Card.Body>
                <Row className="flex-between-center">
                    <Col md>
                        <h5 className="mb-2 mb-md-0">{/*You're almost done*/}</h5>
                    </Col>
                    <Col xs="auto">
                        <Button
                            variant="link"
                            className="text-secondary fw-medium p-0 me-3"
                            type="button"
                            onClick={back}
                        >
                            Отменить
                        </Button>
                        <Button variant="primary" type="submit">
                            Сохранить
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default FooterBlock;
import { createSlice, Dispatch } from "@reduxjs/toolkit";
import { UserShortNewsStore } from "../../interfaces/user/userStore";
import UserService from '../../services/api/userService';

const slice = createSlice({
    initialState: {
        loaded: false,
        loading: false,
        error: null,
        data: []
    } as UserShortNewsStore,
    name: 'userShortNewsStore',
    reducers: {
        setLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        setNews: (state, action) => {
            state.data = action.payload;
            state.error = null;
            state.loaded = true;
            state.loading = false;
        }
    }
})

export const { reducer: userShortNewsReducer } = slice;

const {
    setError,
    setLoading,
    setNews
} = slice.actions;

export const loadNews = () => async (dispatch: Dispatch) => {
    dispatch(setLoading());

    try {
        const api = new UserService();
        const news = await api.fetchUserShortNews();
        dispatch(setNews(news));
        return news;
    }
    catch(e) {
        dispatch(setError(e));
    }
}
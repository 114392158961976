import {FC, PropsWithChildren} from "react";
import classNames from "classnames";

interface SubtleBadgeProps {
    pill?: boolean;
    bg: 'primary' |
    'secondary' |
    'success' |
    'info' |
    'warning' |
    'danger' |
    'light' |
    'dark';
    className?: string;
}

const SubtleBadge: FC<PropsWithChildren<SubtleBadgeProps>> = ({pill, bg, className, children}) => (
    <div
        className={classNames(className, `badge badge-subtle-${bg}`, {
            'rounded-pill': pill
        })}
    >
        {children}
    </div>
)

export default SubtleBadge;
import ConsumerRefService from "@api/consumer/consumerRefService";
import { IRefTarget } from "@interfaces/consumer/refs";
import { FC, useCallback } from "react";
import { toastr } from "react-redux-toastr";
import { useParams } from "react-router-dom";
import { CardBody } from "reactstrap";
import RefTargetForm, { RefTargetFormValues } from "./targetForm";

interface EditRefTargetFormProps {
    target: IRefTarget;
}

const EditRefTargetForm: FC<EditRefTargetFormProps> = ({ target }) => {

    const { ref_id } = useParams<{ ref_id: string }>();

    const initialValues: RefTargetFormValues = {
        goalId: target.goalId,
        isActive: target.isActive
    };

    const onSubmit = useCallback(async (values: RefTargetFormValues) => {
        const api = new ConsumerRefService();
        const result = await api.patchRefTarget(ref_id, {
            id: target.id,
            goalId: values.goalId,
            isActive: values.isActive
        });

        if (result.success) {
            toastr.success('Редактирование реферального бонуса', 'Цель успешно обновлена');
        }
        else {
            if (result.errorCode === 'RefNotFound') {
                toastr.error('Редактирование реферального бонуса', 'Бонус не найден');
            }
            else if (result.errorCode === 'GoalNotFound') {
                toastr.error('Редактирование реферального бонуса', 'Цель не найдена');
            }
            else if (result.errorCode === 'TargetExists') {
                toastr.error('Редактирование реферального бонуса', 'Эта цель уже существует');
            }
            else {
                toastr.error('Редактирование реферального бонуса', 'Неизвестная ошибка');
            }
        }
    }, [ref_id, target.id]);

    return (
        <CardBody>
            <RefTargetForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                editForm />
        </CardBody>
    )
}

export default EditRefTargetForm;
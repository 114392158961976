import {useState, useContext, FC, PropsWithChildren, createContext} from 'react';
import { PageRouting } from '@interfaces/panel/routing';
import { Helmet } from 'react-helmet';
import { useSelector, shallowEqual } from 'react-redux';
import { ReduxStore } from '@interfaces/store';
import { injectChildArray } from '@components/constructor';

const PageRouteContext = createContext<PageRouting>(undefined as any);
const PageRouteProvider = PageRouteContext.Provider;

export function usePageRouteContext() {
    return useContext(PageRouteContext);
}

interface PageProps {
    route_info: PageRouting;
}

export const Page: FC<PropsWithChildren<PageProps>> = ({ children, route_info }) => {

    const [value] = useState(route_info);

    return (
        <PageRouteProvider value={value} >
            <Helmet title={value.title} />
            {children}
        </PageRouteProvider>
    )

}

export const PageName: React.FC = () => {

    const context = usePageRouteContext();
    if (!context?.name) {
        return null;
    }

    return (
        <>{context.name}</>
    )
}

export const PageTitle: React.FC = () => {
    const context = usePageRouteContext();
    if (!context?.title) {
        return null;
    }

    return (
        <>{context.title}</>
    )
}

export const Breadcrumbs: FC<PropsWithChildren> = ({ children }) => {

    const context = usePageRouteContext();
    const infos = useSelector(({ panel }: ReduxStore) => panel.breadcrumbs, shallowEqual);

    if (!context.items || context.items.length === 0) {
        return null;
    }

    const { name, code, items } = context;
    const currentName = (code && infos[code]?.name) || name;

    const crumbs = items.map(({ name, code, useLink, link }, idx) => {

        const bc = code && infos[code];
        name = (bc && bc.name) || name;

        return {
            crumb_idx: idx,
            crumb_name: name,
            crumb_use_link: link && useLink,
            crumb_link: link,
            crumb_active: false
        }
    });

    crumbs.push({
        crumb_idx: crumbs.length,
        crumb_name: currentName,
        crumb_active: true,
        crumb_use_link: false,
        crumb_link: ''
    });

    return injectChildArray(crumbs, children, 'crumb_idx');
}
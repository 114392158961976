import { FC } from "react";
import { useSupportMessageContext } from "../context";
import { Text } from '@components/constructor/components';

const SupportMessageText: FC = (props) => {

    const message = useSupportMessageContext();

    if (!message) {
        return null;
    }

    return (
        <Text text={message.message} {...props} />
    )
}

export default SupportMessageText;
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { CustomQuery, EQueryTarget, IQueryResult } from "@interfaces/query";
import { QueriesStore, ReduxStore } from "@interfaces/store";
import QueryService from "@api/user/queryService";
import FetchResult from "@models/fetchResult";

const slice = createSlice({
    initialState: {
        queries: {
            loaded: false,
            loading: false,
            error: null,
            data: []
        },
        results: {}
    } as QueriesStore,
    name: 'queriesStore',
    reducers: {
        setLoading: (state) => {
            state.queries.loading = true;
            state.queries.error = null;
        },
        setError: (state, action) => {
            state.queries.loaded = false;
            state.queries.data = [];
            state.queries.loading = false;
            state.queries.error = action.payload;
        },
        setQueries: (state, action: PayloadAction<CustomQuery[]>) => {
            state.queries.data = action.payload;
            state.queries.error = null;
            state.queries.loaded = true;
            state.queries.loading = false;
        },
        concatQueries: (state, action: PayloadAction<CustomQuery[]>) => {
            state.queries.error = null;
            state.queries.loaded = true;
            state.queries.loading = false;
            action.payload.forEach(query => {
                if(state.queries.data.findIndex(q => q.id === query.id) === -1) {
                    state.queries.data.push(query);
                }
            })
        },
        setQueryResult: (state, { payload: { id, result } }: PayloadAction<{ id: string, result: IQueryResult }>) => {
            state.results[id] = result;
        }
    }
})

export const { reducer: queryReducer } = slice;

const {
    setError,
    setLoading,
    setQueries,
    setQueryResult,
    concatQueries
} = slice.actions;

export const queryActions = {
    loadQueries: () => async (dispatch: Dispatch) => {
        dispatch(setLoading());
        try {
            const api = new QueryService();
            const queries = await api.fetchQueries();
            dispatch(setQueries(queries));
            return queries;
        }
        catch (e) {
            dispatch(setError(e));
        }
    },
    executeQuery: (id: string, param?: string) => async (dispatch: Dispatch, getState: () => ReduxStore) => {
        try {
            const { queries: { queries: { data: queries }, results } } = getState();
            const query = queries.find(q => q.id === id);

            if(!query) {
                return FetchResult.fault<IQueryResult>();
            }

            let resultId = query.id;
            if(query.target !== EQueryTarget.Server && param) {
                resultId += ":" + param;
            }

            const storedResult = results[resultId];
            if(storedResult?.cacheTime && Date.parse(storedResult.cacheTime) > Date.now()) {
                return FetchResult.fromData<IQueryResult>(storedResult);
            }

            const api = new QueryService();
            const result = await api.executeCustomQuery({
                queryId: id,
                parameter: param
            });

            if (result.success && result.data.cacheTime) {
                dispatch(setQueryResult({
                    id: resultId,
                    result: result.data
                }));
            }

            return result;
        } catch (error) {
            return error as FetchResult<IQueryResult>;
        }
    },
    setQueries,
    concatQueries
}
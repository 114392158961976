import { SidebarCategoryLink } from '../../interfaces/control-panel';
import { PanelLanguage, PanelSettings, LanguageInfo, PanelInfo } from '../../interfaces/panel';
import { Theme } from '../../interfaces/panel/themes';
import IService from './iService';

export default class PanelService extends IService {
    
    projectId = (window as any).ProjectID;

    async fetchPanelSettings(): Promise<PanelSettings> {
        return await this.getAndThrowApi<PanelSettings>(`/panel/settings/`);
    }

    async fetchPanelInfo() {
        return await this.getAndThrowApi<PanelInfo>('/panel/info/');
    }

    async fetchPanelLangHash(lang: string) {
        return await this.getAndThrowApi<string>(`/panel/langhash/${lang}/`);
    }

    async fetchPanelLanguage(lang: string) {
        return await this.getAndThrowApi<PanelLanguage>(`/panel/lang/${lang}/`);
    }

    async fetchLanguageInfos() {
        return await this.getAndThrowApi<LanguageInfo[]>(`/panel/lang/`);
    }

    async fetchPanelTheme(name: string) {
        const result = await fetch(`/themes/${name}/theme.json`);
        const txt = await result.text();
        return JSON.parse(txt.replaceAll('{themeUrl}', `/themes/${name}`)) as Theme;
        //return await result.json() as Theme;
    }

    async fetchSidebar(): Promise<SidebarCategoryLink[]> {
        return await this.getAndThrowApi<SidebarCategoryLink[]>('/user/sidebar/')
    }    
}
import { useRole } from '@hooks/userHooks';
import React, {useCallback, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import { Card } from 'react-bootstrap';
import withPage from '@components/hoc/withPage';
import {ColumnDef} from "@tanstack/react-table";
import {ConsumerShortNews} from "@interfaces/consumer/shortNews";
import {Flex, IconButton} from "@components/falcon/common";
import {Form} from "react-bootstrap";
import {dateFormatter} from "@services/helpers";
import ConsumerShortNewsService from "@api/consumer/consumerShortNewsService";
import {useRemoteTable} from "@hooks/tableHooks";
import AdvanceTableProvider from "@components/falcon/common/tables/context";
import {AdvanceTable, AdvanceTableFooter, AdvanceTableSearchBox} from "@components/falcon/common/tables";

const columns: ColumnDef<ConsumerShortNews>[] = [
    {
        accessorKey: 'title',
        header: 'Заголовок',
        enableSorting: true,
        cell: ({row: {original}}) => (
            <Link to={`/consumer/shortnews/${original.id}/`}>
                <Flex alignItems="center" className="flex-1">
                    <h5 className="mb-0 fs-10">{original.title}</h5>
                </Flex>
            </Link>
        ),
        meta: {
            headerProps: { className: 'pe-1 text-900' },
            cellProps: { className: 'py-2' }
        }
    },
    {
        accessorKey: 'isActive',
        header: 'Активно',
        enableSorting: true,
        cell: ({row: {original}}) => (
            <Form.Check type="checkbox" defaultChecked={original.isActive} disabled />
        ),
        meta: {
            headerProps: { className: 'text-center text-900' },
            cellProps: { className: 'text-center' },
            placeholderSize: 1
        }
    },
    {
        accessorKey: 'createTime',
        header: 'Дата создания',
        enableSorting: true,
        cell: ({row: {original}}) => dateFormatter(original.createTime),
        meta: {
            headerProps: { className: 'text-900 text-end' },
            cellProps: { className: 'text-end' },
            placeholderSize: 4
        }
    },
    {
        accessorKey: 'beginTime',
        header: 'Дата начала',
        enableSorting: true,
        cell: ({row: {original}}) => dateFormatter(original.createTime),
        meta: {
            headerProps: { className: 'text-900 text-end' },
            cellProps: { className: 'text-end' },
            placeholderSize: 4
        }
    },
    {
        accessorKey: 'endTime',
        header: 'Дата конца',
        enableSorting: true,
        cell: ({row: {original}}) => dateFormatter(original.createTime),
        meta: {
            headerProps: { className: 'text-900 text-end' },
            cellProps: { className: 'text-end' },
            placeholderSize: 4
        }
    }

]


const ConsumerShortNewsListPage: React.FC = () => {

    const history = useHistory();
    const [api] = useState(() => new ConsumerShortNewsService());
    const canEdit = useRole('consumer.shortnews.edit');

    const add = useCallback(() => history.push('/consumer/shortnews/add/'), [history]);

    const table = useRemoteTable({
        name: 'consumer.shortnews.list',
        columns,
        defaultState: {
            sorting: [
                {
                    id: 'title',
                    desc: false
                }
            ]
        }
    }, api.fetchConsumerShortNewsList);

    return (
        <AdvanceTableProvider table={table}>
            <Card className="mb-3">
                <Card.Header>
                    <Flex className='align-items-center justify-content-between'>
                        <Flex alignItems="center" className="fs-10">
                            <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">Новости</h5>
                        </Flex>
                        <Flex>
                            {canEdit && (
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="plus"
                                    transform="shrink-3"
                                    className='me-2'
                                    onClick={add}
                                >
                                    <span className="d-none d-sm-inline-block ms-1">Создать</span>
                                </IconButton>
                            )}
                            <Flex><AdvanceTableSearchBox/></Flex>
                        </Flex>
                    </Flex>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowInfo
                        rowsPerPageSelection
                        paginationButtons
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableProvider>
    )
}

export default withPage(ConsumerShortNewsListPage);
import { useDataLoading } from "@hooks/loadingHooks";
import { ReduxStore } from "@interfaces/store";
import { cPaymentsActions } from "@store/consumer/consumerPaymentsStore";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

export function usePaymentBonuses() {
    const bonuses = useSelector(({ consumer: { payments: { bonuses } } }: ReduxStore) => bonuses, shallowEqual);
    return useDataLoading(cPaymentsActions.loadBonuses, bonuses);
}

export function usePaymentBonus(id: string) {
    const dispatch = useDispatch();
    const result = useSelector(({ consumer: { payments: { bonuses } } }: ReduxStore) => ({
        loading: bonuses.loading,
        loaded: bonuses.loaded,
        error: bonuses.error,
        bonus: bonuses.data.find(b => b.id === id)
    }), shallowEqual);

    useEffect(() => {
        if(!result.loading && !result.loaded && !result.error) {
            dispatch(cPaymentsActions.loadBonuses());
        }
    }, [dispatch, result.error, result.loaded, result.loading]);

    return result;
}
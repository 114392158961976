import { usePagination } from "@hooks/components/constructor.hooks";
import {FC, PropsWithChildren, useMemo} from "react";
import { useGoalContext } from "../context";
import { PaginationProps } from "@constructor/components/core/pagination";
import { injectChild } from "@components/constructor";

interface GoalRewardsProps {
    goal_rewards_per_page: number;
}

interface GoalRewardsChildProps {
    goal_rewards_count: number;
}

const GoalRewards: FC<PropsWithChildren<GoalRewardsProps>> = ({ goal_rewards_per_page = 10, children }) => {

    const goal = useGoalContext();
    const rewards = useMemo(() => goal?.rewards || [], [goal?.rewards]);
    const pagination = usePagination(goal_rewards_per_page, rewards.length);

    const data = useMemo<GoalRewardsChildProps & PaginationProps>(() => {
        return {
            goal_rewards_count: rewards.length,
            ...pagination
        }
    }, [rewards.length, pagination]);

    return injectChild(data, children);
}

export default GoalRewards;
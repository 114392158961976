import { usePromisedDispatch } from "@hooks/helperHooks";
import { useLoginServerAuto } from "@hooks/selectors/game.selectors";
import {FC, PropsWithChildren, useCallback} from "react";
import { LoginIdProps } from "@constructor/components/loginServer";
import { PutGameAccountModel } from "@models/user/data";
import { BasicToastrOptions, toastr } from "react-redux-toastr";
import { userActions } from "@store/user";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from 'yup';

interface CreateAccountFormValues {
    loginId: string;
    account: string;
    password: string;
    repeatPassword: string;
}

interface CreateAccountFormProps extends LoginIdProps {
    modalToggle?: () => void;
}

const CreateAccountForm: FC<PropsWithChildren<CreateAccountFormProps>> = ({ children, login_id, modalToggle, ...props }) => {

    const loginServer = useLoginServerAuto(login_id);
    const dispatch = usePromisedDispatch();

    const loginId = loginServer?.id;

    const validation = Yup.object().shape({
        loginId: Yup.string()
            .required("Выберите сервер"),
        account: Yup.string()
            .min(4, "Не менее 4 символов")
            .max(10, "Не более 10 символов")
            .required("Обязательно"),
        password: Yup.string()
            .min(6, "Не менее 6 символов")
            .max(16, "Не более 16 символов")
            .required("Обязательно")
            .matches(/[A-Z]/, "Должен содержать заглавные буквы")
            .matches(/[a-z]/, "Должен содержать прописные буквы")
            .matches(/[0-9]/, "Должен содержать цифры"),
        repeatPassword: Yup.string()
            .required("Обязательно")
            .oneOf([Yup.ref('password')], "Пароли не совпадают")
    });

    const onSubmit = useCallback(async (values: CreateAccountFormValues, actions: FormikHelpers<CreateAccountFormValues>) => {

        const account: PutGameAccountModel = {
            loginServer: values.loginId,
            name: values.account,
            password: values.password
        };

        const result = await dispatch(userActions.createAccount(account));

        if (result.ok) {
            const options: BasicToastrOptions = {
                timeOut: 10000,
                showCloseButton: true,
                progressBar: true,

            }
            toastr.success("Создание игрового аккаунта", "Акаунт успешно создан", options);
            if (modalToggle) {
                modalToggle();
            }
        }
        else {
            if (result.errorCode === 'AlreadyExist') {
                actions.setFieldError('account', 'Аккаунт уже существует');
            }
            else if (result.errorCode === 'InvalidName') {
                actions.setFieldError('account', 'Некорректное имя');
            }
            else if (result.errorCode === 'QueryError') {
                actions.setFieldError('account', 'Ошибка создания аккаунта. Обратитесь к администратору');
            }
            else {
                actions.setFieldError('account', 'Ошибка запроса. Обновите страницу');
            }
        }
    }, [dispatch, modalToggle]);

    return (
        <Formik
            validationSchema={validation}
            onSubmit={onSubmit}
            initialValues={{
                account: '',
                password: '',
                repeatPassword: '',
                loginId: loginId || ''
            } as CreateAccountFormValues}>
            <Form {...props}>
                {children}
            </Form>
        </Formik>
    )
}

export default CreateAccountForm;
import React from 'react';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Header from '../panel/header';
import HeaderTitle from '../panel/header-title';
import { Link } from 'react-router-dom';
import { RouterItemInfo } from '@interfaces/control-panel';
import { Helmet } from 'react-helmet';
import { useSelector, shallowEqual } from 'react-redux';
import { ReduxStore } from '@interfaces/store';
import { PageRouting } from '@interfaces/panel/routing';
import {Card, Col, Row} from "react-bootstrap";
import {Flex} from "@components/falcon/common";


import barChart from '../../assets/img/illustrations/crm-bar-chart.png';
import lineChart from '../../assets/img/illustrations/crm-line-chart.png';

const withPage = <T extends {}>(View: React.ComponentType<T>) => {

    interface BlankProps {
        route_info: PageRouting;
    }

    return class extends React.Component<BlankProps & T> {
        render() {
            const { route_info: { name, items, title, code } } = this.props;

            return (
                <>
                    <Helmet title={title} />
                    <Card className="bg-100 shadow-none border mb-3">
                        <Card.Body className="py-0">
                            <Row className="g-0 justify-content-between">
                                <Col sm="auto">
                                    <Flex alignItems="center">
                                        <img src={barChart} width={90} alt="..." className="ms-n4"/>
                                        <div>
                                            <h4 className="text-primary fw-bold mb-0">{name}</h4>
                                            <h6 className="text-primary fs-10 mb-0"><Breadcrumbs items={items} currentName={name} codeName={code} /></h6>
                                        </div>

                                    </Flex>

                                </Col>
                            </Row>
                            <Row>

                            </Row>
                        </Card.Body>
                    </Card>

                    <View {...this.props} />
                </>
            )
        }
    }
}

interface BreadcrumbsProps {
    items: RouterItemInfo[];
    currentName?: string;
    codeName?: string;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items, currentName, codeName }) => {

    const infos = useSelector(({ panel }: ReduxStore) => panel.breadcrumbs, shallowEqual);
    const bname = (codeName && infos[codeName] && infos[codeName].name) || currentName;

    return (
        <Breadcrumb>
            {
                items.map(({ name, code, useLink, link }, idx) => {

                    const bc = code && infos[code];
                    name = (bc && bc.name) || name;
                    link = (bc && bc.link) || link;

                    return (
                        name &&
                        <BreadcrumbItem key={idx}>
                            {
                                useLink ?
                                    <Link to={link}>{name}</Link> :
                                    name
                            }
                        </BreadcrumbItem>
                    )
                })
            }
            <BreadcrumbItem active>{bname}</BreadcrumbItem>
        </Breadcrumb>
    )
}

export default withPage;
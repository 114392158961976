import { FC } from "react";
import { useBonusLogContext } from "./context";
import { Text } from '@constructor/components';
import { usePaymentCurrency } from "@hooks/selectors/panel.selectors";
import { BonusCodeType, ICodeTypeBalance, ICodeTypeItem } from "@interfaces/user/bonusCodes";
import { useItem } from "@hooks/selectors/user/item.selectors";

const BonusCodeValue: FC = (props) => {

    const code = useBonusLogContext();
    const currency = usePaymentCurrency();

    const itemInfo = code?.valueInfo as ICodeTypeItem;
    const item = useItem(itemInfo?.itemId);

    if(!code) {
        return null;
    }

    if(code.type === BonusCodeType.Balance) {
        return (
            <Text text={`${(code.valueInfo as ICodeTypeBalance).value} ${currency}`} {...props} />
        )
    }
    else {
        
        return (
            <Text text={`${item?.name} x${itemInfo.count}`} {...props} />
        )
    }
}

export default BonusCodeValue;
import React, {FC} from "react";
import {Card, Col, Row} from "react-bootstrap";

interface FormPageHeaderProps {
    title: string;
}

const FormPageHeader: FC<FormPageHeaderProps> = ({title}) => (
    <Card>
        <Card.Body>
            <Row className="flex-between-center">
                <Col md>
                    <h5 className="mb-2 mb-md-0">{title}</h5>
                </Col>
            </Row>
        </Card.Body>
    </Card>
)

export default FormPageHeader;
import React, {Fragment} from "react";
import {shallowEqual} from "react-redux";
import {ConstructionComponentProps, InjectContextType} from "@interfaces/constructor/constructor";

export function injectChild<T>(data: T, children: React.ReactNode, childName?: string) {


    return (
        <Fragment>
            {
                React.Children.map(children, c => {
                    if (React.isValidElement<ConstructionComponentProps>(c)) {

                        const newChildProps = childName ? { ...c.props.childProps, ...{ [childName]: data } } : { ...c.props.childProps, ...data };
                        const newProps = shallowEqual(newChildProps, c.props.childProps) ? c.props : { ...c.props, childProps: newChildProps };
                        return React.cloneElement<ConstructionComponentProps>(c, newProps);
                    }
                    else if (React.isValidElement(c)) {
                        return React.cloneElement(c, c.props);
                    }
                })
            }
        </Fragment>
    )
}

export function injectChildArray<T>(data: T[], children: React.ReactNode, key: keyof(T), childName?: string) {
    return (
        <React.Fragment>
            {
                data.map(m => (
                    <React.Fragment key={m[key] as string}>
                        {
                            React.Children.map(children, c => {
                                if (React.isValidElement<ConstructionComponentProps>(c)) {
                                    const newChildProps = childName ? { ...c.props.childProps, ...{ [childName]: m } } : { ...c.props.childProps, ...m };
                                    const newProps = shallowEqual(newChildProps, c.props.childProps) ? c.props : { ...c.props, childProps: newChildProps };
                                    return React.cloneElement<ConstructionComponentProps>(c, newProps);
                                }
                            })
                        }
                    </React.Fragment>
                ))
            }
        </React.Fragment>
    )
}

export function injectContextArray<T>(data: InjectContextType<T>[], Provider: React.Provider<T>, children: React.ReactNode, childName?: string) {
    return (
        <React.Fragment>
            {
                data.map(({ key, value, childData }) => (
                    <Provider value={value} key={key}>
                        {
                            React.Children.map(children, c => {
                                if (React.isValidElement<ConstructionComponentProps>(c)) {
                                    const newChildProps = childName ? { ...c.props.childProps, ...{ [childName]: childData } } : { ...c.props.childProps, ...childData };
                                    const newProps = shallowEqual(newChildProps, c.props.childProps) ? c.props : { ...c.props, childProps: newChildProps };
                                    return React.cloneElement<ConstructionComponentProps>(c, newProps);
                                }
                            })
                        }
                    </Provider>
                ))
            }
        </React.Fragment>
    )
}

export function injectContextAsArray<T>(data: InjectContextType<T>[], Provider: React.Provider<T>, children: React.ReactNode, childName?: string) {
    return data.map(({ key, value, childData }) => (
        <Provider value={value} key={key}>
            {
                React.Children.map(children, c => {
                    if (React.isValidElement<ConstructionComponentProps>(c)) {
                        const newChildProps = childName ? { ...c.props.childProps, ...{ [childName]: childData } } : { ...c.props.childProps, ...childData };
                        const newProps = shallowEqual(newChildProps, c.props.childProps) ? c.props : { ...c.props, childProps: newChildProps };
                        return React.cloneElement<ConstructionComponentProps>(c, newProps);
                    }
                })
            }
        </Provider>
    ))
}
import {FC, useCallback, useState} from 'react';
import {Form, Formik} from 'formik';
import { FileUploader } from '../../../../services';
import { UploadFileInfo } from '@interfaces/panel/files';
import { useHistory } from 'react-router-dom';
import withPage from '../../../hoc/withPage';
import ConsumerMarketService from '../../../../services/api/consumer/consumerMarketService';
import {Col, Row} from "react-bootstrap";
import {FormIconBlock, FormPageFooter, FormPageHeader} from "@components/falcon/common/forms";
import {BaseInfoBlock} from "@components/consumer/market/categories/forms";
import * as Yup from "yup";
import {toastr} from "react-redux-toastr";

interface AddCategoryModalForm {
    name: string;
    shortName: string;
    description: string;
    icon?: File | null;
}

const AddCategoryForm: FC = () => {

    const history = useHistory();
    const [uploading, setUploading] = useState(false);
    const [uploadState, setUploadState] = useState(0);

    const onProgress = useCallback((event: ProgressEvent<EventTarget>) => {
        setUploadState(event.loaded / event.total * 100);
    }, [setUploadState]);

    const initialValues: AddCategoryModalForm = {
        name: '',
        shortName: '',
        description: '',
        icon: null
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(),
        shortName: Yup.string()
            .min(3)
            .max(64)
            .required(),
        description: Yup.string(),
        icon: Yup.mixed()
    });

    const onSubmit = async (values: AddCategoryModalForm) => {
        const api = new ConsumerMarketService();
        const result = await api.putConsumerMarketCategory({
            name: values.name,
            shortName: values.shortName,
            description: values.description,
            uploadIcon: Boolean(values.icon)
        });

        if (result.ok) {
            if (result.result?.upload && values.icon) {
                const upload: UploadFileInfo = result.result?.upload;
                const file = new FileUploader(upload);
                file.onProgress = onProgress;
                try {
                    setUploading(true);
                    await file.upload(values.icon);
                    toastr.success("Создание категории", "Категория успешно создана");
                    history.replace(`/consumer/market/category/${result.result.id}/`);
                }
                catch (e) {
                    toastr.warning("Создание категории", "Категория создана, но не удалось загрузить изображение.");
                    console.log(e);
                }
                finally {
                    setUploading(false);
                    setUploadState(0);
                }
            }
            else {
                toastr.success("Создание категории", "Категория успешно создана");
                history.replace(`/consumer/market/category/${result.result?.id}/`);
            }

        }
        else {
            if(result.errorCode === "AlreadyExists") {
                toastr.error("Создание категории", "Категория с таким адресом уже существует");
            }
            else {
                toastr.error("Создание категории", "Не удалось создать категорию. Попробуйте позже.");
            }
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            <Form>
                <Row className="g-3">
                    <Col xs={12}>
                        <FormPageHeader title="Создание категории магазина" />
                    </Col>
                    <Col lg={8}>
                        <BaseInfoBlock />
                    </Col>
                    <Col lg={4}>
                        <FormIconBlock
                            title="Иконка категории"
                            uploading={uploading}
                            uploadProgress={uploadState}
                        />
                    </Col>
                    <Col>
                        <FormPageFooter
                            cancelUrl={'/consumer/market/category/'}
                        />
                    </Col>
                </Row>
            </Form>
        </Formik>
    )
}

export default withPage(AddCategoryForm);
import {DownloadFileInfo, UploadFileInfo} from "@interfaces/panel/files";
import { GetFilesModel, UploadFileDoneModel } from "@models/panel";
import IService from "./iService";

export default class FilesService extends IService {

    async finishFileUploading (file: UploadFileDoneModel) {
        return await this.putApi<DownloadFileInfo>('/services/files/', file);
    }

    async failFileUploading (file: UploadFileInfo) {
        return await this.postApi('/services/files/failed/', file);
    }
    
    async getFiles(fileIds: string[]) {
        return await this.postApi<DownloadFileInfo[]>('/services/files/', {
            fileIds
        } as GetFilesModel);
    }
}
export const chartJsDefaultTooltip = (getThemeColor: (color: string) => string) => ({
    backgroundColor: getThemeColor('gray-100'),
    borderColor: getThemeColor('gray-300'),
    borderWidth: 1,
    titleColor: getThemeColor('gray-1100'),
    callbacks: {
        labelTextColor() {
            return getThemeColor('gray-1100');
        }
    }
});
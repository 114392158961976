export interface ConsumerBonusCodesList {
    page: number;
    search?: string;
    count: number;
    codes: ConsumerBonusCode[];
}

export interface ConsumerBonusCodeFull {
    id: string;
    code: string;
    createTime: string;
    isActual: boolean;
    owner: string | null;
    ownerName: string | null;
    type: BonusCodeType;
    value: ICodeTypeItem | ICodeTypeBalance;
    valueString: string;
    maxUseCount: number;
    useCount: number;
    beginTime?: string;
    endTime?: string;
    isActive: boolean;
    access: BonusCodeAccess;
    uses: ConsumerBonusCodeUse[];
}


export interface ConsumerBonusCode {
    id: string;
    code: string;
    createTime: string;
    isUsed: boolean;
    useTime: string;
    value: string;
    user: string | null;
    userName: string | null;
    owner: string | null;
    ownerName: string | null;
    type: BonusCodeType;
}

export interface ConsumerBonusCodeUse {
    id: string;
    codeId: string;
    userId: string;
    userName: string;
    useTime: string;
}

export interface ICodeTypeItem {
    itemId: string;
    count: number;
}

export interface ICodeTypeBalance {
    value: number;
}

export enum BonusCodeType {
    Balance = 'Balance',
    Item = 'Item'
}

export enum BonusCodeAccess {
    Default = 'Default',
    SignUp = 'SignUp'
}
import IService from "@api/iService";
import { ICheckGift, IDailyStat, IGift } from "@interfaces/user/daily";

export default class DailyService extends IService {

    async getGifts() {
        return await this.getApi<IGift[]>('/daily/user/');
    }

    async getStats() {
        return await this.getApi<IDailyStat[]>('/daily/user/stats/');
    }

    async checkDaily () {
        return await this.getApi<ICheckGift>('/daily/user/check/');
    }
    
}
import {FC, useState} from 'react';
import { ConsumerPayment } from '@interfaces/consumer/payments';
import { Link } from 'react-router-dom';
import { dateFormatter } from '../../../services';
import { Card } from 'react-bootstrap';
import withPage from '../../hoc/withPage';
import {ColumnDef} from "@tanstack/react-table";
import {Flex} from "@components/falcon/common";
import {useRemoteTable} from "@hooks/tableHooks";
import {AdvanceTable, AdvanceTableFooter, AdvanceTableSearchBox} from "@components/falcon/common/tables";
import AdvanceTableProvider from "@components/falcon/common/tables/context";
import {usePaymentCurrency} from "@hooks/selectors/panel.selectors";
import {PaymentStateBadge} from "@components/consumer/payments/index";
import CPaymentsService from "@api/consumer/consumerPaymentsService";
import {EPaymentCurrency} from "@interfaces/user/payments";

interface ProfitCellProps {
    profit: number;
    normalizedProfit: number;
    currency: EPaymentCurrency;
}

const ProfitCell: FC<ProfitCellProps> = ({profit, normalizedProfit, currency}) => {
    const sysCurrency = usePaymentCurrency();
    return profit === normalizedProfit ? `${profit} ${sysCurrency}` : `${profit} ${currency} (${normalizedProfit} ${sysCurrency})`
}

const columns: ColumnDef<ConsumerPayment>[] = [
    {
        accessorKey: 'orderId',
        header: 'Заказ',
        enableSorting: true,
        meta: {
            headerProps: { className: 'pe-1 text-900' },
            cellProps: { className: 'py-2' }
        },
        cell: ({row: {original: {orderId, userName, userId}}}) => (
            <>
                <Link to={`/consumer/payments/${orderId}/`}>
                    <strong>{orderId}</strong>
                </Link>{' '}
                от <Link to={`/consumer/users/${userId}/`}><strong>{userName}</strong></Link>
            </>
        )
    },
    {
        accessorKey: 'finishTime',
        header: 'Дата',
        enableSorting: true,
        cell: ({row: {original: {finishTime}}}) => dateFormatter(finishTime),
        meta: {
            placeholderSize: 8
        }
    },
    {
        accessorKey: 'aggregator',
        header: 'Агрегатор',
        enableSorting: false,
        meta: {
            headerProps: { className: 'text-900' },
            placeholderSize: 4
        }
    },
    {
        accessorKey: 'state',
        header: 'Статус',
        enableSorting: false,
        meta: {
            headerProps: {
                className: 'text-center text-900'
            },
            cellProps: {
                className: 'fs-9'
            },
            placeholderSize: 10
        },
        cell: ({row: {original: {state}}}) => (
            <PaymentStateBadge state={state} className="d-flex flex-center"/>
        )
    },
    {
        accessorKey: 'profit',
        header: 'Доход',
        enableSorting: true,
        meta: {
            headerProps: { className: 'text-end text-900' },
            cellProps: {
                className: 'text-end fs-9 fw-medium py-2'
            },
            placeholderSize: 3
        },
        cell: ({row: {original: {normalizedProfit, profit, currency}}}) => (
            <ProfitCell profit={profit} normalizedProfit={normalizedProfit} currency={currency} />
        )
    }
]

const ConsumerPaymentsList: FC = () => {


    const [api] = useState(() => new CPaymentsService());

    const table = useRemoteTable({
        name: 'consumer.payments.list',
        columns,
        defaultState: {
            sorting: [
                {
                    id: 'orderId',
                    desc: true
                }
            ]
        }
    }, api.fetchConsumerPaymentsList);

    return (
        <AdvanceTableProvider table={table}>
            <Card className="mb-3">
                <Card.Header>
                    <Flex className='align-items-center justify-content-between'>
                        <Flex alignItems="center" className="fs-10">
                            <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">Платежи</h5>
                        </Flex>
                        <Flex>
                            <AdvanceTableSearchBox/>
                        </Flex>
                    </Flex>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowInfo
                        rowsPerPageSelection
                        paginationButtons
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableProvider>
    )
}

export default withPage(ConsumerPaymentsList);
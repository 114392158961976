import { UserAuthState } from '../../interfaces/user/users';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthUser } from '../../models/auth/authModels';

const slice = createSlice({
    initialState: {
        isAuthed: false,
        tokenLoaded: false,
        user: null
    } as UserAuthState,
    name: 'userAuthStore',
    reducers: {
        setUser: (state, action: PayloadAction<AuthUser | null>) => {
            state.isAuthed = !!action.payload;
            state.user = action.payload;
            state.tokenLoaded = true;
        },
        setAuthState: (state, action: PayloadAction<boolean>) => {
            state.isAuthed = action.payload;
            state.user = null;
            state.tokenLoaded = true;
        }
    }
})

export const { reducer: userAuthStateReducer } = slice;

export const { setUser, setAuthState } = slice.actions;
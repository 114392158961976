import React, {FC} from "react";
import {Text} from "@constructor/components";
import {useMarketItemContext} from "./context";

const MarketItemName: FC = (props) => {
    const item = useMarketItemContext();
    if (!item?.name) {
        return null;
    }

    return (
        <Text text={item.name} {...props} />
    )
}

export default MarketItemName;
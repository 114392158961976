import ConsumerRefService from "@api/consumer/consumerRefService";
import { AnimatedFontAwesomeIcon, TableSpinner } from "@components/panel";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useStateLoader } from "@hooks/panelHooks";
import { useRole } from "@hooks/userHooks";
import { IRefTarget } from "@interfaces/consumer/refs";
import { FC, memo, useCallback } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { CardBody, CardHeader, CardTitle, Input, Table } from "reactstrap";

const RefTargetsList: FC = () => {

    const history = useHistory();
    const { ref_id } = useParams<{ ref_id: string }>();
    const loadGoal = useCallback((goal_id: string) => {
        const api = new ConsumerRefService();
        return api.getRefTargets(goal_id);
    }, []);

    const canEdit = useRole('consumer.ref.edit');
    const { loading, data: targets = [] } = useStateLoader<IRefTarget[]>(loadGoal, ref_id);

    const toggleModal = () => {
        history.push(`/consumer/refs/${ref_id}/targets/add/`);
    };

    return (
        <>
            <CardHeader>
                {
                    canEdit && (
                        <div className="card-actions float-end">
                            <AnimatedFontAwesomeIcon
                                icon={faPlus}
                                fixedWidth
                                className="align-middle"
                                onClick={toggleModal}
                                animate="spin"
                            />
                        </div>
                    )
                }
                <CardTitle tag="h5">
                    Список условий для реферального бонуса{' '}
                </CardTitle>
            </CardHeader>
            <CardBody>
                <Table striped>
                    <thead>
                        <tr>
                            <th>Название</th>
                            <th>Цель</th>
                            <th>Активно</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ? (
                                <TableSpinner colSpan={3} />
                            ) : targets.length === 0 ? (
                                <tr><td colSpan={3} align='center'>Нет целей</td></tr>
                            ) : targets.map(r => (
                                <RefTarget key={r.id} target={r} />
                            ))
                        }
                    </tbody>
                </Table>
            </CardBody>
        </>
    )
}

interface RefTargetProps {
    target: IRefTarget;
}

const RefTarget: FC<RefTargetProps> = memo(({ target }) => {

    return (
        <tr>
            <td><Link to={`/consumer/refs/${target.refId}/targets/${target.id}/`}>{target.refName}</Link></td>
            <td><Link to={`/consumer/goals/${target.goalId}/`}>{target.goalName}</Link></td>
            <td>
                <Input
                    id={`rw-${target.id}`}
                    type="checkbox"
                    disabled
                    defaultChecked={target.isActive} />
            </td>
        </tr>
    )
});

export default RefTargetsList;
import UserService from "@api/userService";
import { useUserInfo } from "@hooks/userHooks";
import { userActions } from "@store/user";
import { Form, Formik, FormikHelpers } from "formik";
import {FC, PropsWithChildren, useCallback, useMemo} from "react";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import * as Yup from 'yup';

interface ChangeProfileFormValues {
    userName: string;
    publicName: string;
    email: string;
}

const ChangeProfileForm: FC<PropsWithChildren> = ({ children, ...props }) => {

    const dispatch = useDispatch();
    const user = useUserInfo();
    const onSubmit = useCallback(async ({ publicName }: ChangeProfileFormValues, actions: FormikHelpers<ChangeProfileFormValues>) => {

        const api = new UserService();
        const result = await api.patchUserData({ publicName });

        if (result.ok) {
            toastr.success("Данные пользователя", "Данные успешно обновлены");
            dispatch(userActions.loadUserData());
        }
    }, [dispatch]);

    const validation = useMemo(() => Yup.object().shape({
        userName: Yup.string()
            .min(5, 'Не менее 5 символов')
            .max(30, 'Не более 30 символов')
            .required('Обязательно')
    }), []);

    const initial: ChangeProfileFormValues = {
        userName: user.userName,
        email: user.email,
        publicName: user.publicName
    }

    return (
        <Formik
            initialValues={initial}
            validationSchema={validation}
            onSubmit={onSubmit}>
            <Form {...props}>
                {children}
            </Form>
        </Formik>
    )
}

export default ChangeProfileForm;
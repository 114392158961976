export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const themeVariants = ['light', 'dark', 'auto'];
export const settings = {
    isFluid: false,
    isRTL: false,
    isDark: false,
    theme: 'light',
    navbarPosition: 'vertical',
    showBurgerMenu: false, // controls showing vertical nav on mobile
    currency: '$',
    isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
    navbarStyle: 'transparent'
};

export const breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1540
};

export default { navbarBreakPoint, topNavbarBreakpoint, settings, breakpoints };
import { FC, useEffect } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { consumerActions } from '@store/consumer';
import { Chat } from '@components/consumer/support';

interface ConsumerTicketPagePath {
    id: string;
}

const ConsumerTicketPage: FC<RouteComponentProps<ConsumerTicketPagePath>> = () => {

    const dispatch = useDispatch();

    const { id } = useParams<ConsumerTicketPagePath>();
    //const loading = useSelector((state: ReduxStore) => state.consumer.support.messages.loading);
    //const ticket = useTopic(id);

    useEffect(() => {

        dispatch(consumerActions.support.loadTopic(id));
        dispatch(consumerActions.support.loadMessages(id));

    }, [dispatch, id]);

    return (
        <Chat />
    )
}

export default ConsumerTicketPage;
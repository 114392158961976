import {FC, PropsWithChildren} from "react";
import Footer from "./footer";
import {NavbarTop, NavbarVertical} from "./navbar";

const MainLayout: FC<PropsWithChildren> = ({children}) => {
    return (
        <main className='main' id='main'>
            <div className='container'>
                <NavbarVertical/>
                <div className='content'>
                    <NavbarTop/>
                    {/*------ Main Routes ------*/}
                    {children}
                    <Footer/>
                </div>
            </div>
        </main>
    )
}

export default MainLayout;
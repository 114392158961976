import { OperationResult } from "@interfaces/panel/files";
import { PatchItemModel, PutItemModel, SearchItemModel } from "@models/consumer/items";
import { IItem, IItemFull, ISearchItem } from "@interfaces/consumer/items";
import { PanelTableData } from "@interfaces/panel";
import { PanelTableDataModel } from "@models/panel";
import IService from "../iService";

export default class ConsumerItemsService extends IService {

    fetchItemsList = async (model: PanelTableDataModel) => {
        return await this.postApi<PanelTableData<IItem[]>>('/consumer/item/', model);
    }

    async putItem(model: PutItemModel) {
        return await this.putApi<OperationResult>('/consumer/item/', model);
    }

    async patchItem(model: PatchItemModel) {
        return await this.patchApi<OperationResult>('/consumer/item/', model);
    }

    async getItem(id: string) {
        return await this.getApi<IItemFull>(`/consumer/item/${id}/`);
    }

    async deleteItem(id: string) {
        return await this.deleteApi(`/consumer/item/${id}/`);
    }

    async searchItem(model: SearchItemModel) {
        return await this.postApi<ISearchItem[]>('/consumer/item/search/', model);
    }
}
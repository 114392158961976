import ConsumerRefService from "@api/consumer/consumerRefService";
import { EditRefRewardForm } from "@components/consumer/refs";
import withPage from "@components/hoc/withPage";
import { useBreadcrumbs, useStateLoader } from "@hooks/panelHooks";
import { IRefReward } from "@interfaces/consumer/refs";
import { FC, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Card, CardHeader, CardTitle } from "reactstrap";


const EditRefRewardPage: FC = () => {

    const { ref_id, reward_id } = useParams<{ ref_id: string, reward_id: string }>();

    const loadGoal = useCallback((ref_id: string, reward_id: string) => {
        const api = new ConsumerRefService();
        return api.getRefReward(ref_id, reward_id);
    }, []);

    const { data: reward } = useStateLoader<IRefReward>(loadGoal, ref_id, reward_id);

    useBreadcrumbs('consumer.refs.reward.current.name', true, reward?.name);
    useBreadcrumbs('consumer.refs.current.name', true, reward?.refName, `/consumer/refs/${ref_id}/`);
    useBreadcrumbs('consumer.refs.rewards', true, null, `/consumer/refs/${ref_id}/rewards/`);

    if (!reward) {
        return null;
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h5">
                    Редактирование награды {reward?.name}
                </CardTitle>
            </CardHeader>
            <EditRefRewardForm reward={reward}/>
        </Card>
    )
}

export default withPage(EditRefRewardPage);
import {FC} from 'react';
import {Col, Row} from 'react-bootstrap';
import {ConsumerUserFull} from '@interfaces/consumer/users';
import BalanceLogs from "@components/consumer/users/balance/balanceLogs";
import DonateLogs from "@components/consumer/users/balance/donateLogs";
import {useRole} from "@services/hooks";

interface ConsumerUserBalanceProps {
    user: ConsumerUserFull;
}

const ConsumerUserBalance: FC<ConsumerUserBalanceProps> = ({ user }) => {

    const canViewPayments = useRole("consumer.payments.view");

    return (
        <Row xs={1} sm={1} md={1} lg={2}>
            {canViewPayments && (
                <Col md={12} lg={5}>
                    <Row>
                        <Col>
                            <DonateLogs />
                        </Col>
                    </Row>
                </Col>
            )}
            <Col md={12} lg={canViewPayments ? 7 : 12}>
                <BalanceLogs user={user} />
            </Col>
        </Row>
    )
}

export default ConsumerUserBalance;
import { useGameServerAuto } from "@hooks/selectors/game.selectors";
import { FC } from "react";
import { GameIdProps } from ".";
import { Text } from '@constructor/components';

const GameServerChargeRate: FC<GameIdProps> = ({ game_id, ...props }) => {

    const game = useGameServerAuto(game_id);
    if (!game) {
        return null;
    }

    return (
        <Text text={game.chargePower} {...props} />
    )
}

export default GameServerChargeRate;
import { injectChild } from "@components/constructor";
import { useFormikContext } from "formik";
import {FC, PropsWithChildren, useMemo} from "react";

interface SubmitChildProps {
    disabled: boolean;
}

const Submit: FC<PropsWithChildren> = ({ children }) => {

    const context = useFormikContext();

    const data = useMemo<SubmitChildProps>(() => {
        return {
            disabled: context.isSubmitting || !context.isValid
        }
    }, [context.isSubmitting, context.isValid])

    return injectChild(data, children);
}

export default Submit;
import { SelectorsFormsValues } from "@components/constructor/components/core/forms/selectors";
import { useDefaultSelectorFormValues, usePromisedDispatch } from "@hooks/helperHooks";
import { userActions } from "@store/user";
import { Form, Formik } from "formik";
import {FC, PropsWithChildren, useCallback, useMemo} from "react";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import * as Yup from 'yup';

interface CreateSupportTopicFormValues extends SelectorsFormsValues {
    title: string;
    message: string;
}

const CreateSupportTopicForm: FC<PropsWithChildren> = ({children, ...props}) => {

    const history = useHistory();
    const dispatch = usePromisedDispatch();
    const selectors = useDefaultSelectorFormValues();
    
    const initial: CreateSupportTopicFormValues = {
        title: '',
        message: '',
        ...selectors
    }

    const validation = useMemo(() => Yup.object().shape({
        title: Yup.string()
            .min(5, "Не менее 5 символов")
            .max(256, "Не более 250 символов")
            .required("Обязательно"),
        message: Yup.string()
            .min(5, "Не менее 5 символов")
            .max(1000, "Не более 1000 символов")
            .required("Обязательно")
    }), []);

    const onSubmit = useCallback(async (values: CreateSupportTopicFormValues) => {

        const result = await dispatch(userActions.createTask({
            name: values.title,
            message: values.message,
            accountId: values.selected_account || undefined,
            characterId: values.selected_character || undefined,
            gameServerId: values.selected_game_server || undefined,
            loginServerId: values.selected_login_server || undefined
        }));

        if (result.success) {
            toastr.success("Поддержка", "Заявка успешно создана");
            history.push(`/support/${result.data.id}/`)
        }
        else {
            if (result.errorCode === 'MaxCount') {
                toastr.error("Поддержка", "У вас слишком много открытых заявок");
            }
            else if (result.errorCode === 'InvalidModel') {
                toastr.error("Поддержка", "Ошибка запроса. Обновите страницу");
            }
            else {
                toastr.error("Поддержка", "Что-то пошло не так");
            }
        }
    }, [dispatch, history]);

    return (
        <Formik
            initialValues={initial}
            validationSchema={validation}
            onSubmit={onSubmit}>
                <Form {...props}>
                    {children}
                </Form>
        </Formik>
    );
}

export default CreateSupportTopicForm;
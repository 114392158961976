import { IUserTask, IUserTaskMessage, IUserTaskMessageGroup } from "@interfaces/user/support";
import { createContext, useContext } from "react";
import {UploadFileInfo} from "@interfaces/panel/files";

export interface ISupportTopicContext {
    topic?: IUserTask;
    editableMessageId?: string;
    attachedImages?: {
        id: string;
        file: File;
        fileUrl: string;
        uploading: boolean;
        uploadSuccess: boolean;
        uploadFailed: boolean;
        uploadProgress: number;
    }[];
    setAttachedImages?: (files: File[]) => void;
    removeAttachedImage?: (id: string) => void;
    uploadAttachedImages?: (ufi: UploadFileInfo[]) => Promise<any>;
}

export const SupportTopicContext = createContext<ISupportTopicContext>(undefined as any);
export const SupportTopicProvider = SupportTopicContext.Provider;

export function useSupportTopicContext(): ISupportTopicContext | undefined {
    return useContext(SupportTopicContext);
}

export const SupportMessageContext = createContext<IUserTaskMessage>(undefined as any);
export const SupportMessageProvider = SupportMessageContext.Provider;

export function useSupportMessageContext(): IUserTaskMessage | undefined {
    return useContext(SupportMessageContext);
}

export const SupportMessageGroupContext = createContext<IUserTaskMessageGroup>(undefined as any);
export const SupportMessageGroupProvider = SupportMessageGroupContext.Provider;

export function useSupportMessageGroupContext(): IUserTaskMessageGroup | undefined {
    return useContext(SupportMessageGroupContext);
}
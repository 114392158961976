import { Loadable } from '@interfaces/helpers';
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { IUserTask } from '@interfaces/user/support';
import { UserTaskModel } from '@models/user/support';
import SupportService from '@api/user/supportService';

const slice = createSlice({
    initialState: {
        loaded: false,
        loading: false,
        error: null,
        data: []
    } as Loadable<IUserTask[]>,
    name: 'userTasksStore',
    reducers: {
        setLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        setTasks: (state, action: PayloadAction<IUserTask[]>) => {
            state.data = action.payload;
            state.loaded = true;
            state.error = null;
            state.loading = false;
        },
        setTask: (state, action: PayloadAction<IUserTask>) => {
            const task = action.payload;

            state.loading = false;
            state.error = null;
            state.data = state.data.filter(t => t.id !== task.id).concat(task);
        }
    }
})

export const { reducer: userTaskReducer } = slice;

const {
    setError,
    setLoading,
    setTask,
    setTasks
} = slice.actions;

export const loadTasks = () => async (dispatch: Dispatch) => {
    dispatch(setLoading());
    try {
        const api = new SupportService();
        const tasks = await api.fetchUserTasks();
        dispatch(setTasks(tasks));
        return tasks;
    }
    catch(e) {
        dispatch(setError(e));
    }
}

export const loadTask = (id: string) => async (dispatch: Dispatch) => {
    dispatch(setLoading());
    try {
        const api = new SupportService();
        const task = await api.fetchUserTask(id);
        dispatch(setTask(task));
        return task;
    }
    catch(e) {
        dispatch(setError(e));
    }
}

export const createTask = (task: UserTaskModel) => async (dispatch: Dispatch) => {
    const api = new SupportService();
    const result = await api.putUserTask(task);
    if(result.ok && result.result) {
        dispatch(setTask(result.result));
    }
    return result;
}

export interface IGoal {
    id: string;
    name: string;
    description?: string;
    isActive: boolean;
    createTime: string;
    order: number;
    allConditions: boolean;
    hidden: boolean;

    rewardsCount: number;
    conditionsCount: number;
    reachCount: number;
}

export interface IGoalShort {
    id: string;
    name: string;
    isActive: boolean;
    createTime: string;
}

export interface ISearchGoal {
    id: string;
    name: string;
}

export interface IGoalCondition {
    id: string;
    name: string;
    goalId: string;
    goalName?: string;
    isActive: boolean;
    createTime: string;
    type: GoalConditionType;
}

export interface IGoalDonateCondition extends IGoalCondition {
    donateValue: number;
}

export enum GoalConditionType {
    Donate = 'Donate',
    Manual = 'Manual',
    SignUp = 'SignUp',
    SignUpRef = 'SignUpRef'
}

export interface IGoalReward {
    id: string;
    name: string;
    goalId: string;
    goalName?: string;
    order: number;
    createTime: string;
    isActive: boolean;
    type: GoalRewardType;
}

export interface IGoalBalanceReward extends IGoalReward {
    balance: number;
}

export interface IGoalItemReward extends IGoalReward {
    itemId: string;
    count: number;
}

export enum GoalRewardType {
    Balance = 'Balance',
    Item = 'Item'
}

export interface IRefShort {
    id: string;
    name: string;
    isActive: boolean;
    createTime: string;
    order: number;
}

export interface IRef extends IRefShort {
    description?: string;
    hidden: boolean;
    allTargets: boolean;
}

export interface IRefReward {
    id: string;
    name: string;
    refId: string;
    refName?: string;
    order: number;
    createTime: string;
    isActive: boolean;
    type: RefRewardType;
}

export interface IBalanceRefReward extends IRefReward {
    balance: number;
}

export interface IItemRefReward extends IRefReward {
    itemId: string;
    count: number;
}

export interface IPercentRefReward extends IRefReward {
    percent: number;
    minSum: number;
    maxSum: number;
}

export enum RefRewardType {
    Balance = 'Balance',
    Item = 'Item',
    Percent = 'Percent'
}

export interface IRefTarget {
    id: string;
    refId: string;
    refName: string;
    goalId: string;
    goalName: string;
    isActive: boolean;
    createTime: string;
}
import { FC } from "react";
import { useItemContext } from "./context";
import { Text } from '@constructor/components';

const ItemDescription: FC = (props) => {
    const item = useItemContext();

    if (!item?.description) {
        return null;
    }

    return (
        <Text text={item.description} {...props} />
    )
}

export default ItemDescription;
import React from 'react';
import { Spinner } from 'reactstrap';

interface TableSpinnerProps {
    colSpan: number;
    className?: string;
    color?: string;
}

const TableSpinner: React.FC<TableSpinnerProps> = ({ colSpan, className, color }) => (
    <tr>
        <td align="center" colSpan={colSpan}>
            <Spinner className={className} color={color} />
        </td>
    </tr>
)

export default TableSpinner;
import IService from "@api/iService";
import { IGoal, IGoalCondition, IGoalReward, IRef, IRefReward, IRefShort, IRefTarget, ISearchGoal } from "@interfaces/consumer/refs";
import { PanelTableData } from "@interfaces/panel";
import { PatchConditionModel, PatchGoalModel, PatchRefModel, PatchGoalRewardModel, PutConditionModel, PutGoalModel, PutRefModel, PutGoalRewardModel, PutRefRewardModel, PatchRefRewardModel, SearchGoalModel, PutRefTargetModel, PatchRefTargetModel } from "@models/consumer/refs";
import { PanelTableDataModel } from "@models/panel";

export default class ConsumerRefService extends IService {

    getGoals = async (model: PanelTableDataModel) => {
        return await this.postApi<PanelTableData<IGoal[]>>('/consumer/goal/', model);
    }

    async getGoal(goalId: string) {
        return await this.getApi<IGoal>(`/consumer/goal/${goalId}/`);
    }

    searchGoal(name: string) {
        return this.postApi<ISearchGoal[]>('/consumer/goal/search/', {
            name
        } as SearchGoalModel)
    }

    putGoal(model: PutGoalModel) {
        return this.putApi<IGoal>('/consumer/goal/', model);
    }

    patchGoal(model: PatchGoalModel) {
        return this.patchApi<IGoal>('/consumer/goal/', model);
    }

    deleteGoal(id: string) {
        return this.deleteApi(`/consumer/goal/${id}/`);
    }

    getGoalConditions(goalId: string) {
        return this.getApi<IGoalCondition[]>(`/consumer/goal/${goalId}/condition/`);
    }

    getGoalCondition(goalId: string, conditionId: string) {
        return this.getApi<IGoalCondition>(`/consumer/goal/${goalId}/condition/${conditionId}/`);
    }

    putCondition(goalId: string, model: PutConditionModel) {
        return this.putApi<IGoalCondition>(`/consumer/goal/${goalId}/condition/`, model);
    }

    patchCondition(goalId: string, model: PatchConditionModel) {
        return this.patchApi<IGoalCondition>(`/consumer/goal/${goalId}/condition/`, model);
    }

    getGoalRewards(goalId: string) {
        return this.getApi<IGoalReward[]>(`/consumer/goal/${goalId}/reward/`);
    }

    getGoalReward(goalId: string, rewardId: string) {
        return this.getApi<IGoalReward>(`/consumer/goal/${goalId}/reward/${rewardId}/`);
    }

    putGoalReward(goalId: string, model: PutGoalRewardModel) {
        return this.putApi<IGoalReward>(`/consumer/goal/${goalId}/reward/`, model);
    }

    patchGoalReward(goalId: string, model: PatchGoalRewardModel) {
        return this.patchApi<IGoalReward>(`/consumer/goal/${goalId}/reward/`, model);
    }

    getRefs = async (model: PanelTableDataModel) => {
        return await this.postApi<PanelTableData<IRefShort[]>>('/consumer/ref/', model);
    }

    getRef(goalId: string) {
        return this.getApi<IRef>(`/consumer/ref/${goalId}/`);
    }

    putRef(model: PutRefModel) {
        return this.putApi<IRef>('/consumer/ref/', model);
    }

    patchRef(model: PatchRefModel) {
        return this.patchApi<IRef>('/consumer/ref/', model);
    }

    getRefRewards(refId: string) {
        return this.getApi<IRefReward[]>(`/consumer/ref/${refId}/reward/`);
    }

    getRefReward(refId: string, rewardId: string) {
        return this.getApi<IRefReward>(`/consumer/ref/${refId}/reward/${rewardId}/`);
    }

    putRefReward(refId: string, model: PutRefRewardModel) {
        return this.putApi<IRefReward>(`/consumer/ref/${refId}/reward/`, model);
    }

    patchRefReward(refId: string, model: PatchRefRewardModel) {
        return this.patchApi<IRefReward>(`/consumer/ref/${refId}/reward/`, model);
    }

    getRefTargets(refId: string) {
        return this.getApi<IRefTarget[]>(`/consumer/ref/${refId}/target/`);
    }

    getRefTarget(refId: string, targetId: string) {
        return this.getApi<IRefTarget>(`/consumer/ref/${refId}/target/${targetId}/`);
    }

    putRefTarget(refId: string, model: PutRefTargetModel) {
        return this.putApi<IRefTarget>(`/consumer/ref/${refId}/target/`, model);
    }

    patchRefTarget(refId: string, model: PatchRefTargetModel) {
        return this.patchApi<IRefTarget>(`/consumer/ref/${refId}/target/`, model);
    }
}
import {FC, useCallback, useMemo, useState} from "react";
import {Link, useParams} from "react-router-dom";
import ConsumerUsersService from "@api/consumer/consumerUsersService";
import {PanelTableDataModel} from "@models/panel";
import {useLocalizedBlock} from "@services/hooks";
import {Card} from "react-bootstrap";
import {dateFormatter} from "@services/helpers";
import {ColumnDef} from "@tanstack/react-table";
import {ConsumerPaymentShort} from "@interfaces/consumer/payments";
import {Flex} from "@components/falcon/common";
import {useRemoteTable} from "@hooks/tableHooks";
import {AdvanceTable, AdvanceTableFooter} from "@components/falcon/common/tables";
import AdvanceTableProvider from "@components/falcon/common/tables/context";


const DonateLogs: FC = () => {
    const { id } = useParams<{ id: string }>();
    const [api] = useState(() => new ConsumerUsersService());
    const fetchApi = useCallback((model: PanelTableDataModel) => api.fetchPaymentLogs(id, model), [api, id]);
    const lang = useLocalizedBlock('consumer.user.balance.history.donate');

    const columns: ColumnDef<ConsumerPaymentShort>[] = useMemo(() => [
        {
            accessorKey: 'orderId',
            header: '№',
            enableSorting: true,
            cell: ({row: {original: { orderId}}}) => (
                <Link to={`/consumer/payments/${orderId}/`}>
                    <Flex alignItems="center">
                        <h5 className="mb-0 fs-10">{orderId}</h5>
                    </Flex>
                </Link>
            ),
            meta: {
                headerProps: { className: 'pe-1 text-900' },
                cellProps: { className: 'py-2' },
                placeholderSize: 4
            }
        },
        {
            accessorKey: 'finishTime',
            header: lang['date'],
            enableSorting: true,
            cell: ({row: {original: {finishTime}}}) => dateFormatter(finishTime),
            meta: {
                headerProps: { className: 'text-900' },
                placeholderSize: 12
            }
        },
        {
            accessorKey: 'sum',
            header: lang['summ'],
            enableSorting: true,
            meta: {
                headerProps: { className: 'text-900, text-end' },
                cellProps: { className: 'text-end' },
                placeholderSize: 3
            }
        }
    ], [lang]);

    const table = useRemoteTable({
        name: 'consumer.users.payments.logs',
        columns,
        defaultState: {
            sorting: [
                {
                    id: 'finishTime',
                    desc: true
                }
            ]
        }
    }, fetchApi);

    return (
        <AdvanceTableProvider table={table}>
            <Card className="mb-3">
                <Card.Header>
                    <Flex className='align-items-center justify-content-between'>
                        <Flex alignItems="center" className="fs-10">
                            <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">{lang['title']}</h5>
                        </Flex>
                        {/*<Flex>
                            <AdvanceTableSearchBox/>
                        </Flex>*/}
                    </Flex>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowsPerPageSelection
                        paginationButtons
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableProvider>
    );
}

export default DonateLogs;
import { injectChild } from "@components/constructor";
import { PaginationProps } from "@constructor/components/core/pagination";
import { usePagination } from "@hooks/components/constructor.hooks";
import { useTasks } from "@hooks/selectors/user/support.selectors";
import {FC, PropsWithChildren, useMemo} from "react";

interface SupportChildProps {
    support_loaded: boolean;
    support_loading: boolean;
    support_error: boolean;
    support_topics_count: number;
}

interface SupportProps {
    support_topics_per_page?: number;
}

const Support: FC<PropsWithChildren<SupportProps>> = ({ support_topics_per_page = 10, children }) => {

    const {
        loaded,
        loading,
        error,
        data: topics
    } = useTasks();
    const pagination = usePagination(support_topics_per_page, topics.length);
    
    const data = useMemo<SupportChildProps & PaginationProps>(() => {
        return {
            support_loaded: loaded,
            support_loading: loading,
            support_error: Boolean(error),
            support_topics_count: topics.length,
            ...pagination
        }
    }, [error, loaded, loading, pagination, topics.length]);

    return injectChild(data, children);
}

export default Support;
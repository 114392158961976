import moment from 'moment-timezone';
import 'moment/locale/ru';
import TagManager from 'react-gtm-module'
import {ForwardedRef, useEffect, useRef, useState} from "react";

export function fromUtcDate(date: string) {
    moment.locale('ru');
    return moment.tz(date, 'Etc/UTC').tz(moment.tz.guess());
}

export function sendGTMEvent(event: string) {
    TagManager.dataLayer({
        dataLayer: {
            event
        }
    })
}

export function dateFormatter(utc: string) {
    return fromUtcDate(utc).format('lll');
}

export function calendarFormatter(utc: string) {
    return fromUtcDate(utc).calendar();
}

export function randomString(length: number = 10) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

export function randomInteger(min: number, max: number) {
    // случайное число от min до (max+1)
    const rand = min + Math.random() * (max + 1 - min);
    return Math.floor(rand);
}

export function sortByDate<T>(callback: (o: T) => string, order: 'asc' | 'desc' = 'asc') {
    return (one: T, two: T) => (Date.parse(callback(one)) - Date.parse(callback(two))) * (order === 'asc' ? 1 : -1);
}

function getId() {
    return `${Date.now()}:${Math.random()}`
}

export function runWithLock(key: string, fn: () => void, { timeout = 1000, lockWriteTime = 50, checkTime = 10, retry = true } = {}) {
    const timerRunWithLock = () => setTimeout(runWithLock.bind(null, key, fn, { timeout, lockWriteTime, checkTime, retry }), checkTime);
    const result = localStorage.getItem(key);
    if (result) {

        // Check to make sure the lock hasn't expired
        const data = JSON.parse(result);
        if (data.time >= Date.now() - timeout) {
            if (retry) {
                timerRunWithLock();
            }
            return;
        } else {
            localStorage.removeItem(key);
        }
    }
    const id = getId();
    localStorage.setItem(key, JSON.stringify({ id, time: Date.now() }));

    // Delay a bit, to see if another worker is in this section
    setTimeout(() => {
        const currentResult = localStorage.getItem(key);
        const data = JSON.parse(currentResult || "{}");
        if (data.id !== id) {
            if (retry) {
                timerRunWithLock();
            }
            return;
        }

        try {
            fn();
        } finally {
            localStorage.removeItem(key);
        }
    }, lockWriteTime);
}

export function useForwardRef<T>(ref: ForwardedRef<T>, initialValue: any = null) {
    const targetRef = useRef<T>(initialValue);

    useEffect(() => {
        if (!ref) return;

        if (typeof ref === 'function') {
            ref(targetRef.current);
        } else {
            ref.current = targetRef.current;
        }
    }, [ref]);

    return targetRef;
}

export default function useDebounce<T>(value: T, delay: number = 500) {
    // Состояние и сеттер для отложенного значения
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            // Выставить debouncedValue равным value (переданное значение)
            // после заданной задержки
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Вернуть функцию очистки, которая будет вызываться каждый раз, когда ...
            // ... useEffect вызван снова. useEffect будет вызван снова, только если ...
            // ... value будет изменено (смотри ниже массив зависимостей).
            // Так мы избегаем изменений debouncedValue, если значение value ...
            // ... поменялось в рамках интервала задержки.
            // Таймаут очищается и стартует снова.
            // Что бы сложить это воедино: если пользователь печатает что-то внутри ...
            // ... нашего приложения в поле поиска, мы не хотим, чтобы debouncedValue...
            // ... не менялось до тех пор, пока он не прекратит печатать дольше, чем 500ms.
            return () => {
                clearTimeout(handler);
            };
        },
        // Вызывается снова, только если значение изменится
        // мы так же можем добавить переменную "delay" в массива зависимостей ...
        // ... если вы собираетесь менять ее динамически.
        [value, delay]
    );

    return debouncedValue;
}
import { KeyValueSet } from "@interfaces/helpers";
import { FC } from "react";
import { useQueryContext } from "./context";

interface QueryTableHeadProps {
    columns?: KeyValueSet<any>;
}

const QueryTableHead: FC<QueryTableHeadProps> = ({ columns = {} }) => {

    const query = useQueryContext();

    if (!query) {
        return null;
    }

    return (
        <thead>
            <tr>
            {
                query.columns.map(column => {
                    if (column.hidden) {
                        return null;
                    }
                    return (
                        <th key={column.name} {...columns[column.name]}>{column.display}</th>
                    )
                })
            }
            </tr>
        </thead>
    )
}

export default QueryTableHead;
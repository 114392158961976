import InventoryService from "@api/user/inventoryService";
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { IInventoryItem } from "@interfaces/user/items";
import { InventoryStore } from "@interfaces/user/userStore";
import FetchResult from "@models/fetchResult";

const slice = createSlice({
    initialState: {
        loaded: false,
        loading: false,
        error: null,
        data: []
    } as InventoryStore,
    name: 'userInventoryStore',
    reducers: {
        setLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        setItems: (state, action: PayloadAction<IInventoryItem[]>) => {
            state.data = action.payload;
            state.error = null;
            state.loaded = true;
            state.loading = false;
        },
        setItem: (state, { payload: item }: PayloadAction<IInventoryItem>) => {
            const idx = state.data.findIndex(i => i.id === item.id);

            if (idx >= 0) {
                state.data[idx] = item;
            }
            else {
                state.data.push(item);
            }
        },
        deleteItem: (state, { payload: id }: PayloadAction<string>) => {
            state.data = state.data.filter(i => i.id !== id);
        }
    }
})

export const { reducer: userInventoryReducer } = slice;

const {
    setError,
    setItems,
    setLoading,
    setItem,
    deleteItem
} = slice.actions;

export const inventoryActions = {
    loadInventoryItems: (silent: boolean = false) => async (dispatch: Dispatch) => {
        if (!silent) {
            dispatch(setLoading());
        }

        try {
            const api = new InventoryService();
            const items = await api.fetchUserInventory();
            dispatch(setItems(items));
            return items;
        }
        catch (e) {
            dispatch(setError(e));
            return [] as IInventoryItem[];
        }
    },
    loadInventoryItem: (itemId: string) => async (dispatch: Dispatch) => {
        try {
            const api = new InventoryService();
            const result = await api.getInventoryItem(itemId);

            if(result.success) {
                dispatch(setItem(result.data));
            }
            else if(result.errorCode === 'ItemNotFound') {
                dispatch(deleteItem(itemId));
            }

            return result;
        } catch (error) {
            return error as FetchResult<IInventoryItem>;
        }
    },
    setItems
}
import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import InventoryList from "./inventoryList";
import LogsList from "./logsList";

const ConsumerUserInventory: FC = () => {

    return (
        <Row>
            <Col md={12} lg={7}>
                <InventoryList />
            </Col>
            <Col md={12} lg={5}>
                <LogsList />
            </Col>
        </Row>
    )
}

export default ConsumerUserInventory;
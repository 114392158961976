import { IFullData } from '@interfaces/control-panel';
import { UploadFileInfo } from '@interfaces/panel/files';
import { BuyBonusCodeModel } from '@models/user/bonusCodes';
import { GameAccount, GameCharacter, GameProject, GameServer, LoginServer } from '../../interfaces/game';
import { IPersonalBonusCode, IUseBonusCodeResult, IUsedBonusCode } from '../../interfaces/user/bonusCodes';
import { MarketCategory, MarketItem } from '../../interfaces/user/market';
import { IShortNews } from '../../interfaces/user/shortNews';
import { User, UserAuthLog, UserDonateLog, UserNotification } from '../../interfaces/user/users';
import { CustomQueryModel } from '../../models/query/queries';
import { PatchGameAccountModel, PutGameAccountModel, UserDataModel } from '../../models/user/data';
import { BuyItemsModel } from '../../models/user/market';
import { UserChargeAccount, UserPayment, UserPaymentView } from '../../models/user/payments';
import { ChangePasswordModel } from '../../models/users';
import IService from './iService';

export default class UserService extends IService {
    async fetchUserData(): Promise<User> {
        return await this.getAndThrowApi<User>('/user/');
    }

    async fetchUserBalance(): Promise<number> {
        return await this.getAndThrowApi<number>('/user/balance/');
    }

    async fetchAccounts(): Promise<GameAccount[]> {
        return await this.getAndThrowApi<GameAccount[]>('/game/account/');
    }

    async fetchAccount(accountId: string): Promise<GameAccount> {
        return await this.getAndThrowApi<GameAccount>(`/game/account/${accountId}/`);
    }

    async fetchUserUsedBonusCodes() {
        return await this.getAndThrowApi<IUsedBonusCode[]>('/user/bonus/used/');
    }

    async fetchUserPersonalBonusCodes() {
        return await this.getAndThrowApi<IPersonalBonusCode[]>('/user/bonus/personal/');
    }

    async fetchCharactes(): Promise<GameCharacter[]> {
        return await this.getAndThrowApi<GameCharacter[]>('/user/characters/');
    }

    async fetchLogins(): Promise<LoginServer[]> {
        return await this.getAndThrowApi<LoginServer[]>('/user/ls/');
    }
    
    async fetchUserAuthLogs() {
        return await this.getAndThrowApi<UserAuthLog[]>('/user/logs/auth/');
    }

    async fetchUserDonateLogs() {
        return await this.getAndThrowApi<UserDonateLog[]>('/user/logs/donate/');
    }

    async fetchUserNotifications() {
        return await this.getAndThrowApi<UserNotification[]>('/user/notification/');
    }

    async fetchUserNotification(id: string) {
        return await this.getAndThrowApi<UserNotification>(`/user/notification/${id}/`);
    }
    
    async fetchUserProject(): Promise<GameProject> {
        return await this.getAndThrowApi<GameProject>('/user/project/');
    }
    
    async fetchServers(): Promise<GameServer[]> {
        return await this.getAndThrowApi<GameServer[]>('/user/gs/');
    }
    
    async fetchUserMarketItems() {
        return await this.getAndThrowApi<MarketItem[]>('/user/market/items/');
    }

    async fetchUserMarketCategories() {
        return await this.getAndThrowApi<MarketCategory[]>('/user/market/categories/');
    }
    
    async fetchUserShortNews() {
        return await this.getAndThrowApi<IShortNews[]>('/user/shortnews/');
    }

    async putAccount(account: PutGameAccountModel) {
        return await this.putApi('/game/account/', account);
    }

    async patchAccount(account: PatchGameAccountModel) {
        return await this.patchApi('/game/account/', account);
    }

    async deleteUserNotification(id: string) {
        return await this.deleteApi(`/user/notification/${id}`);
    }

    async readUserNotification(id: string) {
        return await this.patchApi(`/user/notification/${id}`, undefined);
    }

    async readAllUserNotifications() {
        return await this.patchApi('/user/notification/all/');
    }

    async buyMarketItem(items: BuyItemsModel) {
        return await this.postApi('/user/market/buy/', items);
    }

    async changeUserPassword(data: ChangePasswordModel) {
        return await this.patchApi('/user/password/', data);
    }

    async fetchUserLastAuthLog() {
        return await this.getApi<UserAuthLog>('/user/logs/auth/last/');
    }

    async patchUserData(user: UserDataModel) {
        return await this.patchApi('/user/', user);
    }

    async exeuteCustomQuery<T = any>(query: CustomQueryModel) {
        return await this.postApi<T>('/query/', query);
    }

    async donateRequest(donate: UserPayment) {
        return await this.postApi<UserPaymentView>('/user/payment/donate/', donate);
    }

    async chargeGameAccount(charge: UserChargeAccount) {
        return await this.postApi('/game/account/', charge);
    }

    async activateUserBonusCode(code: string) {
        return await this.postApi<IUseBonusCodeResult>('/user/bonus/use/', { code });
    }

    async loadData() {
        return await this.getApi<IFullData>('/data/');
    }

    async prepareAvatarUpload() {
        return await this.patchApi<UploadFileInfo>('/user/avatar/');
    }

    buyBonusCode(model: BuyBonusCodeModel) {
        return this.putApi<IPersonalBonusCode>('/user/bonus/personal/', model)
    }
}
import ConsumerRefService from "@api/consumer/consumerRefService";
import { AnimatedFontAwesomeIcon, TableSpinner } from "@components/panel";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useStateLoader } from "@hooks/panelHooks";
import { useRole } from "@hooks/userHooks";
import { GoalConditionType, IGoalCondition, IGoalDonateCondition } from "@interfaces/consumer/refs";
import { FC, memo, useCallback } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { CardBody, CardHeader, CardTitle, Input, Table } from "reactstrap";

const GoalConditionsList: FC = () => {

    const history = useHistory();
    const { goal_id } = useParams<{ goal_id: string }>();
    const loadGoal = useCallback((goal_id: string) => {
        const api = new ConsumerRefService();
        return api.getGoalConditions(goal_id);
    }, []);

    const { loading, data: conditions = [] } = useStateLoader<IGoalCondition[]>(loadGoal, goal_id);
    const canEdit = useRole('consumer.goal.edit');

    const toggleModal = () => { 
        history.push(`/consumer/goals/${goal_id}/conditions/add/`);
    };

    return (
        <>
            <CardHeader>
                {
                    canEdit && (
                        <div className="card-actions float-end">
                            <AnimatedFontAwesomeIcon
                                icon={faPlus}
                                fixedWidth
                                className="align-middle"
                                onClick={toggleModal}
                                animate="spin"
                            />
                        </div>
                    )
                }
                <CardTitle tag="h5">
                    Список условий для достижения{' '}
                </CardTitle>
            </CardHeader>
            <CardBody>
                <Table striped>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Название</th>
                            <th>Активно</th>
                            <th>Тип</th>
                            <th>Значение</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ? (
                                <TableSpinner colSpan={5} />
                            ) : conditions.length === 0 ? (
                                <tr><td colSpan={5} align='center'>Нет условий</td></tr>
                            ) : conditions.map(cond => (
                                <GoalCondition key={cond.id} cond={cond} />
                            ))
                        }
                    </tbody>
                </Table>
            </CardBody>
        </>
    )
}

interface GoalConditionProps {
    cond: IGoalCondition;
}

const GoalCondition: FC<GoalConditionProps> = memo(({ cond }) => (
    <tr>
        <td><Link to={`/consumer/goals/${cond.goalId}/conditions/${cond.id}/`}>{cond.id}</Link></td>
        <td>{cond.name}</td>
        <td>
            <Input
                id={`cd-${cond.id}`}
                type="checkbox"
                disabled
                defaultChecked={cond.isActive} />
        </td>
        <td>{cond.type}</td>
        <td>{cond.type === GoalConditionType.Donate && (cond as IGoalDonateCondition).donateValue}</td>
    </tr>
))

export default GoalConditionsList;
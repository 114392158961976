import {FC} from "react";
import {useMarketCategoryContext} from "./context";
import {useFile} from "@hooks/filesHooks";

const MarketCategoryIcon: FC = (props) => {
    const category = useMarketCategoryContext();
    const file = useFile(category?.iconId);

    if (!file?.data) {
        return null;
    }

    return (
        <img src={file.data?.url} alt={category?.name || ''} {...props} />
    )
}

export default MarketCategoryIcon;
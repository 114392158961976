import {FC, useCallback, useState} from "react";
import ConsumerService from "@api/consumerService";
import {useStateLoader} from "@hooks/panelHooks";
import {StatisticsCard} from "@components/falcon/common";
import bg3 from '../../../../assets/img/icons/spot-illustrations/corner-3.png';

const PaymentsWidget: FC = () => {

    const [api] = useState(() => new ConsumerService());
    const loadWidget = useCallback(() => api.fetchPaymentsWidget(), [api]); //TODO проверить нужен ли этот метод

    const {
        loading,
        data
    } = useStateLoader(loadWidget);

    return (
        <StatisticsCard
            title='Платежи'
            value={data?.total || 0}
            decimal
            suffix=' ₽'
            badgeText={data?.delta + '%'}
            badgeBg='success'
            link='/consumer/payments/'
            linkText='Подробнее'
            image={bg3}
        />
    )
}

export default PaymentsWidget;
import ConsumerRefService from "@api/consumer/consumerRefService";
import { GoalConditionType } from "@interfaces/consumer/refs";
import { FC, useCallback } from "react";
import { toastr } from "react-redux-toastr";
import { useHistory, useParams } from "react-router-dom";
import { CardBody } from "reactstrap";
import GoalConditionForm, { GoalConditionFormValues } from "./goalConditionForm";


const AddConditionForm: FC = () => {

    const { goal_id } = useParams<{ goal_id: string }>();
    const history = useHistory();

    const initialValues: GoalConditionFormValues = {
        name: '',
        donateValue: 0,
        isActive: false,
        type: GoalConditionType.Manual
    };

    const onSubmit = useCallback(async (values: GoalConditionFormValues) => {
        const api = new ConsumerRefService();
        const result = await api.putCondition(goal_id, values);

        if (result.success) {
            toastr.success("Изменение достижения", "Условие достижения успешно добавлено");
            history.replace(`/consumer/goals/${goal_id}/conditions/${result.data.id}/`);
        }
        else {
            if (result.errorCode === 'GoalNotFound') {
                toastr.error('Изменение достижения', 'Достижение не найдено');
            }
            else if (result.errorCode === 'InvalidRequest') {
                toastr.error('Изменение достижения', 'Некорректные параметры условия');
            }
            else if (result.errorCode === 'InvalidType') {
                toastr.error('Изменение достижения', 'Некорректный тип условия');
            }
            else {
                toastr.error('Изменение достижения', 'Неизвестная ошибка');
            }
        }
    }, [goal_id, history]);

    return (
        <CardBody>
            <GoalConditionForm
                initialValues={initialValues}
                onSubmit={onSubmit}
            />
        </CardBody>
    )
}

export default AddConditionForm;
import IService from "@api/iService";
import { IConsumerGift } from "@interfaces/consumer/daily";
import { PatchDailyGiftModel, PutDailyGiftModel } from "@models/consumer/daily";
import {PanelTableDataModel} from "@models/panel";
import {PanelTableData} from "@interfaces/panel";

export default class ConsumerDailyService extends IService {

    async getGifts() {
        return await this.getApi<IConsumerGift[]>('/daily/consumer/');
    }

    fetchGiftsList = async (model: PanelTableDataModel) => {
        return await this.postApi<PanelTableData<IConsumerGift[]>>('/daily/consumer/', model)
    }

    async getGift(id: string) {
        return await this.getApi<IConsumerGift>(`/daily/consumer/${id}/`);
    }

    async putBalanceGift(model: PutDailyGiftModel) {
        return await this.putApi<IConsumerGift>('/daily/consumer/balance/', model);
    }

    async putItemGift(model: PutDailyGiftModel) {
        return await this.putApi<IConsumerGift>('/daily/consumer/item/', model);
    }

    async patchBalanceGift(model: PatchDailyGiftModel) {
        return await this.patchApi<IConsumerGift>('/daily/consumer/balance/', model);
    }

    async patchItemGift(model: PatchDailyGiftModel) {
        return await this.patchApi<IConsumerGift>('/daily/consumer/item/', model);
    }
}
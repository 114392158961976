import {FC, PropsWithChildren, useMemo} from "react";
import { PaginationProps } from "@constructor/components/core/pagination";
import { GoalRewardProvider } from "./context";
import { IGoalReward } from "@interfaces/user/refs";
import { injectContextArray } from "@components/constructor";
import { useGoalContext } from "../context";
import {InjectContextType} from "@interfaces/constructor/constructor";

interface GoalRewardsListChildProps {
    goal_reward_index: number;
    goal_reward_id: string;
    goal_reward_type: string;
}

const GoalRewardsList: FC<PropsWithChildren<PaginationProps>> = ({ pagination_current_page = 0, pagination_per_page = 10, children }) => {

    const goal = useGoalContext();
    const rewards = useMemo(() => goal?.rewards || [], [goal?.rewards]);

    const page = useMemo(() => rewards.slice(
        pagination_current_page * pagination_per_page,
        (pagination_current_page + 1) * pagination_per_page
    ),
        [pagination_current_page, pagination_per_page, rewards]);

    const data = useMemo<InjectContextType<IGoalReward, GoalRewardsListChildProps>[]>(() => page.map((item, idx) => {
        return {
            key: item.id,
            value: item,
            childData: {
                goal_reward_index: pagination_current_page * pagination_per_page + 1 + idx,
                goal_reward_id: item.id,
                goal_reward_type: item.type
            }
        }
    }), [page, pagination_current_page, pagination_per_page]);

    return injectContextArray(data, GoalRewardProvider, children);
}

export default GoalRewardsList;
import {FC, PropsWithChildren, RefObject} from "react";
import Slick, { Settings } from "react-slick";

interface SlidesProps {
    settings?: Settings;
    slider_ref: RefObject<Slick>;
}

const Slides: FC<PropsWithChildren<SlidesProps>> = ({ settings, slider_ref, children }) => {
    return (
        <Slick {...settings} ref={slider_ref}>
            {children}
        </Slick>
    )
}

export default Slides;
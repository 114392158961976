import ConsumerRefService from "@api/consumer/consumerRefService";
import { FC, useCallback } from "react";
import { toastr } from "react-redux-toastr";
import { useHistory, useParams } from "react-router-dom";
import { CardBody } from "reactstrap";
import RefTargetForm, { RefTargetFormValues } from "./targetForm";




const AddRefTargetForm: FC = () => {

    const initialValues: RefTargetFormValues = {
        goalId: '',
        isActive: false
    }

    const { ref_id } = useParams<{ ref_id: string }>();
    const history = useHistory();

    const onSubmit = useCallback(async (values: RefTargetFormValues) => {
        const api = new ConsumerRefService();
        const result = await api.putRefTarget(ref_id, {
            goalId: values.goalId,
            isActive: values.isActive
        });

        if (result.success) {
            toastr.success('Редактирование реферального бонуса', 'Цель успешно создана');
            history.replace(`/consumer/refs/${ref_id}/targets/`);
        }
        else {
            if (result.errorCode === 'RefNotFound') {
                toastr.error('Редактирование реферального бонуса', 'Бонус не найден');
            }
            else if (result.errorCode === 'GoalNotFound') {
                toastr.error('Редактирование реферального бонуса', 'Цель не найдена');
            }
            else if (result.errorCode === 'TargetExists') {
                toastr.error('Редактирование реферального бонуса', 'Эта цель уже существует');
            }
            else {
                toastr.error('Редактирование реферального бонуса', 'Неизвестная ошибка');
            }
        }
    }, [history, ref_id]);

    return (
        <CardBody>
            <RefTargetForm
                initialValues={initialValues}
                onSubmit={onSubmit} />
        </CardBody>
    )
}

export default AddRefTargetForm;
import {FC} from "react";
import {Avatar, Flex} from "@components/falcon/common";
import {useSupportTopicContext} from "@components/consumer/support/context";
import {Link} from "react-router-dom";

const ChatContentIntro: FC = () => {

    const {
        topic
    } =  useSupportTopicContext();

    return (
        <Flex
            alignItems="center"
            className="position-relative p-3 border-bottom mb-3"
        >
            <Avatar
                className="me-3"
                size="2xl"
                fileId={topic?.ownerId}
                name={topic?.ownerUserName}
                rounded="circle"
            />
            <div className="flex-1">
                <h6 className="mb-0">
                    <Link
                        to={`/consumer/users/${topic?.ownerId}`}
                        className=" text-decoration-none stretched-link text-700"
                    >
                        {topic?.ownerUserName}
                    </Link>
                </h6>
               {/* <p className="mb-0">
                    {isGroup
                        ? `You are a member of ${user.name}. Say hi to start conversation to the group.`
                        : `You friends with ${user.name}. Say hi to start the conversation`}
                </p>*/}
            </div>
        </Flex>
    )
}

export default ChatContentIntro;
import {useState, useEffect, FC, useMemo, PropsWithChildren} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { sendGTMEvent } from '@services/helpers';
import { auth } from '@services/auth';
import { ConfirmEmailProvider, ConfirmEmailState } from './context';
import { injectChild } from '@components/constructor';
import { useThemeUrl } from '@hooks/panelHooks';

const ConfirmEmail: FC<PropsWithChildren> = ({ children }) => {

    const [state, setState] = useState<ConfirmEmailState>({
        done: false,
        error: false,
        fetching: true,
        success: false
    });

    const { code } = useParams<{ code: string }>();
    const history = useHistory();
    const redirectUrl = useThemeUrl('confirm.success');

    useEffect(() => {

        const confirm = async () => {
            try {
                const result = await auth().confirmEmail(code);
                const success = result.ok === true;

                if (success) {
                    sendGTMEvent('formsend');
                    toastr.success("Подтведждение почты", "Ваша почта успешно подтверждена");
                    history.replace(redirectUrl || '/auth/');
                }

                setState({
                    fetching: false,
                    done: true,
                    success,
                    error: false
                });
            }
            catch (e) {
                setState({
                    fetching: false,
                    done: true,
                    success: false,
                    error: true
                });
            }
        }

        confirm();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const data = useMemo(() => {
        return {
            email_confirming: state.fetching,
            email_confirmed: state.success,
            email_error: state.error
        }
    }, [state.error, state.fetching, state.success]);

    return (
        <ConfirmEmailProvider value={state}>
            {injectChild(data, children)}
        </ConfirmEmailProvider>
    )
}

export default ConfirmEmail;
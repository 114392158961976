import {FC, PropsWithChildren, useMemo} from "react";
import {useMarketLoading} from "@hooks/loadingHooks";
import {injectChild} from "@components/constructor";

interface MarketChildProps {
    market_loaded: boolean;
    market_loading: boolean;
    market_error: boolean;
    market_items_count: number;
    market_categories_count: number;
}
const Market: FC<PropsWithChildren> = ({ children }) => {
    const { loaded, loading, error, data: market } = useMarketLoading();
    
    const itemsCount = market?.items.length || 0;
    const categoriesCount = market?.categories.length || 0;

    const data = useMemo<MarketChildProps>(() => {
        return {
            market_loaded: loaded,
            market_loading: loading,
            market_error: Boolean(error),
            market_items_count: itemsCount,
            market_categories_count: categoriesCount
        }
    }, [categoriesCount, error, itemsCount, loaded, loading]);

    return injectChild(data, children);
}

export default Market;
import { AddGoalForm } from "@components/consumer/goals";
import withPage from "@components/hoc/withPage";
import { FC } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const AddGoalPage: FC = () => {

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h5">
                    Создание достижения
                </CardTitle>
            </CardHeader>
            <CardBody>
                <AddGoalForm />
            </CardBody>
        </Card>
    )
}

export default withPage(AddGoalPage);
import {UploadFileInfo} from "@interfaces/panel/files";

export interface IUserTask {
    id: string;
    name: string;
    isClosed: boolean;
    createTime: string;
    updateTime: string;
    lastAnswer: string;
    messages?: IUserTaskMessage[];
}

export interface IUserTaskMessage {
    id: string;
    task: string;
    senderId: string;
    senderName: string;
    message: string;
    createTime: string;
    incoming: boolean;
    edited: boolean;
    avatarUrl?: string;
    attachments: IUserTaskMessageAttachment[];
}

export interface IUserTaskMessageAttachment {
    id: string;
    fileId: string;
    order: number;
    type: AttachmentType;
    state: AttachmentState;
    name?: string;
    uploadFile?: UploadFileInfo;
}

export enum AttachmentType {
    Image = "Image",
    Video = "Video",
    File = "File"
}

export enum AttachmentState {
    Uploading = "Uploading",
    Uploaded = "Uploaded",
    Failed = "Failed"
}

export interface IUserTaskMessageGroup {
    task: string;
    senderId: string;
    senderName: string;
    avatarUrl?: string;
    incoming: boolean;
    messages: IUserTaskMessage[];
}
import { createSlice, Dispatch } from "@reduxjs/toolkit";
import { ConsumerProjectInfo } from "../../interfaces/consumer/project";
import { ConsumerProjectInfoStore } from "../../interfaces/consumer/store";
import ConsumerService from '../../services/api/consumerService';

const initialData: ConsumerProjectInfo = {
    projectName: '',
    loginServers: [],
    gameServers: []
}

const slice = createSlice({
    initialState: {
        loaded: false,
        loading: false,
        error: null,
        data: initialData
    } as ConsumerProjectInfoStore,
    name: 'consumerProjectInfoStore',
    reducers: {
        setLoading: (state) => {
            state.loaded = false;
            state.loading = true;
            state.error = null;
        },
        setError: (state, action) => {
            state.loaded = false;
            state.loading = false;
            state.error = action.payload;
            state.data = initialData;
        },
        setInfo: (state, action) => {
            state.loaded = true;
            state.error = null;
            state.data = action.payload;
            state.loading = false;
        }
    }
})

export const { reducer: consumerProjectInfoReducer } = slice;

const {
    setError,
    setInfo,
    setLoading
} = slice.actions;

export const loadProjectInfo = () => async (dispatch: Dispatch) => {
    dispatch(setLoading());
    try {
        const api = new ConsumerService();
        const info = await api.fetchConsumerProjectInfo();
        dispatch(setInfo(info));
        return info;
    }
    catch(e) {
        dispatch(setError(e));
    }
}
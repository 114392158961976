import React, { useMemo } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Col, Label, Input } from 'reactstrap'
import { Formik, FormikHelpers, Form, Field } from 'formik'
import * as Yup from 'yup';
import { FormikRowInput } from '../../panel/formik';
import { toastr } from 'react-redux-toastr';
import {
    BonusCodeAccess,
    BonusCodeType,
    ConsumerBonusCode,
    ICodeTypeBalance,
    ICodeTypeItem
} from '@interfaces/consumer/bonusCodes';
import { FormikItemInput } from '../helpers';
import ConsumerBonusService from '@api/consumer/consumerBonusService';
import ReactDatePicker from 'react-datepicker';

interface ConsumerBonusCodesCreateModalProps {
    isOpened: boolean;
    toggle(): void;
    onSuccess(codes: ConsumerBonusCode[]): void;
}

interface ConsumerBonusCodesCreateModalForm {
    code: string;
    count: number;
    maxUseCount: number;
    type: BonusCodeType;
    itemId: string;
    itemCount: number,
    money: number;
    access: BonusCodeAccess;
    timeLimited: boolean;
    beginTime?: Date;
    endTime?: Date;
}
const ConsumerBonusCodesCreateModal: React.FC<ConsumerBonusCodesCreateModalProps> = ({ isOpened, toggle, onSuccess }) => {

    const initial: ConsumerBonusCodesCreateModalForm = {
        code: '',
        count: 1,
        maxUseCount: 1,
        type: BonusCodeType.Balance,
        itemId: '',
        itemCount: 1,
        money: 100,
        access: BonusCodeAccess.Default,
        timeLimited: false
    };

    const valid = useMemo(() => Yup.object().shape({
        code: Yup.string()
            .min(3)
            .max(36),
        count: Yup.number()
            .min(1)
            .max(100)
            .required(),
        type: Yup.string(),
        access: Yup.string(),
        maxUseCount: Yup.number()
            .min(0)
            .max(10000),
        itemId: Yup.string()
            .when("type", {
                is: BonusCodeType.Item,
                then: Yup.string().required()
            }),
        itemCount: Yup.number()
            .when("type", {
                is: BonusCodeType.Item,
                then: Yup.number()
                    .min(1)
                    .max(2000000000)
                    .required()
            }),
        money: Yup.number()
            .when("type", {
                is: BonusCodeType.Balance,
                then: Yup.number()
                    .min(1)
                    .max(100000)
                    .required()
            })
    }), []);

    const onSubmit = async (values: ConsumerBonusCodesCreateModalForm, actions: FormikHelpers<ConsumerBonusCodesCreateModalForm>) => {

        const api = new ConsumerBonusService();

        const value: ICodeTypeBalance | ICodeTypeItem = values.type === BonusCodeType.Balance ? {
            value: values.money
        } : {
            itemId: values.itemId,
            count: values.itemCount
        }

        const result = await api.putConsumerBonusCodes({
            code: values.code,
            count: values.code ? 1 : values.count,
            maxUseCount: values.maxUseCount,
            type: values.type,
            value: JSON.stringify(value),
            access: values.access,
            beginTime: values.timeLimited ? values.beginTime?.toUTCString() : undefined,
            endTime: values.timeLimited ? values.endTime?.toUTCString() : undefined
        });

        if (result.ok) {
            toastr.success('Создание бонусных кодов', 'Коды успешно созданы!');
            onSuccess(result.result || []);
        }
        else {
            if (result.status === 404) {
                toastr.error('Ошибка создания кодов', 'Некорректные данные. Обновите страницу и попробуйте снова.');
            }
            if (result.errorCode === 'InvalidModel') {
                toastr.error('Ошибка создания кодов', 'Некорректные данные. Обновите страницу и попробуйте снова.');
            }
        }
    }

    return (
        <Modal
            isOpen={isOpened}
            toggle={toggle}
            centered>
            <ModalHeader toggle={toggle}>
                Создание бонусных кодов
            </ModalHeader>
            <Formik
                initialValues={initial}
                validationSchema={valid}
                onSubmit={onSubmit}>
                {({ isSubmitting, isValid, values, setFieldValue }) => (
                    <Form>
                        <ModalBody>
                            <Field
                                label="Код"
                                type="text"
                                name="code"
                                placeholder="EXAMPLE-CODE"
                                description="Код будет сгенерирован если оставить поле пустым"
                                component={FormikRowInput} />
                            {
                                !Boolean(values.code) && (
                                    <Field
                                        label="Количество"
                                        type="number"
                                        name="count"
                                        min={1}
                                        max={100}
                                        placeholder="1-100"
                                        description="Количество кодов, которое вы хотите создать"
                                        component={FormikRowInput} />
                                )
                            }
                            <Field
                                label="Количество использований"
                                type="number"
                                name="maxUseCount"
                                min={0}
                                max={100000}
                                placeholder="1-100000"
                                description="Сколько раз может быть использован каждый код. 0 - неограничено."
                                component={FormikRowInput} />
                            <FormGroup row>
                                <Label sm={3}>Тип кода</Label>
                                <Col sm={9} role='group'>
                                    <FormGroup check>
                                        <Field
                                            type='radio'
                                            name='type'
                                            id='bonus-code-balance'
                                            component={Input}
                                            value={BonusCodeType.Balance}
                                            checked={values.type === BonusCodeType.Balance}
                                            onChange={() => setFieldValue('type', BonusCodeType.Balance)} />
                                        <Label check>Баланс</Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Field
                                            type='radio'
                                            name='type'
                                            id='bonus-code-item'
                                            component={Input}
                                            value={BonusCodeType.Item}
                                            checked={values.type === BonusCodeType.Item}
                                            onChange={() => setFieldValue('type', BonusCodeType.Item)} />
                                        <Label check>Предмет</Label>
                                    </FormGroup>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Доступность</Label>
                                <Col sm={9} role='group'>
                                    <FormGroup check>
                                        <Field
                                            type='radio'
                                            name='type'
                                            id='bonus-code-default'
                                            component={Input}
                                            value={BonusCodeAccess.Default}
                                            checked={values.access === BonusCodeAccess.Default}
                                            onChange={() => setFieldValue('access', BonusCodeAccess.Default)} />
                                        <Label check>В лк</Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Field
                                            type='radio'
                                            name='type'
                                            id='bonus-code-signup'
                                            component={Input}
                                            value={BonusCodeAccess.SignUp}
                                            checked={values.access === BonusCodeAccess.SignUp}
                                            onChange={() => setFieldValue('access', BonusCodeAccess.SignUp)} />
                                        <Label check>При регистрации</Label>
                                    </FormGroup>
                                </Col>
                            </FormGroup>
                            {
                                values.type === BonusCodeType.Balance && (
                                    <Field
                                        label="Сумма"
                                        type="number"
                                        name="money"
                                        min={1}
                                        max={10000}
                                        placeholder="1-10000"
                                        description="Сумма, которую будет давать каждый код"
                                        component={FormikRowInput} />
                                )
                            }
                            {
                                values.type === BonusCodeType.Item && (
                                    <>
                                        <FormGroup row>
                                            <Label sm={3}>Предмет</Label>
                                            <Col sm={9} role='group'>
                                                <Field
                                                    name='itemId'
                                                    component={FormikItemInput} />
                                            </Col>
                                        </FormGroup>
                                        <Field
                                            label="Количество"
                                            type="number"
                                            name="itemCount"
                                            min={1}
                                            max={100}
                                            placeholder="1-100"
                                            description="Количество предметов"
                                            component={FormikRowInput} />
                                    </>
                                )
                            }
                            <FormGroup row>
                                <Label for="isActive" sm={3}>Ограничение по времени</Label>
                                <Col sm={9}>
                                    <Input
                                        type="switch"
                                        id="timeLimited"
                                        name="timeLimited"
                                        checked={values.timeLimited}
                                        onChange={e => setFieldValue('timeLimited', e.target.checked)}
                                    />
                                </Col>
                            </FormGroup>
                            {
                                values.timeLimited && (
                                    <>
                                        <FormGroup row>
                                            <Label for="beginTime" sm={3}>Время начала</Label>
                                            <Col sm={9}>
                                                <ReactDatePicker
                                                    selected={values.beginTime}
                                                    id="beginTime"
                                                    name="beginTime"
                                                    onChange={date => setFieldValue('beginTime', date)}
                                                    showTimeInput
                                                    timeInputLabel="Время:"
                                                    dateFormat="dd.MM.yyyy HH:mm"
                                                    placeholderText="Введите дату старта"
                                                    className="form-control" />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="endTime" sm={3}>Время окончания</Label>
                                            <Col sm={9}>
                                                <ReactDatePicker
                                                    selected={values.endTime}
                                                    id="endTime"
                                                    name="endTime"
                                                    onChange={date => setFieldValue('endTime', date)}
                                                    showTimeInput
                                                    timeInputLabel="Время:"
                                                    dateFormat="dd.MM.yyyy HH:mm"
                                                    placeholderText="Введите дату конца"
                                                    className="form-control" />
                                            </Col>
                                        </FormGroup>
                                    </>
                                )
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" type="button" onClick={toggle}>
                                Закрыть
                            </Button>{" "}
                            <Button type="submit" color="primary" disabled={isSubmitting || !isValid}>
                                Создать
                            </Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default ConsumerBonusCodesCreateModal;
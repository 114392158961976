import {FC} from "react";
import {useMarketItemContext} from "./context";

const MarketItemDescription: FC = () => {
    const item = useMarketItemContext();
    if (!item?.description) {
        return null;
    }

    return (
        <div className='ql-container ql-editor'>
            <div dangerouslySetInnerHTML={{ __html: item.description }} />
        </div>
    )
}

export default MarketItemDescription;
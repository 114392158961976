import {FC, PropsWithChildren} from "react";
import { Item } from "@constructor/components/features/items";
import { useInventoryItemContext } from "./context";

const InventoryItem: FC<PropsWithChildren> = ({ children }) => {

    const invItem = useInventoryItemContext();

    if (!invItem) {
        return null;
    }

    return (
        <Item item_id={invItem.itemId}>
            {children}
        </Item>
    )
}

export default InventoryItem;

import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";
import GameServerChargeRate from "./gameServerChargeRate";
import GameServerName from "./gameServerName";
import GameServersList from "./gameServersList";

export interface GameIdProps {
    game_id?: React.ReactText;
}

const GameServerChargeRateInfo: ConstructionComponentInfo = {
    component: GameServerChargeRate
    
}

const GameServerNameInfo: ConstructionComponentInfo = {
    component: GameServerName
    
}

const GameServersListInfo: ConstructionComponentInfo = {
    component: GameServersList
    
}

export {
    GameServerNameInfo,
    GameServerChargeRateInfo,
    GameServersListInfo
}
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { useNotificationContext } from "./context";

interface NotificationIconProps {
    image?: boolean;
}

const NotificationIcon: FC<NotificationIconProps> = ({ image, ...props }) => {
    const not = useNotificationContext();

    if (!not?.icon) {
        return null;
    }

    if (image) {
        return (
            <img src={not.icon} alt='' {...props} />
        )
    }
    return (
        <FontAwesomeIcon icon={not.icon as IconProp} {...props} />
    )
}

export default NotificationIcon;

import {
    faBox,
    faBoxesStacked,
    faDoorOpen,
    faGift,
    faHouse,
    faInfoCircle, faMoneyBillTransfer,
    faNewspaper,
    faPercent,
    faRankingStar, faReceipt,
    faStore,
    faTimeline,
    faUsers,
    faUsersRays,
    faWheelchair
} from '@fortawesome/free-solid-svg-icons';
import { SidebarCategoryLink } from '@interfaces/control-panel';

export const consumerItems: SidebarCategoryLink[] = [
    {
        to: '/',
        name: 'На главную',
        isOpen: false,
        icon: faHouse,
        exact: true
    },
    {
        to: '/consumer/',
        name: 'Сводка',
        isOpen: false,
        icon: faInfoCircle,
        exact: true
    },
    {
        to: '/consumer/users/',
        name: 'Пользователи',
        isOpen: false,
        icon: faUsers,
        role: 'consumer.users.view'
    },
    {
        to: '/consumer/payments/',
        name: 'Донат',
        isOpen: false,
        icon: faMoneyBillTransfer,
        role: 'consumer.payments.view',
        children: [
            {
                to: '/consumer/payments/',
                name: 'Платежи',
                icon: faReceipt,
                exact: true
            },
            {
                to: '/consumer/payments/bonuses/',
                name: 'Бонусы пополнения',
                icon: faPercent
            }
        ]
    },
    {
        to: '/consumer/support/',
        name: 'Поддержка',
        isOpen: false,
        icon: faWheelchair,
        role: 'consumer.support'
    },
    {
        to: '/consumer/bonuses/',
        name: 'Бонусные коды',
        isOpen: false,
        icon: faGift,
        role: 'consumer.bonus.view'
    },
    {
        to: '/consumer/shortnews/',
        name: 'Новости',
        isOpen: false,
        icon: faNewspaper,
        role: 'consumer.shortnews.view'
    },
    {
        to: '/consumer/item/',
        name: 'Предметы',
        isOpen: false,
        icon: faBoxesStacked,
        role: 'consumer.items.view'
    },
    {
        to: '/consumer/market/',
        name: 'Магазин',
        isOpen: false,
        icon: faStore,
        role: 'consumer.items.shop.view',
        children: [
            {
                to: '/consumer/market/item/',
                name: 'Предметы',
                icon: faBox
            },
            {
                to: '/consumer/market/category/',
                name: 'Категории',
                icon: faBoxesStacked
            }
        ]
    },
    {
        to: '/consumer/daily/',
        name: 'Бонусы за вход',
        isOpen: false,
        icon: faTimeline,
        role: 'consumer.daily.view'
    },
    {
        to: '/consumer/refs/',
        name: 'Реферальная система',
        isOpen: false,
        icon: faUsersRays,
        role: 'consumer.ref.view'
    },
    {
        to: '/consumer/goals/',
        name: 'Достижения',
        isOpen: false,
        icon: faRankingStar,
        role: 'consumer.goal.view'
    },
    {
        to: '/logout/',
        name: 'Выход',
        icon: faDoorOpen,
        isOpen: false
    }
]
import { Table } from "@tanstack/react-table";
import {createContext, FC, PropsWithChildren, useContext} from "react";


export const AdvanceTableContext = createContext<Table<any>>({} as Table<any>);

interface AdvanceTableProviderProps {
    table: Table<any>
}

const AdvanceTableProvider: FC<PropsWithChildren & AdvanceTableProviderProps> = ({ children, table }) => {
    return (
        <AdvanceTableContext.Provider value={{ ...table }}>
            {children}
        </AdvanceTableContext.Provider>
    );
};

export const useAdvanceTableContext = <TData,>() => useContext<Table<TData>>(AdvanceTableContext);

export default AdvanceTableProvider;
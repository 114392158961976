import { usePaymentCurrency } from "@hooks/selectors/panel.selectors";
import { FC } from "react";
import { useQueryContext } from "./context";
import { Text } from '@components/constructor/components';

const QueryPrice: FC = (props) => {

    const query = useQueryContext();
    const currency = usePaymentCurrency();

    if(!query) {
        return null;
    }

    return (
        <Text text={`${query.price} ${currency}`} {...props} />
    )
}

export default QueryPrice;
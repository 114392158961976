import { FC } from "react";
import { useUserName } from "@hooks/selectors/user.selectors";
import { Text } from '@constructor/components';

const UserName: FC = (props) => {

    const name = useUserName();

    return (
        <Text text={name} {...props} />
    )
}

export default UserName;
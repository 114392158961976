import { usePagination } from "@hooks/components/constructor.hooks";
import { useDonateBonuses } from "@hooks/selectors/user/payments.selectors";
import { PaginationProps } from "@constructor/components/core/pagination";
import {FC, PropsWithChildren, useMemo} from "react";
import { injectChild } from "@components/constructor";

interface DonateBonusesProps {
    donate_bonuses_per_page?: number;
}

interface DonateBonusesChildProps {
    donate_bonuses_count: number;
}

const DonateBonuses: FC<PropsWithChildren<DonateBonusesProps>> = ({donate_bonuses_per_page = 10, children}) => {

    const bonuses = useDonateBonuses();
    const pagination = usePagination(donate_bonuses_per_page, bonuses.length);

    const childData = useMemo<PaginationProps & DonateBonusesChildProps>(() => {
        return {
            donate_bonuses_count: bonuses.length,
            ...pagination
        }
    }, [bonuses.length, pagination]);

    return injectChild(childData, children);
}

export default DonateBonuses;
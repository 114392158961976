import { useTopicAuto } from "@hooks/selectors/user/support.selectors";
import { FC } from "react";
import { Text } from '@components/constructor/components';

const SupportTopicName: FC = (props) => {
    const topic = useTopicAuto();

    if (!topic) {
        return null;
    }

    return (
        <Text text={topic.name} {...props} />
    )
}

export default SupportTopicName;
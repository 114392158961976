import ConsumerRefService from "@api/consumer/consumerRefService";
import { IRef } from "@interfaces/consumer/refs";
import { FC, useCallback } from "react";
import { toastr } from "react-redux-toastr";
import { CardBody } from "reactstrap";
import RefForm, { ConditionType, RefFormValues } from "./refForm";

interface EditRefFormProps {
    refObj: IRef;
}

const EditRefForm: FC<EditRefFormProps> = ({ refObj: ref }) => {

    const initialValues: RefFormValues = {
        name: ref.name,
        description: ref.description || '',
        conditionType: ref.allTargets ? ConditionType.All : ConditionType.One,
        hidden: ref.hidden,
        isActive: ref.isActive,
        order: ref.order
    }

    const onSubmit = useCallback(async (values: RefFormValues) => {
        const api = new ConsumerRefService();
        const result = await api.patchRef({
            id: ref.id,
            name: values.name,
            description: values.description,
            hidden: values.hidden,
            allTargets: values.conditionType === ConditionType.All,
            isActive: values.isActive,
            order: values.order
        });

        if (result.success) {
            toastr.success('Реферальная система', 'Достижение обновлено');
        }
        else {
            if (result.errorCode === '') {
                toastr.error('Реферальная система', 'Достижение не найдено');
            }
            else {
                toastr.error('Реферальная система', 'Неизвестная ошибка');
            }
        }
    }, [ref.id]);

    return (
        <CardBody>
            <RefForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                editForm />
        </CardBody>
    )
}

export default EditRefForm;
import {FC, RefAttributes} from 'react';
import {useFileUrl} from "@hooks/filesHooks";
import {ImageProps} from "react-bootstrap/Image";
import {Image} from "react-bootstrap";

interface LoadableImageProps {
    fileId: string;
}
const LoadableImage: FC<LoadableImageProps & ImageProps & RefAttributes<HTMLImageElement>> = ({fileId, src,  ...props }) => {

    const fileUrl = useFileUrl(fileId, src);

    return (
        <Image
            {...props}
            src={fileUrl}
        />
    )
}

export default LoadableImage;
import {FC, PropsWithChildren, useMemo} from "react";
import { injectContextArray } from "@components/constructor";
import { useInventory } from "@hooks/selectors/user.selectors";
import { IInventoryItem } from "@interfaces/user/items";
import { PaginationProps } from "@constructor/components/core/pagination";
import { InventoryItemProvider } from "./context";
import { useItems } from "@hooks/selectors/user/item.selectors";
import {InjectContextType} from "@interfaces/constructor/constructor";

interface InventoryItemsListChildProps {
    inventory_item_index: number;
    inventory_item_id: string;
}

const InventoryItemsList: FC<PropsWithChildren<PaginationProps>> = ({ pagination_current_page = 0, pagination_per_page = 10, children }) => {

    const items = useInventory();

    const itemIds = items.map(i => i.itemId);
    useItems(itemIds);

    const page = useMemo(() => items.slice(pagination_current_page * pagination_per_page, (pagination_current_page + 1) * pagination_per_page), [items, pagination_current_page, pagination_per_page]);

    const data = useMemo<InjectContextType<IInventoryItem, InventoryItemsListChildProps>[]>(() => page.map((item, idx) => {
        return {
            key: item.id,
            value: item,
            childData: {
                inventory_item_index: pagination_current_page * pagination_per_page + 1 + idx,
                inventory_item_id: item.id
            }
        }
    }), [page, pagination_current_page, pagination_per_page]);

    return injectContextArray(data, InventoryItemProvider, children);
}

export default InventoryItemsList;
import * as React from 'react';
import classNames from 'classnames';
import {FC, PropsWithChildren} from "react";

interface WrapperProps {
    className?: string;
}

const Wrapper: FC<PropsWithChildren<WrapperProps>> = ({ className, children }) => (
  <div className={classNames("wrapper", className)}>{children}</div>
);

export default Wrapper;
import { Loadable } from "@interfaces/helpers";
import { DownloadFileInfo } from "@interfaces/panel/files";
import { ReduxStore } from "@interfaces/store";
import { panelActions } from "@store/panelStore";
import {useEffect} from "react";
import { shallowEqual, useSelector } from "react-redux";
import { usePromisedDispatch } from "./helperHooks";

export function useFile(id: string = ''): Loadable<DownloadFileInfo | undefined> {
    const file = useSelector(({ panel: { files } }: ReduxStore) => files[id]);

    const dispatch = usePromisedDispatch();

    useEffect(() => {
        const load = async () => {
            await dispatch(panelActions.loadFiles([id]));
        }

        if(!id) {
            return;
        }

        if (file === undefined) {
            load();
        }
        else if (file.data
            && !file.loading
            && !file.data.isPermanent
            && Date.parse(file.data.createTime) + file.data.lifetime * 1000 <= Date.now()) {
            load();
        }
    }, [dispatch, file, id]);

    return file || {
        loaded: false,
        loading: false
    };
}

export function useFiles(ids: string[]) {

    const dispatch = usePromisedDispatch();
    const files = useSelector(({ panel: { files } }: ReduxStore) =>
        Object.entries(files)
            .filter(([id]) => ids.includes(id))
            .map(([, file]) => file), shallowEqual);

    const toLoad = ids.filter(id => !files.find(f => f.data?.id === id));
    const toRefresh = files.filter(f => f.data
        && !f.loading
        && !f.data.isPermanent
        && Date.parse(f.data.createTime) + f.data.lifetime * 1000 <= Date.now())
        .map(f => f.data?.id as string);

    useEffect(() => {
        dispatch(panelActions.loadFiles(toLoad.concat(toRefresh)));
    }, []); //иначе в цикл падает

    return files;
}

export function useFileUrl(id?: string, defaultSrc?: string): string {
    const file = useFile(id);
    return file.data?.url || defaultSrc || '';
}

export function useFilesUrls(ids: string[], defaultSrc: string[] = []): string[] {
    const files = useFiles(ids);

    if(ids.length === 0 && defaultSrc.length > 0) {
        return defaultSrc;
    }

    return files.map((f, idx) => f.data?.url || (defaultSrc.length > idx && defaultSrc[idx]) || '');
}
import React, {FC, useCallback, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import ConsumerUsersService from "@api/consumer/consumerUsersService";
import {PanelTableDataModel} from "@models/panel";
import {Card} from "react-bootstrap";
import {dateFormatter} from "@services/helpers";
import {useLocalizedBlock} from "@services/hooks";
import {
    ConsumerUserBalanceLog,
    ConsumerUserBalanceLogReasonType,
    ConsumerUserFull
} from "@interfaces/consumer/users";
import {ColumnDef} from "@tanstack/react-table";
import {useRemoteTable} from "@hooks/tableHooks";
import {Flex} from "@components/falcon/common";
import {AdvanceTable, AdvanceTableFooter} from "@components/falcon/common/tables";
import AdvanceTableProvider from "@components/falcon/common/tables/context";

interface BalanceLogsProps {
    user: ConsumerUserFull;
}

const BalanceLogs: FC<BalanceLogsProps> = ({user}) => {
    const { id } = useParams<{ id: string }>();
    const [api] = useState(() => new ConsumerUsersService());
    const fetchApi = useCallback((model: PanelTableDataModel) => api.fetchBalanceLogs(id, model), [api, id]);

    const lang = useLocalizedBlock('consumer.user.balance.history.balance');
    const getBalanceLogReason = useCallback((log: ConsumerUserBalanceLog) => {
        let reason = getBalanceReason(log.reasonType);
        if (log.reasonType === ConsumerUserBalanceLogReasonType.GameCharge && log.reasonId) {
            const account = user?.accounts.find(a => a.id === log.reasonId);
            if (account) {
                reason += ` ${account.name}`;
            }
        }

        return reason;
    }, [user?.accounts]);

    const columns: ColumnDef<ConsumerUserBalanceLog>[] = useMemo(() => [
        {
            accessorKey: 'date',
            header: lang['date'],
            enableSorting: true,
            cell: ({row: {original: {date}}}) => dateFormatter(date),
            meta: {
                headerProps: { className: 'pe-1 text-900' },
                cellProps: { className: 'py-2' },
                placeholderSize: 11
            }
        },
        {
            accessorKey: 'reasonId',
            header: lang['reason'],
            enableSorting: true,
            cell: ({row: {original}}) => getBalanceLogReason(original),
            meta: {
                headerProps: { className: 'text-900' },
                placeholderSize: [8, 11]
            }
        },
        {
            accessorKey: 'summ',
            header: lang['summ'],
            enableSorting: true,
            meta: {
                headerProps: { className: 'text-900 text-end' },
                cellProps: { className: 'text-end' },
                placeholderSize: 3
            }
        }
    ], [lang, getBalanceLogReason]);

    const table = useRemoteTable({
        name: 'consumer.users.balance.logs',
        columns,
        defaultState: {
            sorting: [
                {
                    id: 'date',
                    desc: true
                }
            ]
        }
    }, fetchApi);

    return (
        <AdvanceTableProvider table={table}>
            <Card className="mb-3">
                <Card.Header>
                    <Flex className='align-items-center justify-content-between'>
                        <Flex alignItems="center" className="fs-10">
                            <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">История баланса</h5>
                        </Flex>
                        {/*<Flex>
                            <AdvanceTableSearchBox/>
                        </Flex>*/}
                    </Flex>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowsPerPageSelection
                        paginationButtons
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableProvider>
    );
}

function getBalanceReason(reason: ConsumerUserBalanceLogReasonType) {
    switch (reason) {
        case ConsumerUserBalanceLogReasonType.Payment:
            return "Пополнение баланса";
        case ConsumerUserBalanceLogReasonType.BonusCode:
            return "Использование бонусного кода"
        case ConsumerUserBalanceLogReasonType.AdminCharge:
            return "Изменение администратором";
        case ConsumerUserBalanceLogReasonType.Shop:
            return "Покупка в магазине";
        case ConsumerUserBalanceLogReasonType.GameCharge:
            return "Пополнение игрового аккаунта";
        case ConsumerUserBalanceLogReasonType.CustomQuery:
            return "Выполнение платного запроса";
        case ConsumerUserBalanceLogReasonType.BonusCodeBuy:
            return "Покупка бонусного кода";
        case ConsumerUserBalanceLogReasonType.Rollback:
            return "Отмена транзакции";
        case ConsumerUserBalanceLogReasonType.Daily:
            return "Получение ежедневной награды";
        case ConsumerUserBalanceLogReasonType.Api:
            return "API запрос";
        case ConsumerUserBalanceLogReasonType.Goal:
            return "Получение достижения";
        case ConsumerUserBalanceLogReasonType.Ref:
            return "Реферальная награда";
        case ConsumerUserBalanceLogReasonType.RefPercent:
            return "Реферальная награда (процент)";

        default:
            return "Unk";
    }
}

export default BalanceLogs;
import { useGoals } from "@hooks/selectors/user/goals.selectors";
import {FC, PropsWithChildren, useMemo} from "react";
import { PaginationProps } from "@constructor/components/core/pagination";
import { usePagination } from "@hooks/components/constructor.hooks";
import { injectChild } from "@components/constructor";

interface GoalsProps {
    goals_per_page?: number;
}

interface GoalsChildProps {
    goals_loaded: boolean;
    goals_loading: boolean;
    goals_error: boolean;
    goals_count: number;
}

const Goals: FC<PropsWithChildren<GoalsProps>> = ({ goals_per_page = 10, children }) => {

    const goals = useGoals();
    const pagination = usePagination(goals_per_page, goals.data.length);

    const data = useMemo<GoalsChildProps & PaginationProps>(() => ({
        goals_loaded: goals.loaded,
        goals_loading: goals.loading,
        goals_error: goals.error,
        goals_count: goals.data.length,
        ...pagination
    }), [goals.data.length, goals.error, goals.loaded, goals.loading, pagination]);

    return injectChild(data, children);
}

export default Goals;
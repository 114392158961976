import {FC, PropsWithChildren, useCallback, useMemo} from 'react';
import {ConstructionComponentData, ConstructionComponentProps} from '@interfaces/constructor/constructor';
import { useTheme } from '@hooks/panelHooks';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { sidebarActions } from '@store/sidebarStore';
import { getComponent } from './getComponent';
import { Theme } from '@interfaces/panel/themes';
import { auth } from '@services/auth';
import { useGlobalVariables } from '@hooks/selectors/panel.selectors';
import { KeyValueSet } from '@interfaces/helpers';
import { panelActions } from '@store/panelStore';
import {Parser} from "expr-eval";

function first(...values: any[]) {
    return values.find(x => x !== undefined);
}

function replaceValue(value: string, childProps: any, globalVars: KeyValueSet<string | number>) {

    //Дополнительная проверка передаваемого типа
    if(typeof value !== 'string') {
        return value;
    }

    if (/^\{[A-Za-z-._]+\}$/.test(value)) {
        const c = value.substring(1, value.length - 1);
        return first(childProps[c], globalVars[c]);
    }

    if (/^!\{[A-Za-z-._]+\}$/.test(value)) {
        const c = value.substring(2, value.length - 1);
        const val = first(childProps[c], globalVars[c]);
        if (val !== undefined) {
            return !val;
        }
        return value;
    }
    const matches = value.match(/\{[A-Za-z-._]+\}/g);

    if (!matches) {
        return value;
    }

    let result: string = value;
    matches.forEach(match => {

        const c = match.substring(1, match.length - 1);
        const val = first(childProps[c], globalVars[c]);
        if (val !== undefined) {
            result = result.replace(match, val.toString());
        }
    });

    return result;
}

function replaceProps(props: any, childProps: any, globalVars: KeyValueSet<string | number>, actions: KeyValueSet<(...args: any[]) => any>) {

    if (typeof props !== 'object' || props === null || Array.isArray(props) || !childProps) {
        return props;
    }

    const newProps = { ...props };

    Object.entries(props).forEach(([key, value]) => {

        if (typeof value === 'string' && value) {
            //Если значение - строка, то заменяем в ней все переменные
            const result = replaceValue(value, childProps, globalVars);
            if(result !== undefined) {
                newProps[key] = result;
            }
        }
        else if(key === 'ref' || value === null || value === undefined || typeof value !== 'object') {
            //Игнорируем объект рефа, пустые значения и значения-не объекты. их мы заменили ранее
            return;
        }
        else if (Array.isArray(value)) {
            newProps[key] = value.map(x => replaceValue(x, childProps, globalVars));
        } else {
            //Если значение = объект - рекурсивно обрабатываем объект с заменой значений

            const p = replaceProps(newProps[key], childProps, globalVars, actions);
            if (p !== newProps[key]) {
                newProps[key] = p;
            }

            //Если значение - объект с полем action - пробуем собрать из него вызов функции
            const v = value as KeyValueSet<any>;

            if(typeof v.action === 'string') {
                const action = actions[v.action] || childProps[v.action];
                if(typeof action !== 'function') {
                    return;
                }
                const params = Array.isArray(v.params) ? v.params.map(x => replaceValue(x, childProps, globalVars)) : [];
                const func = replaceValue(v.function, childProps, globalVars);

                newProps[key] = () => {
                    action(...params);
                    if(typeof func === 'function') {
                        func();
                    }
                };
            }
        }
    });

    if (newProps.className && typeof newProps.className === 'object') {

        Object.entries(newProps.className).forEach(([key, value]) => {
            if(typeof value === "string") {
                try {
                    newProps.className[key] = Boolean(Parser.evaluate(value));
                }
                catch(e) {}
            }
        });

        const newCN = classNames(newProps.className);
        if (newProps.className !== newCN) {
            //console.log(newProps.className);
            newProps.className = newCN;
        }
    }

    return newProps;
}



function toArray<T>(values: T | T[] | undefined): T[] {
    if (Array.isArray(values)) {
        return values;
    }
    else if (values) {
        return [values];
    }
    return [];
}

function makeChildrens(
    theme: Theme | undefined, 
    childData: ConstructionComponentData | ConstructionComponentData[] | undefined, 
    childProps: any, 
    children: React.ReactNode,
    proxiedChildren: React.ReactNode, 
    globalVars: KeyValueSet<string | number>,
    actions: KeyValueSet<() => any>,
    wrap: boolean = false) {

    if (childData) {
        return toArray(childData).map((child, idx) => {

            const Tag = getComponent(child.component, theme);

            if (typeof Tag === 'string' && !wrap && Tag !== 'Children') {

                const newProps = replaceProps(child.props, childProps, globalVars, actions);

                return (
                    <Tag key={child.guid || idx} {...newProps}>
                        {makeChildrens(theme, child.children, childProps, children, proxiedChildren, globalVars, actions)}
                    </Tag>
                )
            }
            return (
                <ConstructionComponent key={child.guid || idx} data={child} childProps={childProps} proxiedChildren={children || proxiedChildren} />
            )
        });
    }
    else {
        return children;
    }
}


export const ConstructionComponent: FC<PropsWithChildren<ConstructionComponentProps>> = ({ data, name, childProps, children, proxiedChildren }) => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const globalVars = useGlobalVariables();

    const setGlobalVariable = useCallback((name: string, value: string) => dispatch(panelActions.setVariable({
        name, value
    })), [dispatch]);

    const deleteGlobalVariable = useCallback((name: string) => dispatch(panelActions.deleteVariable(name)), [dispatch]); 

    const sidebar_toggle = useCallback(() => dispatch(sidebarActions.toggleSidebar()), [dispatch]);

    const actions: KeyValueSet<(...args: any[]) => any> = useMemo(() => ({
        setGlobalVariable,
        deleteGlobalVariable
    }), [deleteGlobalVariable, setGlobalVariable]);

    //инициализируем глобальные функции в childProps
    if (!childProps)
        childProps = {};
    if (!childProps.sidebar_toggle)
        childProps.sidebar_toggle = sidebar_toggle;
    if (!childProps.logout)
        childProps.logout = auth().signOut;

    if (theme?.components && name) {
        if (name.startsWith('CC.')) {
            name = name.substring(3)
        }
        data = theme.components[name];
        //console.log(`CC: ${JSON.stringify(data)}`);
    }

    let broke = false;

    if (!data) {
        broke = true; //костыль, чтобы вызвать useMemo
        data = {
            component: '',
            children: undefined,
            props: {}
        }
    }

    let { component: componentName, children: childData, props } = data;

    if (!componentName) {
        console.log(data);
    }

    //Заменяем переменные в пропсах
    const newProps = useMemo(() => {
        return replaceProps(props, childProps, globalVars, actions) || {};
    }, [props, childProps, globalVars, actions]);
    //const newProps = replaceProps(props, childProps) || {};

    if (broke)
        return null;

    //Формируем класснейм
    //if (newProps.className)
    //    newProps.className = classNames(newProps.className);

    const ComponentInfo = getComponent(componentName, theme);

    if (!ComponentInfo) {
        console.log(`${componentName} not found`);
    }

    if (typeof ComponentInfo === 'string') {

        if (ComponentInfo === 'Children') {
            return (
                <>{proxiedChildren}</>
            )
        }

        return (
            <ComponentInfo key={data.guid} {...newProps}>
                {makeChildrens(theme, childData, childProps, children, proxiedChildren, globalVars, actions)}
            </ComponentInfo>
        )
    }

    const { injectChild, takedChildProps, component: Component } = ComponentInfo;

    if(!Component) {
        console.log(`Отсутствует компонент ${componentName}`);
        console.log(ComponentInfo);
    }

    toArray(injectChild).forEach(c => { //Внедряем нужные пропсы

        if (childProps[c] !== undefined) {
            newProps[c] = childProps[c];
        }

    });

    toArray(takedChildProps).forEach(p => { //Если некоторые пропсы не нужно передавать дальше
        delete childProps[p]; //удаляем пропс
    });

    if (componentName.startsWith('CC.')) {
        return (
            <ConstructionComponent name={componentName} childProps={childProps} proxiedChildren={children} >
                {makeChildrens(theme, childData, childProps, children, proxiedChildren, globalVars, actions)}
            </ConstructionComponent>
        )
    }

    return (
        <Component key={data.guid} {...newProps}>
            {makeChildrens(theme, childData, childProps, children, proxiedChildren, globalVars, actions, true)}
        </Component>
    )
}

export default ConstructionComponent;
import {FC, PropsWithChildren, useRef, useState} from 'react';
import { FormikHelpers, Formik, Form } from 'formik';
import { BasicToastrOptions, toastr } from 'react-redux-toastr';
import ReCAPTCHA from 'react-google-recaptcha';
import * as Yup from 'yup';
import { injectChild } from '@components/constructor';
import { useLocalizedBlock } from '@services/hooks';
import { auth } from '@services/auth';

interface ForgotPasswordFormValues {
    email: string;
}

const ForgotPasswordForm: FC<PropsWithChildren> = ({ children, ...props }) => {

    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [recaptcha, setRecaptcha] = useState<string | null>(null);
    const locale = useLocalizedBlock('guest.password.forgot');
    const yupErrors = useLocalizedBlock('yup.message.errors');

    const onSubmit = async (values: ForgotPasswordFormValues, actions: FormikHelpers<ForgotPasswordFormValues>) => {

        if (!recaptcha) {
            actions.setFieldError("email", locale['errors.recaptcha']);
            return;
        }

        const result = await auth().forgotPassword({ email: values.email, recaptcha });

        const options: BasicToastrOptions = {
            timeOut: 10000,
            showCloseButton: true,
            progressBar: true,
        }

        if (result.ok) {
            toastr.success(locale['header.toastr'], locale['success'], options);
        }
        else {
            if (result.errorCode === 'SmtpError') {
                toastr.error(locale['header.toastr'], locale['errors.SmtpError']);
            }
            else if (result.errorCode === 'InvalidModel') {
                toastr.error(locale['header.toastr'], locale['errors.InvalidModel']);
            }
            else if (result.errorCode === 'TooOften') {
                toastr.error(locale['header.toastr'], locale['errors.toooften']);
            }
            recaptchaRef.current?.reset();
        }
    }

    const initial: ForgotPasswordFormValues = {
        email: ''
    }

    const validate = Yup.object().shape({
        email: Yup.string()
            .email(yupErrors['email'])
            .required(yupErrors['required'])
    });

    return (
        <Formik
            initialValues={initial}
            onSubmit={onSubmit}
            validationSchema={validate}>
            <Form {...props}>
                {
                    injectChild({
                        recaptcha: {
                            ref: recaptchaRef,
                            onChange: setRecaptcha
                        }
                    }, children)
                }
            </Form>
        </Formik>
    )
}

export default ForgotPasswordForm;
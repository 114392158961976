import ConsumerRefService from "@api/consumer/consumerRefService";
import { EditGoalForm, GoalConditionsList, GoalRewardsList } from "@components/consumer/goals";
import withPage from "@components/hoc/withPage";
import { useBreadcrumbs, useStateLoader } from "@hooks/panelHooks";
import { IGoal } from "@interfaces/consumer/refs";
import { FC, useCallback } from "react";
import { NavLink, Route, Switch, useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, CardTitle, Nav, NavItem } from "reactstrap";

const EditGoalPage: FC = () => {

    const { goal_id } = useParams<{ goal_id: string }>();
    const loadGoal = useCallback(() => {
        const api = new ConsumerRefService();
        return api.getGoal(goal_id);
    }, [goal_id]);

    const { data: goal } = useStateLoader<IGoal>(loadGoal);
    useBreadcrumbs('consumer.goals.current.name', false, goal?.name);

    if (!goal) {
        return null;
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h5">
                    Редактирование достижения {goal?.name}
                </CardTitle>
            </CardHeader>
            <CardBody>
                <Nav tabs>
                    <NavItem>
                        <NavLink to={`/consumer/goals/${goal_id}/`} className='nav-link' activeClassName="active" replace exact>
                            Основное
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to={`/consumer/goals/${goal_id}/conditions/`} className='nav-link' activeClassName="active" replace>
                            Условия
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to={`/consumer/goals/${goal_id}/rewards/`} className='nav-link' activeClassName="active" replace>
                            Награды
                        </NavLink>
                    </NavItem>
                </Nav>
                <Switch>
                    <Route path={`/consumer/goals/:goal_id/`} exact>
                        <EditGoalForm goal={goal} />
                    </Route>
                    <Route path={`/consumer/goals/:goal_id/conditions/`}>
                        <GoalConditionsList />
                    </Route>
                    <Route path={`/consumer/goals/:goal_id/rewards/`}>
                        <GoalRewardsList />
                    </Route>
                </Switch>
            </CardBody>
        </Card>
    )
}

export default withPage(EditGoalPage);
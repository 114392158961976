import React from 'react';
import { FontAwesomeIconProps, FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface AnimatedFontAwesomeIconProps {
    animate?: "spin" | "pulse"
}

interface AnimatedFontAwesomeIconState {
    hovered: boolean;
}
export default class AnimatedFontAwesomeIcon extends React.Component<AnimatedFontAwesomeIconProps & FontAwesomeIconProps, AnimatedFontAwesomeIconState> {

    state = {
        hovered: false
    }

    setHovered = (hovered: boolean) => {
        this.setState({
            hovered
        });
    }

    render() {
        const { animate, ...faProps } = this.props;
        const { hovered } = this.state;

        return (
            <FontAwesomeIcon 
                {...faProps} 
                onMouseEnter={() => this.setHovered(true)} 
                onMouseLeave={() => this.setHovered(false)}
                spin={animate === "spin" && hovered}
                pulse={animate === "pulse" && hovered}/>
        )
    }
}
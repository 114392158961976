import SupportTopicInputAttachedImagesList from "./inputAttachedImagesList";
import {ConstructionComponentInfo} from "@interfaces/constructor/constructor";

const SupportTopicInputAttachedImagesListInfo: ConstructionComponentInfo = {
    component: SupportTopicInputAttachedImagesList,
    addedChildProps: [
        'attached_image_id',
        'attached_image_url',
        'remove_attached_image'
    ]
}

export {
    SupportTopicInputAttachedImagesListInfo
}
import { injectChild } from "@components/constructor";
import { usePromisedDispatch } from "@hooks/helperHooks";
import { useThemeUrl } from "@hooks/panelHooks";
import { useRefCode } from "@hooks/selectors/user/refs.selectors";
import { IRefCode } from "@interfaces/user/refs";
import { userActions } from "@store/user";
import {FC, PropsWithChildren, useCallback, useMemo, useState} from "react";
import { toastr } from "react-redux-toastr";
import { RefCodeProvider } from "./context";

interface RefCodeChildProps {
    ref_code_loading: boolean;
    ref_code_loaded: boolean;
    ref_code_error: boolean;
    ref_code_exists: boolean;

    ref_code_creating: boolean;
    create_ref_code: () => any;
    copy_ref_code: () => any;
}

const RefCode: FC<PropsWithChildren> = ({ children }) => {

    const dispatch = usePromisedDispatch();
    const code = useRefCode();
    const [creating, setCreating] = useState(false);
    const regUrl = useThemeUrl('signup');
    const link = code.data?.code && `${window.location.protocol}//${window.location.hostname}${regUrl}?r=${code.data.code}`;

    const createCode = useCallback(async () => {

        setCreating(true);
        const result = await dispatch(userActions.refs.createCode());

        if (result.success) {
            toastr.success('Реферальная система', 'Реферальный код создан.');
        }
        else {
            if (result.errorCode === 'CreateError') {
                toastr.error('Реферальная система', 'Не удалось создать реферальный код. Попробуйте еще раз.');
            }
            else {
                toastr.error('Реферальная система', 'Неизвестная ошибка.');
            }
        }
        setCreating(false);
    }, [dispatch]);

    const copyCode = useCallback(() => {
        if(link) {
            navigator.clipboard.writeText(link);
            toastr.success('Реферальная система', 'Ссылка скопирована в буфер обмена');
        }
    }, [link]);

    const data = useMemo<RefCodeChildProps>(() => ({
        ref_code_error: code.error,
        ref_code_loaded: code.loaded,
        ref_code_loading: code.loading,
        ref_code_exists: code.loaded && Boolean(code.data),

        ref_code_creating: creating,
        create_ref_code: createCode,
        copy_ref_code: copyCode
    }), [code.data, code.error, code.loaded, code.loading, copyCode, createCode, creating]);

    return (
        <RefCodeProvider value={code.data as IRefCode}>
            {injectChild(data, children)}
        </RefCodeProvider>
    )
}

export default RefCode;
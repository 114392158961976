import IService from "@api/iService";
import {
    ConsumerBonusCode,
    ConsumerBonusCodeFull,
    ConsumerBonusCodesList,
    ConsumerBonusCodeUse
} from "@interfaces/consumer/bonusCodes";
import { PanelTableData } from "@interfaces/panel";
import { ConsumerAddBonusCodesModel, ConsumerBonusCodesListModel, ConsumerPatchBonusCodeModel } from "@models/consumer/bonusCodes";
import { PanelTableDataModel } from "@models/panel";

export default class ConsumerBonusService extends IService {

    async fetchConsumerBonusCodes(model: ConsumerBonusCodesListModel) {
        return await this.postApi<ConsumerBonusCodesList>('/consumer/bonus/', model);
    }

    getBonusCodesList(model: PanelTableDataModel) {
        return this.postApi<PanelTableData<ConsumerBonusCode[]>>('/consumer/bonus/', model);
    }

    async putConsumerBonusCodes(codes: ConsumerAddBonusCodesModel) {
        return await this.putApi<ConsumerBonusCode[]>('/consumer/bonus/', codes);
    }

    async patchBonusCode(model: ConsumerPatchBonusCodeModel) {
        return await this.patchApi<ConsumerBonusCodesList>('/consumer/bonus/', model);
    }
    
    async getBonusCode(id: string) {
        return await this.getApi<ConsumerBonusCodeFull>(`/consumer/bonus/${id}/`);
    }

    fetchBonusCodeUsesList = async (id: string, model: PanelTableDataModel) => {
        return await this.postApi<PanelTableData<ConsumerBonusCodeUse[]>>(`/consumer/bonus/${id}/uses/`, model);
    }
}
import ConsumerBonusService from "@api/consumer/consumerBonusService";
import { ConsumerBonusCodeUse } from "@interfaces/consumer/bonusCodes";
import { PanelTableDataModel } from "@models/panel";
import { dateFormatter } from "@services/helpers";
import { FC, useCallback, useState } from "react";
import {Link, useParams} from "react-router-dom";
import {ColumnDef} from "@tanstack/react-table";
import {Flex} from "@components/falcon/common";
import {useRemoteTable} from "@hooks/tableHooks";
import {Card, Col, Row} from "react-bootstrap";
import {AdvanceTable, AdvanceTableFooter} from "@components/falcon/common/tables";
import AdvanceTableProvider from "@components/falcon/common/tables/context";

//TODO add sorting
const columns: ColumnDef<ConsumerBonusCodeUse>[] = [
    {
        accessorKey: 'userName',
        header: 'Пользователь',
        enableSorting: false,
        meta: {
            headerProps: { className: 'pe-1 text-900' },
            cellProps: { className: 'py-2' }
        },
        cell: ({row: {original: {userId, userName}}}) => (
            <Link to={`/consumer/users/${userId}/`}>
                <Flex alignItems="center">
                    <div className="flex-1">
                        <h5 className="mb-0 fs-10">{userName}</h5>
                    </div>
                </Flex>
            </Link>
        )
    },
    {
        accessorKey: 'useTime',
        header: 'Дата использования',
        enableSorting: false,
        meta: {
            headerProps: { className: 'text-900 text-end' },
            cellProps: { className: 'text-end' },
            placeholderSize: 6
        },
        cell: ({row: {original: {useTime}}}) => dateFormatter(useTime)

    }
]

const ConsumerBonusCodeUsesList: FC = () => {

    const { code_id } = useParams<{ code_id: string }>();
    const [api] = useState(() => new ConsumerBonusService());
    const fetch = useCallback((data: PanelTableDataModel) => api.fetchBonusCodeUsesList(code_id, data), [api, code_id]);

    const table = useRemoteTable({
        name: 'consumer.bonus.uses.list',
        columns,
        defaultState: {
            pagination: {
                pageSize: 5,
                pageIndex: 0
            }
        }
    }, fetch);

    return (
        <AdvanceTableProvider table={table}>
            <Card className="mb-3">
                <Card.Header as="h6" className="bg-body-tertiary">
                    Использования кода
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        emptyText="Код не использовался"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowInfo
                        rowsPerPageSelection
                        paginationButtons
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableProvider>
    )
}

export default ConsumerBonusCodeUsesList;
import { FC } from "react";
import { usePersonalCodeContext } from "./context";
import { Text } from '@constructor/components';
import { fromUtcDate } from "@services/helpers";

interface PersonalBonusCodeUseTimeProps {
    default_value?: string;
    format?: string;
}

const PersonalBonusCodeUseTime: FC<PersonalBonusCodeUseTimeProps> = ({default_value, format = 'lll', ...props}) => {
    
    const code = usePersonalCodeContext();
    if (!code?.useTime && !default_value) {
        return null;
    }

    const value = code?.useTime ? fromUtcDate(code.useTime).format(format) : default_value as string;

    return (
        <Text text={value} {...props} />
    )
}

export default PersonalBonusCodeUseTime;
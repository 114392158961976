import { FC } from "react";
import Cropper from "react-cropper";
import { useUploadAvatarContext } from "./context";

const AvatarCropper: FC = (props) => {

    const context = useUploadAvatarContext();

    if(!context?.imageUrl) {
        return null;
    }

    return (
        <Cropper
            ref={context.cropper_ref}
            src={context.imageUrl}
            guides={false}
            aspectRatio={1}
            {...props}            
        />
    )
}

export default AvatarCropper;
import {FC, useCallback, useMemo, useState} from 'react';
import * as Yup from 'yup';
import {Formik, Form} from 'formik';
import { toastr } from 'react-redux-toastr';
import { IItemFull } from '@interfaces/consumer/items';
import ConsumerItemsService from '@api/consumer/consumerItemsService';
import { FileUploader } from '@services/files/fileUploader';
import {Col, Row} from "react-bootstrap";
import {FormIconBlock, FormPageFooter, FormPageHeader} from "@components/falcon/common/forms";
import {BaseInfoBlock, ServersBlock} from "@components/consumer/items/forms";


interface EditItemFormProps {
    item: IItemFull;
}

interface EditItemFormValues {
    name: string;
    description: string;
    isActive: boolean;
    icon: File | null;
    innerId: string;
    servers: string[];
}

const EditItemForm: FC<EditItemFormProps> = ({ item }) => {

    const [uploading, setUploading] = useState(false);
    const [uploadState, setUploadState] = useState(0);

    const onProgress = useCallback((event: ProgressEvent<EventTarget>) => {
        setUploadState(event.loaded / event.total * 100);
    }, [setUploadState]);

    const validationSchema = useMemo(() => Yup.object().shape({
        name: Yup.string()
            .max(256)
            .required(),
        description: Yup.string()
            .max(10000),
        innerId: Yup.string()
            .required(),
        servers: Yup.array()
    }), []);

    const initialValues: EditItemFormValues = {
        name: item.name,
        description: item.description,
        isActive: item.isActive,
        innerId: item.innerId,
        icon: null,
        servers: item.servers
    }

    const onSubmit = useCallback(async (values: EditItemFormValues) => {
        const api = new ConsumerItemsService();
        const result = await api.patchItem({
            id: item.id,
            name: values.name,
            description: values.description,
            innerId: values.innerId,
            isActive: values.isActive,
            uploadIcon: Boolean(values.icon),
            servers: values.servers
        });

        if (result.success) {
            const data = result.data;
            if (values.icon) {
                if (data.upload) {
                    const upload = data.upload;
                    const file = new FileUploader(upload);
                    file.onProgress = onProgress;
                    try {
                        setUploading(true);
                        await file.upload(values.icon);
                        toastr.success("Редактирование предмета", "Предмет успешно обновлен.");
                    }
                    catch (e) {
                        toastr.warning("Редактирование предмета", "Предмет обновлен, но не удалось загрузить изображение.");
                        console.log(e);
                    }
                    finally {
                        setUploading(false);
                        setUploadState(0);
                    }
                }
                else {
                    toastr.warning("Редактирование предмета", "Предмет обновлен, но не удалось загрузить изображение.");
                }
            }
            else {
                toastr.success("Редактирование предмета", "Предмет успешно обновлен.");
            }
        }
        else {
            if (result.errorCode === 'ItemNotFound') {
                toastr.error("Редактирование предмета", "Предмет не найден. Обновите страницу и попробуйте еще раз.");
            }
            else if (result.errorCode === 'BucketNotFound') {
                toastr.error("Редактирование предмета", "Отсутствует хранилище файлов. Создайте его из панели администратора и повторите попытку.");
            }
            else {
                toastr.error("Редактирование предмета", "Внутренняя ошибка");
            }
        }
    }, [item.id, onProgress]);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            <Form>
                <Row className="g-3">
                    <Col xs={12}>
                        <FormPageHeader title={`Редактирование предмета "${item.name}"`} />
                    </Col>
                    <Col lg={8}>
                        <BaseInfoBlock editForm/>
                    </Col>
                    <Col lg={4} className="sticky-sidebar">
                        <FormIconBlock
                            title="Иконка предмета"
                            iconId={item.iconId}
                            uploading={uploading}
                            uploadProgress={uploadState}
                        />
                        <ServersBlock />
                    </Col>
                    <Col>
                        <FormPageFooter cancelUrl={`/consumer/item/`} />
                    </Col>
                </Row>
            </Form>
        </Formik>
    )
}

export default EditItemForm;
import {FC, PropsWithChildren, useMemo} from "react";
import { injectContextArray } from "@components/constructor";
import { useChargingGameServers } from "@hooks/selectors/game.selectors";
import { GameServer } from "@interfaces/game";
import { GameServerProvider } from "@constructor/components/core/servers/game/context";
import {InjectContextType} from "@interfaces/constructor/constructor";

const DonateRateGames: FC<PropsWithChildren> = ({ children }) => {

    const games = useChargingGameServers();

    const data = useMemo<InjectContextType<GameServer>[]>(() => games.map(gs => {
        return {
            key: gs.id,
            value: gs
        }
    }), [games])

    return injectContextArray(data, GameServerProvider, children);
}

export default DonateRateGames;
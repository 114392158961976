import { KeyValueSet } from "./helpers";

export interface CustomQuery {
    id: string;
    name: string;
    displayName?: string;
    icon?: string;
    price: number;
    serverId: string;
    target: EQueryTarget;
    viewType: EQueryViewType;
    resultType: EQueryResultType;
    columns: IQueryTableColumn[];
    access: EQueryAccess;
}

export interface CustomQueryParameter {
    name: string;
    value: string;
}

export interface QueryResultTable {
    idColumn: string;
    columns: {
        name: string;
        display: string;
        hidden: boolean;
    }[];
    data: {
        [key: string]: object;
    }[];
}

export enum EQueryViewType {
    Modal = 'MODAL'
}

export enum EQueryTarget {
    Server = 'SERVER',
    Account = 'ACCOUNT',
    Character = 'CHARACTER'
}

export enum EQueryResultType {
    Boolean = 'BOOLEAN',
    Message = 'MESSAGE',
    Table = 'TABLE',
    Object = 'OBJECT'
}

export enum EQueryAccess {
    Public = 'PUBLIC',
    User = 'USER',
    Admin = 'ADMIN'
}

export interface IQueryResult {
    queryId: string;
    parameter?: string;
    message?: string;
    rows?: KeyValueSet<string>[];
    result?: KeyValueSet<string>;
    success?: boolean;
    cacheTime?: string;
}

export interface IQueryTableColumn {
    name: string;
    display: string;
    order: number;
    hidden: boolean;
    key: boolean;
}
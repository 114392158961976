import { useAuthLogContext } from "./context";
import { Text } from '@constructor/components';
import { fromUtcDate } from "@services/helpers";
import { FC } from "react";

interface AuthLogDateProps {
    format?: string;
}
const AuthLogDate: FC<AuthLogDateProps> = ({ format = 'lll', ...props }) => {

    const log = useAuthLogContext();
    if (!log) {
        return null;
    }

    return (
        <Text text={fromUtcDate(log.date).format(format)} {...props} />
    )
}

export default AuthLogDate;
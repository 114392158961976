import { Loadable } from '../../interfaces/helpers';
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { GameProject } from '../../interfaces/game';
import UserService from '../../services/api/userService';

const emptyProject = {
    id: '',
    name: '',
    gameServers: [],
    loginServers: []
};

const slice = createSlice({
    initialState: {
        loaded: false,
        loading: false,
        error: null,
        data: emptyProject
    } as Loadable<GameProject>,
    name: 'userProjectStore',
    reducers: {
        setLoading: (state) => {
            state.loading = true;
            state.error = null;
            state.loaded = false;
            state.data = emptyProject;
        },
        setError: (state, action) => {
            state.loading = false;
            state.loaded = false;
            state.error = action.payload;
        },
        setProject: (state, action: PayloadAction<GameProject>) => {
            state.data = action.payload;
            state.error = null;
            state.loaded = true;
            state.loading = false;
        }
    }
})

export const { reducer: userProjectReducer } = slice;

const { 
    setError,
    setLoading,
    setProject
} = slice.actions;

export const projectActions = {
    loadProject: () => async (dispatch: Dispatch) => {
        dispatch(setLoading());
        try {
            const api = new UserService();
            const project = await api.fetchUserProject();
            
            dispatch(setProject(project));
            return project;
        }
        catch(e) {
            dispatch(setError(e));
        }
    },
    setProject
}
import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";
import Dropdown from "./dropdown";
import DropdownOption from "./dropdownOption";
import DropdownValue from "./dropdownValue";

const DropdownInfo: ConstructionComponentInfo = {
    component: Dropdown,
    props: [
        'name'
    ],
    requiredProps: [
        'name'
    ],
    addedChildProps: [
        'dropdown_opened',
        'dropdown_value',
        'dropdown_open',
        'dropdown_close'
    ]
}

const DropdownOptionInfo: ConstructionComponentInfo = {
    component: DropdownOption,
    requiredParent: [
        'Dropdown'
    ],
    props: [
        'value'
    ],
    requiredProps: [
        'value'
    ],
    addedChildProps: [
        'dropdown_option_value',
        'dropdown_select_option'
    ]
}

const DropdownValueInfo: ConstructionComponentInfo = {
    component: DropdownValue,
    requiredParent: [
        'Dropdown'
    ],
    props: [
        'value'
    ],
    requiredProps: [
        'value'
    ]
}

export {
    DropdownInfo,
    DropdownOptionInfo,
    DropdownValueInfo
}
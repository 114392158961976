import {FC, useState} from "react";
import {Avatar, Flex} from "@components/falcon/common";
import {useSupportMessageContext} from "@components/consumer/support/context";
import classNames from "classnames";
import ChatMessageOptions from "./chatMessageOptions";
import {LightBox, LightBoxGallery} from "@components/falcon";
import {Col, Row} from "react-bootstrap";
import {fromUtcDate} from "@services/helpers";
import {EMessageAttachmentState} from "@interfaces/consumer/support";
import {LoadableImage} from "@components/panel";

const ChatMessage: FC = () => {

    const {
        incoming: isLeft,
        senderName,
        attachments,
        message: text,
        createTime
    } = useSupportMessageContext();

    const [imageIndex, setImageIndex] = useState<number | null>(null);
    const uploadedAttachmets = attachments.filter(x => x.state === EMessageAttachmentState.Uploaded);

    return (
        <Flex className={classNames('p-3', {'d-block': !isLeft})}>
            {isLeft && <Avatar
                size="l"
                className="me-2"
                name={senderName}
                rounded="circle"
            />}
            <div
                className={classNames('flex-1', {
                    'd-flex justify-content-end': !isLeft
                })}
            >
                <div
                    className={classNames('w-xxl-75', {
                        'w-100': !isLeft
                    })}
                >
                    <Flex
                        alignItems="center"
                        className={classNames('hover-actions-trigger', {
                            'flex-end-center': !isLeft,
                            'align-items-center': isLeft
                        })}
                    >
                        {!isLeft && <ChatMessageOptions/>}
                        {uploadedAttachmets.length > 1 ? (
                            <div className="chat-message chat-gallery">
                                {text && (
                                    <p className="mb-0">{text}</p>
                                )}
                                <LightBoxGallery
                                    index={imageIndex}
                                    setIndex={setImageIndex}
                                    imageIds={uploadedAttachmets.map(x => x.fileId)}
                                >
                                    <Row className="mx-n1">
                                        {uploadedAttachmets.map((img, index) => {
                                            return (
                                                <Col
                                                    xs={6}
                                                    md={4}
                                                    className="px-1"
                                                    style={{minWidth: 50}}
                                                    key={index}
                                                >
                                                    <LoadableImage
                                                        fluid
                                                        rounded
                                                        className="mb-2 cursor-pointer"
                                                        fileId={img.fileId}
                                                        alt=""
                                                        width={150}
                                                        onClick={() => setImageIndex(index)}
                                                    />
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </LightBoxGallery>
                            </div>
                        ) : (
                            <>
                                <div
                                    className={classNames('p-2 rounded-2 chat-message', {
                                        'bg-200': isLeft,
                                        'bg-primary text-white': !isLeft
                                    })}
                                >
                                    {text && (
                                        <p className="mb-0">{text}</p>
                                    )}
                                    {uploadedAttachmets.length === 1 && (
                                        <LightBox fileId={uploadedAttachmets[0].fileId}>
                                            <LoadableImage
                                                fluid
                                                rounded
                                                fileId={uploadedAttachmets[0].fileId}
                                                width={150}
                                                alt=""
                                            />
                                        </LightBox>
                                    )}
                                </div>
                            </>
                        )}
                        {isLeft && <ChatMessageOptions/>}
                    </Flex>
                    <div
                        className={classNames('text-400 fs-11', {
                            'text-end': !isLeft
                        })}
                    >
                        <span className="fw-semibold me-2">{senderName}</span>
                        {fromUtcDate(createTime).format('LT')}
                        {/*{!isLeft && !!message && !!status && (
                            <FontAwesomeIcon
                                icon={classNames({
                                    check: status === 'seen' || status === 'sent',
                                    'check-double': status === 'delivered'
                                })}
                                className={classNames('ms-2', {
                                    'text-success': status === 'seen'
                                })}
                            />
                        )}*/}
                    </div>
                </div>
            </div>
        </Flex>
    )
}

export default ChatMessage;
import {Dropdown} from "react-bootstrap";
import {FC, PropsWithChildren} from "react";
import {DropDirection} from "react-bootstrap/DropdownContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

interface CardDropdownProps {
    btnRevealClass?: string;
    drop?: DropDirection;
    icon?: IconProp;
}

const CardDropdown: FC<PropsWithChildren<CardDropdownProps>> = ({
    btnRevealClass = '',
    drop,
    children,
    icon = 'ellipsis-h'
                                                                }) => {

    //TODO add rtl support
    const isRTL = false;

    return (
        <Dropdown
            className="font-sans-serif btn-reveal-trigger"
            align={isRTL ? 'start' : 'end'}
            drop={drop}
        >
            <Dropdown.Toggle
                variant="reveal"
                size="sm"
                data-boundary="viewport"
                className={classNames('text-600', {
                    [btnRevealClass]: btnRevealClass,
                    'btn-reveal': !btnRevealClass
                })}
            >
                <FontAwesomeIcon icon={icon} className="fs-11" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="border py-0">
                {children}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default CardDropdown;
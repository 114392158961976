import ConsumerUsersService from "@api/consumer/consumerUsersService";
import {FC, useCallback, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {ColumnDef} from "@tanstack/react-table";
import {IInventoryItem} from "@interfaces/consumer/users";
import {Avatar, Flex} from "@components/falcon/common";
import {dateFormatter} from "@services/helpers";
import {useRemoteTable} from "@hooks/tableHooks";
import {PanelTableDataModel} from "@models/panel";
import {Card} from "react-bootstrap";
import {AdvanceTable, AdvanceTableFooter, AdvanceTableSearchBox} from "@components/falcon/common/tables";
import AdvanceTableProvider from "@components/falcon/common/tables/context";

const columns: ColumnDef<IInventoryItem>[] = [
    {
        accessorKey: 'itemName',
        header: 'Название',
        meta: {
            headerProps: { className: 'pe-1 text-900' },
            cellProps: {
                className: 'py-2'
            }
        },
        cell: ({row: {original: {id, iconId, itemName}}}) => (
            <Link to={`/consumer/item/${id}/`}>
                <Flex alignItems="center">
                    <Avatar fileId={iconId} name={itemName} size="xl" className="me-2"/>
                    <div className="flex-1">
                        <h5 className="mb-0 fs-10">{itemName}</h5>
                    </div>
                </Flex>
            </Link>
        )
    },
    {
        accessorKey: 'count',
        header: 'Количество',
        enableSorting: true,
        meta: {
            headerProps: { className: 'text-900' }
        }
    },
    {
        accessorKey: 'updateTime',
        header: 'Дата изменения',
        enableSorting: true,
        cell: ({row: {original: {updateTime}}}) => dateFormatter(updateTime),
        meta: {
            headerProps: { className: 'text-900 text-end' },
            cellProps: {
                className: 'text-end'
            }
        }
    }
]

const InventoryList: FC = () => {

    const { id } = useParams<{ id: string }>();
    const [api] = useState(() => new ConsumerUsersService());
    const fetchApi = useCallback((model: PanelTableDataModel) => api.fetchInventoryList(id, model), [api, id]);

    const table = useRemoteTable({
        name: 'consumer.users.items.list',
        columns,
        defaultState: {
            sorting: [
                {
                    id: 'itemName',
                    desc: true
                }
            ]
        }
    }, fetchApi);

    return (
        <AdvanceTableProvider table={table}>
            <Card className="mb-3">
                <Card.Header>
                    <Flex className='align-items-center justify-content-between'>
                        <Flex alignItems="center" className="fs-10">
                            <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">Инвентарь</h5>
                        </Flex>
                        <Flex>
                            <AdvanceTableSearchBox/>
                        </Flex>
                    </Flex>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowInfo
                        rowsPerPageSelection
                        paginationButtons
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableProvider>
    )
}

export default InventoryList;
import {CSSProperties, forwardRef} from "react";
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    LegendComponent,
    TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import {CanvasRenderer} from 'echarts/renderers';
import {getColor, rgbaColor} from "../../../../helpers/utils";
import EChartsReactCore from "echarts-for-react/lib/core";
import {usePaymentCurrency} from "@hooks/selectors/panel.selectors";
import {EChartsOption} from "echarts-for-react/src/types";

echarts.use([LineChart, TooltipComponent, GridComponent, LegendComponent, CanvasRenderer]);

interface LineChartProps<T extends Record<string, number[]>> {
    style?: CSSProperties;
    data: T;
    status: keyof T;
}

function useOption<T extends Record<string, number[]>> (data: T, paymentStatus: keyof T, isDark: boolean): EChartsOption {

    const currency = usePaymentCurrency();
    let hour = (new Date()).getHours();
    const labels: string[] = [];

    for (let i = 0; i < 24; i++) {
        labels.push(`${hour}:00`);
        hour++;
        if (hour > 23) {
            hour -= 24;
        }
    }

    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'none'
            },
            padding: [7, 10],
            backgroundColor: getColor('gray-100'),
            borderColor: getColor('gray-300'),
            borderWidth: 1,
            transitionDuration: 0,
            textStyle: {
                fontWeight: 500,
                fontSize: 12,
                color: getColor('gray-1100')
            },
            formatter: (params: any) => `${params[0].axisValue} - ${params[0].value} ${currency}`
        },
        xAxis: {
            type: 'category',
            data: labels,
            boundaryGap: false,
            splitLine: {
                show: true,
                lineStyle: {
                    color: rgbaColor('#fff', 0.1)
                },
                interval: 0
            },
            axisLine: {
                lineStyle: {
                    color: rgbaColor('#fff', 0.1)
                }
            },
            axisTick: {
                show: true,
                length: 10,
                lineStyle: {
                    color: rgbaColor('#fff', 0.1)
                }
            },
            axisLabel: {
                color: getColor('gray-400'),
                fontWeight: 600,
                fontSize: 12,
                margin: 15,
                interval: window.innerWidth < 768 ? 'auto' : 0,
                formatter: (value: string) => value.substring(0, value.length - 3)
            }
        },
        yAxis: {
            type: 'value',
            axisPointer: {
                show: false
            },
            splitLine: {
                show: false
            },
            axisLabel: {
                show: false
            },
            axisTick: { show: false },
            axisLine: { show: false }
        },
        series: [
            {
                type: 'line',
                smooth: true,
                data: data[paymentStatus].map(item => item.toFixed(2)),
                symbol: 'emptyCircle',
                itemStyle: {
                    color: isDark ? getColor('primary') : getColor('white')
                },
                lineStyle: {
                    color: isDark
                        ? rgbaColor(getColor('primary'), 0.8)
                        : rgbaColor(getColor('white'), 0.8)
                },
                areaStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0,
                                color: isDark
                                    ? rgbaColor(getColor('primary'), 0.5)
                                    : rgbaColor('#fff', 0.5)
                            },
                            {
                                offset: 1,
                                color: isDark
                                    ? rgbaColor(getColor('primary'), 0)
                                    : rgbaColor('#fff', 0)
                            }
                        ]
                    }
                },
                emphasis: {
                    lineStyle: {
                        width: 2
                    }
                }
            }
        ],
        grid: { right: 15, left: 15, bottom: '15%', top: 0 }
    };
}

const LineCharts = forwardRef<EChartsReactCore, LineChartProps<any>>(({ data, status, style }, ref) => {

    const option = useOption(data, status, false);

    return (
        <ReactEChartsCore
            echarts={echarts}
            ref={ref}
            option={option}
            style={style}
        />
    );
});

export default LineCharts;
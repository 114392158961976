import { usePublicName } from "@hooks/selectors/user.selectors";
import { FC } from "react";
import { Text } from '@components/constructor/components'

const UserPublicName: FC = (props) => {
    const name = usePublicName();
    return (
        <Text text={name} {...props} />
    )
}

export default UserPublicName;
import { apiUrl } from '../..';
import FetchResult from '../../models/fetchResult';
import { auth } from '../auth';

export default class IService {

    private async fetchApi<T>(url: string, request: RequestInit) {
        try {
            const response = await fetch(apiUrl + url, {
                ...request,
                credentials: 'include'
            });
            const result = await FetchResult.create<T>(response);

            if (!result.ok && result.status === 401) {
                await auth().signOut();
            }

            return result;
        }
        catch (e: any) {
            const em = new FetchResult<T>();
            em.ok = false;
            em.errorCode = "FetchError";
            em.status = 504;
            em.message = e.message;
            return em;
        }
    }

    protected async getApi<T = any>(url: string) {
        const request: RequestInit = {
            headers: await this.getAuthHeader(),
            credentials: 'include'
        };
        return await this.fetchApi<T>(url, request);
    }

    protected async getAndThrowApi<T = any>(url: string) {
        const result = await this.getApi<T>(url);
        if (result.ok && result.result) {
            return result.result;
        }
        throw result;
    }

    protected async putApi<T = any>(url: string, data: any) {
        const headers = await this.getAuthHeader();
        headers.set('Content-Type', 'application/json');

        const request: RequestInit = {
            method: 'PUT',
            credentials: 'include',
            headers: headers,
            body: JSON.stringify(data)
        };
        return await this.fetchApi<T>(url, request);
    }

    protected async patchApi<T = any>(url: string, data?: any) {
        const headers = await this.getAuthHeader();
        headers.set('Content-Type', 'application/json');

        const request: RequestInit = {
            method: 'PATCH',
            credentials: 'include',
            headers: headers,
            body: JSON.stringify(data)
        };
        return await this.fetchApi<T>(url, request);
    }

    protected async postApi<T = any>(url: string, data?: any) {
        const headers = await this.getAuthHeader();
        headers.set('Content-Type', 'application/json');

        const request: RequestInit = {
            method: 'POST',
            credentials: 'include',
            headers: headers,
            body: JSON.stringify(data)
        };
        return await this.fetchApi<T>(url, request);
    }

    protected async postAndThrowApi<T = any>(url: string, data: any) {
        const result = await this.postApi<T>(url, data);
        if (result.ok && result.result) {
            return result.result;
        }
        throw result;
    }

    protected async deleteApi<T = any>(url: string, data?: any) {
        const headers = await this.getAuthHeader();
        headers.set('Content-Type', 'application/json');

        const request: RequestInit = {
            method: 'DELETE',
            credentials: 'include',
            headers: headers
        };
        if (data) {
            request.body = JSON.stringify(data);
        }
        return await this.fetchApi<T>(url, request);
    }

    private async getAuthHeader() : Promise<Headers> {

        const token = await auth().getToken();

        const headers = new Headers();
        if(token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    }
}
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { ReduxStore } from "@interfaces/store";
import { useEffect } from "react";
import { UserAuthState } from "@interfaces/user/users";
import { Dispatch } from "redux";
import { Loadable } from "@interfaces/helpers";
import { panelActions } from '@store/panelStore';
import { userActions } from '@store/user';

function useUserError(): any {
    return useSelector(({ user, sidebar, queries }: ReduxStore) => {
        return user.project.error
            || user.accounts.error
            || user.characters.error
            || user.user.error
            || sidebar.error
            || user.inventory.error
            || queries.queries.error;
    });
}

function useUserLoading(): boolean {
    return useSelector(({ user, sidebar, queries }: ReduxStore) => {
        return user.project.loading
            || user.accounts.loading
            || user.characters.loading
            || user.user.loading
            || user.inventory.loading
            || sidebar.loading
            || queries.queries.loading;
    });
}

function useUserLoaded(): boolean {
    return useSelector(({ user, sidebar, queries }: ReduxStore) => {
        return user.project.loaded
            && user.accounts.loaded
            && user.characters.loaded
            && user.inventory.loaded
            && user.user.loaded
            && sidebar.loaded
            && queries.queries.loaded;
    })
}

export function usePanel() {
    return useSelector(({ panel: { settings, theme } }: ReduxStore) => {
        return {
            panelLoaded: settings.loaded && theme.loaded,
            panelLoading: settings.loading || theme.loading,
            panelError: settings.error || theme.error,
            panelActive: settings.data.isActive,
            panelTitle: settings.data.title,
            panelTheme: theme.data,
            gtmId: settings.data.googleTagId,
            ymId: settings.data.ymId
        };
    }, shallowEqual);
}

export function useUser() {
    return {
        userDataLoading: useUserLoading(),
        userDataLoaded: useUserLoaded(),
        userDataError: useUserError()
    }
}

export function useAuth(): UserAuthState {
    return useSelector(({ user: { authState } }: ReduxStore) => authState, shallowEqual);
}

export function useLanguage(name: string) {
    const lang = useSelector((state: ReduxStore) => state.panel.languages[name], shallowEqual);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!lang) {
            dispatch(panelActions.loadLanguage(name));
        }
    })
}

export function useIsAuthed() {
    return useSelector(({ user: { authState } }: ReduxStore) => authState.isAuthed && authState.tokenLoaded);
}

export function useDataLoading<T>(loadData: (param?: any) => (dispatch: Dispatch) => Promise<any>, data: Loadable<T>, ...params: any[]) {

    const dispatch = useDispatch();
    const { loading, loaded, error } = data;

    useEffect(() => {
        if (!loading && !loaded && !error) {
            dispatch(loadData(...params));
        }
    }, [dispatch, error, loadData, loaded, loading, params]);

    return data;
}

export function useMarketLoading() {
    const market = useSelector(({ user }: ReduxStore) => user.market);
    return useDataLoading(userActions.loadMarket, market);
}
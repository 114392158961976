import UserService from "@api/userService";
import { Form, Formik, FormikHelpers } from "formik";
import {FC, PropsWithChildren, useCallback, useMemo} from "react";
import { toastr } from "react-redux-toastr";
import * as Yup from 'yup';

interface ChangePasswordFormValues {
    oldPassword: string;
    newPassword: string;
    repeatPassword: string;
}

const ChangePasswordForm: FC<PropsWithChildren> = ({ children, ...props }) => {

    const validation = useMemo(() => Yup.object().shape({
        oldPassword: Yup.string()
            .min(6, "Не менее 6 символов")
            .max(64, "Не более 64 символов")
            .required("Обязательно"),
        newPassword: Yup.string()
            .min(6, "Не менее 6 символов")
            .max(64, "Не более 64 символов")
            .required("Обязательно")
            .matches(/[A-Z]/, "Должен содержать заглавные буквы")
            .matches(/[a-z]/, "Должен содержать прописные буквы")
            .matches(/[0-9]/, "Должен содержать цифры"),
        repeatPassword: Yup.string()
            .min(6, "Не менее 6 символов")
            .max(64, "Не более 64 символов")
            .oneOf([Yup.ref("newPassword")], "Пароли не совпадают")
            .required("Обязательно"),
    }), []);

    const onSubmit = useCallback(async ({ oldPassword, newPassword }: ChangePasswordFormValues, actions: FormikHelpers<ChangePasswordFormValues>) => {

        const api = new UserService();
        const result = await api.changeUserPassword({
            oldPassword,
            newPassword
        });

        if (result.ok) {
            toastr.success("Смена пароля", "Пароль успешно изменен");
            actions.resetForm();
        }
        else {
            if(result.errorCode === 'InvalidPassword') {
                actions.setFieldError('oldPassword', 'Неверный пароль');
            }
            else {
                toastr.error("Смена пароля", "Что-то пошло не так");
            }
        }
    }, []);

    const initial: ChangePasswordFormValues = {
        oldPassword: "",
        newPassword: "",
        repeatPassword: ""
    }

    return (
        <Formik
            initialValues={initial}
            onSubmit={onSubmit}
            validationSchema={validation}>
            <Form {...props}>
                {children}
            </Form>
        </Formik>
    );
}

export default ChangePasswordForm;
import {FC} from "react";
import {Card, Col, Form, Row} from "react-bootstrap";
import {Field, useFormikContext} from "formik";
import {FormikRowInput} from "@components/panel/formik";
import {EPaymentBonusType} from "@interfaces/user/payments";
import {usePaymentCurrency} from "@hooks/selectors/panel.selectors";

const SumBlock: FC = () => {

    const {
        values,
        setFieldValue
    } = useFormikContext<{type: EPaymentBonusType}>();

    const currency = usePaymentCurrency();

    return (
        <Card className="mb-3">
            <Card.Header as="h6" className="bg-body-tertiary">
                Сумма
            </Card.Header>
            <Card.Body>
                <Field
                    label='Сумма'
                    type='number'
                    name='value'
                    placeholder='1+'
                    description='Сумма или % бонуса за пополнение'
                    component={FormikRowInput}
                    suffix={values.type === EPaymentBonusType.Percent ? '%' : currency}
                />
                <Form.Group as={Row}>
                    <Form.Label column sm={3} for='new-bonus-threshold'>
                        Тип порога
                    </Form.Label>
                    <Col sm={9}>
                        <Field
                            id='new-bonus-type-fixed'
                            type='radio'
                            name='type'
                            label='Фиксированный'
                            inline
                            component={Form.Check}
                            value={EPaymentBonusType.Fixed}
                            checked={values.type === EPaymentBonusType.Fixed}
                            onChange={() => setFieldValue('type', EPaymentBonusType.Fixed)} />
                        <Field
                            id='new-bonus-type-percent'
                            type='radio'
                            name='type'
                            label='Процент'
                            inline
                            component={Form.Check}
                            value={EPaymentBonusType.Percent}
                            checked={values.type === EPaymentBonusType.Percent}
                            onChange={() => setFieldValue('type', EPaymentBonusType.Percent)} />
                    </Col>
                </Form.Group>
            </Card.Body>
        </Card>
    )
}

export default SumBlock;
import ConsumerRefService from "@api/consumer/consumerRefService";
import { GoalRewardType } from "@interfaces/consumer/refs";
import { FormikHelpers } from "formik";
import { FC, useCallback } from "react";
import { toastr } from "react-redux-toastr";
import { useHistory, useParams } from "react-router-dom";
import { CardBody } from "reactstrap";
import GoalRewardForm, { GoalRewardFormValues } from "./goalRewardForm";

const AddGoalRewardForm: FC = () => {

    const { goal_id } = useParams<{ goal_id: string }>();
    const history = useHistory();

    const initialValues: GoalRewardFormValues = {
        name: '',
        isActive: false,
        order: 0,
        type: GoalRewardType.Balance,
        balance: 100,
        itemId: '',
        count: 1
    };

    const onSubmit = useCallback(async (values: GoalRewardFormValues, actions: FormikHelpers<GoalRewardFormValues>) => {
        const api = new ConsumerRefService();
        const result = await api.putGoalReward(goal_id, values);

        if (result.success) {
            toastr.success("Изменение достижения", "Награда достижения успешно добавлена");
            history.replace(`/consumer/goals/${goal_id}/rewards/${result.data.id}/`);
        }
        else {
            if (result.errorCode === 'GoalNotFound') {
                toastr.error('Изменение достижения', 'Достижение не найдено');
            }
            else if (result.errorCode === 'RewardNotFound') {
                toastr.error('Изменение достижения', 'Награда не найдена');
            }
            else if (result.errorCode === 'InvalidRequest') {
                toastr.error('Изменение достижения', 'Некорректные параметры награды');
            }
            else if (result.errorCode === 'DisabledItem') {
                actions.setFieldError('itemId', 'Предмет отключен');
            }
            else if (result.errorCode === 'InvalidType') {
                toastr.error('Изменение достижения', 'Некорректный тип награды');
            }
            else {
                toastr.error('Изменение достижения', 'Неизвестная ошибка');
            }
        }
    }, [goal_id, history]);

    return (
        <CardBody>
            <GoalRewardForm
                initialValues={initialValues}
                onSubmit={onSubmit} />
        </CardBody>
    )
}

export default AddGoalRewardForm;
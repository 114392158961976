import {FC} from "react";
import {Card} from "react-bootstrap";
import {FormikRowCheckbox, FormikRowInput} from "@components/panel/formik";
import {Field} from "formik";

interface RefMainBlockProps {
    editForm?: boolean;
}

const RefMainBlock: FC<RefMainBlockProps> = ({editForm}) => (
    <Card className="mb-3">
        <Card.Header as="h6" className="bg-body-tertiary">
            Основное
        </Card.Header>
        <Card.Body>
            <Field
                label='Название'
                type='text'
                name='name'
                placeholder='Название бонуса'
                component={FormikRowInput}
            />
            <Field
                label='Описание'
                type='text'
                name='description'
                placeholder='Описание бонуса'
                component={FormikRowInput}
            />
            {editForm && (
                <>
                    <Field
                        label='Активно'
                        id='new-bonus-active'
                        name='isActive'
                        type='switch'
                        component={FormikRowCheckbox}
                    />
                    <Field
                        label='Скрытый'
                        id='new-bonus-hidden'
                        name='hidden'
                        type='switch'
                        component={FormikRowCheckbox}
                        description="Скрытый бонус не отображается в личном кабинете"
                    />
                    <Field
                        label='Порядковый номер'
                        type='number'
                        name='order'
                        placeholder='Порядковый номер'
                        component={FormikRowInput}
                    />
                </>
            )}
        </Card.Body>
    </Card>
)

export default RefMainBlock;
import { loadBonusCodes, consumerBonusCodesReducer } from './consumerBonusesStore';
import { loadDashboard, consumerDashboardReducer } from './consumerDashboardStore';
import { loadProjectInfo, consumerProjectInfoReducer } from './consumerInfoStore';
import {
    consumerSupportReducer,
    cSupportActions
} from './consumerSupportStore';
import { loadUser, loadRoles, consumerUsersReducer } from './consumerUserStore';
import { ConsumerStore } from '@interfaces/consumer/store';

import { combineReducers } from '@reduxjs/toolkit';
import { cPaymentsReducer } from './consumerPaymentsStore';
export const consumerReducer = combineReducers<ConsumerStore>({
    support: consumerSupportReducer,
    users: consumerUsersReducer,
    dashboard: consumerDashboardReducer,
    projectInfo: consumerProjectInfoReducer,
    bonusCodes: consumerBonusCodesReducer,
    payments: cPaymentsReducer
});

export const consumerActions = {
    loadRoles,
    loadUser,
    loadBonusCodes,
    loadDashboard,
    loadProjectInfo,

    support: cSupportActions
}
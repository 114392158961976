import {FC} from "react";
import {useMarketCategoryContext} from "./context";

const MarketCategoryDescription: FC = (props) => {
    const category = useMarketCategoryContext();
    if(!category?.description) {
        return null;
    }

    return (
        <div className='ql-container ql-editor'>
            <div dangerouslySetInnerHTML={{ __html: category.description }} />
        </div>
    )
}

export default MarketCategoryDescription;
import { GoalRewardType, IItemGoalReward } from "@interfaces/user/refs";
import {FC, PropsWithChildren} from "react";
import { Item } from "../../items";
import { useGoalRewardContext } from "./context";

const GoalReward: FC<PropsWithChildren> = ({ children }) => {
    const reward = useGoalRewardContext();
    if (!reward) {
        return null;
    }

    if (reward.type === GoalRewardType.Item) {
        return (
            <Item item_id={(reward as IItemGoalReward).itemId}>
                {children}
            </Item>
        )
    }

    return (
        <>{children}</>
    );
}

export default GoalReward;
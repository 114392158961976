import {FC} from "react";
import {useAdvanceTableContext} from "./context";
import {Placeholder, Table} from 'react-bootstrap';
import {Row, flexRender, Header} from '@tanstack/react-table';
import classNames from 'classnames';
import {randomInteger} from "@services/helpers";

interface AdvanceTableProps {
    headerClassName?: string;
    bodyClassName?: string;
    rowClassName?: string;
    tableProps?: object;
    emptyText?: string;
}

const AdvanceTable: FC<AdvanceTableProps> = <TData,>({
    headerClassName,
    bodyClassName,
    rowClassName,
    emptyText,
    tableProps}: AdvanceTableProps) => {

    const {
        getRowModel,
        getFlatHeaders,
        getState,
        options: {meta}
    } = useAdvanceTableContext<TData>();

    const {
        pagination: {pageSize}
    } = getState();

    const loading = (meta as any).loading;

    return (
        <div className="table-responsive scrollbar">
            <Table {...tableProps}>
                <thead className={headerClassName}>
                <tr>
                    {getFlatHeaders().map(header => {
                        const meta = header.column.columnDef.meta as any;
                        return (
                            <th
                                key={header.id}
                                {...meta?.headerProps}
                                className={classNames(
                                    meta?.headerProps?.className,
                                    {
                                        sort: header.column.getCanSort(),
                                        desc: header.column.getIsSorted() === 'desc',
                                        asc: header.column.getIsSorted() === 'asc'
                                    }
                                )}
                                onClick={header.column.getToggleSortingHandler()}
                            >
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                            </th>
                        );
                    })}
                </tr>
                </thead>
                <tbody className={bodyClassName}>
                {
                    loading ? (
                        <LoadingRows
                            headers={getFlatHeaders()}
                            rowsCount={getRowModel().rows.length || pageSize}
                            rowClassName={rowClassName}
                        />
                    ) : getRowModel().rows.length > 0 ? (
                        <TableData
                            rows={getRowModel().rows}
                            rowClassName={rowClassName}
                        />
                    ) : (
                        <tr>
                            <td
                                colSpan={getFlatHeaders().length}
                                align="center">
                                {emptyText || 'Нет данных'}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    )
}

interface LoadingRowsProps {
    headers: Header<any, any>[];
    rowsCount: number;
    rowClassName?: string;
}

const LoadingRows: FC<LoadingRowsProps> = ({headers, rowsCount, rowClassName}) => Array.from({length: rowsCount}).map((_, rowIdx) => (
    <tr key={`phr-${rowIdx}`} className={rowClassName}>
        {headers.map((header) => {
            const meta = header.column.columnDef.meta as any;
            const placeholderSize = Array.isArray(meta?.placeholderSize) ? randomInteger(meta.placeholderSize[0], meta.placeholderSize[1])
                : (meta?.placeholderSize ?? randomInteger(2,8));
            return (
                <Placeholder as="td" key={`phc-${header.id}`} animation="glow" {...meta?.cellProps}>
                    <Placeholder xs={placeholderSize}/>
                </Placeholder>
            )}
        )}
    </tr>
));

interface TableDataProps<TData> {
    rows: Row<TData>[];
    rowClassName?: string;
}

const TableData = <TData,>({rows, rowClassName}: TableDataProps<TData>) => rows.map(row => (
    <tr key={row.id} className={rowClassName}>
        {row.getVisibleCells().map(cell => {
            const meta = cell.column.columnDef.meta as any;
            return (
                <td key={cell.id} {...meta?.cellProps}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
            )
        })}
    </tr>
));

export default AdvanceTable;
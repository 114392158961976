import {FC} from "react";
import { Tab } from "react-bootstrap";
import {useSortedTopics} from "@hooks/selectors/consumer/support.selectors";
import ChatHeader from "./chatHeader";
import ChatBody from "./chatBody";
import MessageTextArea from "./messageTextArea";

const ChatContent: FC = () => {

    const topics = useSortedTopics();

    return (
        <Tab.Content className="card-chat-content">
            {topics.map(topic => (
                <Tab.Pane key={topic.id} eventKey={topic.id} className="card-chat-pane">
                    <ChatHeader />
                    <ChatBody topic={topic} />
                </Tab.Pane>
            ))}
            <MessageTextArea />
        </Tab.Content>
    )
}

export default ChatContent;
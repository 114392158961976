import {ConsumerPayment, ConsumerPaymentFull, ICPaymentBonus} from "@interfaces/consumer/payments";
import { PatchPaymentBonusModel, PutPaymentBonusModel } from "@models/consumer/payments";
import IService from "../iService";
import {PanelTableDataModel} from "@models/panel";
import {PanelTableData} from "@interfaces/panel";

export default class CPaymentsService extends IService {

    async loadBonuses() {
        return await this.getApi<ICPaymentBonus[]>('/consumer/payments/bonus/');
    }

    async getBonus(id: string) {
        return await this.getApi<ICPaymentBonus>(`/consumer/payments/bonus/${id}/`);
    }

    async deleteBonus(id: string) {
        return await this.deleteApi(`/consumer/payments/bonus/${id}/`);
    }

    async putBonus(model: PutPaymentBonusModel) {
        return await this.putApi<ICPaymentBonus>('/consumer/payments/bonus/', model);
    }

    async patchBonus(model: PatchPaymentBonusModel) {
        return await this.patchApi<ICPaymentBonus>('/consumer/payments/bonus/', model);
    }


    fetchConsumerPaymentsList = async (model: PanelTableDataModel) => {
        return await this.postApi<PanelTableData<ConsumerPayment[]>>('/consumer/payments/', model);
    }

    fetchConsumerPayment = async (id: string) => {
        return await this.getApi<ConsumerPaymentFull>(`/consumer/payments/${id}/`);
    }

    fetchBonuses = async (model: PanelTableDataModel) => {
        return await this.postApi<PanelTableData<ICPaymentBonus[]>>(`/consumer/payments/bonus/`, model);
    }
}
import {FC} from "react";
import {useMarketCategoryContext} from "./context";
import {Text} from "@constructor/components";

const MarketCategoryName: FC = (props) => {
    const category = useMarketCategoryContext();
    if(!category?.name) {
        return null;
    }

    return (
        <Text text={category.name} {...props}/>
    )
}

export default MarketCategoryName;
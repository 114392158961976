import { useQueryByName, useQueryResult } from "@hooks/selectors/user/query.selectors";
import {FC, PropsWithChildren, useEffect, useMemo, useState} from "react";
import { useAccountContext } from "@constructor/components/core/accounts/context";
import { EQueryTarget, IQueryResult } from "@interfaces/query";
import { QueryProvider, QueryResultProvider } from "./context";
import { injectChild } from "@components/constructor";
import { useGameServerAuto, useLoginServerAuto } from "@hooks/selectors/game.selectors";
import { usePromisedDispatch } from "@hooks/helperHooks";
import { queryActions } from "@store/queryStore";
import FetchResult from "@models/fetchResult";

interface QueryProps {
    query_id: string;
    server_id?: string;
}

interface QueryChildProps {
    query_executing: boolean;
    query_result_rows: number;
    query_result_exists: boolean;
}

const Query: FC<PropsWithChildren<QueryProps>> = ({ query_id, server_id, children }) => {

    const dispatch = usePromisedDispatch();

    const account = useAccountContext();
    const game = useGameServerAuto();
    const login = useLoginServerAuto();

    // '' вместо идишника чтобы ничего не вернуло
    const serverQuery = useQueryByName(query_id, server_id || 'none');
    const accountQuery = useQueryByName(query_id, account?.loginServer || 'none');
    const gameQuery = useQueryByName(query_id, game?.id || 'none')
    const loginQuery = useQueryByName(query_id, login?.id || 'none');

    const query = serverQuery || accountQuery || gameQuery || loginQuery;

    let parameter: string | undefined;

    if (query?.target === EQueryTarget.Account) {
        parameter = account?.id;
    }

    const cachedResult = useQueryResult(query?.id || '', parameter);
    const [result, setResult] = useState<IQueryResult | null>(cachedResult);
    const [executing, setExecuting] = useState(!Boolean(cachedResult));

    useEffect(() => {

        const execute = async () => {
            if (!query) {
                return;
            }

            let result: FetchResult<IQueryResult> | null = null;
            if (query.target === EQueryTarget.Account && account) {
                if (account.loginServer === query.serverId
                    || (query.serverId === game?.id && game.loginId === account.loginServer)) {
                    result = await dispatch(queryActions.executeQuery(query.id, account.id));
                }
            }
            else if (query.target === EQueryTarget.Server) {
                result = await dispatch(queryActions.executeQuery(query.id));
            }

            if (result && result?.success) {
                setResult(result.data);
                setExecuting(false);
            }
        }

        if (!cachedResult) {
            execute();
        }
    }, [account, cachedResult, dispatch, game?.id, game?.loginId, query]);

    const data = useMemo<QueryChildProps>(() => {
        return {
            query_executing: executing,
            query_result_rows: result?.rows?.length || 0,
            query_result_exists: Boolean(result)
        }
    }, [executing, result]);

    if (!query
        || (query.target === EQueryTarget.Account && !account)) {
        return null
    }

    if (result) {
        return (
            <QueryProvider value={query}>
                <QueryResultProvider value={result}>
                    {injectChild(data, children)}
                </QueryResultProvider>
            </QueryProvider>
        )
    }
    else {
        return (
            <QueryProvider value={query}>
                {injectChild(data, children)}
            </QueryProvider>
        )
    }
}

export default Query;
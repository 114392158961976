import { FC } from "react";
import { useSupportMessageContext } from "../context";
import { Text } from '@components/constructor/components';

const SupportMessageTitle: FC = (props) => {
    const message = useSupportMessageContext();

    if (!message) {
        return null;
    }

    return (
        <Text text={message.senderName} {...props} />
    )
}

export default SupportMessageTitle;
import { FC } from "react";
import { useAuthLogContext } from "./context";
import { Text } from '@constructor/components';

const AuthLogIP: FC = (props) => {
    const log = useAuthLogContext();
    if (!log) {
        return null;
    }

    return (
        <Text text={log.ip} {...props} />
    )
}

export default AuthLogIP;
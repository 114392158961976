import {FC} from "react";
import {EModifyType, EPaymentBonusType, EThresholdType} from "@interfaces/user/payments";
import {PaymentAgregator} from "@interfaces/consumer/payments";
import {Form, Formik} from "formik";
import {Col, Row} from "react-bootstrap";
import {
    ActionBlock,
    BaseInfoBlock,
    DatesFormBlock,
    FooterBlock,
    SumBlock,
    ThresholdBlock
} from "@components/consumer/payments/bonuses/forms";
import {FormPageHeader} from "@components/falcon/common/forms";

export interface EditBonusFormValues {
    name: string;
    isActive: boolean;
    value: number;
    type: EPaymentBonusType;
    threshold: number;
    thresholdType: EThresholdType;
    startDate?: string;
    startTime?: string;
    endDate?: string;
    endTime?: string;
    daysOfWeek?: number[];
    hidden: boolean;
    aggregators: PaymentAgregator[];
    modifyType: EModifyType;
}

interface EditBonusFormProps {
    title: string;
    bonus: EditBonusFormValues;
    onSubmit: (values: EditBonusFormValues) => Promise<any>;
}

const EditBonusForm: FC<EditBonusFormProps> = ({bonus, onSubmit, title}) => {

    const initial: EditBonusFormValues = {
        ...bonus
    }

    return (
        <Formik
            initialValues={initial}
            onSubmit={onSubmit}>
            <Form>
                <Row className="g-3">
                    <Col xs={12}>
                        <FormPageHeader title={title} />
                    </Col>
                    <Col lg={8}>
                        <BaseInfoBlock />
                        <SumBlock />
                        <ThresholdBlock />
                    </Col>
                    <Col lg={4} className="sticky-sidebar">
                        <DatesFormBlock />
                        <ActionBlock />
                    </Col>
                    <Col>
                        <FooterBlock />
                    </Col>
                </Row>
            </Form>
        </Formik>
    )
}

export default EditBonusForm;
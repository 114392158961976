import {FC} from "react";
import classNames from "classnames";

interface InfoItemProps {
    title: string;
    content: string;
    className?: string;
}

const InfoItem: FC<InfoItemProps> = ({title, content, className}) => (
    <div className={classNames('mb-4', className)}>
        <h6
            className={classNames({
                'mb-1': title === 'Email' || title === 'Phone Number'
            })}
        >
            {title}
        </h6>
        {title === 'Email' && (
            <a className="fs-10" href={`mailto: ${content}`}>
                {content}
            </a>
        )}
        {title === 'Phone Number' && (
            <a className="fs-10" href={`tel: ${content.replace(/\s+/g, '')}`}>
                {content}
            </a>
        )}
        {title !== 'Email' && title !== 'Phone Number' && (
            <p className="mb-0 text-700 fs-10">{content}</p>
        )}
    </div>
)

export default InfoItem;
import React, { useMemo } from 'react';
import { Switch } from 'react-router-dom';
import { BonusCodePage, ConsumerBonusCodesListPage } from "@components/pages/consumer/bonuses";
import { ConsumerShortNewsListPage, EditShortNewsPage, AddShortNewsPage } from "@components/pages/consumer/shortNews";
import { ConsumerMarketCategoryList, ConsumerMarketAddCategory, ConsumerMarketEditCategory, ConsumerMarketItemsList, ConsumerMarketAddItem, ConsumerMarketEditItem } from "@components/consumer/market";
import { ConsumerPaymentsList, ConsumerPayment } from "@components/consumer/payments";
import ConsumerDashboard from '@components/pages/consumer/dashboard';
import ConsumerUserListPage from '@components/pages/consumer/users/consumerUserListPage';
import ConsumerUserPage from '@components/pages/consumer/users/consumerUserPage';
import ConsumerTicketsPage from '@components/pages/consumer/support/consumerTicketsPage';
import ConsumerTicketPage from '@components/pages/consumer/support/consumerTicketPage';
import { RouterItem } from '@interfaces/control-panel';
import { useAuth, useAllRoles } from '@services/hooks';
import { childRoutes } from './routes';
import {BonusListPage, CreatePaymentBonusPage, EditPaymentBonusPage} from '@components/pages/consumer/payments/bonuses';
import { ItemsList } from '@components/consumer/items';
import { AddItemPage, EditItemPage } from '@components/pages/consumer/items';
import { ConsumerDailyGiftListPage } from '@components/pages/consumer/daily';
import EditDailyGiftPage from '@components/pages/consumer/daily/editDailyGiftPage';
import { AddGoalConditionPage, AddGoalPage, AddGoalRewardPage, EditGoalConditionPage, EditGoalPage, EditGoalRewardPage, GoalsListPage } from '@components/pages/consumer/goals';
import { AddRefPage, AddRefRewardPage, AddRefTargetPage, EditRefPage, EditRefRewardPage, EditRefTargetPage, RefsListPage } from '@components/pages/consumer/refs';
import {MainLayout} from "@components/falcon";

const consumerRoutes: RouterItem = {
    path: '/consumer/',
    name: 'Управление',
    needAuth: true,
    authRole: 'consumer',
    component: ConsumerDashboard,
    children: [
        {
            path: '/consumer/users/',
            name: 'Пользователи',
            authRole: 'consumer.users.view',
            component: ConsumerUserListPage,
            children: [
                {
                    path: '/consumer/users/:id',
                    name: 'Пользователь',
                    code: 'consumer.users.current.name',
                    component: ConsumerUserPage
                }
            ]
        },
        {
            path: '/consumer/payments/',
            name: 'Платежи',
            authRole: 'consumer.payments.view',
            component: ConsumerPaymentsList,
            children: [
                {
                    path: '/consumer/payments/:payment_id([0-9]+)/',
                    name: 'Платеж',
                    code: 'consumer.payments.current.name',
                    component: ConsumerPayment
                },
                {
                    path: '/consumer/payments/bonuses/',
                    name: 'Бонусы',
                    authRole: 'consumer.payments.bonuses.view',
                    component: BonusListPage,
                    children: [
                        {
                            path: '/consumer/payments/bonuses/new/',
                            name: 'Создание бонуса',
                            authRole: 'consumer.payments.bonuses.view',
                            component: CreatePaymentBonusPage
                        },
                        {
                            path: '/consumer/payments/bonuses/:bonus_id/',
                            name: 'Бонус',
                            authRole: 'consumer.payments.bonuses.view',
                            code: 'consumer.payments.bonuses.current.name',
                            component: EditPaymentBonusPage,
                        }
                    ]
                }
            ]
        },
        {
            path: '/consumer/support/',
            name: 'Поддержка',
            authRole: 'consumer.support',
            component: ConsumerTicketsPage,
            children: [
                {
                    path: '/consumer/support/:id',
                    name: 'Заявка',
                    exact: false,
                    component: ConsumerTicketPage
                }
            ]
        },
        {
            path: '/consumer/bonuses/',
            name: 'Бонусные коды',
            authRole: 'consumer.bonus.view',
            component: ConsumerBonusCodesListPage,
            children: [
                {
                    path: '/consumer/bonuses/:code_id/',
                    name: 'Бонусный код',
                    authRole: 'consumer.bonus.view',
                    code: 'consumer.bonus.current.name',
                    component: BonusCodePage,
                }
            ]
        },
        {
            path: '/consumer/shortnews/',
            name: 'Короткие новости',
            authRole: 'consumer.shortnews.view',
            component: ConsumerShortNewsListPage,
            children: [
                {
                    path: '/consumer/shortnews/add/',
                    name: 'Добавление новости',
                    authRole: 'consumer.shortnews.edit',
                    component: AddShortNewsPage
                },
                {
                    path: '/consumer/shortnews/:id/',
                    name: 'Редактирование новости',
                    code: 'consumer.shortnews.current.name',
                    component: EditShortNewsPage
                }
            ]
        },
        {
            path: '/consumer/item/',
            name: 'Предметы',
            authRole: 'consumer.items.view',
            component: ItemsList,
            children: [
                {
                    path: '/consumer/item/add/',
                    name: 'Добавить предмет',
                    authRole: 'consumer.items.edit',
                    component: AddItemPage
                },
                {
                    path: '/consumer/item/:item_id/',
                    name: 'Редактирование предмета',
                    code: 'consumer.item.current.name',
                    component: EditItemPage
                }
            ]
        },
        {
            path: '/consumer/market/',
            name: 'Магазин',
            authRole: 'consumer.items.shop.view',
            children: [
                {
                    path: '/consumer/market/category/',
                    name: 'Категории',
                    component: ConsumerMarketCategoryList,
                    children: [
                        {
                            path: '/consumer/market/category/add/',
                            name: 'Добавить категорию',
                            component: ConsumerMarketAddCategory,
                            authRole: 'consumer.items.shop.edit'
                        },
                        {
                            path: '/consumer/market/category/:category_id/',
                            name: 'Редактирование категории',
                            code: 'consumer.market.category.current.name',
                            component: ConsumerMarketEditCategory
                        }
                    ]
                },
                {
                    path: '/consumer/market/item/',
                    name: 'Предметы',
                    component: ConsumerMarketItemsList,
                    children: [
                        {
                            path: '/consumer/market/item/add/',
                            name: 'Добавление предмета',
                            component: ConsumerMarketAddItem,
                            authRole: 'consumer.items.shop.edit'
                        },
                        {
                            path: '/consumer/market/item/:item_id/',
                            name: 'Редактирование предмета',
                            code: 'consumer.market.item.current.name',
                            component: ConsumerMarketEditItem
                        }
                    ]
                }
            ]
        },
        {
            path: '/consumer/daily/',
            name: 'Бонусы за вход',
            authRole: 'consumer.daily.view',
            component: ConsumerDailyGiftListPage,
            children: [
                {
                    path: '/consumer/daily/:gift_id/',
                    name: 'Редактирование подарка',
                    code: 'consumer.daily.current.name',
                    component: EditDailyGiftPage
                }
            ]
        },
        {
            path: '/consumer/goals/',
            name: 'Достижения',
            authRole: 'consumer.goal.view',
            component: GoalsListPage,
            children: [
                {
                    path: '/consumer/goals/add/',
                    name: 'Создание достижения',
                    authRole: 'consumer.goal.edit',
                    component: AddGoalPage
                },
                {
                    path: [
                        '/consumer/goals/:goal_id/',
                        '/consumer/goals/:goal_id/conditions/',
                        '/consumer/goals/:goal_id/rewards/'
                    ],
                    name: 'Редактирование достижения',
                    code: 'consumer.goals.current.name',
                    component: EditGoalPage,
                    children: [
                        {
                            path: '/consumer/goals/:goal_id/conditions/',
                            name: 'Условия',
                            code: 'consumer.goals.conditions',
                            useLink: true,
                            children: [
                                {
                                    path: '/consumer/goals/:goal_id/conditions/add/',
                                    name: 'Создание условия',
                                    authRole: 'consumer.goal.edit',
                                    component: AddGoalConditionPage
                                },
                                {
                                    path: '/consumer/goals/:goal_id/conditions/:condition_id/',
                                    name: 'Редактирование условия',
                                    code: 'consumer.goals.condition.current.name',
                                    component: EditGoalConditionPage
                                }
                            ]
                        },
                        {
                            path: '/consumer/goals/:goal_id/rewards/',
                            name: 'Награды',
                            code: 'consumer.goals.rewards',
                            useLink: true,
                            children: [
                                {
                                    path: '/consumer/goals/:goal_id/rewards/add/',
                                    name: 'Создание награды',
                                    authRole: 'consumer.goal.edit',
                                    component: AddGoalRewardPage
                                },
                                {
                                    path: '/consumer/goals/:goal_id/rewards/:reward_id/',
                                    name: 'Редактирование награды',
                                    code: 'consumer.goals.reward.current.name',
                                    component: EditGoalRewardPage
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            name: 'Реферальная система',
            path: '/consumer/refs/',
            authRole: 'consumer.ref.view',
            component: RefsListPage,
            children: [
                {
                    path: '/consumer/refs/add/',
                    name: 'Создание реферальной награды',
                    authRole: 'consumer.goal.edit',
                    component: AddRefPage
                },
                {
                    path: [
                        '/consumer/refs/:ref_id/',
                        '/consumer/refs/:ref_id/targets/',
                        '/consumer/refs/:ref_id/rewards/'
                    ],
                    name: 'Редактирование реферальной награды',
                    code: 'consumer.refs.current.name',
                    component: EditRefPage,
                    children: [
                        {
                            path: '/consumer/refs/:ref_id/targets/',
                            name: 'Условия',
                            code: 'consumer.refs.targets',
                            useLink: true,
                            children: [
                                {
                                    path: '/consumer/refs/:ref_id/targets/add/',
                                    name: 'Создание условия',
                                    authRole: 'consumer.ref.edit',
                                    component: AddRefTargetPage
                                },
                                {
                                    path: '/consumer/refs/:ref_id/targets/:target_id/',
                                    name: 'Редактирование условия',
                                    code: 'consumer.refs.target.current.name',
                                    component: EditRefTargetPage
                                }
                            ]
                        },
                        {
                            path: '/consumer/refs/:ref_id/rewards/',
                            name: 'Награды',
                            code: 'consumer.refs.rewards',
                            useLink: true,
                            children: [
                                {
                                    path: '/consumer/refs/:ref_id/rewards/add/',
                                    name: 'Создание награды',
                                    authRole: 'consumer.ref.edit',
                                    component: AddRefRewardPage
                                },
                                {
                                    path: '/consumer/refs/:ref_id/rewards/:reward_id/',
                                    name: 'Редактирование награды',
                                    code: 'consumer.refs.reward.current.name',
                                    component: EditRefRewardPage
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
};

const ConsumerRoutes: React.FC = () => {

    const { isAuthed } = useAuth();
    const roles = useAllRoles();

    const childs = useMemo(() => childRoutes(consumerRoutes, roles, isAuthed), [isAuthed, roles]);

    if (childs.length === 0) {
        return null;
    }

    return (
        <MainLayout>
            <Switch>
                {childs}
            </Switch>
        </MainLayout>
    )
}

export default ConsumerRoutes;
import {FC, PropsWithChildren, useMemo} from "react";
import {useMarketItems} from "@hooks/selectors/services.selectors";
import {injectContextArray} from "@components/constructor";
import {MarketItem} from "@interfaces/user/market";
import {useMarketCategoryContext} from "../categories/context";
import {MarketItemProvider} from "./context";
import {PaginationProps} from "@constructor/components/core/pagination";
import {InjectContextType} from "@interfaces/constructor/constructor";

interface MarketItemsListChildProps {
    has_discount: boolean;
    has_limit: boolean;
    market_item_has_icon: boolean;
    item_id: string;
    market_item_id: string;
    market_item_index: number;
}

const MarketItemsList: FC<PropsWithChildren<PaginationProps>> = ({ pagination_current_page = 0, pagination_per_page = 10, children }) => {
    const category = useMarketCategoryContext();
    const { items } = useMarketItems(category?.id);
    const page = useMemo(() => items.slice(pagination_current_page * pagination_per_page, (pagination_current_page + 1) * pagination_per_page),
        [items, pagination_current_page, pagination_per_page]);
    const data = useMemo<InjectContextType<MarketItem, MarketItemsListChildProps>[]>(() => page.map((item, idx) => ({
        key: item.id,
        value: item,
        childData: {
            has_discount: item.price > item.discountPrice,
            has_limit: item.buyLimit > 0,
            market_item_has_icon: Boolean(item.iconId),
            item_id: item.itemId,
            market_item_id: item.id,
            market_item_index: pagination_current_page * pagination_per_page + 1 + idx,
        }
    })), [page, pagination_current_page, pagination_per_page]);

    return injectContextArray(data, MarketItemProvider, children);
}

export default MarketItemsList;
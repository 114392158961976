import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";

import Item from "./item";
import ItemDescription from "./itemDescription";
import ItemIcon from "./itemIcon";
import ItemName from "./itemName";

const ItemInfo: ConstructionComponentInfo = {
    component: Item,
    addedChildProps: [
        'item_loaded',
        'item_loading',
        'item_error',
        'item_has_icon'
    ],
    injectChild: [
        'item_id'
    ]
}

const ItemNameInfo: ConstructionComponentInfo = {
    component: ItemName,
    requiredParent: [
        'Item'
    ]
}

const ItemDescriptionInfo: ConstructionComponentInfo = {
    component: ItemDescription,
    requiredParent: [
        'Item'
    ]
}

const ItemIconInfo: ConstructionComponentInfo = {
    component: ItemIcon,
    requiredParent: [
        'Item'
    ]
}

export {
    Item,

    ItemInfo,
    ItemNameInfo,
    ItemDescriptionInfo,
    ItemIconInfo
}
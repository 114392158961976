import ConsumerRefService from "@api/consumer/consumerRefService";
import { GoalConditionType, IGoalCondition, IGoalDonateCondition } from "@interfaces/consumer/refs";
import { FC, useCallback } from "react";
import { toastr } from "react-redux-toastr";
import { CardBody } from "reactstrap";
import GoalConditionForm, { GoalConditionFormValues } from "./goalConditionForm";

interface EditConditionFormProps {
    cond: IGoalCondition;
}

const EditConditionForm: FC<EditConditionFormProps> = ({ cond }) => {

    const initialValues: GoalConditionFormValues = {
        name: cond.name,
        isActive: cond.isActive,
        type: cond.type,
        donateValue: cond.type === GoalConditionType.Donate ? (cond as IGoalDonateCondition).donateValue : 0
    }

    const onSubmit = useCallback(async (values: GoalConditionFormValues) => {
        const api = new ConsumerRefService();
        const result = await api.patchCondition(cond.goalId, {
            id: cond.id,
            name: values.name,
            isActive: values.isActive,
            donateValue: values.donateValue
        });

        if (result.success) {
            toastr.success("Изменение достижения", "Условие достижения успешно обновлено");
        }
        else {
            if (result.errorCode === 'GoalNotFound') {
                toastr.error('Изменение достижения', 'Достижение не найдено');
            }
            else if(result.errorCode === 'ConditionNotFound') {
                toastr.error('Изменение достижения', 'Условие не найдено');
            }
            else if(result.errorCode === 'InvalidRequest') {
                toastr.error('Изменение достижения', 'Некорректные параметры условия');
            }
            else {
                toastr.error('Изменение достижения', 'Неизвестная ошибка');
            }
        }
        
    }, [cond.goalId, cond.id]);

    return (
        <CardBody>
            <GoalConditionForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                editForm />
        </CardBody>
    )
}

export default EditConditionForm;
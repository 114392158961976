import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";
import GoalReward from "./goalReward";
import GoalRewardBalance from "./goalRewardBalance";
import GoalRewardDescription from "./goalRewardDescription";
import GoalRewardItemCount from "./goalRewardItemCount";
import GoalRewardName from "./goalRewardName";
import GoalRewards from "./goalRewards";
import GoalRewardsList from "./goalRewardsList";

const GoalRewardInfo: ConstructionComponentInfo = {
    component: GoalReward,
    requiredParent: [
        'GoalRewardsList'
    ]
}

const GoalRewardBalanceInfo: ConstructionComponentInfo = {
    component: GoalRewardBalance,
    requiredParent: [
        'GoalRewardsList'
    ]
}

const GoalRewardDescriptionInfo: ConstructionComponentInfo = {
    component: GoalRewardDescription,
    requiredParent: [
        'GoalRewardsList'
    ]
}

const GoalRewardItemCountInfo: ConstructionComponentInfo = {
    component: GoalRewardItemCount,
    requiredParent: [
        'GoalRewardsList'
    ]
}

const GoalRewardNameInfo: ConstructionComponentInfo = {
    component: GoalRewardName,
    requiredParent: [
        'GoalRewardsList'
    ]
}

const GoalRewardsInfo: ConstructionComponentInfo = {
    component: GoalRewards,
    props: [
        'goal_rewards_per_page'
    ],
    addedChildProps: [
        'goal_rewards_count',
        'pagination_current_page',
        'pagination_total_pages',
        'pagination_per_page',
        'pagination_set_page',
        'pagination_next_page',
        'pagination_prev_page'
    ]
}

const GoalRewardsListInfo: ConstructionComponentInfo = {
    component: GoalRewardsList,
    injectChild: [
        'pagination_current_page',
        'pagination_per_page'
    ],
    addedChildProps: [
        'goal_reward_index',
        'goal_reward_id',
        'goal_reward_type'
    ]
}

export {
    GoalRewardInfo,
    GoalRewardBalanceInfo,
    GoalRewardDescriptionInfo,
    GoalRewardItemCountInfo,
    GoalRewardNameInfo,
    GoalRewardsInfo,
    GoalRewardsListInfo
}
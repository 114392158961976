import {useState, useCallback, FC} from 'react';
import { useHistory } from 'react-router-dom';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import { FileUploader } from '../../../../services';
import { UploadFileInfo } from '@interfaces/panel/files';
import { toastr } from 'react-redux-toastr';
import withPage from '../../../hoc/withPage';
import ConsumerMarketService from '../../../../services/api/consumer/consumerMarketService';
import {Col, Row} from "react-bootstrap";
import {FormIconBlock, FormPageFooter, FormPageHeader} from "@components/falcon/common/forms";
import {BaseInfoBlock, ItemBlock} from "@components/consumer/market/items/forms";

interface AddItemFormValues {
    name: string;
    description: string;
    itemId: string;
    price: number;
    count: number;
    icon: File | null;
    categories: string[];
}

const AddItemForm: FC = () => {

    const history = useHistory();
    const [uploading, setUploading] = useState(false);
    const [uploadState, setUploadState] = useState(0);

    const onProgress = useCallback((event: ProgressEvent<EventTarget>) => {
        setUploadState(event.loaded / event.total * 100);
    }, [setUploadState]);

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .max(256)
            .required(),
        description: Yup.string()
            .max(10000),
        price: Yup.number()
            .moreThan(0)
            .required(),
        itemId: Yup.string()
            .required(),
        count: Yup.number()
            .min(1)
            .max(2000000000)
            .required()
    });

    const initialValues: AddItemFormValues = {
        name: '',
        description: '',
        price: 0,
        itemId: '',
        count: 1,
        icon: null,
        categories: []
    }

    const onSubmit = useCallback(async (values: AddItemFormValues) => {
        const api = new ConsumerMarketService();
        const result = await api.putConsumerMarketItem({
            name: values.name,
            description: values.description,
            itemId: values.itemId,
            count: values.count,
            price: values.price,
            uploadIcon: Boolean(values.icon),
            categories: values.categories
        });

        if (result.ok) {
            if (result.result?.upload && values.icon) {
                const upload: UploadFileInfo = result.result?.upload;
                const file = new FileUploader(upload);
                file.onProgress = onProgress;
                try {
                    setUploading(true);
                    await file.upload(values.icon);
                    toastr.success("Создание предмета", "Предмет успешно добавлен.");
                }
                catch (e) {
                    toastr.warning("Создание предмета", "Предмет добавлен, но не удалось загрузить изображение.");
                    console.log(e);
                }
                finally {
                    setUploading(false);
                    setUploadState(0);
                    history.replace(`/consumer/market/item/${result.result.id}/`);
                }
            }
            else {
                toastr.success("Создание предмета", "Предмет успешно добавлен.");
                history.replace(`/consumer/market/item/${result.result?.id}/`);
            }
        }
        else {
            if (result.errorCode === 'BucketNotFound') {
                toastr.error("Редактирование категории", "Отсутствует хранилище файлов. Создайте его из панели администратора и повторите попытку.");
            }
            else {
                toastr.error("Редактирование категории", "Внутренняя ошибка");
            }
        }
    }, [history, onProgress]);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            <Form>
                <Row className="g-3">
                    <Col xs={12}>
                        <FormPageHeader title="Создание товара" />
                    </Col>
                    <Col lg={8}>
                        <BaseInfoBlock />
                    </Col>
                    <Col lg={4}>
                        <FormIconBlock
                            title="Иконка товара"
                            uploading={uploading}
                            uploadProgress={uploadState}
                        />
                        <ItemBlock />
                    </Col>
                    <Col>
                        <FormPageFooter
                            cancelUrl={'/consumer/market/item/'}
                        />
                    </Col>
                </Row>
            </Form>
        </Formik>
    )
}

export default withPage(AddItemForm);
import React, { useState } from 'react';
import { IConsumerMarketCategory } from '@interfaces/consumer/market';
import {Link, useHistory} from 'react-router-dom';
import ConsumerMarketService from '@api/consumer/consumerMarketService';
import withPage from '@components/hoc/withPage';
import {useRemoteTable} from "@hooks/tableHooks";
import {ColumnDef} from "@tanstack/react-table";
import {Avatar, Flex, IconButton} from "@components/falcon/common";
import AdvanceTableProvider from "@components/falcon/common/tables/context";
import {Card, Col, Form, Row} from "react-bootstrap";
import {AdvanceTable, AdvanceTableFooter, AdvanceTableSearchBox} from "@components/falcon/common/tables";
import {useRole} from "@services/hooks";

const columns: ColumnDef<IConsumerMarketCategory>[] = [
    {
        accessorKey: 'name',
        header: 'Название',
        enableSorting: true,
        meta: {
            headerProps: { className: 'pe-1 text-900' },
            cellProps: { className: 'py-2' }
        },
        cell: ({row: {original: {id, name, iconId}}}) => (
            <Link to={`/consumer/market/category/${id}/`}>
                <Flex alignItems="center">
                    <Avatar fileId={iconId} name={name} size="xl" className="me-2"/>
                    <div className="flex-1">
                        <h5 className="mb-0 fs-10">{name}</h5>
                    </div>
                </Flex>
            </Link>
        )
    },
    {
        accessorKey: 'shortName',
        header: 'Адрес',
        enableSorting: true,
        meta: {
            headerProps: { className: 'text-900' },
            placeholderSize: [2, 4]
        }
    },
    {
        accessorKey: 'isActive',
        header: 'Активно',
        enableSorting: false,
        meta: {
            headerProps: { className: 'text-center text-900' },
            cellProps: { className: 'text-center' },
            placeholderSize: 1
        },
        cell: ({row: {original: {isActive}}}) => (
            <Form.Check type="checkbox" defaultChecked={isActive} disabled />
        )
    }
]

const CategoriesList: React.FC = () => {


    const history = useHistory();
    const [api] = useState(() => new ConsumerMarketService());
    const canEdit = useRole("consumer.items.shop.edit");

    const table = useRemoteTable({
        name: 'consumer.refs.list',
        columns,
        defaultState: {
            sorting: [{id: 'name', desc: false}]
        }
    }, api.fetchConsumerMarketCategoriesList);

    return (
        <AdvanceTableProvider table={table}>
            <Card className="mb-3">
                <Card.Header>
                    <Row className="flex-between-center">
                        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                            <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">Категории магазина</h5>
                        </Col>
                        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                            <div className="d-flex">
                                {canEdit && (
                                    <IconButton
                                        variant="falcon-default"
                                        size="sm"
                                        icon="plus"
                                        transform="shrink-3"
                                        className='me-2'
                                        onClick={() => history.push('/consumer/market/category/add/')}
                                    >
                                        <span className="d-none d-sm-inline-block ms-1">Создать</span>
                                    </IconButton>
                                )}
                                <Flex><AdvanceTableSearchBox/></Flex>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        emptyText="Нет категорий"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowInfo
                        rowsPerPageSelection
                        paginationButtons
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableProvider>
    )
}

export default withPage(CategoriesList);
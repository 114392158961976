import {FC} from "react";
import {Col, Row} from "react-bootstrap";
import {OnlineWidget, PaymentsChartWidget, PaymentsWidget, SignUpsWidget} from "@components/consumer/dashboard";
import withPage from "@components/hoc/withPage";

const Dashboard: FC = () => {
    return (
        <>
            <Row className="g-3">
                <Col xxl={9}>
                   <PaymentsChartWidget />
                </Col>
                <Col>
                    <Row className="g-3">
                        <Col  md={4} xxl={12}>
                            <OnlineWidget />
                        </Col>
                        <Col md={4} xxl={12}>
                            <PaymentsWidget />
                        </Col>
                        <Col md={4} xxl={12}>
                            <SignUpsWidget />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default withPage(Dashboard);
import {FC} from "react";
import {Button, Card, Col, Row} from "react-bootstrap";
import {useHistory} from "react-router-dom";

interface FormFooterBlockProps {
    cancelUrl?: string;
    descText?: string;
}

const FormPageFooter: FC<FormFooterBlockProps> = ({cancelUrl, descText}) => {

    const history = useHistory();
    const back = () => history.replace(cancelUrl || '');


    return (
        <Card>
            <Card.Body>
                <Row className="flex-between-center">
                    <Col md>
                        <h5 className="mb-2 mb-md-0">{descText}</h5>
                    </Col>
                    <Col xs="auto">
                        {cancelUrl && (
                            <Button
                                variant="link"
                                className="text-secondary fw-medium p-0 me-3"
                                type="button"
                                onClick={back}
                            >
                                Отменить
                            </Button>
                        )}
                        <Button variant="primary" type="submit">
                            Сохранить
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default FormPageFooter;
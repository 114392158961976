import {FC} from "react";
import { Nav } from 'react-bootstrap';
import {ConsumerTicket} from "@interfaces/consumer/support";
import classNames from "classnames";
import {Avatar, Flex} from "@components/falcon/common";
import {useChatMessages} from "@hooks/selectors/consumer/support.selectors";
import {fromUtcDate, sortByDate} from "@services/helpers";
import {LastMessage} from ".";

interface ChatThreadProps {
    topic: ConsumerTicket;
}

const ChatThread: FC<ChatThreadProps> = ({topic}) => {

    const messages = useChatMessages(topic.id);
    const sortedMessages = messages.sort(sortByDate(x =>x.createTime, 'desc'));
    const lastMessage = sortedMessages.length > 0 ? sortedMessages[0] : topic.lastMessage;
    const threadReaded = topic.lastUserName !== topic.ownerUserName;

    return (
        <Nav.Link
            eventKey={topic.id}
            className={classNames(`chat-contact hover-actions-trigger p-3`, {
                'unread-message': !threadReaded,
                'read-message': threadReaded
            })}
        >
            {/*<div className="d-md-none d-lg-block">
                <ChatSidebarDropdownAction/>
            </div>*/}
            <Flex>
                <Avatar
                    //className={user.status}
                    rounded="circle"
                    fileId={topic.ownerId}
                    name={topic.ownerUserName}
                    size="xl"
                />
                <div className="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
                    <Flex justifyContent="between">
                        <h6 className="mb-0 chat-contact-title">{topic.name}</h6>
                        <span className="message-time fs-11">
                            {' '}{fromUtcDate(topic.updateTime).format("D MMM")}{' '}
                        </span>
                    </Flex>
                    <div className="min-w-0">
                        <div className="chat-contact-content pe-3">
                            {lastMessage && <LastMessage message={lastMessage} />}
{/*                            <div className="position-absolute bottom-0 end-0 hover-hide">
                                {!!lastMessage?.status && (
                                    <FontAwesomeIcon
                                        icon={classNames({
                                            check:
                                                lastMessage.status === 'seen' ||
                                                lastMessage.status === 'sent',
                                            'check-double': lastMessage.status === 'delivered'
                                        })}
                                        transform="shrink-5 down-4"
                                        className={classNames({
                                            'text-success': lastMessage.status === 'seen',
                                            'text-400':
                                                lastMessage.status === 'delivered' ||
                                                lastMessage.status === 'sent'
                                        })}
                                    />
                                )}
                            </div>*/}
                        </div>
                    </div>
                </div>
            </Flex>
        </Nav.Link>
    )
}

export default ChatThread;
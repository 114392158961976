import { FC } from "react";
import { useAccountContext } from "./context";
import { Text } from '@components/constructor/components'
import { fromUtcDate } from "@services/helpers";

interface AccountCreateTimeProps {
    format?: string;
}
const AccountCreateTime: FC<AccountCreateTimeProps> = ({ format = 'lll', ...props }) => {

    const { createTime } = useAccountContext();
    return (
        <Text text={fromUtcDate(createTime).format(format)} {...props} />
    )
}

export default AccountCreateTime;
import { FC } from "react";
import { useGoalContext } from "./context";
import { Text } from '@constructor/components';

const GoalDescription: FC = (props) => {
    const goal = useGoalContext();

    if (!goal?.description) {
        return null;
    }

    return (
        <Text text={goal.description} {...props} />
    )
}

export default GoalDescription;
import {FC} from "react";
import {useLocalizedBlock} from "@services/hooks";
import {Table} from "reactstrap";
import {CustomQuery, IQueryResult} from "@interfaces/query";

interface QueryExecuteTableResultProps {
    query: CustomQuery;
    result: IQueryResult;
}

const QueryExecuteTableResult: FC<QueryExecuteTableResultProps> = ({query, result}) => {

    const columns = query.columns.filter(x => !x.hidden);
    const keyColumn = columns.find(x => x.key)?.name || '';
    const hasRows = result.rows && result.rows.length > 0;

    const lang = useLocalizedBlock(`queries.${query.name.toLowerCase()}.result`);
    const defaultLang = useLocalizedBlock('queries.default.result');

    return (
        <Table striped>
            <thead>
            <tr>
                {columns.map((column, index) => (
                    <th key={index}>{lang[`columns.${column.display}`] || column.display}</th>
                ))}
            </tr>
            </thead>
            <tbody>
            {hasRows ? result.rows!.map((row, index) => (
                <tr key={row[keyColumn] || index}>
                    {query.columns.map((column, index) => (
                        <td key={index}>{lang[`data.${row[column.name]}`] || row[column.name]}</td>
                    ))}
                </tr>
            )) : (
                <tr>
                    <td colSpan={columns.length} align='center'>{lang['empty'] || defaultLang['empty'] || 'Нет результатов'}</td>
                </tr>
            )}
            </tbody>
        </Table>
    )
}

export default QueryExecuteTableResult;
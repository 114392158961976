import { FC } from "react";
import { useBonusLogContext } from "./context";
import { Text } from '@constructor/components';

const BonusCode: FC = (props) => {

    const code = useBonusLogContext();

    if (!code) {
        return null;
    }

    return (
        <Text text={code.code} {...props} />
    )
}

export default BonusCode;
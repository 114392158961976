import { GameAccount } from "@interfaces/game";
import { createContext, useContext } from "react";

const AccountContext = createContext<GameAccount>(undefined as any);

export const AccountProvider = AccountContext.Provider;

export function useAccountContext() {
    const context = useContext(AccountContext);
    return context;
}
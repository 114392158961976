import { injectChild } from "@components/constructor";
import {PropsWithChildren, RefObject, useCallback} from "react";
import { useMemo } from "react";
import { FC, useRef } from "react";
import Slick from "react-slick";

interface SliderChildProps {
    slider_next: () => void;
    slider_prev: () => void;
    slider_pause: () => void;
    slider_play: () => void;
    slider_ref: RefObject<Slick>;
}

const Slider: FC<PropsWithChildren> = ({ children }) => {

    const slider_ref = useRef<Slick>(null);

    const slider_next = useCallback(() => slider_ref.current?.slickNext(), []);
    const slider_prev = useCallback(() => slider_ref.current?.slickPrev(), []);
    const slider_pause = useCallback(() => slider_ref.current?.slickPause(), []);
    const slider_play = useCallback(() => slider_ref.current?.slickPause(), []);

    const data = useMemo<SliderChildProps>(() => {
        return {
            slider_next,
            slider_pause,
            slider_play,
            slider_prev,
            slider_ref
        }
    }, [slider_next, slider_pause, slider_play, slider_prev]);

    return injectChild(data, children);
}

export default Slider;
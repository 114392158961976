import { FC } from "react";
import { useRefCodeContext } from "./context";
import { Text } from '@constructor/components';
import { useThemeUrl } from "@hooks/panelHooks";

interface RefCodeLinkProps {
    tag?: string;
}

const RefCodeLink: FC<RefCodeLinkProps> = ({ tag, ...props }) => {

    const redirectUrl = useThemeUrl('signup');
    const code = useRefCodeContext();
    if (!code?.code) {
        return null;
    }

    const link = `${window.location.protocol}//${window.location.hostname}${redirectUrl}?r=${code.code}`;

    if (tag === 'input') {
        return (
            <input value={link} {...props} />
        )
    }

    return (
        <Text tag={tag} text={link} {...props} />
    )
}

export default RefCodeLink;
import {
    ColumnDef,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel, PaginationState, RowSelectionState, SortingState,
    useReactTable
} from '@tanstack/react-table';
import IndeterminateCheckbox from "@components/falcon/common/tables/indeterminateCheckbox";
import {PanelTableDataModel} from "@models/panel";
import FetchResult from "@models/fetchResult";
import {PanelTableData, ReactTableState} from "@interfaces/panel";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {Updater} from "@tanstack/table-core/src/types";
import {ReduxStore} from "@interfaces/store";
import {panelActions} from "@store/panelStore";

function selectionColumn<T>(selectionColumnWidth?: number | string, selectionHeaderClassname?: string): ColumnDef<T> {
    return {
        id: 'selection',
        accessorKey: '',
        header: ({table}) => (
            <IndeterminateCheckbox
                className="form-check mb-0"
                {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler()
                }}
            />
        ),
        cell: ({row}) => (
            <IndeterminateCheckbox
                className="form-check mb-0"
                {...{
                    checked: row.getIsSelected(),
                    disabled: !row.getCanSelect(),
                    indeterminate: row.getIsSomeSelected(),
                    onChange: row.getToggleSelectedHandler()
                }}
            />
        ),
        meta: {
            headerProps: {
                className: selectionHeaderClassname,
                style: {
                    width: selectionColumnWidth
                }
            },
            cellProps: {
                style: {
                    width: selectionColumnWidth
                }
            }
        }
    };
};

interface useAdvanceTableProps<TData> {
    columns: any[];
    data: TData[];
    sortable: boolean;
    selection: boolean;
    selectionColumnWidth: string;
    selectionHeaderClassname: string;
    pagination: boolean;
    initialState: any;
    perPage: number;

}

export function useAdvanceTable<TData> ({
                             columns,
                             data,
                             sortable,
                             selection,
                             selectionColumnWidth,
                             selectionHeaderClassname,
                             pagination,
                             initialState,
                             perPage = 10 }
                         : useAdvanceTableProps<TData>) {
    const state = {
        pagination: { pageSize: pagination ? perPage : data.length },
        ...initialState
    };
    return useReactTable({
        data,
        columns: selection
            ? [
                selectionColumn(selectionColumnWidth, selectionHeaderClassname),
                ...columns
            ]
            : columns,
        enableSorting: sortable,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        initialState: state
    });
}


interface TableProps<TData> {
    columns: ColumnDef<TData>[];
    defaultState?: Partial<ReactTableState>;
    selection?: boolean;
    selectionColumnWidth?: number | string;
    selectionHeaderClassname?: string;
}

interface RemoteTableProps<TData> extends TableProps<TData> {
    name: string;
}

function useTableState2(name: string, defaultState: Partial<ReactTableState>): ReactTableState {
    const state = useSelector(({ panel }: ReduxStore) => panel.tableStates2[name], shallowEqual);

    return {
        pagination: {
            pageIndex: 0,
            pageSize: 20
        },
        sorting: [],
        rowSelection: {},
        ...defaultState,
        ...state
    }
}

export function useRemoteTable<T extends { id: number | string }>(props: RemoteTableProps<T>,
                                                                  fetchApi: (data: PanelTableDataModel) => Promise<FetchResult<PanelTableData<T[]>>>) {
    const { name, columns, defaultState = {},
        selection, selectionColumnWidth, selectionHeaderClassname} = props;
    const dispatch = useDispatch();
    
    const {
        sorting, rowSelection, pagination, globalFilter
    } = useTableState2(name, defaultState);
    
    //const [sorting, setSorting] = useState<SortingState>(initialState.sorting);
    //const [pagination, setPagination] = useState<PaginationState>(initialState.pagination);
    //const [rowSelection, setRowSelection] = useState<RowSelectionState>(initialState.rowSelection);
    const [pagesCount, setPagesCount] = useState<number>(-1);
    const [loading, setLoading] = useState<boolean>(false);
    
    const [data, setData] = useState<T[]>([]);
    
    const setState = useCallback((state: Partial<ReactTableState>) => {

        //console.log('setState', state)

        dispatch(panelActions.setTableState2({
            id: name,
            state
        }));
    }, [dispatch, name]);

    const setFetchResult = useCallback((result: PanelTableData<T[]>) => {

        const sorting = result.sortField ? [{ id: result.sortField, desc: result.sortOrder?.toLowerCase() === 'desc' }] : [];
        const pagination = {
            pageIndex: result.page,
            pageSize: result.sizePerPage
        };

        setPagesCount(result.totalSize);
        setState({
            sorting,
            pagination
        });

        setData(result.data);
    }, [setState]);
    
    const fetchData = async (sorting: SortingState, pagination: PaginationState, filter?: string | null, silent: boolean = false) => {

        if(!silent)
            setLoading(true);

        const result = await fetchApi({
            page: pagination.pageIndex,
            sizePerPage: pagination.pageSize,
            sortField: sorting[0]?.id,
            sortOrder: sorting[0]?.desc ? 'desc' : 'asc',
            search: filter
        });
        if (result.success) {
            setFetchResult(result.data);
        }
        setLoading(false);
    }
    
    const setPagination = (updater: Updater<PaginationState>) => {
        const newPaginationValue = updater instanceof Function ? updater(pagination) : updater;

        if(shallowEqual(newPaginationValue, pagination)) {
            return;
        }
        
        fetchData(sorting, newPaginationValue, globalFilter);
        
        setState({
            pagination: newPaginationValue
        })
    }

    const setSorting = (updater: Updater<SortingState>) => {
        const newSortingValue = updater instanceof Function ? updater(sorting) : updater;

        if(shallowEqual(newSortingValue, sorting)) {
            return;
        }
        
        fetchData(newSortingValue, pagination, globalFilter);
        
        setState({
            sorting: newSortingValue
        });
    }

    const setRowSelection = (updater: Updater<RowSelectionState>) => {
        const newRowSelectionValue = updater instanceof Function ? updater(rowSelection) : updater;

        setState({
            rowSelection: newRowSelectionValue
        });
    }

    const setGlobalFilter = (updater: Updater<any>) => {
        const newGlobalFilter = updater instanceof Function ? updater(globalFilter) : updater;
        if(shallowEqual(newGlobalFilter, globalFilter)) {
            return;
        }

        fetchData(sorting, pagination, newGlobalFilter);

        setState({
            globalFilter: newGlobalFilter
        });
    }

    const refreshData = (silent: boolean = false) => fetchData(sorting, pagination, globalFilter, silent);

    //Загрузка данных при первом рендере
    useEffect(() => {
        fetchData(sorting, pagination, globalFilter);
    }, [fetchApi]);

    return useReactTable<T>({
        columns: selection
            ? [
                selectionColumn(selectionColumnWidth, selectionHeaderClassname),
                ...columns
            ]
            : columns,
        data: data,
        state: {
            pagination,
            sorting,
            rowSelection,
            globalFilter
        },
        meta: {
            loading,
            refreshData
        },

        manualSorting: true,
        manualPagination: true,
        manualFiltering: true,
        rowCount: pagesCount,

        getCoreRowModel: getCoreRowModel(),

        onSortingChange: setSorting,
        onPaginationChange: setPagination,
        onRowSelectionChange: setRowSelection,
        onGlobalFilterChange: setGlobalFilter
    });
}

export function useTable<T extends { id: number | string }>(props: TableProps<T>, data: T[]) {
    const { columns,
        defaultState = {},
        selection,
        selectionColumnWidth,
        selectionHeaderClassname,
    } = props;

    return useReactTable<T>({
        columns: selection
            ? [
                selectionColumn(selectionColumnWidth, selectionHeaderClassname),
                ...columns
            ]
            : columns,
        data,
        meta: {
            loading: false
        },
        initialState: defaultState,
        //enableSorting: so
        //rowCount: pagesCount,

        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),

        //onGlobalFilterChange: setGlobalFilter
    });
}
import { injectChild } from "@components/constructor";
import { useFormikContext } from "formik";
import {FC, PropsWithChildren, ReactText, useCallback, useMemo, useState} from "react";
import { DropdownContextState, DropdownProvider } from "./context";

interface DropdownProps {
    name: string;
}

interface DropdownChildProps {
    dropdown_opened: boolean;
    dropdown_value: ReactText;
    dropdown_open: () => void;
    dropdown_close: () => void;
    dropdown_toggle: () => void;
}

const Dropdown: FC<PropsWithChildren<DropdownProps>> = ({ name, children }) => {

    const { setFieldValue, setFieldTouched, values } = useFormikContext<any>();
    const [isOpened, setOpened] = useState(false);
    const value = values[name];

    const setValue = useCallback((value: ReactText) => {
        setFieldTouched(name, true, false);
        setFieldValue(name, value);        
        setOpened(false);
    }, [name, setFieldTouched, setFieldValue]);

    const open = useCallback(() => setOpened(true), []);
    const close = useCallback(() => setOpened(false), []);
    const toggle = useCallback(() => setOpened(!isOpened), [isOpened]);

    const data = useMemo<DropdownChildProps>(() => {
        return {
            dropdown_opened: isOpened,
            dropdown_open: open,
            dropdown_value: value,
            dropdown_close: close,
            dropdown_toggle: toggle
        }
    }, [close, isOpened, open, toggle, value]);

    const context = useMemo<DropdownContextState>(() => {
        return {
            setValue,
            value: value
        }
    }, [setValue, value])

    return (
        <DropdownProvider value={context}>
            {injectChild(data, children)}
        </DropdownProvider>
    );
}

export default Dropdown;
import { injectContextArray } from "@components/constructor";
import { PaginationProps } from "@components/constructor/components/core/pagination";
import { usePersonalCodes } from "@hooks/userHooks";
import { IPersonalBonusCode } from "@interfaces/user/bonusCodes";
import {FC, PropsWithChildren, useMemo} from "react";
import { PersonalCodeProvider } from "./context";
import {InjectContextType} from "@interfaces/constructor/constructor";

interface PersonalBonusCodesListChildData {
    personal_code_index: number;
    personal_code_used: boolean;
}

const PersonalBonusCodesList: FC<PropsWithChildren<PaginationProps>> = ({ pagination_current_page = 0, pagination_per_page = 10, children }) => {

    const { data: codes } = usePersonalCodes();
    const page = codes.slice(pagination_current_page * pagination_per_page, (pagination_current_page + 1) * pagination_per_page);

    const data = useMemo<InjectContextType<IPersonalBonusCode, PersonalBonusCodesListChildData>[]>(() => page.map((code, idx) => {
        return {
            key: code.id,
            value: code,
            childData: {
                personal_code_index: pagination_current_page * pagination_per_page + 1 + idx,
                personal_code_used: code.isUsed
            }
        }
    }), [page, pagination_current_page, pagination_per_page]);

    return injectContextArray(data, PersonalCodeProvider, children);
}

export default PersonalBonusCodesList;
import { CustomQuery, IQueryResult } from "@interfaces/query";
import { createContext, useContext } from "react";

const QueryResultContext = createContext<IQueryResult>(undefined as any);
export const QueryResultProvider = QueryResultContext.Provider;

export function useQueryResultContext(): IQueryResult | undefined {
    return useContext(QueryResultContext);
}

const QueryContext = createContext<CustomQuery>(undefined as any);
export const QueryProvider = QueryContext.Provider;

export function useQueryContext(): CustomQuery | undefined {
    return useContext(QueryContext);
}
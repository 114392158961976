import { GameServer } from "@interfaces/game";
import { createContext, useContext } from "react";

const GameServerContext = createContext<GameServer>(undefined as any);

export const GameServerProvider = GameServerContext.Provider;
export const GameServerConsumer = GameServerContext.Consumer;

export function useGameServerContext(): GameServer | undefined {
    const context = useContext(GameServerContext);
    return context;
}
import {FC, useCallback} from "react";
import {useSidebar} from "@hooks/selectors/panel.selectors";
import {sidebarActions} from "@store/sidebarStore";
import {useDispatch} from "react-redux";
import {OverlayTrigger, Button, Tooltip} from "react-bootstrap";
import {OverlayInjectedProps} from "react-bootstrap/Overlay";
import exp from "node:constants";

const renderTooltip: FC<OverlayInjectedProps> = ({style}, ...props) => (
    <Tooltip style={{ ...style, position: 'fixed' }} id="button-tooltip" {...props}>
        Toggle Navigation
    </Tooltip>
);

const ToggleButton: FC = () => {

    const dispatch = useDispatch();
    const {
        isOnRight,
        isFluid
    } = useSidebar();
    const sidebar_toggle = useCallback(() => dispatch(sidebarActions.toggleSidebar()), [dispatch]);

    const handleClick = () => {
        document
            .getElementsByTagName('html')[0]
            .classList.toggle('navbar-vertical-collapsed');
        sidebar_toggle();
    };

    return (
        <OverlayTrigger
            placement={
                isFluid ? (isOnRight ? 'bottom' : 'right') : isOnRight ? 'bottom' : 'left'
            }
            overlay={renderTooltip}
        >
            <div className="toggle-icon-wrapper">
                <Button
                    variant="link"
                    className="navbar-toggler-humburger-icon navbar-vertical-toggle"
                    id="toggleNavigationTooltip"
                    onClick={handleClick}
                >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
                </Button>
            </div>
        </OverlayTrigger>
    );
}

export default ToggleButton;
import React, {FC, useState} from "react";
import { Form, Col, Row } from 'react-bootstrap';
import Select from 'react-select/async';
import makeAnimated from 'react-select/animated';
import {MultiValue, SingleValue} from "react-select";
import {FieldProps} from "formik";

interface FormikAsyncSelectProps extends FieldProps {
    disabled?: boolean;
    isMulti?: boolean;
    loadOptions: (text: string) => Promise<AsyncSelectOption[]>;

    description?: string;

    row?: boolean;
    label?: string;
    labelSize?: number;
}

export const FormikAsyncSelect: FC<FormikAsyncSelectProps> = ({
    field: { name, value },
    form: { setFieldValue, errors },
    disabled,
    isMulti,
    description,

    row,
    label,
    labelSize = 3,

    loadOptions
}) => {

    const onChange = (option: string | string[] | null) => {
        if (Array.isArray(option)) {
            if(isMulti) {
                return setFieldValue(name, option);
            }
            else if(option.length > 0) {
                return setFieldValue(name, option[0]);
            }
            else {
                return setFieldValue(name, null);
            }
        } else if(isMulti) {
            return setFieldValue(name, option ? [option] : []);
        }
        else {
            return setFieldValue(name, option || null);
        }
    }

    const select = <AsyncSelect
        name={name}
        value={value}
        isMulti={isMulti}
        disabled={disabled}
        loadOptions={loadOptions}
        onChange={onChange}
    />;

    if(row) {
        return (
            <Form.Group as={(row ? Row : undefined)} className="mb-3">
                <Form.Label
                    column={row}
                    htmlFor={name}
                    className={"label-color"}
                    sm={labelSize}>
                    {label}
                </Form.Label>
                <Col sm={12-labelSize}>
                    {select}
                    <Form.Control.Feedback type="invalid">{errors[name]?.toString()}</Form.Control.Feedback>
                    {description && <Form.Text>{description}</Form.Text>}
                </Col>
            </Form.Group>
        )
    }
    else {
        return (
            <Col md={12}>
                <Form.Group>
                    <Form.Label column={false}>{label}</Form.Label>
                </Form.Group>
                {select}
                <Form.Control.Feedback type="invalid">{errors[name]?.toString()}</Form.Control.Feedback>
                {description && <Form.Text>{description}</Form.Text>}
            </Col>
        )
    }
}

const animatedComponents = makeAnimated();

interface AsyncSelectProps {
    name: string;
    value: string | string[];
    disabled?: boolean;
    isMulti?: boolean;
    loadOptions: (text: string) => Promise<AsyncSelectOption[]>;
    //preloadOptions?: ()
    onChange: (value: string | string[] | null) => void;
    placeholder?: string;
}

interface AsyncSelectOption {
    value: string;
    label?: string;
}

const AsyncSelect: FC<AsyncSelectProps> = ({name, value, placeholder, disabled, isMulti, loadOptions, onChange}) => {

    const [allOptions, setAllOptions] = useState<AsyncSelectOption[]>([]);
    //const [values, setValues] = useState<MultiValue<AsyncSelectOption> | SingleValue<AsyncSelectOption>>([]);
    //const sv : PropsValue<AsyncSelectOption> = Array.isArray(value) ? value.map(item => ({value: item})) : {value};

    placeholder = placeholder || 'Выберите...';

    //console.log(sv);

    const oc = (option: MultiValue<AsyncSelectOption> | SingleValue<AsyncSelectOption>) => {
        //setValues(option);
        if(Array.isArray(option)) {
            onChange(option.map((item: AsyncSelectOption) => item.value));
        }
        else {
            onChange((option as SingleValue<AsyncSelectOption>)?.value || null);
        }
    }

    const loadAndSaveOptions = async (text: string) => {
        const options = await loadOptions(text);
        setAllOptions(state => state.concat(
            options.filter(option =>
                !state.find(item => item.value === option.value)
            )));
        return options;
    }

    const values = allOptions.filter(option => {
        if(Array.isArray(value)) {
            return value.includes(option.value);
        }
        else {
            return option.value === value;
        }
    });

    return (
        <Select
            name={name}
            isDisabled={disabled}
            components={animatedComponents}
            className="react-select-container"
            classNamePrefix="react-select"
            cacheOptions
            defaultOptions
            isMulti={isMulti}
            loadOptions={loadAndSaveOptions}
            onChange={oc}
            value={isMulti ? values : values.length > 0 ? values[0] : null}
            placeholder={placeholder}
        />
    )
}

export default AsyncSelect;
import React from 'react';
import { useLocalizedText } from '@services/hooks';

interface TextProps {
    text: React.ReactText;
    tag?: string | React.ElementType;
    replaces?: any; 
}

const Text: React.FC<TextProps> = ({ text, tag: Tag, replaces, ...props }) => {

    const display = useLocalizedText(text, replaces);

    if (Tag) {
        return (
            <Tag {...props}>
                {display}
            </Tag>
        )
    }

    return <React.Fragment>
        {display}
    </React.Fragment>;
}

export default Text;
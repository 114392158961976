import React, {FC, useEffect, useState} from 'react';
import { ConsumerUserFull } from '@interfaces/consumer/users';
import { ConsumerUserRoles, ConsumerUserBalance, ConsumerUserDetails } from '.';
import { useLocalizedBlock, useRole } from '@services/hooks';
import ConsumerUserSafety from './userSafety';
import { ConsumerUserInventory } from './items';
import {Button, Card, Col, FormControl, InputGroup, Nav, Row, Tab} from "react-bootstrap";
import SimpleBar from "simplebar-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Avatar, Background, FalconCardHeader, Flex} from "@components/falcon/common";
import {InfoItem} from "@components/consumer/users/details";
import {fromUtcDate} from "@services/helpers";
import cornerBg from "../../../assets/img/illustrations/corner-2.png"
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Field, Form, Formik} from "formik";
import ConsumerUsersService from "@api/consumer/consumerUsersService";
import {toastr} from "react-redux-toastr";
import {consumerActions} from "@store/consumer";
import {usePromisedDispatch} from "@hooks/helperHooks";
import {usePaymentCurrency} from "@hooks/selectors/panel.selectors";

interface ConsumerUserInfoProps {
    user: ConsumerUserFull;
}

interface EditBalanceFormProps {
    userId: string;
    initialValue: number;
    onCancel: () => any;
}

interface EditBalanceFormValues {
    balance: number;
}

const EditBalanceForm: FC<EditBalanceFormProps> = ({userId, initialValue, onCancel}) => {

    const dispatch = usePromisedDispatch();

    const initialValues: EditBalanceFormValues = {
        balance: initialValue
    }

    const onSubmit = async (values: EditBalanceFormValues) => {
        const api = new ConsumerUsersService();
        const result = await api.changeConsumerUserBalance({
            user: userId,
            from: initialValue,
            to: values.balance
        });

        if (result.ok) {
            toastr.success('Изменение баланса', 'Баланс пользователя успешно изменен.');
            await dispatch(consumerActions.loadUser(userId, true));
            onCancel();
        }
        else if (result.errorCode) {
            if (result.errorCode === 'ConcurrencyError') {
                toastr.error('Изменение баланса', 'Баланс пользователя уже изменился. Попробуйте еще раз.');
                await dispatch(consumerActions.loadUser(userId, true));
            }
            else if (result.errorCode === 'UserNotFound') {
                toastr.error('Ошибка', 'Пользователь не найден.');
            }
            else if (result.errorCode === 'InvalidModel') {
                toastr.error('Ошибка', 'Ошибка запроса. Обновите страницу и попробуйте еще раз.');
            }
            else {
                toastr.error('Ошибка', 'Неизвестная ошибка.');
            }
        }
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            <Form>
                <InputGroup>
                    <Field type="number" name="balance" as={FormControl} size="sm" />
                    <Button type="submit" variant="outline-success" size="sm">
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                    <Button variant="outline-danger" onClick={onCancel} size="sm">
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </InputGroup>
            </Form>
        </Formik>
    )
}

interface TotalDonateInfoProps {
    userId: string;
}

const TotalDonateInfo: FC<TotalDonateInfoProps> = ({userId}) => {

    const [totalDonate, setTotalDonate] = useState<string>('');
    const canViewPayments = useRole("consumer.payments.view");
    const currency = usePaymentCurrency();

    useEffect(() => {
        if(canViewPayments) {
            const api = new ConsumerUsersService();
            api.fetchUserTotalDonate(userId).then((result) => {
                setTotalDonate(result.totalDonate.toString());
            }).catch();
        }
    }, [canViewPayments, userId]);
    
    if (!canViewPayments) {
        return null;
    }
    
    return (
        <InfoItem title="Общий донат" content={`${totalDonate} ${currency}`} />
    )
}

const ConsumerUserInfo: FC<ConsumerUserInfoProps> = ({ user }) => {

    const lang = useLocalizedBlock('consumer.user.page', {
        username: user.userName,
        email: user.email
    });

    const [editBalance, setEditBalance] = useState(false);

    
    const canViewItems = useRole('consumer.users.inventory.view');
    const canEdit = useRole("consumer.users.edit");

    return (
        <Row className="g-3">
            <Col xxl={3} xl={4}>
                <div className="position-xl-sticky top-0">
                    <Card className="mb-xl-3">
                        <FalconCardHeader
                            title={"Пользователь"}
                            titleTag="h6"
                            className="py-2"
                        />
                        <Card.Body className="bg-body-tertiary">
                            <Card className="border rounded-3 bg-white dark__bg-1000 shadow-none">
                                <Background
                                    image={cornerBg}
                                    className="bg-card d-none d-sm-block d-xl-none"
                                />
                                <Card.Body className="row g-0 flex-column flex-sm-row flex-xl-column z-1 align-items-center">
                                    <Col xs="auto" className="text-center me-sm-x1 me-xl-0">
                                        <Avatar
                                            fileId={user.avatarId}
                                            src={user.avatarUrl}
                                            className="ticket-preview-avatar"
                                            rounded="circle"
                                            name={user.userName}
                                        />
                                    </Col>
                                    <Col sm={8} md={6} lg={4} xl={12} className="ps-sm-1 ps-xl-0">
                                        <p className="fw-semibold mb-0 text-800 text-center text-sm-start text-xl-center mb-3 mt-3 mt-sm-0 mt-xl-3">
                                            {user.userName}
                                        </p>
                                        <Flex justifyContent="center" className="gap-2" alignItems="center">
                                            {editBalance ? (
                                                    <EditBalanceForm
                                                        userId={user.id}
                                                        initialValue={user.balance}
                                                        onCancel={() => setEditBalance(false)}
                                                    />
                                                ) : (
                                                <>
                                                    Баланс: {user.balance}
                                                    {canEdit && (
                                                        <FontAwesomeIcon
                                                            icon="pen"
                                                            className="fs-11 d-inline-block"
                                                            onClick={() => setEditBalance(true)}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </Flex>
                                    </Col>
                                </Card.Body>
                            </Card>
                            <Row className="border rounded-3 p-x1 mt-3 bg-white dark__bg-1000 mx-0 g-0">
                                <Col md={6} xl={12} className="pe-md-4 pe-xl-0">
                                    <InfoItem title="Id" content={user.id} />
                                    <InfoItem title="Email" content={user.email} />
                                    <InfoItem title="Ник" content={user.publicName} />
                                    <InfoItem title="Регистрация" content={fromUtcDate(user.createTime).format('lll')} />
                                    <TotalDonateInfo userId={user.id} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
            </Col>
            <Col xxl={9} xl={8}>
            <Card>
{/*                    <CardHeader>
                        <CardTitle tag="h5">
                            {lang['title']}
                        </CardTitle>
                    </CardHeader>*/}
                    <Tab.Container defaultActiveKey="main">
                        <SimpleBar>
                            <Card.Header className="p-0 border-bottom">
                                <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="main"
                                            className="d-flex align-items-center py-3 px-x1 mb-0"
                                        >
                                            <FontAwesomeIcon icon="address-card" className="text-600 tab-icon" />
                                            <h6 className="text-600 mb-0 ms-1">{lang['tabs.main']}</h6>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="balance"
                                            className="d-flex align-items-center py-3 px-x1 mb-0"
                                        >
                                            <FontAwesomeIcon icon="wallet" className="text-600 tab-icon" />
                                            <h6 className="text-600 mb-0 ms-1">{lang['tabs.balance']}</h6>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="roles"
                                            className="d-flex align-items-center py-3 px-x1 mb-0"
                                        >
                                            <FontAwesomeIcon icon="user-lock" className="text-600 tab-icon" />
                                            <h6 className="text-600 mb-0 ms-1">{lang['tabs.roles']}</h6>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="safety"
                                            className="d-flex align-items-center py-3 px-x1 mb-0"
                                        >
                                            <FontAwesomeIcon icon="arrows-turn-to-dots" className="text-600 tab-icon" />
                                            <h6 className="text-600 mb-0 ms-1">{lang['tabs.safety']}</h6>
                                        </Nav.Link>
                                    </Nav.Item>
                                    {canViewItems && (
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey="inventory"
                                                className="d-flex align-items-center py-3 px-x1 mb-0"
                                            >
                                                <FontAwesomeIcon icon="boxes-stacked" className="text-600 tab-icon" />
                                                <h6 className="text-600 mb-0 ms-1">Инвентарь</h6>
                                            </Nav.Link>
                                        </Nav.Item>
                                    )}
                                </Nav>
                            </Card.Header>
                        </SimpleBar>
                        <Card.Body className="bg-body-tertiary">
                            <Tab.Content>
                                <Tab.Pane eventKey="main">
                                    <ConsumerUserDetails user={user} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="balance">
                                    <ConsumerUserBalance user={user} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="roles">
                                    <ConsumerUserRoles user={user} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="safety">
                                    <ConsumerUserSafety />
                                </Tab.Pane>
                                {
                                    canViewItems && (
                                        <Tab.Pane eventKey="inventory">
                                            <ConsumerUserInventory />
                                        </Tab.Pane>
                                    )
                                }
                            </Tab.Content>
                        </Card.Body>
                    </Tab.Container>
                </Card>
            </Col>
        </Row>
    )
}

export default ConsumerUserInfo;
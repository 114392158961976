import {FC, PropsWithChildren, useMemo} from "react";
import {useMarketCategories} from "@hooks/selectors/services.selectors";
import {injectContextArray} from "@components/constructor";
import {MarketCategory} from "@interfaces/user/market";
import {MarketCategoryProvider} from "./context";
import {InjectContextType} from "@interfaces/constructor/constructor";
import {useParams} from "react-router-dom";

interface MarketCategoriesListProps {
    default_category?: string;
}

interface MarketCategoriesListChildProps {
    market_category_has_icon: boolean;
    market_category_active: boolean;
    market_category_id: string;
    market_category_url: string;
    market_category_loading: boolean;
    market_category_loaded: boolean;
    market_category_error: boolean;
    market_category_items_count: number;
}

const MarketCategoriesList: FC<PropsWithChildren<MarketCategoriesListProps>> = ({default_category, children}) => {

    const { loading, loaded, error, categories } = useMarketCategories();
    const {category_url} = useParams<{category_url?: string}>();

    const data = useMemo<InjectContextType<MarketCategory, MarketCategoriesListChildProps>[]>(() => categories.map((category) => {
        return {
            key: category.id,
            value: category,
            childData: {
                market_category_has_icon: Boolean(category.icon),
                market_category_active: category_url ? category.shortName === category_url : category.shortName === default_category,
                market_category_id: category.id,
                market_category_url: category.shortName,
                market_category_error: Boolean(error),
                market_category_loaded: loaded,
                market_category_loading: loading,
                market_category_items_count: category?.items.length || 0
            }
        }
    }), [categories, category_url, default_category, error, loaded, loading]);

    return injectContextArray(data, MarketCategoryProvider, children);
}

export default MarketCategoriesList;
import { FC } from "react";
import { useConfirmEmailContext } from "./context";
import { Text } from '@constructor/components';

const ConfirmEmailMessage: FC = (props) => {

    const context = useConfirmEmailContext();
    if (!context) {
        return null;
    }

    const { error, success, fetching } = context;

    if (error) {
        return <Text text='Не удалось проверить код. Обновите страницу или попробуйте позднее.' {...props}/>
    }
    if (fetching) {
        return <Text text='Подождите...' {...props}/>
    }
    if (!success) {
        return <Text text='Код подтверждения не найден либо истек срок его действия.' {...props}/>
    }

    return null;
}

export default ConfirmEmailMessage;
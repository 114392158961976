import { FC } from "react";
import { useDonateBonusContext } from "./context";
import { Text } from '@constructor/components';
import { EPaymentBonusType } from "@interfaces/user/payments";
import { usePaymentCurrency } from "@hooks/selectors/panel.selectors";

const DonateBonusValue: FC = (props) => {

    const bonus = useDonateBonusContext()
    const currency = usePaymentCurrency();

    if(!bonus){
        return null;
    }

    const text = bonus.type === EPaymentBonusType.Fixed ? `${bonus.value} ${currency}` : `${bonus.value}%`;

    return (
        <Text text={text} {...props} />
    )
}

export default DonateBonusValue;
import {FC} from "react";
import {Card, Col, Form, Row} from "react-bootstrap";
import {FormikRowInput, FormikRowSelect} from "@components/panel/formik";
import {Field, useFormikContext} from "formik";
import {EThresholdType} from "@interfaces/user/payments";
import {PaymentAgregator} from "@interfaces/consumer/payments";

const ThresholdBlock: FC = () => {

    const {
        values,
        setFieldValue
    } = useFormikContext<{thresholdType: EThresholdType}>();

    return (
        <Card className="mb-3">
            <Card.Header as="h6" className="bg-body-tertiary">
                Порог применения
            </Card.Header>
            <Card.Body>
                <Field
                    label='Порог'
                    type='number'
                    name='threshold'
                    placeholder='1+'
                    description='Порог, после которого применяется бонус'
                    component={FormikRowInput}
                />
                <Form.Group as={Row}>
                    <Form.Label column sm={3} for='new-bonus-threshold'>
                        Тип порога
                    </Form.Label>
                    <Col sm={9}>
                        <Field
                            id='new-bonus-thresholdType-onetime'
                            type='radio'
                            name='thresholdType'
                            label='Единоразовый'
                            inline
                            component={Form.Check}
                            value={EThresholdType.OneTime}
                            checked={values.thresholdType === EThresholdType.OneTime}
                            onChange={() => setFieldValue('thresholdType', EThresholdType.OneTime)} />
                        <Field
                            id='new-bonus-thresholdType-total'
                            type='radio'
                            name='thresholdType'
                            label='Накопительный'
                            inline
                            component={Form.Check}
                            value={EThresholdType.Total}
                            checked={values.thresholdType === EThresholdType.Total}
                            onChange={() => setFieldValue('thresholdType', EThresholdType.Total)} />
                    </Col>
                </Form.Group>
                <Field
                    id='new-bonus-aggregators'
                    name='aggregators'
                    type='select'
                    label='Агрегаторы'
                    description='Бонус будет работать с выбранными агрегаторами'
                    component={FormikRowSelect}
                    multiple
                >
                    <option value={PaymentAgregator.Unitpay}>Unitpay</option>
                    <option value={PaymentAgregator.Enot}>Enot</option>
                    <option value={PaymentAgregator.Prime}>PrimePayments</option>
                    <option value={PaymentAgregator.PayPalych}>PayPalych</option>
                    <option value={PaymentAgregator.Antilopay}>Antilopay</option>
                </Field>
            </Card.Body>
        </Card>
    )
}


export default ThresholdBlock;
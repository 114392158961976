import React, {FC} from "react";
import {Card, Col, Form} from "react-bootstrap";
import {ItemInput} from "@components/consumer/helpers";
import {Field, useFormikContext} from "formik";
import {FormikInput} from "@components/panel/formik";
import {usePaymentCurrency} from "@hooks/selectors/panel.selectors";

const ItemBlock: FC = () => {

    const {
        values,
        setFieldValue,
        errors,
    } = useFormikContext<{itemId: string}>();

    const currency = usePaymentCurrency();

    return (
        <Card className="mb-3">
            <Card.Header as="h6" className="bg-body-tertiary">
                Содержимое
            </Card.Header>
            <Card.Body>
                <Col md={12}>
                    <Form.Group>
                        <Form.Label>Предмет</Form.Label>
                    </Form.Group>
                    <ItemInput
                        name="itemId"
                        value={values.itemId}
                        setFieldValue={setFieldValue}/>
                    <Form.Control.Feedback type="invalid">{errors.itemId}</Form.Control.Feedback>
                </Col>
                <Field
                    label='Количество'
                    name='count'
                    type='number'
                    placeholder='Количество предметов в позиции'
                    component={FormikInput}
                />
                <Field
                    label='Цена'
                    name='price'
                    type='number'
                    placeholder='Цена за позицию'
                    suffix={currency}
                    component={FormikInput}
                />
            </Card.Body>
        </Card>

    )
}

export default ItemBlock;
import {FC} from "react";
import {Card} from "react-bootstrap";
import {Field} from "formik";
import {FormikRowCheckbox, FormikRowInput} from "@components/panel/formik";

const BaseInfoBlock: FC = () => (
    <Card className="mb-3">
        <Card.Header as="h6" className="bg-body-tertiary">
            Основное
        </Card.Header>
        <Card.Body>
            <Field
                label='Название'
                type='text'
                name='name'
                placeholder='Название бонуса'
                component={FormikRowInput}
            />
            <Field
                label='Активно'
                id='new-bonus-active'
                name='isActive'
                type='switch'
                component={FormikRowCheckbox}
            />
            <Field
                label='Скрытый'
                id='new-bonus-hidden'
                name='hidden'
                type='switch'
                component={FormikRowCheckbox}
                description="Скрытый бонус не отображается в личном кабинете"
            />
        </Card.Body>
    </Card>
)

export default BaseInfoBlock;
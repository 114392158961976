import { IConsumerDashboard } from "@interfaces/consumer/dashboard";
import { Loadable } from "@interfaces/helpers";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "redux";
import ConsumerService from '../../services/api/consumerService';

export type ConsumerDashboardStore = Loadable<IConsumerDashboard>;

const slice = createSlice({
    initialState: {
        loaded: false,
        loading: false,
        error: null,
        data: {
            NewAccounts: 0,
            NowOnline: 0,
            OpenedTasks: 0,
            ServersOnline: {},
            PaymentsStat: {
                hourProfit: [],
                profitDiff: 0,
                totalProfit: 0,
            }
        }
    } as ConsumerDashboardStore,
    name: 'consumerDashboardStore',
    reducers: {
        setLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        setDashboard: (state, action: PayloadAction<IConsumerDashboard>) => {
            state.data = action.payload;
            state.loaded = true;
            state.error = null;
            state.loading = false;
        }
    }
})

export const { reducer: consumerDashboardReducer } = slice;

const {
    setDashboard,
    setError,
    setLoading
} = slice.actions;

export const loadDashboard = (silent: boolean = false) => async (dispatch: Dispatch) => {
    if(!silent) {
        dispatch(setLoading());
    }
    try {
        const api = new ConsumerService();
        const dash = await api.fetchConsumerDashboard();
        dispatch(setDashboard(dash));
        return dash;
    }
    catch(e) {
        dispatch(setError(e));
    }
}
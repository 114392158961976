import { Loadable } from '@interfaces/helpers';
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { User } from '@interfaces/user/users';
import UserService from '@api/userService';

const emptyUser: User = {
    roles: [],
    balance: 0,
    userName: "",
    email: "",
    avatar: "",
    publicName: '',
    createTime: '',
    lang: 'ru'
}

const slice = createSlice({
    initialState: {
        loaded: false,
        loading: false,
        error: null,
        data: emptyUser
    } as Loadable<User>,
    name: 'userDataStore',
    reducers: {
        setLoading: (state) => {
            state.loading = true;
            state.loaded = false;
            state.error = null;
        },
        setError: (state, action) => {
            state.loading = false;
            state.loaded = false;
            state.error = action.payload;
        },
        setData: (state, action: PayloadAction<User>) => {
            state.loading = false;
            state.loaded = true;
            state.error = null;
            state.data = action.payload;
        },
        setBalance: (state, action: PayloadAction<number>) => {
            state.data.balance = action.payload;
        },
        clear: (state) => {
            state.loaded = false;
            state.loading = false;
            state.error = null;
            state.data = emptyUser;
        }
    }
});

export const { reducer: userDataReducer } = slice;

const {
    clear: clearUserData,
    setBalance,
    setData,
    setError,
    setLoading
} = slice.actions;

export const dataActions = {
    loadUserData: () => async (dispatch: Dispatch) => {
        dispatch(setLoading());
        try {
            const api = new UserService();
            const data = await api.fetchUserData();
            dispatch(setData(data));
            return data;
        }
        catch(e) {
            dispatch(setError(e));
        }
    },
    loadBalance: () => async (dispatch: Dispatch) => {
        try {
            const api = new UserService();
            const balance = await api.fetchUserBalance();
            dispatch(setBalance(balance));
            return balance;
        }
        catch(e) {
            dispatch(setError(e));
        }
    },
    setUserData: setData,
    clearUserData,
    setBalance
}

const loadUserData = () => async (dispatch: Dispatch) => {
    dispatch(setLoading());
    try {
        const api = new UserService();
        const data = await api.fetchUserData();
        dispatch(setData(data));
        return data;
    }
    catch(e) {
        dispatch(setError(e));
    }
}

const loadBalance = () => async (dispatch: Dispatch) => {
    try {
        const api = new UserService();
        const balance = await api.fetchUserBalance();
        dispatch(setBalance(balance));
        return balance;
    }
    catch(e) {
        dispatch(setError(e));
    }
}

export {
    clearUserData,
    loadUserData,
    loadBalance,
    setBalance
}
import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";
import RefStats from "./refStats";
import RefStatName from "./statName";
import RefStatReceiveTime from "./statReceiveTime";
import RefStatsList from "./statsList";

const RefStatsInfo: ConstructionComponentInfo = {
    component: RefStats,
    addedChildProps: [
        'ref_stats_loaded',
        'ref_stats_loading',
        'ref_stats_error',
        'ref_stats_count',
        'pagination_current_page',
        'pagination_total_pages',
        'pagination_per_page',
        'pagination_set_page',
        'pagination_next_page',
        'pagination_prev_page'
    ],
    props: [
        'ref_stats_per_page'
    ]
}

const RefStatNameInfo: ConstructionComponentInfo = {
    component: RefStatName
}

const RefStatReceiveTimeInfo: ConstructionComponentInfo = {
    component: RefStatReceiveTime
}

const RefStatsListInfo: ConstructionComponentInfo = {
    component: RefStatsList,
    addedChildProps: [
        'ref_id',
        'ref_index'
    ],
    injectChild: [
        'pagination_current_page',
        'pagination_per_page'
    ]
}

export {
    RefStatsInfo,
    RefStatNameInfo,
    RefStatReceiveTimeInfo,
    RefStatsListInfo
}
import {ChangeEvent, FC, useCallback, useEffect, useState} from "react";
import {Button, Card, Col, Form, Image, ProgressBar, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TextareaAutosize from 'react-textarea-autosize';
import Picker from '@emoji-mart/react';
import classNames from "classnames";
import {useSupportTopicContext} from "@components/consumer/support/context";
import {Flex} from "@components/falcon/common";
import {useChatMessage} from "@hooks/selectors/consumer/support.selectors";
import {LightBoxGallery} from "@components/falcon";
import {FormikHelpers, useFormik} from "formik";
import {consumerActions} from "@store/consumer";
import {usePromisedDispatch} from "@hooks/helperHooks";
import {AttachmentType} from "@interfaces/user/support";
import {toastr} from "react-redux-toastr";

interface MessageTextAreaForm {
    message: string;
}

const MessageTextArea: FC = () => {

    const dispatch = usePromisedDispatch();

    const {
        topic,
        scroll: {
            setScrollToBottom
        },
        editableMessageId,
        setEditableMessageId,
        attachedImages,
        setAttachedImages,
        uploadAttachedImages
    } = useSupportTopicContext();
    
    const editableMessage = useChatMessage(topic?.id, editableMessageId);

    const [previewEmoji, setPreviewEmoji] = useState(false);
    const [imageIndex, setImageIndex] = useState<number | null>(null);

    const submit = async (values: MessageTextAreaForm, actions: FormikHelpers<MessageTextAreaForm>) => {

        const id = topic?.id;

        if(!id) return;

        const result = editableMessage
            ? await dispatch(consumerActions.support.patchMessage(id, editableMessage.id, {
                message: values.message
            })) : await dispatch(consumerActions.support.putMessage(id, {
                message: values.message,
                attachments: attachedImages?.map(file => ({
                    name: file.file.name,
                    type: AttachmentType.Image
                }))
            }));

        if(result.success) {
            if(!editableMessageId && attachedImages && attachedImages.length > 0 && result.data.attachments) {
                await uploadAttachedImages(result.data.attachments.filter(x => !!x.uploadFile).map(x => x.uploadFile!));
            }

            setEditableMessageId();
            setAttachedImages([]);
            setScrollToBottom(true);
            await actions.setFieldValue('message', '');
        }
        else {
            if(result.errorCode === "EmptyMessage") {
                toastr.error("Поддержка", "Сообщение не может быть пустым");
            }
            else if(result.errorCode === "TopicNotFound") {
                toastr.error("Поддержка", "Заявка не найдена");
            }
            else if(result.errorCode === "MessageNotFound"){
                toastr.error("Поддержка", "Сообщение не найдено");
            }
            else if(result.errorCode === "ClosedTopic") {
                toastr.error("Поддержка", "Нельзя отправить сообщение в закрытой заявке");
            }
            else if(result.errorCode === "TooFast") {
                toastr.error("Поддержка", "Слишком быстро отправляете сообщения");
            }
            else {
                toastr.error("Поддержка", "Что-то пошло не так");
            }
        }
    }

    const {
        handleSubmit,
        handleReset,
        isSubmitting,
        setFieldValue,
        values: {message},
    } = useFormik<MessageTextAreaForm>({
        initialValues: {
            message: editableMessage?.message || ''
        },
        onSubmit: submit
    });
    
    const setMessage = useCallback((message: string) => {
        setFieldValue('message', message);
    }, [setFieldValue]);
    
    const addEmoji = (e: any) => {
        let emoji = e.native;
        setMessage(message + emoji);
        setPreviewEmoji(false);
    };

    const cancelEdit = () => {
        setEditableMessageId();
        setMessage('');
    }

    const onAttachChanged = ({currentTarget: {files}}: ChangeEvent<HTMLInputElement>) => {
        const array = files ? Array.from(files) : [];
        setAttachedImages(array);
    }

    useEffect(() => {
        if (editableMessage) {
            setMessage(editableMessage.message);
        }
    }, [editableMessage, setMessage]);

    const isDark = false;

    const isImagesAttached = !!attachedImages && attachedImages.length > 0;

    return (
        <Flex direction='column'>
            {editableMessage && (
                <Card.Body className="border-200 border-bottom border-top">
                    <Flex>
                        <div className="vr me-3"/>
                        <span className="flex-grow-1">{editableMessage.message}</span>
                        <Form.Label className="chat-file-upload cursor-pointer">
                            <FontAwesomeIcon
                                icon="close"
                                onClick={cancelEdit}
                            />
                        </Form.Label>
                    </Flex>
                </Card.Body>
            )}
            {(isImagesAttached && !editableMessage) && (
                <Card.Body className="border-200 border-bottom border-top">
                    <div className="chat-message chat-gallery">
                        <LightBoxGallery
                            index={imageIndex}
                            setIndex={setImageIndex}
                            images={attachedImages.map(x => x.fileUrl)}
                        >
                            <Row className="mx-n1">
                                {attachedImages?.length > 0 ? attachedImages.map((img, index) => (
                                    <Col
                                        className="px-1 mt-1 mb-1"
                                        style={{minWidth: 50, maxWidth: 150}}
                                        key={index}
                                    >
                                        <Flex direction='column'>
                                            <div style={{height: 100, minWidth: 50, maxWidth: 150}} className="mb-1">
                                                <Image
                                                    fluid
                                                    rounded
                                                    style={{height: "100%", width: "100%", objectFit: 'cover', overflow: 'hidden'}}
                                                    className="mb-2 cover-image"
                                                    src={img.fileUrl}
                                                    alt=""
                                                    onClick={() => setImageIndex(index)}
                                                />
                                            </div>
                                            {img.uploadProgress?.uploading && (
                                                <ProgressBar now={img.uploadProgress.uploadProgress} animated />
                                            )}
                                        </Flex>
                                    </Col>
                                )) : (
                                    <Image
                                        fluid
                                        rounded
                                        className="mb-2 cursor-pointer"
                                        src={attachedImages[0].fileUrl}
                                        alt=""
                                        onClick={() => setImageIndex(0)} />
                                )}
                            </Row>
                        </LightBoxGallery>
                    </div>
                </Card.Body>
            )}
            <Form className="chat-editor-area"
                  onSubmit={handleSubmit}
                  onReset={handleReset}
            >
                <TextareaAutosize
                    minRows={1}
                    maxRows={6}
                    value={message}
                    placeholder="Введите сообщение"
                    onChange={({ target }) => setMessage(target.value)}
                    className="form-control outline-none resize-none rounded-0 border-0 emojiarea-editor"
                />

                <Form.Group controlId="chatFileUpload">
                    <Form.Label className="chat-file-upload cursor-pointer">
                        <FontAwesomeIcon icon="paperclip" />
                    </Form.Label>
                    <Form.Control
                        type="file"
                        className="d-none"
                        accept=".jpeg, .png, .jpg"
                        multiple={false}
                        onChange={onAttachChanged}
                    />
                </Form.Group>

                <Button
                    variant="link"
                    className="emoji-icon "
                    onClick={() => setPreviewEmoji(!previewEmoji)}
                >
                    <FontAwesomeIcon
                        icon={'laugh-beam'}
                        onClick={() => setPreviewEmoji(!previewEmoji)}
                    />
                </Button>

                {previewEmoji && (
                    <div className="chat-emoji-picker" dir="ltr">
                        <Picker
                            set="google"
                            onEmojiSelect={addEmoji}
                            theme={isDark ? 'dark' : 'light'}
                            previewPosition="none"
                            skinTonePosition="none"
                        />
                    </div>
                )}

                <Button
                    variant="send"
                    size="sm"
                    className={classNames('shadow-none', {
                        'text-primary': message.length > 0
                    })}
                    type="submit"
                    disabled={isSubmitting}
                >
                    Отправить
                </Button>
            </Form>
        </Flex>

    )
}

export default MessageTextArea;
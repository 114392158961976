import { injectContextArray } from "@components/constructor";
import { useDonateLogs } from "@hooks/selectors/user/payments.selectors";
import { UserDonateLog } from "@interfaces/user/users";
import {FC, PropsWithChildren, useMemo} from "react";
import { DonateLogItemProvider } from "./context";
import { PaginationProps } from "@constructor/components/core/pagination";
import {InjectContextType} from "@interfaces/constructor/constructor";

interface DonateLogsListChildProps {
    donate_log_item_index: number;
}

const DonateLogsList: FC<PropsWithChildren<PaginationProps>> = ({ pagination_current_page = 0, pagination_per_page = 10, children }) => {

    const { loading, data: logs } = useDonateLogs();
    const page = logs.slice(pagination_current_page * pagination_per_page, (pagination_current_page + 1) * pagination_per_page);

    const data = useMemo<InjectContextType<UserDonateLog, DonateLogsListChildProps>[]>(() => page.map((log, idx) => {
        return {
            key: log.id,
            value: log,
            childData: {
                donate_log_item_index: pagination_current_page * pagination_per_page + 1 + idx
            }
        }
    }), [page, pagination_current_page, pagination_per_page]);

    if (loading || logs.length === 0) {
        return null;
    }

    return injectContextArray(data, DonateLogItemProvider, children);
}

export default DonateLogsList;
import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faCogs, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const NavbarSettings: React.FC<RouteComponentProps> = ({ history }) => {

    const linkTo = (url: string) => {
        history.push(url);
    }

    return (
        <UncontrolledDropdown nav inNavbar className="ml-lg-1">
            <DropdownToggle nav caret>
                <FontAwesomeIcon icon={faCog} className="align-middle" />
            </DropdownToggle>
            <DropdownMenu end style={{right: 0, left: 'auto'}}>
                <DropdownItem onClick={() => linkTo('/settings/')}>
                    <FontAwesomeIcon
                        icon={faCogs}
                        fixedWidth
                        className="mr-2 align-middle"
                    />
                    Настройки
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => linkTo('/logout/')}>
                    <FontAwesomeIcon
                        icon={faArrowAltCircleRight}
                        fixedWidth
                        className="mr-2 align-middle"
                    />
                    Выйти
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

export default withRouter(NavbarSettings);
import {FC, useCallback, useState} from "react";
import {Collapse, Nav} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {sidebarActions} from "@store/sidebarStore";
import {NavLink, useLocation} from "react-router-dom";
import {SidebarCategoryLink, SidebarItemLink} from "@interfaces/control-panel";
import NavbarVerticalMenuItem from "./navbarVerticalMenuItem";

interface CollapseItemsProps {
    route: SidebarCategoryLink;
}

const CollapseItems: FC<CollapseItemsProps> = ({route}) => {

    const { pathname } = useLocation();

    const checkLink = (children: SidebarCategoryLink) => {
        if (children.to === pathname) {
            return true;
        }
        return Boolean(children.children && children.children.some(x => x.to === pathname));
    };

    const [open, setOpen] = useState(checkLink(route));

    return (
        <Nav.Item as='li'>
            <Nav.Link onClick={() => setOpen(!open)} className='dropdown-indicator cursor-pointer' aria-expanded={open}>
                <NavbarVerticalMenuItem route={route} />
            </Nav.Link>
            <Collapse in={open}>
                <Nav className="flex-column nav" as="ul">
                    <NavbarVerticalMenu routes={route.children!} />
                </Nav>
            </Collapse>
        </Nav.Item>
    )
}

interface NavbarVerticalMenuProps {
    routes: SidebarCategoryLink[];
}

const NavbarVerticalMenu: FC<NavbarVerticalMenuProps> = ({routes}) => {

    const dispatch = useDispatch();
    const toggleBurger = useCallback(() => dispatch(sidebarActions.setBurgerMenu(false)), [dispatch]);

    return (
        <>
            {routes.map((route, idx) => {
                if(route.children) {
                    return (
                        <CollapseItems
                            route={route}
                            key={idx} />
                    );
                }

                return (
                    <Nav.Item key={idx} as='li' onClick={toggleBurger}>
                        <NavLink to={route.to} className='nav-link' activeClassName='active' exact={route.exact}>
                            <NavbarVerticalMenuItem route={route} />
                        </NavLink>
                    </Nav.Item>
                )
            })}
        </>
    )
}

export default NavbarVerticalMenu;
import { injectChild, injectContextAsArray } from "@components/constructor";
import { useDailyGifts, useDailyStats } from "@hooks/selectors/user/daily.selectors";
import { EGiftType, IGift, IItemGiftInfo } from "@interfaces/user/daily";
import {
    createContext,
    FC,
    PropsWithChildren,
    ReactNode,
    RefObject,
    useCallback,
    useContext,
    useMemo,
    useState
} from "react";
import Text from "../Text";
import Slick, { Settings } from "react-slick";
import { usePromisedDispatch } from "@hooks/helperHooks";
import { dailyActions } from "@store/user/userDailyStore";
import { toastr } from "react-redux-toastr";
import { useItems } from "@hooks/selectors/user/item.selectors";
import { Item } from "@constructor/components/features/items";
import {InjectContextType} from "@interfaces/constructor/constructor";

const DailyGiftContext = createContext<IGift>(undefined as any);
const DailyGiftProvider = DailyGiftContext.Provider;

function useDailyGiftContext(): IGift | undefined {
    return useContext(DailyGiftContext);
}

const DailyGiftStats: FC = (props) => {

    const stats = useDailyStats();

    return (
        <Text text={stats.length} {...props} />
    )
}

interface DailyGiftsListProps {
    children: ReactNode;
    slider?: Settings;
    slider_ref?: RefObject<Slick>;
}

const DailyGiftsList = ({ children, slider, slider_ref }: DailyGiftsListProps) => {
    
    const gifts = useDailyGifts();
    const stats = useDailyStats();

    const itemIds = gifts.filter(g => g.giftType === EGiftType.ItemGift).map(g => (g.giftInfo as IItemGiftInfo).itemId);
    useItems(itemIds);

    const data = useMemo<InjectContextType<IGift>[]>(() => gifts.map((gift) => {
        return {
            key: gift.id,
            value: gift,
            childData: {
                gift_type: gift.giftType,
                repeat: gift.repeat,
                is_assigned: gift.isAssigned,
                is_assigned_now: gift.isAssigned && gift.days <= stats.length
            }
        }
    }), [gifts, stats.length]);

    if (slider || slider_ref) {
        return (
            <Slick {...slider} ref={slider_ref}>
                {injectContextAsArray(data, DailyGiftProvider, children)}
            </Slick>
        )
    }

    return injectContextAsArray(data, DailyGiftProvider, children);
}

const DailyGift: FC<PropsWithChildren> = ({ children }) => {
    const gift = useDailyGiftContext();

    if (!gift) {
        return null;
    }

    if (gift.giftType === EGiftType.ItemGift) {
        const info = gift.giftInfo as IItemGiftInfo;
        return (
            <Item item_id={info.itemId}>
                {children}
            </Item>
        )
    }
    else {
        return (
            <>{children}</>
        )
    }
}

const DailyGiftName: FC = (props) => {
    const gift = useDailyGiftContext();

    if (!gift?.name) {
        return null;
    }

    return (
        <Text text={gift.name} {...props} />
    )
}

const DailyGiftDays: FC = (props) => {
    const gift = useDailyGiftContext();

    if (gift?.days === undefined) {
        return null;
    }

    return (
        <Text text={gift.days} {...props} />
    )
}

const DailyGiftItemCount: FC = (props) => {
    const gift = useDailyGiftContext();

    if (gift?.giftType !== EGiftType.ItemGift) {
        return null;
    }

    return (
        <Text text={(gift.giftInfo as IItemGiftInfo).count} {...props} />
    )
}

const DailyGiftReceive: FC<PropsWithChildren> = ({ children }) => {

    const dispatch = usePromisedDispatch();
    const [receivingGift, setRecGift] = useState(false);

    const receiveGift = useCallback(async () => {
        setRecGift(true);

        const result = await dispatch(dailyActions.checkGitfs());
        if(result.success) {
            dispatch(dailyActions.loadStats());
            //dispatch(dailyActions.loadGifts());

            if(result.data.newGifts.length > 0) {
                toastr.success("Подарки", "Получены новые подарки");
            }
            else if(result.data.updatedGifts.length > 0) {
                toastr.success("Подарки", "Прогресс обновлен, подарок уже был выдан ранее");
            }
            else {
                toastr.success("Подарки", "Прогресс обновлен, но нет подарков для получения");
            }
        }
        else {
            if(result.errorCode === 'NoGifts') {
                toastr.success("Подарки", "Нет подарков для получения");
            }
            else if(result.errorCode === 'HasToday') {
                toastr.success("Подарки", "Сегодня Вы уже запрашивали получение подарка");
            }
            else {
                toastr.error("Подарки", "Что-то пошло не так");
            }
        }

        setRecGift(false);
    }, [dispatch]);

    const data = useMemo(() => {
        return {
            receive_gift: receiveGift,
            receiving_gift: receivingGift
        }
    }, [receiveGift, receivingGift]);

    return injectChild(data, children);
}

export {
    DailyGift,
    DailyGiftStats,
    DailyGiftsList,
    DailyGiftName,
    DailyGiftDays,
    DailyGiftItemCount,
    DailyGiftReceive
}
export default class FetchResult<T = any> {

    public result?: T;
    public ok?: boolean;
    public errorCode?: string;
    public message?: string;
    public status?: number;
    public errorData?: any;

    public get success() {
        return Boolean(this.ok && this.result);
    }

    public get data() {
        return this.result as T;
    }

    public static async create<T = any>(res: Response) {

        const fr = new FetchResult<T>();
        let data;
        try {
            data = await res.json();
        }
        catch(e){
            //console.log(e);
        }

        fr.ok = res.ok;
        fr.status = res.status;
        fr.setData(data);
 

        return fr;
    }

    public static fault<T = any>(message?: string) {
        const fr = new FetchResult<T>();
        fr.ok = false;
        fr.status = -1;
        fr.message = message;
        return fr;
    }

    public static fromData<T = any>(data: T) {
        const fr = new FetchResult<T>();
        fr.ok = true;
        fr.status = 200;
        fr.result = data;
        return fr;
    }
    
    setData = (data: any) => {

        if(!data){
            return;
        }

        if(this.ok){
            this.result = data as T;
        }
        else {
            if(Array.isArray(data)) {
                if(data.length > 0) {
                    this.setError(data[0]);
                }
            }
            else{
                this.setError(data);
            }
        }
    }

    setError = (data: any) => {
        if(data.error) {
            this.errorCode = data.error;
        }
        if(data.code) {
            this.errorCode = data.code;
        }
        this.message = data.error_description;
        this.errorData = data.error_data;
    }
}
import { ConstructionComponentInfo } from "@interfaces/constructor/constructor"
import UserAvatar from "./userAvatar"
import UserBalance from "./userBalance"
import UserEmail from "./userEmail"
import UserLastAuth from "./userLastAuth"
import UserName from "./userName"
import UserPublicName from "./userPublicName"

const UserNameInfo: ConstructionComponentInfo = {
    component: UserName
}

const UserEmailInfo: ConstructionComponentInfo = {
    component: UserEmail
}

const UserBalanceInfo: ConstructionComponentInfo = {
    component: UserBalance
}

const UserPublicNameInfo: ConstructionComponentInfo = {
    component: UserPublicName
}

const UserAvatarInfo: ConstructionComponentInfo = {
    component: UserAvatar
}

const UserLastAuthInfo: ConstructionComponentInfo = {
    component: UserLastAuth
}

export {
    UserAvatarInfo,
    UserBalanceInfo,
    UserEmailInfo,
    UserLastAuthInfo,
    UserPublicNameInfo,
    UserNameInfo
}
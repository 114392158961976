import { useSelector, shallowEqual } from "react-redux";
import { ReduxStore } from "@interfaces/store";
import { ReactText } from "react";
import { useGameServerContext } from "@constructor/components/core/servers/game/context";
import { useParams } from "react-router-dom";
import { useLoginServerContext } from "@components/constructor/components/loginServer/context";
import { useGlobalVariable } from "./panel.selectors";

/**
 * Выбрать нужный логинсервер по доступным данным: context > router > props
 * @param id id сервера из пропсов
 */
export function useLoginServerAuto(id?: ReactText) {

    const logins = useLoginServers();
    const globalId = useGlobalVariable("login_id", logins.length > 0 ? logins[0].id : undefined);
    const context = useLoginServerContext();
    const { login_id: params } = useParams<{ login_id: string }>();

    return useLoginServer(context?.id || params || id || globalId);
}

/**
 * Возвращает логин по id
 * Если не указан id и логин всего один - вернет первый логин
 * @param id 
 * @returns 
 */
export function useLoginServer(id?: ReactText) {
    // eslint-disable-next-line eqeqeq
    const login = useSelector(({ user: { project: { data: { loginServers } } } }: ReduxStore) => id ? loginServers.find(login => login.id == id) : loginServers.length === 1 ? loginServers[0] : undefined);

    return login;
}

export function useLoginServers() {
    return useSelector(({ user }: ReduxStore) => user.project.data.loginServers);
}

/**
 * Выбрать нужный геймсервер по доступным данным: context > router > props
 * @param id id сервера из пропсов
 */
export function useGameServerAuto(id?: ReactText) {

    const loginServer = useLoginServerAuto();
    const gameServers = useGameServers(loginServer?.id);
    const globalId = useGlobalVariable("game_id", gameServers.length > 0 ? gameServers[0].id : undefined);
    const context = useGameServerContext();
    const { game_id: params } = useParams<{ game_id: string }>();

    return useGameServer(context?.id || params || id || globalId);
}

export function useGameServer(id?: React.ReactText) {
    // eslint-disable-next-line eqeqeq
    return useSelector(({ user: { project: { data: { gameServers } } } }: ReduxStore) => id ? gameServers.find(gs => gs.id == id) : gameServers.length === 1 ? gameServers[0] : undefined);
}

/**
 * Список геймсерверов по логинсерверу (или всех)
 */
export function useGameServers(login?: React.ReactText) {
    // eslint-disable-next-line eqeqeq
    return useSelector(({ user: { project: { data } } }: ReduxStore) => login ? data.gameServers.filter(gs => gs.loginId == login) : data.gameServers, shallowEqual);
}

/**
 * Список игровых серверов, доступных для пополнения
 */
export function useChargingGameServers(login?: string) {
    return useSelector(({ user: { project: { data } } }: ReduxStore) => data.gameServers.filter(gs => (!login || gs.loginId === login) && gs.canCharge && gs.chargePower > 0), shallowEqual);
}

/**
 * Список логин серверов, доступных для пополнения
 */
export function useChargingLoginServers() {
    return useSelector(({ user }: ReduxStore) => user.project.data.loginServers.filter(ls => ls.canCharge && ls.chargePower > 0), shallowEqual);
}
import { userActions } from "@store/user";
import {FC, PropsWithChildren, useCallback, useMemo} from "react";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { ModalProps } from "@constructor/components/modals";
import { useInventoryItemContext } from "./context";
import * as Yup from 'yup';
import { Form, Formik } from "formik";
import { SelectorsFormsValues } from "@constructor/components/core/forms/selectors";
import { CountHelper } from "@constructor/components/core/forms";
import { useDefaultSelectorFormValues } from "@hooks/helperHooks";
import InventoryService from "@api/user/inventoryService";

interface SendInventoryItemFormValues extends SelectorsFormsValues {
    count: number;
}

const SendInventoryItemForm: FC<PropsWithChildren<ModalProps>> = ({ modalToggle, children, ...props }) => {

    const dispatch = useDispatch();
    const item = useInventoryItemContext();
    const selectors = useDefaultSelectorFormValues();

    const onSubmit = useCallback(async (values: SendInventoryItemFormValues) => {
        if (!item) {
            return;
        }

        const api = new InventoryService();
        const result = await api.sendInventoryItem({
            itemId: item.id,
            count: values.count,
            accountId: values.selected_account || '',
            characterId: values.selected_character || '',
            gameServerId: values.selected_game_server
        });

        if (result.ok) {
            toastr.success('Отправка предмета', 'Предмет успешно отправлен');
            dispatch(userActions.inventory.loadInventoryItems(true));
            if (modalToggle) {
                modalToggle();
            }
        }
        else {
            if (result.errorCode === 'NoTarget') {
                toastr.error('Отправка предмета', 'Укажите, куда отправить предмет.');
            }
            else if (result.errorCode === 'ItemNotFound') {
                toastr.error('Отправка предмета', 'Предмет не найден. Попробуйте еще раз.');
                dispatch(userActions.inventory.loadInventoryItems(true));
            }
            else if (result.errorCode === 'NotEnoughtItem') {
                toastr.error('Отправка предмета', 'У вас нет столько предметов. Попробуйте еще раз.');
                dispatch(userActions.inventory.loadInventoryItems(true));
            }
            else if (result.errorCode === 'AccountNotFound') {
                toastr.error('Отправка предмета', 'Аккаунт не найден. Попробуйте еще раз');
            }
            else if (result.errorCode === 'GameNotFound') {
                toastr.error('Отправка предмета', 'Игровой сервер не найден. Попробуйте еще раз.');
            }
            else if (result.errorCode === 'CharNotFound') {
                toastr.error('Отправка предмета', 'Персонаж не найден. Попробуйте еще раз.');
                dispatch(userActions.characters.loadCharacters());
            }
            else if (result.errorCode === 'SendError') {
                toastr.error('Отправка предмета', 'Ошибка отправки. Обратитесь к администрации');
            }
            else if(result.errorCode === 'ServerDisabled') {
                toastr.error('Отправка предмета', 'На этот сервер нельзя отправить предмет');
            }
            else if(result.errorCode === 'SendingDisabled') {
                toastr.error('Отправка предмета', 'На этот сервер нельзя отправить предмет');
            }
            else if(result.errorCode === 'ServerNotIncluded') {
                toastr.error('Отправка предмета', 'Выбранный предмет нельзя отправить на этот сервер');
            }
            else {
                toastr.error('Отправка предмета', 'Что-то пошло не так');
            }
        }
    }, [dispatch, item, modalToggle]);


    const validate = useMemo(() => Yup.object().shape({
        count: Yup.number()
            .min(1, 'Не менее 1')
            .max(item?.count || 0, `Не более ${item?.count || 0}`)
            .required('Обязательно'),
        selected_account: Yup.string()
            .required('Обязательно'),
        selected_character: Yup.string()
            .required('Обязательно')
    }), [item?.count]);

    if (!item) {
        return null;
    }

    const initial: SendInventoryItemFormValues = {
        count: 1,
        ...selectors
    };


    return (
        <Formik onSubmit={onSubmit} initialValues={initial} validationSchema={validate}
        >
            <Form {...props}>
                <CountHelper
                    name='count'
                    min={1} 
                    max={item?.count}>
                    {children}
                </CountHelper>
            </Form>
        </Formik>
    )
}

export default SendInventoryItemForm;
import {FC, useCallback, useState} from 'react';
import {Link} from 'react-router-dom';
import {calendarFormatter, dateFormatter} from '@services/helpers';
import {BonusCodeType, ConsumerBonusCode} from "@interfaces/consumer/bonusCodes";
import ConsumerBonusService from "@api/consumer/consumerBonusService";
import {PanelTableDataModel} from "@models/panel";
import {ItemName} from "@components/consumer/helpers";
import {ColumnDef} from "@tanstack/react-table";
import {useRemoteTable} from "@hooks/tableHooks";
import {Card} from "react-bootstrap";
import {Flex, IconButton} from "@components/falcon/common";
import {AdvanceTable, AdvanceTableFooter, AdvanceTableSearchBox} from "@components/falcon/common/tables";
import AdvanceTableProvider from "@components/falcon/common/tables/context";
import {useRole} from "@services/hooks";

function valueFormatter(value: string, code: ConsumerBonusCode) {
    const data = JSON.parse(value);
    const itemId = data?.ItemId;
    const itemCount = data?.Count;
    const balanceValue = data?.Value;

    if(code.type === BonusCodeType.Balance) {
        return `${balanceValue} ₽`;
    }

    return (
        <>
            <ItemName itemId={itemId} /> x{itemCount}
        </>
    )
}

function ownerFormatter(code: ConsumerBonusCode) {
    if(!code.owner) {
        return '-';
    }

    return (
        <Link to={`/consumer/users/${code.owner}/`}>{code.ownerName}</Link>
    )
}

const columns: ColumnDef<ConsumerBonusCode>[] = [
    {
        accessorKey: 'code',
        header: 'Код',
        enableSorting: true,
        meta: {
            headerProps: { className: 'pe-1 text-900' },
            cellProps: { className: 'py-2' }
        },
        cell: ({row: {original}}) => (
            <Link to={`/consumer/bonuses/${original.id}/`}>
                <Flex alignItems="center" className="flex-1">
                    <h5 className="mb-0 fs-10">{original.code}</h5>
                </Flex>
            </Link>
        )
    },
    {
        accessorKey: 'createTime',
        header: 'Дата создания',
        enableSorting: true,
        meta: {
            headerProps: { className: 'text-900' }
        },
        cell: ({row: {original}}) => calendarFormatter(original.createTime),
    },
    {
        accessorKey: 'value',
        header: 'Награда',
        enableSorting: false,
        meta: {
            headerProps: { className: 'text-900' }
        },
        cell: ({row: {original}}) => valueFormatter(original.value, original),
    },
    {
        accessorKey: 'owner',
        header: 'Создатель',
        enableSorting: false,
        meta: {
            headerProps: { className: 'text-900' }
        },
        cell: ({row: {original}}) => ownerFormatter(original),
    },
    {
        accessorKey: 'isUsed',
        header: 'Актуален',
        enableSorting: false,
        meta: {
            headerProps: { className: 'text-center text-900' }
        },
        cell: ({row: {original}}) => !original.isUsed ? 'Да' : 'Нет'
    },
    {
        accessorKey: 'user',
        header: 'Использовавший',
        enableSorting: false,
        meta: {
            headerProps: { className: 'text-900' }
        },
        cell: ({row: {original}}) => {
            return original.user ? <Link to={`/consumer/users/${original.user}/`}>{original.userName}</Link> : '-';
        }
    },
    {
        accessorKey: 'useTime',
        header: 'Последнее использование',
        enableSorting: true,
        meta: {
            headerProps: { className: 'text-900' }
        },
        cell: ({row: {original}}) => original.useTime ? dateFormatter(original.useTime) : '-',

    }
]

interface ConsumerBonusCodesListProps {
    onCreateClick: () => any;
}

const ConsumerBonusCodesList: FC<ConsumerBonusCodesListProps> = ({onCreateClick}) => {

    const [api] = useState(() => new ConsumerBonusService());
    const fetch = useCallback((data: PanelTableDataModel) => api.getBonusCodesList(data), [api]);
    const canEdit = useRole('consumer.bonus.edit');

    const table = useRemoteTable({
        name: 'consumer.bonus.list',
        columns,
        defaultState: {
            sorting: [{id: 'createTime', desc: true}]
        }
    }, fetch);

    return (
        <AdvanceTableProvider table={table}>
            <Card className="mb-3">
                <Card.Header>
                    <Flex className='align-items-center justify-content-between'>
                        <Flex alignItems="center" className="fs-10">
                            <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">Бонусные коды</h5>
                        </Flex>
                        <Flex>
                            {canEdit && (
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="plus"
                                    transform="shrink-3"
                                    className='me-2'
                                    onClick={onCreateClick}
                                >
                                    <span className="d-none d-sm-inline-block ms-1">Создать</span>
                                </IconButton>
                            )}
                            <Flex><AdvanceTableSearchBox/></Flex>
                        </Flex>
                    </Flex>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowInfo
                        rowsPerPageSelection
                        paginationButtons
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableProvider>
    )
}

export default ConsumerBonusCodesList;
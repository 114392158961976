import { FC } from "react";
import { useNotificationContext } from "./context";
import { Text } from '@constructor/components';

const NotificationMessage: FC = (props) => {
    const not = useNotificationContext();

    if (!not?.message) {
        return null;
    }

    return (
        <Text text={not.message} {...props} replaces={not.replaces} />
    )
}

export default NotificationMessage;
import { ReduxStore } from "@interfaces/store";
import { IStoredItem, itemActions } from "@store/user/userItemsStore";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

export function useItem(id: string): IStoredItem {
    const dispatch = useDispatch();
    const item = useSelector(({ user: { items } }: ReduxStore) => items.find(i => i.id === id));

    if (!item && id) {
        dispatch(itemActions.loadItems([id]));
    }

    return item || {
        id,
        loaded: false,
        loading: true,
        error: null,
        name: '',
        servers: []
    };
}

export function useItems(ids: string[]): IStoredItem[] {
    const dispatch = useDispatch();
    const items = useSelector(({ user: { items } }: ReduxStore) => items.filter(item => ids.includes(item.id)), shallowEqual);

    if (items.length !== ids.length) {
        const existingIds = items.map(item => item.id);
        const toLoad = ids.filter(id => !existingIds.includes(id));
        
        if(toLoad.length > 0) {
            dispatch(itemActions.loadItems(toLoad));
        }

        items.concat(toLoad.map(id => {
            return {
                id,
                loaded: false,
                loading: true,
                error: null,
                name: '',
                servers: []
            }
        }))
    }

    return items;
}
import { injectChild } from "@components/constructor";
import { PaginationProps } from "@components/constructor/components/core/pagination";
import { usePagination } from "@hooks/components/constructor.hooks";
import { useRefStats } from "@hooks/selectors/user/refs.selectors";
import {FC, PropsWithChildren, useMemo} from "react";

interface RefStatsProps {
    ref_stats_per_page?: number;
}

interface RefStatsChildProps {
    ref_stats_loaded: boolean;
    ref_stats_loading: boolean;
    ref_stats_error: boolean;
    ref_stats_count: number;
}

const RefStats: FC<PropsWithChildren<RefStatsProps>> = ({ ref_stats_per_page = 10, children }) => {

    const stats = useRefStats();
    const pagination = usePagination(ref_stats_per_page, stats.data.length);

    const data = useMemo<RefStatsChildProps & PaginationProps>(() => ({
        ref_stats_loaded: stats.loaded,
        ref_stats_loading: stats.loading,
        ref_stats_error: stats.error,
        ref_stats_count: stats.data.length,
        ...pagination
    }), [stats.data.length, stats.error, stats.loaded, stats.loading, pagination]);

    return injectChild(data, children);
}

export default RefStats;
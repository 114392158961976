import {shallowEqual, useSelector} from "react-redux";
import {ReduxStore} from "@interfaces/store";

export function useLoginServer(id: string) {
    return useSelector(({ consumer: { projectInfo: { data: {loginServers}} } }: ReduxStore) =>
        loginServers.find(x => x.id === id), shallowEqual);
}

export function useLoginServers() {
    return useSelector(({ consumer: { projectInfo: { data: {loginServers}} } }: ReduxStore) => loginServers);
}

export function useGameServer(id: string) {
    return useSelector(({ consumer: { projectInfo: { data: {gameServers}} } }: ReduxStore) =>
        gameServers.find(x => x.id === id), shallowEqual);
}

export function useGameServers(loginId?: string) {
    return useSelector(({ consumer: { projectInfo: { data: {gameServers}} } }: ReduxStore) =>
        loginId ? gameServers.filter(x => x.login === loginId) : gameServers, shallowEqual);
}


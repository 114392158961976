import {FC, useState} from "react";
import {Flex} from "@components/falcon/common";
import classNames from "classnames";
import {useAdvanceTableContext} from "./context";
import {Button, Form, Pagination} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface AdvanceTableFooterProps {
    viewAllBtn?: boolean;
    navButtons?: boolean;
    paginationButtons?: boolean;
    paginationRound?: number;
    className?: string;
    rowInfo?: boolean;
    rowsPerPageSelection?: boolean;
    rowsPerPageOptions?: number[];
}

const AdvanceTableFooter: FC<AdvanceTableFooterProps> = ({
                                                             viewAllBtn,
                                                             navButtons,
                                                             paginationButtons,
                                                             paginationRound = 2,
                                                             className,
                                                             rowInfo,
                                                             rowsPerPageOptions = [5, 10, 20, 50],
                                                             rowsPerPageSelection
                                                         }) => {

    const {
        setPageSize,
        previousPage,
        nextPage,
        getCanNextPage,
        getCanPreviousPage,
        getState,
        getPrePaginationRowModel,
        getPaginationRowModel,
        getPageCount,
        setPageIndex,
        getRowCount
    } = useAdvanceTableContext();

    const {
        pagination: {pageSize, pageIndex}
    } = getState();
    const [perPage] = useState(pageSize);
    const [isAllVisible, setIsAllVisible] = useState(false);

    return (
        <Flex
            className={classNames(
                className,
                'align-items-center justify-content-between'
            )}>
            <Flex alignItems="center" className="fs-10">
                {rowInfo && (
                    <p className="mb-0">
                        <span className="d-none d-sm-inline-block me-2">
                          С {pageSize * pageIndex + 1} по{' '}
                            {pageSize * pageIndex + getPaginationRowModel().rows.length} из{' '}
                            {getRowCount()}
                        </span>
                        {viewAllBtn && (
                            <>
                                <span className="d-none d-sm-inline-block me-2">&mdash;</span>
                                <Button
                                    variant="link"
                                    size="sm"
                                    className="py-2 px-0 fw-semibold"
                                    onClick={() => {
                                        setIsAllVisible(!isAllVisible);
                                        setPageSize(
                                            isAllVisible
                                                ? perPage
                                                : getPrePaginationRowModel().rows.length
                                        );
                                    }}
                                >
                                    Показать {isAllVisible ? 'меньше' : 'все'}
                                    <FontAwesomeIcon
                                        icon="chevron-right"
                                        className="ms-1 fs-11"
                                    />
                                </Button>
                            </>
                        )}
                    </p>
                )}
                {rowsPerPageSelection && (
                    <>
                        <p className="mb-0 mx-2">На странице:</p>
                        <Form.Select
                            size="sm"
                            className="w-auto"
                            onChange={e => setPageSize(Number(e.target.value))}
                            defaultValue={pageSize}
                        >
                            {rowsPerPageOptions.map(value => (
                                <option value={value} key={value}>
                                    {value}
                                </option>
                            ))}
                        </Form.Select>
                    </>
                )}
            </Flex>
            {paginationButtons && (
                <Flex>
                    <Button
                        size="sm"
                        variant="falcon-default"
                        onClick={() => previousPage()}
                        className={classNames({ disabled: !getCanPreviousPage() })}
                    >
                        <FontAwesomeIcon icon="chevron-left" />
                    </Button>
                    <ul className="pagination mb-0 mx-2">
                        {Array.from(Array(getPageCount()).keys())
                            .filter(page => Math.abs(page - pageIndex) <= paginationRound)
                            .map((page) => (
                                <li key={page} className={classNames({ active: pageIndex === page })}>
                                    <Button
                                        size="sm"
                                        variant="falcon-default"
                                        className={classNames('page', {
                                            'me-2': page !== pageIndex + paginationRound && page !== getPageCount() - 1
                                        })}
                                        onClick={() => setPageIndex(page)}
                                    >
                                        {page + 1}
                                    </Button>
                                </li>
                            ))}
                    </ul>
                    <Button
                        size="sm"
                        variant="falcon-default"
                        onClick={() => nextPage()}
                        className={classNames({ disabled: !getCanNextPage() })}
                    >
                        <FontAwesomeIcon icon="chevron-right" />
                    </Button>
                </Flex>
            )}
            {navButtons && (
                <Flex>
                    <Button
                        size="sm"
                        variant={getCanPreviousPage() ? 'primary' : 'tertiary'}
                        onClick={() => previousPage()}
                        className={classNames({disabled: !getCanPreviousPage()})}
                    >
                        Предыдущая
                    </Button>
                    <Button
                        size="sm"
                        variant={getCanNextPage() ? 'primary' : 'tertiary'}
                        className={classNames('px-4 ms-2', {
                            disabled: !getCanNextPage()
                        })}
                        onClick={() => nextPage()}
                    >
                        Следующая
                    </Button>
                </Flex>
            )}
        </Flex>
    );
}

export default AdvanceTableFooter;
import { FC } from "react";
import { usePaymentCurrency } from "@hooks/selectors/panel.selectors";
import { useDonateBonusContext } from "./context";
import { Text } from '@constructor/components';

const DonateBonusThreshold: FC = (props) => {

    const bonus = useDonateBonusContext()
    const currency = usePaymentCurrency();

    if(!bonus){
        return null;
    }

    return (
        <Text text={`${bonus.threshold} ${currency}`} {...props} />
    )
    
}

export default DonateBonusThreshold;
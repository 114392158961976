import React, { useEffect, useMemo } from 'react';
import TagManager from 'react-gtm-module';

export const CurrentYear: React.FC = React.memo(() => {

    const year = useMemo(() => new Date().getFullYear(), []);

    return (
        <>{year}</>
    )
})

interface GoogleTagProps {
    tag?: any;
}

export const GoogleTag: React.FC<GoogleTagProps> = ({ tag }) => {

    useEffect(() => {
        TagManager.initialize(tag)
    }, [tag]);

    return null;
}
import { useLastAuth } from "@hooks/selectors/user.selectors";
import { FC } from "react";
import { Text } from '@components/constructor/components';
import { fromUtcDate } from "@services/helpers";

interface UserLastAuthProps {
    format?: string;
}

export const UserLastAuth: FC<UserLastAuthProps> = ({ format = 'lll', ...props }) => {
    const { log } = useLastAuth();

    if (!log) {
        return null;
    }

    return (
        <Text text={fromUtcDate(log.date).format(format)} {...props} />
    )
}

export default UserLastAuth;
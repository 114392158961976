import {forwardRef, PropsWithChildren} from "react";
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {ButtonProps} from "react-bootstrap/Button";

interface IconButtonProps {
    icon: IconProp;
    iconAlign?: 'left' | 'right' | 'middle';
    iconClassName?: string;
    transform?: string;
}

const IconButton = forwardRef<HTMLButtonElement, PropsWithChildren<IconButtonProps & ButtonProps>>(
    (
        { icon, iconAlign = 'left', iconClassName, transform, children, ...rest },
        ref
    ) => (
        <Button {...rest} ref={ref}>
            {iconAlign === 'right' && children}
            <FontAwesomeIcon
                icon={icon}
                className={classNames(iconClassName, {
                    'me-1': children && iconAlign === 'left',
                    'ms-1': children && iconAlign === 'right'
                })}
                transform={transform}
            />
            {iconAlign === 'left' || iconAlign === 'middle' ? children : false}
        </Button>
    )
);

export default IconButton;
import { injectChild } from "@components/constructor";
import { useShortNews } from "@hooks/components/user.components.hooks";
import {FC, PropsWithChildren, useMemo} from "react";

const NewsBlock: FC<PropsWithChildren> = ({ children }) => {

    const news = useShortNews();

    const data = useMemo(() => {
        return {
            news_count: news.data.length,
            news_loading: news.loading
        }
    }, [news.data.length, news.loading]);

    return injectChild(data, children);
}

export default NewsBlock;
import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";
import { KeyValueSet } from "@interfaces/helpers";
import { ErrorMessageInfo, FieldInfo, ReCAPTCHAInfo, SubmitInfo } from "@constructor/components/core/forms";
import { LoginServerSelectorInfo, GameServerSelectorInfo, AccountSelectorInfo, CharacterSelectorInfo } from "@constructor/components/core/forms/selectors";
import { AccountsListInfo, CharactersListInfo, GameServersListInfo, LoginServersListInfo } from "@constructor/components/core/forms/lists";
import { DropdownInfo, DropdownOptionInfo, DropdownValueInfo } from "@constructor/components/core/forms/dropdown";



const infos: KeyValueSet<ConstructionComponentInfo> = {
    FieldInfo,
    SubmitInfo,
    ReCAPTCHAInfo,
    ErrorMessageInfo,

    LoginServerSelectorInfo,
    GameServerSelectorInfo,
    AccountSelectorInfo,
    CharacterSelectorInfo,

    AccountsListInfo,
    CharactersListInfo,
    GameServersListInfo,
    LoginServersListInfo,

    DropdownInfo,
    DropdownOptionInfo,
    DropdownValueInfo
}

export default infos;
import React, {FC} from "react";
import {Card, Col, Form} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import {useFormikContext} from "formik";

const DatesBlock: FC = () => {

    const {
        values,
        setFieldValue,
        //errors,
    } = useFormikContext<{beginTime: Date, endTime: Date}>();

    return (
        <Card className="mb-3">
            <Card.Header as="h6" className="bg-body-tertiary">
                Даты активности
            </Card.Header>
            <Card.Body>
                <Col md={12}>
                    <Form.Group>
                        <Form.Label>Время начала</Form.Label>
                    </Form.Group>
                    <ReactDatePicker
                        selected={values.beginTime}
                        id="endTime"
                        name="endTime"
                        onChange={date => setFieldValue('beginTime', date)}
                        showTimeInput
                        timeInputLabel="Время:"
                        dateFormat="dd.MM.yyyy HH:mm"
                        className="form-control"
                        placeholderText="Введите дату старта" />
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Label>Время завершения</Form.Label>
                    </Form.Group>
                    <ReactDatePicker
                        selected={values.endTime}
                        id="endTime"
                        name="endTime"
                        onChange={date => setFieldValue('endTime', date)}
                        showTimeInput
                        timeInputLabel="Время:"
                        dateFormat="dd.MM.yyyy HH:mm"
                        className="form-control"
                        placeholderText="Введите дату завершения" />
                </Col>
            </Card.Body>
        </Card>
    )
}

export default DatesBlock;
import {useState, useCallback, useMemo, FC} from 'react';
import { useHistory } from 'react-router-dom';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import { UploadFileInfo } from '@interfaces/panel/files';
import { toastr } from 'react-redux-toastr';
import { FileUploader } from '@services/files/fileUploader';
import ConsumerItemsService from '@api/consumer/consumerItemsService';
import {Col, Row} from "react-bootstrap";
import {FormIconBlock, FormPageFooter, FormPageHeader} from "@components/falcon/common/forms";
import {BaseInfoBlock, ServersBlock} from "@components/consumer/items/forms";

interface AddItemFormValues {
    name: string;
    description: string;
    innerId: string;
    icon: File | null;
    servers: string[];
}

const AddItemForm: FC = () => {

    const history = useHistory();
    const [uploading, setUploading] = useState(false);
    const [uploadState, setUploadState] = useState(0);

    const onProgress = useCallback((event: ProgressEvent<EventTarget>) => {
        setUploadState(event.loaded / event.total * 100);
    }, [setUploadState]);

    const validationSchema = useMemo(() => Yup.object().shape({
        name: Yup.string()
            .max(256)
            .required(),
        description: Yup.string()
            .max(10000),
        innerId: Yup.string()
            .required(),
        servers: Yup.array()
    }), []);

    const initialValues: AddItemFormValues = {
        name: '',
        description: '',
        innerId: '',
        icon: null,
        servers: []
    }

    const onSubmit = useCallback(async (values: AddItemFormValues) => {
        const api = new ConsumerItemsService();

        const result = await api.putItem({
            name: values.name,
            description: values.description,
            innerId: values.innerId,
            uploadIcon: Boolean(values.icon),
            servers: values.servers
        });

        if (result.success) {
            const data = result.data;
            if (values.icon) {
                if (data.upload) {
                    const upload: UploadFileInfo = data.upload;
                    const file = new FileUploader(upload);
                    file.onProgress = onProgress;
                    try {
                        setUploading(true);
                        await file.upload(values.icon);
                        toastr.success("Создание предмета", "Предмет успешно добавлен.");
                    }
                    catch (e) {
                        toastr.warning("Создание предмета", "Предмет добавлен, но не удалось загрузить изображение.");
                        console.log(e);
                    }
                    finally {
                        setUploading(false);
                        setUploadState(0);
                        history.replace(`/consumer/item/${data.id}/`);
                    }
                }
                else {
                    toastr.warning("Создание предмета", "Предмет добавлен, но не удалось загрузить изображение.");
                    history.replace(`/consumer/item/${data.id}/`);
                }
            }
            else {
                toastr.success("Создание предмета", "Предмет успешно добавлен.");
                history.replace(`/consumer/item/${data.id}/`);
            }
        }
        else {
            if (result.errorCode === 'BucketNotFound') {
                toastr.error("Редактирование категории", "Отсутствует хранилище файлов. Создайте его из панели администратора и повторите попытку.");
            }
            else {
                toastr.error("Редактирование категории", "Внутренняя ошибка");
            }
        }
    }, [history, onProgress]);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            <Form>
                <Row className="g-3">
                    <Col xs={12}>
                        <FormPageHeader title="Создание предмета" />
                    </Col>
                    <Col lg={8}>
                        <BaseInfoBlock />
                    </Col>
                    <Col lg={4} className="sticky-sidebar">
                        <FormIconBlock
                            title="Иконка предмета"
                            uploading={uploading}
                            uploadProgress={uploadState}
                        />
                        <ServersBlock />
                    </Col>
                    <Col>
                        <FormPageFooter cancelUrl={`/consumer/item/`} />
                    </Col>
                </Row>
            </Form>
        </Formik>
    )
}

export default AddItemForm;
import { usePagination } from "@hooks/components/constructor.hooks";
import { useAuthLogs } from "@hooks/userHooks";
import {FC, PropsWithChildren, useEffect, useMemo} from "react";
import { PaginationProps } from "@constructor/components/core/pagination";
import { injectChild } from "@components/constructor";
import { useDispatch } from "react-redux";
import { userActions } from "@store/user";

interface AuthLogsProps {
    auth_logs_per_page?: number;
}

interface AuthLogsChildProps {
    auth_logs_count: number;
    auth_logs_loading: boolean;
}

const AuthLogs: FC<PropsWithChildren<AuthLogsProps>> = ({ auth_logs_per_page = 10, children }) => {

    const dispatch = useDispatch();
    const { loading, data: logs } = useAuthLogs();
    const pagination = usePagination(auth_logs_per_page, logs.length);

    useEffect(() => {
        dispatch(userActions.loadAuthLogs());
    }, [dispatch]);

    const data = useMemo<AuthLogsChildProps & PaginationProps>(() => {
        return {
            auth_logs_count: logs.length,
            auth_logs_loading: loading,
            ...pagination
        }
    }, [loading, logs.length, pagination]);

    return injectChild(data, children);
}

export default AuthLogs;
import { ConsumerBonusCodesStore } from '@interfaces/consumer/store';
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { ConsumerBonusCodesList } from '@interfaces/consumer/bonusCodes';
import { ConsumerBonusCodesListModel } from '@models/consumer/bonusCodes';
import ConsumerBonusService from '@api/consumer/consumerBonusService';

const slice = createSlice({
    initialState: {
        count: 0,
        page: 0,
        codes: {
            loaded: false,
            loading: false,
            error: null,
            data: []
        }
    } as ConsumerBonusCodesStore,
    name: 'consumerBonusesStore',
    reducers: {
        setLoading: (state) => {
            state.codes = {
                loading: true,
                loaded: false,
                error: null,
                data: []
            }
        },
        setError: (state, action) => {
            state.codes = {
                loaded: false,
                loading: false,
                error: action.payload,
                data: []
            }
        },
        setCodes: (state, action: PayloadAction<ConsumerBonusCodesList>) => {
            const { codes, count, page } = action.payload;
            state.codes = {
                error: null,
                loaded: true,
                loading: false,
                data: codes
            };
            state.count = count;
            state.page = page
        }
    }
})

export const { reducer: consumerBonusCodesReducer } = slice;

const {
    setCodes,
    setError,
    setLoading
} = slice.actions;

export const loadBonusCodes = (model: ConsumerBonusCodesListModel) => async (dispatch: Dispatch) => {
    dispatch(setLoading());
    try {
        const api = new ConsumerBonusService();
        const result = await api.fetchConsumerBonusCodes(model);

        if(result.ok && result.result) {
            dispatch(setCodes(result.result));
        }
        else {
            dispatch(setError(result));
        }
        return result;
    }
    catch(e) {
        dispatch(setError(e));
    }
}
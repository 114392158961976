import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Spinner } from 'reactstrap';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { ReduxStore } from '@interfaces/store';
import withPage from '../../../hoc/withPage';
import { ConsumerUserInfo } from '../../../consumer/users';
import { useConsumerProjectInfo, useBreadcrumbs } from '@services/hooks';
import { consumerActions } from '@store/consumer';

const ConsumerUserPage: React.FC = () => {

    const user = useSelector((state: ReduxStore) => state.consumer.users.current.data);
    const [rolesLoaded, rolesLoading, rolesError] = useSelector(({ consumer: { users: { allowedRoles } } }: ReduxStore) => [allowedRoles.loaded, allowedRoles.loading, allowedRoles.error], shallowEqual);
    const { loading: infoLoading, loaded: infoLoaded } = useConsumerProjectInfo();
    const loading = useSelector((state: ReduxStore) => state.consumer.users.current.loading) || rolesLoading || infoLoading;
    const loaded = rolesLoaded && infoLoaded;
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (id) {
            dispatch(consumerActions.loadUser(id));
        }

    }, [dispatch, id]);

    useEffect(() => {
        if (!rolesLoaded && !rolesLoading && !rolesError) {
            dispatch(consumerActions.loadRoles());
        }
    }, [dispatch, rolesLoading, rolesLoaded, rolesError]);

    useBreadcrumbs('consumer.users.current.name', true, user?.email);

    if (!user || !loaded) {
        return (
            <Card body className="text-center">
                {loading ? <Spinner /> : 'Пользователь не найден'}
            </Card>
        )
    }

    return (
        <ConsumerUserInfo user={user} />
    )
}

export default withPage(ConsumerUserPage);
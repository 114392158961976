import {ForwardedRef, forwardRef, MutableRefObject, useEffect, useRef} from "react";
import { Form } from 'react-bootstrap';
import classNames from "classnames";

interface IndeterminateCheckboxProps {
    indeterminate: boolean;
    className?: string;
}

const IndeterminateCheckbox = forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(
    ({ indeterminate, className, ...rest }, ref) => {
        const defaultRef = useRef<HTMLInputElement>(null);

        const resolvedRef: ForwardedRef<HTMLInputElement> | MutableRefObject<HTMLInputElement> = ref || defaultRef;

        useEffect(() => {

            if(typeof resolvedRef === 'function') {
                //Я хер знает почему он решил, что resolvedRef может быть функцией
            }
            else if(resolvedRef?.current) {
                resolvedRef.current.indeterminate = indeterminate;
            }


        }, [resolvedRef, indeterminate]);

        return (
            <Form.Check
                type="checkbox"
        className={classNames(
            'form-check mb-0 d-flex align-items-center',
            className
    )}
    >
        <Form.Check.Input
            type="checkbox"
        className="mt-0"
        ref={resolvedRef}
        {...rest}
        />
        </Form.Check>
    );
    }
);

export default IndeterminateCheckbox;
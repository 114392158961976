import React, {FC} from "react";
import {Card, Col, Form, Row} from "react-bootstrap";
import {FormikRowCheckbox, FormikRowInput} from "@components/panel/formik";
import {Field, useFormikContext} from "formik";
import {useRole} from "@services/hooks";
import ReactQuill from "../../../panel/reactQuill";

const BaseInfoBlock: FC = () => {

    const canEdit = useRole('consumer.shortnews.edit');

    const {
        values,
        setFieldValue,
        errors
    } = useFormikContext<{text: string}>();

    return (
        <Card className="mb-3">
            <Card.Header as="h6" className="bg-body-tertiary">
                Новость
            </Card.Header>
            <Card.Body>
                <Field
                    label='Заголовок'
                    type='text'
                    name='title'
                    placeholder='Заголовок новости'
                    disabled={!canEdit}
                    component={FormikRowInput}
                />
                <Form.Group as={Row} className="mb-3">
                    <Form.Label
                        column
                        htmlFor='description'
                        sm={3}>Описание</Form.Label>
                    <Col sm={9}>
                        <ReactQuill
                            defaultValue={values.text}
                            onChange={value => setFieldValue('text', value)} />
                        <Form.Control.Feedback>{errors.text}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Field
                    label='Активно'
                    type="switch"
                    id="isActive"
                    name="isActive"
                    disabled={!canEdit}
                    component={FormikRowCheckbox}
                />
            </Card.Body>
        </Card>
    )
}

export default BaseInfoBlock;
import {FC} from "react";
import {useSupportMessageContext, useSupportTopicContext} from "@components/consumer/support/context";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ChatMessageOptions: FC = () => {

    const {
        deleteMessage,
        setEditableMessageId
    } = useSupportTopicContext();

    const {
        id: messageId,
        task: topicId,
        incoming,
    } = useSupportMessageContext() || {};

    return (
        <ul className="hover-actions position-relative list-inline mb-0 text-400 mx-2">
            {!incoming && (
                <li className="list-inline-item cursor-pointer chat-option-hover">
                    <OverlayTrigger
                        overlay={
                            <Tooltip style={{position: 'fixed'}}>Редактировать</Tooltip>
                        }
                    >
                        <div onClick={() => setEditableMessageId(messageId)}>
                            <FontAwesomeIcon icon="edit" className="d-inline-block"/>
                        </div>
                    </OverlayTrigger>
                </li>
            )}
            <li className="list-inline-item cursor-pointer chat-option-hover">
                <OverlayTrigger
                    overlay={
                        <Tooltip style={{position: 'fixed'}}>Удалить</Tooltip>
                    }
                >
                    <div onClick={() => deleteMessage(topicId, messageId)}>
                        <FontAwesomeIcon icon="trash-alt" className="d-inline-block"/>
                    </div>
                </OverlayTrigger>
            </li>
        </ul>
    )
}

export default ChatMessageOptions;
import React, {FC, useCallback} from "react";
import {Card, Col, Form, Row} from "react-bootstrap";
import {Field, useFormikContext} from "formik";
import {FormikAsyncSelect, FormikRowCheckbox, FormikRowInput} from "@components/panel/formik";
import ReactQuill from "@components/panel/reactQuill";
import {FormFeedback} from "reactstrap";
import ConsumerMarketService from "@api/consumer/consumerMarketService";

interface BaseBlockFormProps {
    editForm?: boolean;
}

const BaseInfoBlock: FC<BaseBlockFormProps> = ({editForm}) => {

    const {
        values,
        setFieldValue,
        errors
    } = useFormikContext<{description: string}>();

    const searchCategories = useCallback((text: string) => {
        const api = new ConsumerMarketService();

        return api.searchCategories({
            name: text
        }).then(res => {
            if (res.success) {
                return res.data.map(category => {
                    return {
                        value: category.id,
                        label: category.name
                    }
                })
            }
            return [];
        });
    }, []);

    return (
        <Card className="mb-3">
            <Card.Header as="h6" className="bg-body-tertiary">
                Основное
            </Card.Header>
            <Card.Body>
                <Field
                    label='Название'
                    type='text'
                    name='name'
                    placeholder='Название позиции'
                    component={FormikRowInput}
                />
                <Form.Group as={Row} className="mb-3">
                    <Form.Label
                        column={true}
                        htmlFor='description'
                        sm={3}>Описание</Form.Label>
                    <Col sm={9}>
                        <ReactQuill
                            defaultValue={values.description}
                            onChange={value => setFieldValue('description', value)} />
                        <FormFeedback>{errors.description}</FormFeedback>
                    </Col>
                </Form.Group>
                {editForm && (
                    <>
                        <Field
                            label='Активно'
                            id='new-bonus-active'
                            name='isActive'
                            type='switch'
                            component={FormikRowCheckbox}
                        />
                        <Field
                            label="Категории"
                            name="categories"
                            isMulti
                            loadOptions={searchCategories}
                            row
                            component={FormikAsyncSelect}
                        />
                    </>
                )}
            </Card.Body>
        </Card>
    )
}

export default BaseInfoBlock;
import { injectChild } from "@components/constructor";
import { useItem } from "@hooks/selectors/user/item.selectors";
import {FC, PropsWithChildren, useMemo} from "react";
import { ItemProvider } from "./context";

interface ItemProps {
    item_id: string;
}

const Item: FC<PropsWithChildren<ItemProps>> = ({ item_id, children }) => {

    const item = useItem(item_id);

    const data = useMemo(() => {
        return {
            item_loaded: item.loaded,
            item_loading: item.loading,
            item_error: Boolean(item.error),
            item_has_icon: Boolean(item.iconId)
        }
    }, [item.error, item.iconId, item.loaded, item.loading]);

    return (
        <ItemProvider value={item}>
            {injectChild(data, children)}
        </ItemProvider>
    )
}

export default Item;
import UserService from "@api/userService";
import { BonusCodeType } from "@interfaces/consumer/bonusCodes";
import { ICodeTypeBalance } from "@interfaces/user/bonusCodes";
import { userActions } from "@store/user";
import { Form, Formik, FormikHelpers } from "formik";
import {FC, PropsWithChildren, useCallback, useMemo} from "react";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import * as Yup from 'yup';

interface BonusCodeUseFormValues {
    code: string;
}

const BonusCodeUseForm: FC<PropsWithChildren> = ({ children, ...props }) => {

    const dispatch = useDispatch();
    const validate = useMemo(() => Yup.object().shape({
        code: Yup.string()
            .required('Обязательно')
    }), []);

    const onSubmit = useCallback(async ({ code }: BonusCodeUseFormValues, actions: FormikHelpers<BonusCodeUseFormValues>) => {

        const api = new UserService();
        const result = await api.activateUserBonusCode(code);

        if (result.ok) {
            const code = result.data;
            if(code.type === BonusCodeType.Balance) {
                toastr.success('Активация бонусного кода', `Вы успешно активировали код на ${(code.valueInfo as ICodeTypeBalance).value} ₽.`);
            }
            else {
                toastr.success('Активация бонусного кода', `Вы успешно активировали код`);
            }
            
            dispatch(userActions.loadBalance());
            dispatch(userActions.loadUsedBonusCodes());
        }
        else {
            if (result.status === 404) {
                toastr.error('Активация бонусного кода', 'Такого кода не существует.');
            }
            else if (result.errorCode === 'CodeNotFound') {
                toastr.error('Активация бонусного кода', 'Такого кода не существует.');
            }
            else if (result.errorCode === 'AlreadyUsed') {
                toastr.error('Активация бонусного кода', 'Данный код уже был активирован.');
            }
            else {
                toastr.error('Активация бонусного кода', 'Что-то пошло не так.');
            }
        }
    }, [dispatch]);

    const initial: BonusCodeUseFormValues = {
        code: ''
    };

    return (
        <Formik
            initialValues={initial}
            validationSchema={validate}
            onSubmit={onSubmit}>
            <Form {...props}>
                {children}
            </Form>
        </Formik>
    )
}

export default BonusCodeUseForm;
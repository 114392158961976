import { RefsList } from "@components/consumer/refs";
import withPage from "@components/hoc/withPage";
import { FC } from "react";

const RefsListPage: FC = () => {

    return (
        <RefsList />
    )
}

export default withPage(RefsListPage);
import React, {FC} from "react";
import {Col, Form, InputGroup} from "react-bootstrap";
import {FieldProps} from "formik";

interface FormikInputProps extends FieldProps {
    description?: string;
    label?: string;
    prefix?: string;
    suffix?: string;
}

const FormikInput: FC<FormikInputProps> = (prop) => {
    const {
        field,
        form: { touched, errors },
        label,
        description,
        suffix, prefix,
        ...props
    } = prop;

    return (
        <Col md={12}>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
            </Form.Group>
            {prefix || suffix ? (
                <InputGroup>
                    {prefix && <InputGroup.Text>{prefix}</InputGroup.Text>}
                    <Form.Control {...props} {...field} isInvalid={Boolean(touched[field.name] && errors[field.name])} />
                    {suffix && <InputGroup.Text>{suffix}</InputGroup.Text>}
                </InputGroup>
            ) : (
                <Form.Control {...props} {...field} isInvalid={Boolean(touched[field.name] && errors[field.name])} />
            )}

            <Form.Control.Feedback type="invalid">{errors[field.name]?.toString()}</Form.Control.Feedback>
            {description && <Form.Text>{description}</Form.Text>}
        </Col>
    )
}

export default FormikInput;
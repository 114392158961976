import { PaginationProps } from "@constructor/components/core/pagination";
import { useRefStats } from "@hooks/selectors/user/refs.selectors";
import { IRefStat } from "@interfaces/user/refs";
import {FC, PropsWithChildren, useMemo} from "react";
import { RefStatProvider } from "./context";
import { injectContextArray } from "@components/constructor";
import {InjectContextType} from "@interfaces/constructor/constructor";

interface RefStatsListChildProps {
    stat_id: string;
    stat_index: number;
}

const RefStatsList: FC<PropsWithChildren<PaginationProps>> = ({ pagination_current_page = 0, pagination_per_page = 10, children }) => {

    const { data: stats } = useRefStats();
    const page = useMemo(() => stats.slice(pagination_current_page * pagination_per_page, (pagination_current_page + 1) * pagination_per_page), [stats, pagination_current_page, pagination_per_page]);

    const data = useMemo<InjectContextType<IRefStat, RefStatsListChildProps>[]>(() => page.map((item, idx) => {
        return {
            key: item.id,
            value: item,
            childData: {
                stat_id: item.id,
                stat_index: pagination_current_page * pagination_per_page + 1 + idx
            }
        }
    }), [page, pagination_current_page, pagination_per_page]);

    return injectContextArray(data, RefStatProvider, children);
}

export default RefStatsList;
import { createRoot } from 'react-dom/client';
import App from './components/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const isDev = false;

export const apiUrl = isDev ? 'https://localhost:5106' : 'https://api.gameweb.solutions';
export const signalUrl = isDev ? 'http://localhost:5110' : 'https://ws.gameweb.solutions';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();

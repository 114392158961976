import ConsumerItemsService from "@api/consumer/consumerItemsService";
import { FieldProps } from "formik";
import { FC, useCallback, useEffect, useState } from "react";
import AsyncSelect from 'react-select/async';

export const FormikItemInput: FC<FieldProps & {disabled?: boolean}> = ({ field: { name, value }, form: { setFieldValue }, disabled }) => (
    <ItemInput
        name={name}
        value={value}
        setFieldValue={setFieldValue}
        disabled={disabled}
    />
)

interface ItemInputProps {
    name: string;
    value: any;
    disabled?: boolean;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const ItemInput: FC<ItemInputProps> = ({ name, value, disabled, setFieldValue }) => {

    const [selectValue, setValue] = useState<any>({
        value
    });

    const searchItems = useCallback(async (text: string) => {
        const api = new ConsumerItemsService();

        const items = await api.searchItem({
            name: text
        });

        if (items.success) {
            return items.data.map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            })
        }
        return [];
    }, []);

    useEffect(() => {
        const load = async () => {
            const api = new ConsumerItemsService();
            const result = await api.getItem(value);

            if (result.success) {
                setValue({
                    value: result.data.id,
                    label: result.data.name
                })
            }
        }

        if (value) {
            load();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AsyncSelect
            isDisabled={disabled}
            name={name}
            className="react-select-container"
            classNamePrefix="react-select"
            cacheOptions
            defaultOptions
            loadOptions={searchItems}
            onChange={(option) => {
                setFieldValue(name, option?.value);
                setValue(option);
            }}
            value={selectValue}
        />
    )
}

export default ItemInput;
import { ReduxStore } from "@interfaces/store";
import { useSelector } from "react-redux";

export function useDonateBonuses() {
    return useSelector(({ user }: ReduxStore) => user.donateBonuses);
}

export function useDonateBonus(id: string) {
    return useSelector(({ user }: ReduxStore) => user.donateBonuses.find(b => b.id === id));
}

export function useDonateLogs() {
    return useSelector((state: ReduxStore) => state.user.logs.donate)
}
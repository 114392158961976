import { ItemChangeReason } from "@interfaces/user/items";
import { KeyValueSet } from "../helpers";

export interface ConsumerUser {
    id: string;
    email: string;
    userName: string;
    publicName: string;
    avatarId?: string;
    balance: number;
    createTime: string;
}

export interface ConsumerUserFull {
    id: string;
    email: string;
    userName: string;
    publicName: string;
    avatarUrl?: string;
    avatarId?: string;
    balance: number;
    createTime: string;
    roles: string[];
    donateLogs: ConsumerUserDonateLog[];
    balanceLogs: ConsumerUserBalanceLog[];
    authLogs: IUserSecurityLog[];
    accounts: ConsumerUserAccount[];
}

export interface ConsumerUserLogs {
    donateLogs: ConsumerUserDonateLog[];
    balanceLogs: ConsumerUserBalanceLog[];
}

export interface ConsumerUserAccount {
    id: string;
    innerId: string;
    name: string;
    createTime: string;
    loginId: string;
}

export interface ConsumerUserDonateLog {
    id: string;
    date: string;
    summ: string;
}

export interface IUserTotalDonate {
    totalDonate: number;
}

export interface IUserSecurityLog {
    id: string;
    date: string;
    ip: string;
    type: ESecurityLogType;
}

export enum ESecurityLogType {
    SignIn,
    SignUp,
    ChangePassword,
    ResetPassword
}

export interface ConsumerUserBalanceLog {
    id: string;
    date: string;
    reasonId: string;
    reasonType: ConsumerUserBalanceLogReasonType;
    reasonText: string;
    reasonParams: KeyValueSet<string>;
    summ: number;
}

export enum ConsumerUserBalanceLogReasonType {
    Payment,
    BonusCode,
    AdminCharge,
    Shop,
    GameCharge,
    CustomQuery,
    BonusCodeBuy,
    Rollback,
    Daily,
    Api,
    Goal,
    Ref,
    RefPercent
}

export interface ConsumerUserList {
    page: number;
    count: number;
    users: ConsumerUser[];
}

export interface IInventoryItem {
    id: string;
    itemId: string;
    itemName: string;
    iconId?: string;
    userId: string;
    count: string;
    createTime: string;
    updateTime: string;
    suspended: boolean;
}

export interface IInventoryLog {
    id: string;
    date: string;
    itemId: string;
    inventoryId: string;
    count: number;
    reason: ItemChangeReason
}
import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";
import GoalDescription from "./goalDescription";
import GoalName from "./goalName";
import Goals from "./goals";
import GoalsList from "./goalsList";

const GoalsInfo: ConstructionComponentInfo = {
    component: Goals,
    addedChildProps: [
        'goals_loaded',
        'goals_loading',
        'goals_error',
        'goals_count',
        'pagination_current_page',
        'pagination_total_pages',
        'pagination_per_page',
        'pagination_set_page',
        'pagination_next_page',
        'pagination_prev_page'
    ],
    props: [
        'goals_per_page'
    ]
}

const GoalsListInfo: ConstructionComponentInfo = {
    component: GoalsList,
    addedChildProps: [
        'goal_id',
        'goal_index',
        'goal_reached'
    ],
    injectChild: [
        'pagination_current_page',
        'pagination_per_page'
    ]
}

const GoalNameInfo: ConstructionComponentInfo = {
    component: GoalName,
    requiredParent: [
        'GoalsList'
    ]
}

const GoalDescriptionInfo: ConstructionComponentInfo = {
    component: GoalDescription,
    requiredParent: [
        'GoalsList'
    ]
}

export {
    GoalsInfo,
    GoalsListInfo,
    GoalNameInfo,
    GoalDescriptionInfo
}
import {FC} from "react";
import {Doughnut} from 'react-chartjs-2';
import {ChartData} from 'chart.js';
import {getColor} from "../../../../helpers/utils";
import {chartJsDefaultTooltip} from "../../../../helpers/chartjs-utils";

interface HalfDoughnutChartProps {
    items: HalfDoughnutChartItem[];
    color: string;
}

interface HalfDoughnutChartItem {
    label: string;
    value: number;
}

const HalfDoughnutChart: FC<HalfDoughnutChartProps> = ({color, items}) => {

    const data: ChartData<"doughnut", number[], string> = {
        labels: items.map(item => item.label),
        datasets: [
            {
                data: items.map(item => item.value),
                backgroundColor: [getColor(color), getColor('gray-300')],
                borderColor: [getColor(color), getColor('gray-300')]
            }

        ]
    }

    const options = {
        rotation: -90,
        circumference: 180,
        cutoutPercentage: 80,
        plugins: {
            legend: {
                display: false
            },
            tooltip: chartJsDefaultTooltip(getColor)
        }
    }

    return (
        <Doughnut data={data} options={options} width={112} />
    )
}

export default HalfDoughnutChart;
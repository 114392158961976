import * as React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'reactstrap';

interface SidebarItemProps {
    name: string;
    badgeColor?: string;
    badgeText?: string;
    icon?: IconProp;
    exact?: boolean;
    to: string;
}

type Props = SidebarItemProps;

const SidebarItem: React.FC<Props> = (props) => {

    const { name, badgeColor, badgeText, icon, to, exact } = props;
    const location = useLocation();

    const getSidebarItemClass = (path: string) => {
        return (exact ? location.pathname === path : location.pathname.startsWith(path)) ? "active" : "";
    };

    return (
        <li className={"sidebar-item " + getSidebarItemClass(to)}>
            <NavLink to={to} className="sidebar-link" activeClassName="active">
                {icon ? (
                    <React.Fragment>
                        <FontAwesomeIcon
                            icon={icon}
                            fixedWidth
                            className="align-middle mr-2"
                        />{" "}
                        <span className="align-middle">{name}</span>
                    </React.Fragment>
                ) : (
                        name
                    )}{" "}
                {badgeColor && badgeText ? (
                    <Badge color={badgeColor} size={18} pill className="sidebar-badge">
                        {badgeText}
                    </Badge>
                ) : null}
            </NavLink>
        </li>
    );
}

export default SidebarItem;
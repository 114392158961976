import ItemService from "@api/user/itemsService";
import { ILoadable } from "@interfaces/helpers";
import { IItem } from "@interfaces/user/items";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { panelActions } from "@store/panelStore";

export type IStoredItem = IItem & ILoadable;

const slice = createSlice({
    name: 'itemsStore',
    initialState: [] as IStoredItem[],
    reducers: {
        setItems: (state, {payload: items}: PayloadAction<IItem[]>) => {
            items.forEach(item => {
                const idx = state.findIndex(i => i.id === item.id);
                if(idx >= 0) {
                    state[idx] = {
                        ...item,
                        loaded: true,
                        loading: false,
                        error: null
                    };
                }
                else {
                    state.push({
                        ...item,
                        loaded: true,
                        loading: false,
                        error: null
                    });
                }
            })
        },
        setLoading: (state, {payload: ids}: PayloadAction<string[]>) => {
            ids.forEach(id => {
                const idx = state.findIndex(i => i.id === id);
                if(idx >= 0) {
                    state[idx] = {
                        ...state[idx],
                        loading: true,
                        error: null
                    };
                }
                else {
                    state.push({
                        id,
                        name: '',
                        servers: [],
                        loaded: false,
                        loading: true,
                        error: null
                    })
                }
            });
        },
        setErrors: (state, {payload: ids}: PayloadAction<string[]>) => {
            ids.forEach(id => {
                const idx = state.findIndex(i => i.id === id);
                if(idx >= 0) {
                    state[idx] = {
                        ...state[idx],
                        loading: false,
                        error: true
                    };
                }
                else {
                    state.push({
                        id,
                        name: '',
                        servers: [],
                        loaded: false,
                        loading: false,
                        error: true
                    })
                }
            });
        }
    }
});

export const { reducer: itemsReducer } = slice;

const {
    setItems,
    setErrors,
    setLoading
} = slice.actions;

export const itemActions = {
    loadItems: (ids: string[]) => async (dispatch: any) => {
        try {
            const api = new ItemService();
            dispatch(setLoading(ids));
            const result = await api.getItems(ids);

            if(result.success) {
                const icons = result.data.filter(i => i.iconId).map(i => i.iconId as string);
                dispatch(panelActions.loadFiles(icons));

                dispatch(setItems(result.data));
                const loadedItems = result.data.map(i => i.id);
                dispatch(setErrors(ids.filter(id => !loadedItems.includes(id))));
            }
            else {
                dispatch(setErrors(ids));
            }
        } catch (error) {
            
        }
    },
    setItems
}
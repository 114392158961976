import UserService from "@api/userService";
import { useGameServerAuto, useLoginServerAuto } from "@hooks/selectors/game.selectors";
import { useBalance } from "@hooks/selectors/user.selectors";
import { UserChargeAccount } from "@models/user/payments";
import { userActions } from "@store/user";
import { Form, Formik, FormikHelpers } from "formik";
import {FC, PropsWithChildren} from "react";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { AccountIdProps } from ".";
import { ModalProps } from "../../modals";
import { useAccountContext } from "./context";
import * as Yup from 'yup';
import { GameIdProps } from "@constructor/components/core/servers/game";
import { SelectorsFormsValues } from "../forms/selectors";
import { useDefaultSelectorFormValues } from "@hooks/helperHooks";
import { LoginIdProps } from "../../loginServer";

export interface ChargeAccountFormValues extends SelectorsFormsValues {
    count: number;
}

const ChargeAccountForm: FC<PropsWithChildren<LoginIdProps & GameIdProps & AccountIdProps & ModalProps>> = ({ login_id, game_id, account_id, children, modalToggle, ...props }) => {

    const account = useAccountContext();
    const loginServer = useLoginServerAuto(login_id);
    const gameServer = useGameServerAuto(game_id);
    const dispatch = useDispatch();
    const balance = useBalance();
    const { selected_account, selected_login_server, selected_game_server, ...selectors } = useDefaultSelectorFormValues();

    if (!account && !account_id) {
        return null;
    }

    const onSubmit = async (values: ChargeAccountFormValues, actions: FormikHelpers<ChargeAccountFormValues>) => {

        const request: UserChargeAccount = {
            account: values.selected_account,
            gameServer: values.selected_game_server,
            count: values.count
        }

        const api = new UserService();
        const result = await api.chargeGameAccount(request);

        if (result.ok) {
            dispatch(userActions.loadBalance());
            toastr.success('Пополнение баланса', 'Баланс аккаунта успешно пополнен.');
            if (modalToggle) {
                modalToggle();
            }
        }
        else {
            if (result.errorCode === 'NotEnough') {
                toastr.error('Пополнение баланса', 'Недостаточно средств.');
            }
            else if (result.errorCode === 'DataNotFound') {
                toastr.error('Пополнение баланса', 'Ошибка пополнения. Обновите страницу и попробуйте еще раз.');
            }
            else if (result.errorCode === 'CantCharge') {
                toastr.error('Пополнение баланса', 'Ошибка пополнения. Обновите страницу и попробуйте еще раз.');
            }
            else if (result.errorCode === 'QueryError') {
                toastr.error('Пополнение баланса', 'Ошибка пополнения. Обратитесь к администратору.');
            }
            else if (result.errorCode === 'ConcurrencyError') {
                toastr.error('Пополнение баланса', 'Ошибка пополнения. Обновите страницу и попробуйте еще раз.');
            }
            else if (result.errorCode === 'RechargeError') {
                toastr.error('Пополнение баланса', 'Ошибка пополнения. Не удалось пополнить баланс и вернуть списанные средства. Срочно обратитесь к администратору.');
            }
            else {
                toastr.error('Пополнение баланса', 'Что-то пошло не так');
            }
        }
    }

    const initial: ChargeAccountFormValues = {
        count: Math.min(100, balance),
        ...selectors,
        selected_account: account?.id || account_id || selected_account || '',
        selected_game_server: gameServer?.id || selected_game_server || '',
        selected_login_server: loginServer?.id || selected_login_server || ''
    }

    const validate = Yup.object().shape({
        count: Yup.number()
            .min(1, "Не менее 1")
            .max(balance, "Недостаточно средств")
            .required("Обязательно"),
        selected_account: Yup.string()
            .required('Обязательно'),
    });

    return (
        <Formik
            validationSchema={validate}
            initialValues={initial}
            onSubmit={onSubmit}>
            <Form {...props}>
                {children}
            </Form>
        </Formik>
    )
}

export default ChargeAccountForm;
import {createContext, useContext} from "react";
import {LoginServer} from "@interfaces/game";

const LoginServerContext = createContext<LoginServer | undefined>(undefined as any);

export const LoginServerProvider = LoginServerContext.Provider;
export const LoginServerConsumer = LoginServerContext.Consumer;

export function useLoginServerContext(): LoginServer | undefined {
    return useContext(LoginServerContext);
}
import { fromUtcDate } from "@services/helpers";
import { FC } from "react";
import { useSupportMessageContext, useSupportMessageGroupContext } from "../context";
import { Text } from '@components/constructor/components';

interface SupportMessageTimeProps {
    format?: string;
}

const SupportMessageTime: FC<SupportMessageTimeProps> = ({format = 'lll', ...props}) => {

    const message = useSupportMessageContext();
    const group = useSupportMessageGroupContext();

    if (!message && !group) {
        return null;
    }

    const time = message?.createTime || group?.messages[0].createTime as string;

    return (
        <Text text={fromUtcDate(time).format(format)} {...props} />
    )
}

export default SupportMessageTime;
import { injectChild } from "@components/constructor";
import {FC, PropsWithChildren, ReactText, useCallback, useMemo} from "react";
import { useDropdownContext } from "./context";

interface DropdownOptionProps {
    value: ReactText;
}

interface DropdownOptionChildProps {
    dropdown_option_value: ReactText;
    dropdown_select_option: () => void;
}
const DropdownOption: FC<PropsWithChildren<DropdownOptionProps>> = ({ value, children }) => {

    const context = useDropdownContext();
    const selectOption = useCallback(() => context?.setValue(value), [context, value]);

    const data = useMemo<DropdownOptionChildProps>(() => {
        return {
            dropdown_option_value: value,
            dropdown_select_option: selectOption
        }
    }, [selectOption, value])

    return injectChild(data, children);
}

export default DropdownOption;
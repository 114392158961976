import {FC} from "react";
import {Card} from "react-bootstrap";
import {Field} from "formik";
import {FormikInput, FormikRowInput, FormikSelect} from "@components/panel/formik";

const DatesFormBlock: FC = () => (
    <Card className="mb-3">
        <Card.Header as="h6" className="bg-body-tertiary">
            Даты активности
        </Card.Header>
        <Card.Body>
            <Field
                name='startDate'
                label='Дата начала'
                type='date'
                component={FormikInput}
                description='Оставьте пустым, если не нужно' />
            <Field
                name='startTime'
                label='Время начала'
                type='time'
                component={FormikInput}
                description='Оставьте пустым, если не нужно' />
            <Field
                name='endDate'
                label='Дата окончания'
                type='date'
                component={FormikInput}
                description='Оставьте пустым, если не нужно' />
            <Field
                name='endTime'
                label='Время окончания'
                type='time'
                component={FormikInput}
                description='Оставьте пустым, если не нужно' />
            <Field
                name='daysOfWeek'
                label='Дни недели'
                type='select'
                id='new-bonus-days'
                description='Бонус будет работать по выбранным дням недели'
                component={FormikSelect}
                multiple >
                <option value={1}>Понедельник</option>
                <option value={2}>Вторник</option>
                <option value={3}>Среда</option>
                <option value={4}>Четверг</option>
                <option value={5}>Пятница</option>
                <option value={6}>Суббота</option>
                <option value={0}>Воскресенье</option>
            </Field>
        </Card.Body>
    </Card>
)

export default DatesFormBlock;
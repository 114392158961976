import {FC, useRef} from "react";
import {Navbar} from "react-bootstrap";
import classNames from "classnames";
import {navbarBreakPoint} from "@services/config";
import {Logo} from "@components/falcon/common";
import TopNavRightSideNavItem from "./topNavRightSideNavItem";

interface NavbarTopElementsProps {
    navbarPosition: 'top' | 'double-top' | 'vertical' | 'combo';
    handleBurgerMenu: () => any;
    navbarCollapsed: boolean;
}

const NavbarTopElements: FC<NavbarTopElementsProps> = ({navbarPosition, navbarCollapsed, handleBurgerMenu}) => {
    const burgerMenuRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <Navbar.Toggle
                ref={burgerMenuRef}
                className={classNames('toggle-icon-wrapper me-md-3 me-2', {
                    'd-lg-none':
                        navbarPosition === 'top' || navbarPosition === 'double-top',
                    [`d-${navbarBreakPoint}-none`]:
                    navbarPosition === 'vertical' || navbarPosition === 'combo'
                })}
                as="div"
            >
                <button
                    className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
                    onClick={handleBurgerMenu}
                    id="burgerMenu"
                >
                    <span className="navbar-toggle-icon">
                        <span className="toggle-line"/>
                    </span>
                </button>
            </Navbar.Toggle>

            <Logo at="navbar-top" textClass="text-primary" width={40} id="topLogo"/>

            <TopNavRightSideNavItem/>
        </>
    );
}

export default NavbarTopElements;
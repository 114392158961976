import { PanelLanguages } from "../../interfaces/panel";

export function storeLanguages(langs: PanelLanguages) {
    localStorage.setItem('panel.languages', JSON.stringify(langs));
}

export function loadLanguages() {
    const json = localStorage.getItem('panel.languages');
    if(json != null) {
        return JSON.parse(json) as PanelLanguages;
    }
    return null;
}
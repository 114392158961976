import { FieldProps } from 'formik';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import {Flex} from "@components/falcon/common";

interface FormikRowCheckboxProps extends FieldProps {
    description?: string;
    label?: string;
    id?: string;
    placeholder?: string;
    labelSize?: number;
}

const FormikRowCheckbox: React.FC<FormikRowCheckboxProps> = (prop) => {
    let {
        field: { ...fields },
        form: { touched, errors },
        description,
        label,
        id,
        placeholder,
        labelSize = 3,
        ...props
    } = prop;

    if (!id) {
        id = fields.name;
    }

    return (
        <Form.Group as={Row} className="mb-3">
            <Form.Label
                column
                htmlFor={id}
                className={"label-color"}
                sm={labelSize}>
                {label}
            </Form.Label>
            <Col sm={12-labelSize} className="d-flex">
                <Flex alignItems='center'>
                    <Form.Check
                        checked={fields.value}
                        id={id} label={placeholder}
                        {...props}
                        {...fields}
                        isInvalid={Boolean(touched[fields.name] && errors[fields.name])}
                    />
                    <Form.Control.Feedback type="invalid">{errors[fields.name]?.toString()}</Form.Control.Feedback>
                    {description && <Form.Text>{description}</Form.Text>}
                </Flex>
            </Col>
        </Form.Group>
    );
}
export default FormikRowCheckbox;
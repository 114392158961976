import {FC} from "react";
import { Text } from '@components/constructor/components';
import {useTaskMessage, useTopicAuto} from "@hooks/selectors/user/support.selectors";

interface SupportEditingMessageTextProps {
    editable_message?: string;
}

const SupportEditableMessageText: FC<SupportEditingMessageTextProps> = ({editable_message, ...props}) => {

    const topic = useTopicAuto();
    const message = useTaskMessage(topic?.id, editable_message);

    if(!message) {
        return null;
    }

    return (
        <Text text={message.message} {...props} />
    )
}

export default SupportEditableMessageText;
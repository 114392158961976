import { ConstructionComponentInfo } from "@interfaces/constructor/constructor"
import AccountCreateTime from "./accountCreateTime"
import AccountList from "./accountList"
import AccountName from "./accountName"
import ChangeAccountPasswordForm from "./changeAccountPasswordForm"
import ChargeAccountCount from "./chargeAccountCount"
import ChargeAccountForm from "./chargeAccountForm"
import CreateAccountForm from "./createAccountForm"

export interface AccountIdProps {
    account_id?: string;
}

const AccountListInfo: ConstructionComponentInfo = {
    component: AccountList
}

const AccountNameInfo: ConstructionComponentInfo = {
    component: AccountName,
    requiredParent: [
        'AccountList'
    ]
}

const AccountCreateTimeInfo: ConstructionComponentInfo = {
    component: AccountCreateTime,
    requiredParent: [
        'AccountList'
    ]
}

const CreateAccountFormInfo: ConstructionComponentInfo = {
    component: CreateAccountForm,
    injectChild: [
        'login_id',
        'modalToggle'
    ]
}

const ChangeAccountPasswordFormInfo: ConstructionComponentInfo = {
    component: ChangeAccountPasswordForm,
    injectChild: [
        'modalToggle'
    ]
}

const ChargeAccountFormInfo: ConstructionComponentInfo = {
    component: ChargeAccountForm,
    injectChild: [
        'modalToggle',
        'game_id',
        'login_id'
    ]
}

const ChargeAccountCountInfo: ConstructionComponentInfo = {
    component: ChargeAccountCount,
    requiredParent: [
        'ChargeAccountForm'
    ],
    injectChild: [
        'game_id',
        'login_id'
    ]
}

export {
    AccountCreateTimeInfo,
    AccountListInfo,
    AccountNameInfo,
    ChangeAccountPasswordFormInfo,
    ChargeAccountCountInfo,
    ChargeAccountFormInfo,
    CreateAccountFormInfo
}
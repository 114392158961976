import { GoalRewardType, IBalanceGoalReward } from "@interfaces/user/refs";
import { FC } from "react";
import { useGoalRewardContext } from "./context";
import { Text } from '@constructor/components';

const GoalRewardBalance: FC = (props) => {
    const reward = useGoalRewardContext();
    if (reward?.type !== GoalRewardType.Balance) {
        return null;
    }

    return (
        <Text text={(reward as IBalanceGoalReward).balance} {...props} />
    )
}

export default GoalRewardBalance;
import { ICPaymentBonus } from "@interfaces/consumer/payments";
import { EPaymentBonusType, EThresholdType } from "@interfaces/user/payments";
import {FC, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {ColumnDef} from "@tanstack/react-table";
import CPaymentsService from "@api/consumer/consumerPaymentsService";
import {useRemoteTable} from "@hooks/tableHooks";
import {Card, Form} from "react-bootstrap";
import {Flex, IconButton} from "@components/falcon/common";
import {AdvanceTable, AdvanceTableFooter, AdvanceTableSearchBox} from "@components/falcon/common/tables";
import AdvanceTableProvider, {useAdvanceTableContext} from "@components/falcon/common/tables/context";
import {useRole} from "@services/hooks";

const columns: ColumnDef<ICPaymentBonus>[] = [
    {
        accessorKey: 'name',
        header: 'Название',
        enableSorting: true,
        meta: {
            headerProps: { className: 'pe-1 text-900' },
            cellProps: { className: 'py-2' }
        },
        cell: ({row: {original}}) => (
            <Link to={`/consumer/payments/bonuses/${original.id}/`}>
                <strong>{original.name}</strong>
            </Link>
        )
    },
    {
        accessorKey: 'isActive',
        header: 'Активно',
        enableSorting: true,
        meta: {
            headerProps: { className: 'text-center text-900' },
            cellProps: { className: 'text-center' }
        },
        cell: ({row: {original: {id, isActive}}}) => (
            <Form.Check id={`cb-${id}`} checked={isActive} disabled />
        )
    },
    {
        accessorKey: 'startDate',
        header: 'С',
        enableSorting: false,
        meta: {
            headerProps: { className: 'text-900' }
        },
        cell: ({row: {original: {startDate, startTime}}}) => formatDate(startDate, startTime)
    },
    {
        accessorKey: 'endDate',
        header: 'По',
        enableSorting: false,
        meta: {
            headerProps: { className: 'text-900' }
        },
        cell: ({row: {original: {endDate, endTime}}}) => formatDate(endDate, endTime)
    },
    {
        accessorKey: 'thresholdType',
        header: 'Тип',
        enableSorting: false,
        meta: {
            headerProps: { className: 'text-900' }
        },
        cell: ({row: {original}}) => getTypeName(original.thresholdType)
    },
    {
        accessorKey: 'value',
        header: 'Сумма',
        enableSorting: false,
        meta: {
            headerProps: { className: 'text-end text-900' },
            cellProps: {
                className: 'text-end fs-9 fw-medium py-2'
            }
        },
        cell: ({row: {original}}) => getValue(original)
    }
]

const BonusList: FC = () => {

    const [api] = useState(() => new CPaymentsService());
    const table = useRemoteTable({
        name: 'consumer.payments.bonuses.list',
        columns,
        selection: true,
        selectionColumnWidth: 30,
        defaultState: {
            sorting: [{id: 'name', desc: false}]
        }
    }, api.fetchBonuses);

    return (
        <AdvanceTableProvider table={table}>
            <Card className="mb-3">
                <BonusListHeader />
                <Card.Body className="p-0">
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowInfo
                        rowsPerPageSelection
                        paginationButtons
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableProvider>
    )
}

const BonusListHeader: FC = () => {

    const canEdit = useRole('consumer.payments.bonuses.edit');
    const history = useHistory();

    const {
        getSelectedRowModel
    } = useAdvanceTableContext<ICPaymentBonus>();

    const anyRowsSelected = getSelectedRowModel().rows.length > 0;

    return (
        <Card.Header>
            <Flex className='align-items-center justify-content-between'>
                <Flex alignItems="center" className="fs-10">
                    <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">Бонусы за пополнение</h5>
                </Flex>
                <Flex>
                    {anyRowsSelected ? (
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="minus"
                            transform="shrink-3"
                            className='me-2'
                        >
                            <span className="d-none d-sm-inline-block ms-1">Удалить</span>
                        </IconButton>
                    ) : (
                        <>
                            {canEdit && (
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="plus"
                                    transform="shrink-3"
                                    className='me-2'
                                    onClick={() => history.push('/consumer/payments/bonuses/new/')}
                                >
                                    <span className="d-none d-sm-inline-block ms-1">Создать</span>
                                </IconButton>
                            )}
                            <Flex><AdvanceTableSearchBox/></Flex>
                        </>
                    )}
                </Flex>
            </Flex>
        </Card.Header>
    )
}

function getValue(bonus: ICPaymentBonus) {
    if (bonus.type === EPaymentBonusType.Fixed) {
        return `${bonus.value} ₽`;
    }
    else {
        return `+ ${bonus.value}%`;
    }
}

function getTypeName(type: EThresholdType) {
    switch (type) {
        case EThresholdType.OneTime:
            return 'Единоразово';
        case EThresholdType.Total:
            return 'Накопительный';
        case EThresholdType.Aggregator:
            return 'Агрегатор';
        default:
            return 'Неизвестно';
    }
}

interface BonusItemProps {
    bonus: ICPaymentBonus;
}

function formatDate(date?: string, time?: string) {
    if (!date && !time) {
        return '-';
    }
    return `${date} ${time}`.trim();
}

export default BonusList;
import {FC} from "react";
import {CustomQuery, EQueryResultType, IQueryResult} from "@interfaces/query";
import QueryExecuteBooleanResult from "./results/queryExecuteBooleanResult";
import QueryExecuteMessageResult from "./results/queryExecuteMessageResult";
import QueryExecuteTableResult from "./results/queryExecuteTableResult";
import QueryExecuteObjectResult from "./results/queryExecuteObjectResult";

interface QueryExecuteResultProps {
    query: CustomQuery;
    result: IQueryResult;
}

const QueryExecuteResult: FC<QueryExecuteResultProps> = ({query, result}) => {

    if(query.resultType === EQueryResultType.Boolean) {
        return (
            <QueryExecuteBooleanResult query={query} result={result} />
        )
    }
    if(query.resultType === EQueryResultType.Message) {
        return (
            <QueryExecuteMessageResult query={query} result={result} />
        )
    }
    if(query.resultType === EQueryResultType.Table) {
        return (
            <QueryExecuteTableResult query={query} result={result} />
        )
    }
    if(query.resultType === EQueryResultType.Object) {
        return (
            <QueryExecuteObjectResult query={query} result={result} />
        )
    }

    console.error(`Unknown query result type: ${query.resultType}`)

    return null;
}

export default QueryExecuteResult;
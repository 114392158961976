import ConsumerRefService from "@api/consumer/consumerRefService";
import { FC, useCallback } from "react";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import GoalForm, { ConditionType, GoalFormValues } from "./goalForm";

const AddGoalForm: FC = () => {
    
    const initialValues: GoalFormValues = {
        name: '',
        description: '',
        isActive: false,
        order: 0,
        conditionType: ConditionType.All,
        hidden: false
    }

    const history = useHistory();

    const onSubmit = useCallback(async (values: GoalFormValues) => {
        const api = new ConsumerRefService();
        const result = await api.putGoal({
            name: values.name,
            description: values.description,
            order: values.order
        });

        if(result.success) {
            toastr.success("Изменение достижения", "Достижение успешно обновлено");
            history.replace(`/consumer/goals/${result.data.id}/`);
        }
        else {
            if(result.errorCode === 'GoalNotFound') {
                toastr.error('Изменение достижения', 'Достижение не найдено');
            }
            else {
                toastr.error('Изменение достижения', 'Неизвестная ошибка');
            }
        }
    }, [history]);

    return (
        <GoalForm 
            initialValues={initialValues} 
            onSubmit={onSubmit} />
    )   
}

export default AddGoalForm;
import DonateBonusService from "@api/user/donateBonusService";
import { IDonateBonus } from "@interfaces/user/payments";
import FetchResult from "@models/fetchResult";
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

export type IDonateBonusStore = IDonateBonus[];

const slice = createSlice({
    name: 'donateBonusStore',
    initialState: [] as IDonateBonusStore,
    reducers: {
        setBonuses: (state, action: PayloadAction<IDonateBonus[]>) => {
            return action.payload;
        },
        setBonus: (state, { payload: bonus }: PayloadAction<IDonateBonus>) => {
            const idx = state.findIndex(b => b.id === bonus.id);
            if (idx >= 0) {
                state[idx] = bonus;
            }
            else {
                state.concat(bonus);
            }
        },
        deleteBonus: (state, { payload: id }: PayloadAction<string>) => {
            return state.filter(b => b.id !== id);
        }
    }
});

export const { reducer: donateBonusReducer } = slice;

const {
    deleteBonus,
    setBonus,
    setBonuses
} = slice.actions;

export const donateBonusActions = {
    loadAll: () => async (dispatch: Dispatch) => {
        try {
            const api = new DonateBonusService();
            const result = await api.getBonuses();
            if(result.success) {
                dispatch(setBonuses(result.data));
            }

            return result;
        } catch (error) {
            return error as FetchResult<IDonateBonus[]>;
        }
    },
    loadBonus: (id: string) => async (dispatch: Dispatch) => {
        try {
            const api = new DonateBonusService();
            const result = await api.getBonus(id);

            if(result.success) {
                dispatch(setBonus(result.data));
            }
            else if(result.errorCode === 'BonusNotFound') {
                dispatch(deleteBonus(id));
            }

            return result;
        } catch (error) {
            return error as FetchResult<IDonateBonus>;
        }
    },
    setBonuses
}
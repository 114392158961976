import ConsumerRefService from "@api/consumer/consumerRefService";
import { EditRefForm, RefRewardsList, RefTargetsList } from "@components/consumer/refs";
import withPage from "@components/hoc/withPage";
import { useBreadcrumbs, useStateLoader } from "@hooks/panelHooks";
import { IRef } from "@interfaces/consumer/refs";
import { FC, useCallback } from "react";
import { NavLink, Route, Switch, useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, CardTitle, Nav, NavItem } from "reactstrap";

const EditRefPage: FC = () => {

    const { ref_id } = useParams<{ ref_id: string }>();

    const loadRef = useCallback((ref_id: string) => {
        const api = new ConsumerRefService();
        return api.getRef(ref_id);
    }, []);

    const { data: ref } = useStateLoader<IRef>(loadRef, ref_id);
    useBreadcrumbs('consumer.refs.current.name', false, ref?.name);

    if (!ref) {
        return null;
    }

    return (
        <>
            <Nav tabs>
                <NavItem>
                    <NavLink to={`/consumer/refs/${ref_id}/`} className='nav-link' activeClassName="active" replace exact>
                        Основное
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to={`/consumer/refs/${ref_id}/targets/`} className='nav-link' activeClassName="active" replace>
                        Цели
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to={`/consumer/refs/${ref_id}/rewards/`} className='nav-link' activeClassName="active" replace>
                        Награды
                    </NavLink>
                </NavItem>
            </Nav>
            <Switch>
                <Route path={`/consumer/refs/:ref_id/`} exact>
                    <EditRefForm refObj={ref} />
                </Route>
                <Route path={`/consumer/refs/:ref_id/targets/`}>
                    <RefTargetsList />
                </Route>
                <Route path={`/consumer/refs/:ref_id/rewards/`}>
                    <RefRewardsList />
                </Route>
            </Switch>
        </>
    )
}

export default withPage(EditRefPage);
import { Text } from "@constructor/components";
import { useTopicAuto } from "@hooks/selectors/user/support.selectors";
import { FC } from "react";

const SupportTopicStatus: FC = (props) => {

    const topic = useTopicAuto();

    if (!topic) {
        return null;
    }

    let state: string = '';

    if(topic.isClosed) {
        state = 'Закрыт';
    }
    else {
        state = 'Открыт'
    }
    
    return (
        <Text text={state} {...props} />
    )
}

export default SupportTopicStatus;
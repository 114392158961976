import { useLoginServerAuto } from "@hooks/selectors/game.selectors";
import { Text } from '@constructor/components';
import { ReactText } from "react";

interface LoginServerNameProps {
    login_id?: ReactText;
}

const LoginServerName: React.FC<LoginServerNameProps> = ({ login_id, ...props }) => {

    const loginServer = useLoginServerAuto(login_id);
    if(!loginServer?.name) {
        return null;
    }

    return (
        <Text text={loginServer.name} {...props}></Text>
    )
}

export default LoginServerName;
import {FC} from "react";
import {Card, Form as BForm} from "react-bootstrap";
import {EModifyType} from "@interfaces/user/payments";
import {useFormikContext} from "formik";

const ActionBlock: FC = () => {

    const {
        values,
        setFieldValue
    } = useFormikContext<{modifyType: EModifyType}>();

    return (
        <Card className="mb-3">
            <Card.Header as="h6" className="bg-body-tertiary">
                Действие бонуса
            </Card.Header>
            <Card.Body>
                <BForm.Check
                    type='radio'
                    name='modifyType'
                    id='new-bonus-modifyType-add'
                    value={EModifyType.Add}
                    checked={values.modifyType === EModifyType.Add}
                    onChange={() => setFieldValue('modifyType', EModifyType.Add)}
                    label='Добавить к сумме пополнения'
                />
                <BForm.Check
                    type='radio'
                    name='modifyType'
                    id='new-bonus-modifyType-subtract'
                    value={EModifyType.Dec}
                    checked={values.modifyType === EModifyType.Dec}
                    onChange={() => setFieldValue('modifyType', EModifyType.Dec)}
                    label='Вычесть из суммы оплаты'
                />
            </Card.Body>
        </Card>
    )
}

export default ActionBlock;
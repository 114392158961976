import { AddGoalRewardForm } from "@components/consumer/goals";
import withPage from "@components/hoc/withPage";
import {FC, useCallback} from "react";
import { Card, CardHeader, CardTitle } from "reactstrap";
import {useParams} from "react-router-dom";
import ConsumerRefService from "@api/consumer/consumerRefService";
import {useBreadcrumbs, useStateLoader} from "@hooks/panelHooks";

const AddGoalRewardPage: FC = () => {

    const { goal_id } = useParams<{ goal_id: string }>();

    const loadGoal = useCallback((goal_id: string) => {
        const api = new ConsumerRefService();
        return api.getGoal(goal_id);
    }, []);

    const { data: goal } = useStateLoader(loadGoal, goal_id);
    useBreadcrumbs('consumer.goals.current.name', true, goal?.name, `/consumer/goals/${goal_id}/`);
    useBreadcrumbs('consumer.goals.rewards', true, null, `/consumer/goals/${goal_id}/rewards/`);

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h5">
                    Создание награды достижения
                </CardTitle>
            </CardHeader>
            <AddGoalRewardForm />
        </Card>
    )
}

export default withPage(AddGoalRewardPage);
import {SidebarData, SidebarStore} from '@interfaces/store';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SidebarCategoryLink } from '@interfaces/control-panel';

const slice = createSlice({
    initialState: {
        loading: false,
        loaded: false,
        error: null,
        isOpen: false,
        isOnRight: false,
        isFluid: false,
        showBurgerMenu: false,
        theme: 'light',
        isDark: false,
        links: []
    } as SidebarStore,
    name: 'sidebarStore',
    reducers: {
        toggleSidebar: (state) => {
            state.isOpen = !state.isOpen;
        },
        toggleSidebarItem: (state, action: PayloadAction<number>) => {
            const idx = action.payload;
            if(idx < state.links.length) {
                state.links[idx].isOpen = !state.links[idx].isOpen;
            }
        },
        setBurgerMenu: (state, action: PayloadAction<boolean>) => {
            state.showBurgerMenu = action.payload;
        },
        setTheme: (state, action: PayloadAction<string>) => {
            state.theme = action.payload;
        },
        setSidebar: (state, action: PayloadAction<Partial<SidebarData>>) => {
            return {
                ...state,
                ...action.payload
            }
        },
        /*setError: (state, action) => {
            state.isLoading = false;
            state.isLoaded = false;
            state.links = [];
            state.error = action.payload;
        },
        setLoading: (state) => {
            state.isLoading = true;
            state.isLoaded = false;
            state.error = null;
        },*/
        setLinks: (state, action: PayloadAction<SidebarCategoryLink[]>) => {
            state.loaded = true;
            state.error = null;
            state.links = action.payload;
            state.loading = false;
        }
    }
})

export const { reducer: sidebarReducer } = slice;

const {
    //setError,
    setLinks,
    //setLoading,
    toggleSidebar,
    toggleSidebarItem,
    setBurgerMenu,
    setTheme,
    setSidebar
} = slice.actions;

export const sidebarActions = {
    toggleSidebar,
    toggleSidebarItem,
    /*loadSidebar: () => async (dispatch: Dispatch) => {
        dispatch(setLoading());
        try {
            const api = new PanelService();
            const links = await api.fetchSidebar();
            dispatch(setLinks(links));
            return links;
        }
        catch(e) {
            dispatch(setError(e));
        }
    },*/
    setLinks,
    setBurgerMenu,
    setTheme,
    setSidebar
}
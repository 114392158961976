import {FC, PropsWithChildren, ReactText} from "react";
import { useDropdownContext } from "./context";

interface DropdownValueProps {
    value: ReactText;
}

const DropdownValue: FC<PropsWithChildren<DropdownValueProps>> = ({ value, children }) => {

    const context = useDropdownContext();

    if (context?.value !== value) {
        return null;
    }

    return (
        <>{children}</>
    )
}

export default DropdownValue;
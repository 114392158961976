import { injectChild } from "@components/constructor";
import { PaginationProps } from "@components/constructor/components/core/pagination";
import { usePagination } from "@hooks/components/constructor.hooks";
import { usePersonalCodes } from "@hooks/userHooks";
import {FC, PropsWithChildren, useMemo} from "react";

interface PersonalBonusCodesProps {
    personal_codes_per_page?: number;
}

interface PersonalBonusCodesChildProps {
    personal_codes_count: number;
    personal_codes_loading: boolean;
}

const PersonalBonusCodes: FC<PropsWithChildren<PersonalBonusCodesProps>> = ({ children, personal_codes_per_page = 10 }) => {

    const { loading, data } = usePersonalCodes();
    const pagination = usePagination(personal_codes_per_page, data.length);

    const childData = useMemo<PaginationProps & PersonalBonusCodesChildProps>(() => ({
        personal_codes_count: data.length,
        personal_codes_loading: loading,
        ...pagination
    }), [data.length, loading, pagination]);

    return injectChild(childData, children);
}

export default PersonalBonusCodes;
import { useGameServers } from "@hooks/selectors/game.selectors";
import classNames from "classnames";
import { Field, useFormikContext } from "formik";
import { FC, useCallback } from "react";
import { SelectorsFormsValues } from ".";
import { FormFieldProps } from "..";

const GameServerSelector: FC<FormFieldProps> = ({ className, invalidClassName, ...props }) => {

    const { values: { selected_login_server, selected_character }, touched, errors, setFieldValue, handleChange, setFieldTouched } = useFormikContext<SelectorsFormsValues>();
    const servers = useGameServers(selected_login_server || undefined);

    const onChange = useCallback((e: any) => {
        handleChange(e);
        if (selected_character) {
            setFieldValue('selected_character', '');
            setFieldTouched('selected_character', false);
        }
    }, [handleChange, selected_character, setFieldTouched, setFieldValue]);

    const invalid = Boolean(errors['selected_game_server'] && touched['selected_game_server']);

    const cn = classNames(className, invalidClassName && {
        [invalidClassName]: invalid
    });

    return (
        <Field
            id="selected_game_server"
            name="selected_game_server"
            as="select"
            onChange={onChange}
            className={cn}
            invalid={invalid || undefined}
            {...props}>
            <option value='' key={'empty'}>Выберите игровой сервер</option>
            {
                selected_login_server &&
                servers.map(server => <option value={server.id} key={server.id}>{server.name}</option>)
            }
        </Field>
    )
}

export default GameServerSelector;
import {forwardRef, useEffect, useLayoutEffect, useRef} from "react";
import Quill, {EmitterSource, Range} from "quill";
import {useForwardRef} from "@services/helpers";

interface ReactQuillProps {
    readOnly?: boolean;
    defaultValue?: string;
    onChange?: (value: string) => void;
    onSelectionChange?: (range: Range, oldRange: Range, source: EmitterSource) => void;
}

const ReactQuill = forwardRef<Quill, ReactQuillProps>(
    ({ readOnly, defaultValue, onChange, onSelectionChange }, ref) => {

        ref = useForwardRef(ref);
        const containerRef = useRef<HTMLDivElement>(null);
        const defaultValueRef = useRef(defaultValue);
        const onTextChangeRef = useRef(onChange);
        const onSelectionChangeRef = useRef(onSelectionChange);

        useLayoutEffect(() => {
            onTextChangeRef.current = onChange;
            onSelectionChangeRef.current = onSelectionChange;
        });

        useEffect(() => {
            ref!.current?.enable(!readOnly);
        }, [ref, readOnly]);

        useEffect(() => {
            const container = containerRef.current;
            if(!container) {
                return;
            }
            const editorContainer = container.appendChild(
                container.ownerDocument.createElement('div'),
            );
            const quill = new Quill(editorContainer, {
                theme: 'snow',
                modules: {
                    toolbar: [
                        [{ 'header': [1, 2, 3, false] }],
                        ['bold', 'italic', 'underline'],
                        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                        ['link'],
                        ['clean']
                    ]
                }
            });

            ref.current = quill;

            if (defaultValueRef.current) {
                quill.root.innerHTML = defaultValueRef.current;
                //quill.setText(defaultValueRef.current, 'silent');
            }

            quill.on(Quill.events.TEXT_CHANGE, (...args) => {
                onTextChangeRef.current?.(quill.getSemanticHTML());
            });

            quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
                onSelectionChangeRef.current?.(...args);
            });

            return () => {
                ref.current = null;
                container.innerHTML = '';
            };
        }, [ref]);

        return <div ref={containerRef}></div>;
    });

export default ReactQuill;
import React, {FC} from "react";
import {Card, Col, Form} from "react-bootstrap";
import {useRole} from "@services/hooks";
import {ConsumerShortNewsFull} from "@interfaces/consumer/shortNews";
import {fromUtcDate} from "@services/helpers";
import {Link} from "react-router-dom";

interface CreateInfoBlockProps {
    news: ConsumerShortNewsFull;
}

const CreateInfoBlock: FC<CreateInfoBlockProps> = ({news: {author, authorName, createTime}}) => {

    const canViewUser = useRole('consumer.users.view');

    return (
        <Card className="mb-3">
            <Card.Header as="h6" className="bg-body-tertiary">
                Информация о создании
            </Card.Header>
            <Card.Body>
                <Col md={12}>
                    <Form.Group>
                        <Form.Label>Дата создания</Form.Label>
                    </Form.Group>
                    {fromUtcDate(createTime).format('lll')}
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Label>Автор</Form.Label>
                    </Form.Group>
                    {
                        canViewUser ?
                            <Link to={`/consumer/users/${author}`}>{authorName}</Link>
                            : authorName
                    }
                </Col>
            </Card.Body>
        </Card>
    )
}

export default CreateInfoBlock;
import React, { useState } from 'react';
import withPage from '../../../hoc/withPage';
import { ConsumerBonusCodesList, ConsumerBonusCodesCreateModal, ConsumerCreatedBonusCodesListModal } from '../../../consumer/bonuses';
import {ConsumerBonusCode} from "@interfaces/consumer/bonusCodes";

const ConsumerBonusCodesListPage: React.FC = () => {

    const [isCreateModalOpened, setCreateModalOpened] = useState(false);
    const [isListModalOpened, setListModalOpened] = useState(false);
    const [createdCodes, setCreatedCodes] = useState<ConsumerBonusCode[]>([]);
    const onSuccess = (codes: ConsumerBonusCode[]) => {
        setCreatedCodes(codes);
        setCreateModalOpened(false);
        setListModalOpened(true);
        //dispatch(consumerActions.loadBonusCodes({ page: currentPage, search: searchText }));
        //TODO: Обновление списка кодов
    }

    return (
        <>
            <ConsumerBonusCodesList onCreateClick={() => setCreateModalOpened(true)}/>
            <ConsumerBonusCodesCreateModal
                isOpened={isCreateModalOpened}
                toggle={() => setCreateModalOpened(!isCreateModalOpened)}
                onSuccess={onSuccess}/>
            <ConsumerCreatedBonusCodesListModal
                isOpened={isListModalOpened}
                toggle={() => setListModalOpened(!isListModalOpened)}
                codes={createdCodes}/>
        </>
    )
}

export default withPage(ConsumerBonusCodesListPage);
import {KeyValueSet} from "@interfaces/helpers";
import {HttpTransportType, HubConnection, HubConnectionBuilder} from "@microsoft/signalr";
import {auth} from "@services/auth";
import {randomString} from "@services/helpers";
import {consumerActions} from "@store/consumer";
import {userActions} from "@store/user";
import {useEffect, useMemo, useState} from "react";
import {signalUrl} from "../..";
import {usePromisedDispatch} from "./helperHooks";
import {useIsAuthed} from "./loadingHooks";
import {
    AccountDeletedView,
    AccountUpdatedView,
    BalanceChangedView,
    ConsumerSupportMessageCreatedView,
    ConsumerSupportMessageDeletedView,
    ConsumerSupportMessageUpdatedView,
    ConsumerSupportTopicCreatedView,
    ConsumerSupportTopicDeletedView,
    ConsumerSupportTopicUpdatedView,
    InventoryItemChangedView,
    NotificationAddedView,
    NotificationDeletedView,
    NotificationReadedView,
    SupportMessageCreatedView,
    SupportMessageDeletedView,
    SupportMessageUpdatedView,
    SupportTopicCreatedView,
    SupportTopicDeletedView,
    SupportTopicUpdatedView
} from "@interfaces/signalr";
import {inventoryActions} from "@store/user/userInventoryStore";

type SignalrMessageTypes = {
    SupportMessageCreated: SupportMessageCreatedView;
    SupportMessageUpdated: SupportMessageUpdatedView;
    SupportMessageDeleted: SupportMessageDeletedView;
    SupportTopicCreated: SupportTopicCreatedView;
    SupportTopicDeleted: SupportTopicDeletedView;
    SupportTopicUpdated: SupportTopicUpdatedView;
    ConsumerSupportMessageCreated: ConsumerSupportMessageCreatedView;
    ConsumerSupportMessageUpdated: ConsumerSupportMessageUpdatedView;
    ConsumerSupportMessageDeleted: ConsumerSupportMessageDeletedView;
    ConsumerSupportTopicCreated: ConsumerSupportTopicCreatedView;
    ConsumerSupportTopicDeleted: ConsumerSupportTopicDeletedView;
    ConsumerSupportTopicUpdated: ConsumerSupportTopicUpdatedView;
    InventoryItemChanged: InventoryItemChangedView;
    BalanceChanged: BalanceChangedView;
    AccountUpdated: AccountUpdatedView;
    AccountDeleted: AccountDeletedView;
    NotificationAdded: NotificationAddedView;
    NotificationReaded: NotificationReadedView;
    NotificationDeleted: NotificationDeletedView;
}

interface SignalrMessages {
    SupportMessageCreated: (e: SupportMessageCreatedView) => any;
    SupportMessageUpdated: (e: SupportMessageUpdatedView) => any;
    SupportMessageDeleted: (e: SupportMessageDeletedView) => any;
    SupportTopicCreated: (e: SupportTopicCreatedView) => any;
    SupportTopicDeleted: (e: SupportTopicDeletedView) => any;
    SupportTopicUpdated: (e: SupportTopicUpdatedView) => any;

    ConsumerSupportMessageCreated: (e: ConsumerSupportMessageCreatedView) => any;
    ConsumerSupportMessageUpdated: (e: ConsumerSupportMessageUpdatedView) => any;
    ConsumerSupportMessageDeleted: (e: ConsumerSupportMessageDeletedView) => any;
    ConsumerSupportTopicCreated: (e: ConsumerSupportTopicCreatedView) => any;
    ConsumerSupportTopicDeleted: (e: ConsumerSupportTopicDeletedView) => any;
    ConsumerSupportTopicUpdated: (e: ConsumerSupportTopicUpdatedView) => any;

    InventoryItemChanged: (e: InventoryItemChangedView) => any;

    BalanceChanged: (e: BalanceChangedView) => any;
    AccountUpdated: (e: AccountUpdatedView) => any;
    AccountDeleted: (e: AccountDeletedView) => any;
    NotificationAdded: (e: NotificationAddedView) => any;
    NotificationReaded: (e: NotificationReadedView) => any;
    NotificationDeleted: (e: NotificationDeletedView) => any;
}

function useSignalrHandlers(): Partial<SignalrMessages> {
    const dispatch = usePromisedDispatch();

    return useMemo(() => ({
        SupportMessageCreated: (e: SupportMessageCreatedView) => dispatch(userActions.loadTaskMessages(e.topicId)),
        SupportMessageUpdated: (e: SupportMessageUpdatedView) => dispatch(userActions.loadTaskMessages(e.topicId)),
        SupportMessageDeleted: (e: SupportMessageDeletedView) => dispatch(userActions.loadTaskMessages(e.topicId)),
        SupportTopicCreated: (e: SupportTopicCreatedView) => dispatch(userActions.loadTask(e.topicId)),
        SupportTopicDeleted: (e: SupportTopicDeletedView) => dispatch(userActions.loadTask(e.topicId)),
        SupportTopicUpdated: (e: SupportTopicUpdatedView) => dispatch(userActions.loadTask(e.topicId)),

        ConsumerSupportMessageCreated: (e: ConsumerSupportMessageCreatedView) => dispatch(consumerActions.support.loadMessage(e.topicId, e.messageId)),
        ConsumerSupportMessageUpdated: (e: ConsumerSupportMessageUpdatedView) => dispatch(consumerActions.support.loadMessage(e.topicId, e.messageId)),
        ConsumerSupportMessageDeleted: (e: ConsumerSupportMessageDeletedView) => dispatch(consumerActions.support.removeMessage(e.messageId)),
        ConsumerSupportTopicCreated: (e: ConsumerSupportTopicCreatedView) => dispatch(consumerActions.support.loadTopic(e.topicId, true)),
        ConsumerSupportTopicDeleted: (e: ConsumerSupportTopicDeletedView) => {
            dispatch(consumerActions.support.removeTopic(e.topicId));
            dispatch(consumerActions.support.removeMessages(e.topicId));
        },
        ConsumerSupportTopicUpdated: (e: ConsumerSupportTopicUpdatedView) => dispatch(consumerActions.support.loadTopic(e.topicId, true)),

        InventoryItemChanged: (e: InventoryItemChangedView) => dispatch(inventoryActions.loadInventoryItem(e.itemId)),
        BalanceChanged: (e: BalanceChangedView) => dispatch(userActions.setBalance(e.balance)),
        AccountUpdated: (e: AccountUpdatedView) => dispatch(userActions.loadGameAccount(e.accountId, true)),
        AccountDeleted: (e: AccountDeletedView) => dispatch(userActions.removeGameAccount(e.accountId)),
        NotificationAdded: (e: NotificationAddedView) => dispatch(userActions.loadNotification(e.notificationId)),
        NotificationReaded: (e: NotificationReadedView) => dispatch(userActions.setNotificationReaded(e.notificationId)),
        NotificationDeleted: (e: NotificationDeletedView) => dispatch(userActions.removeNotification(e.notificationId)),
    }), [dispatch]);
}

interface Subscribe {
    callback: (...args: any[]) => any;
    validator?: (...args: any[]) => boolean;
}

const subscribes: Record<string, Subscribe[]> = {};

export function useSubscribe<K extends keyof SignalrMessageTypes>(event: K,
                                                                  callback: (e: SignalrMessageTypes[K]) => any,
                                                                  validator?: (e: SignalrMessageTypes[K]) => boolean) {
    useEffect(() => {
        if(subscribes[event] === undefined) {
            subscribes[event] = [];
        }

        subscribes[event].push({
            callback,
            validator
        });

        return () => {
            subscribes[event] = subscribes[event].filter(sub => sub.callback !== callback);
        }
    }, [callback, event, validator]);

}

function wrapHandler(event: string, callback: (...args: any[]) => void) {
    return (...args: any[]) => {
        //console.log(event);
        callback(...args);
        if(subscribes[event] !== undefined) {
            subscribes[event].forEach(s => {
                if(!s.validator || s.validator(...args)) {
                    s.callback(...args);
                }
            });
        }
    }
}

export function useSignalr() {

    const [sticky] = useState(() => randomString(15));
    const isAuthed = useIsAuthed();
    const handlers = useSignalrHandlers();

    const [connection, setConnection] = useState<HubConnection>();

    useEffect(() => {
        const hubConnection = new HubConnectionBuilder()
            .withUrl(signalUrl + '/users', {
                accessTokenFactory: () => auth().getToken(),
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            })
            .withAutomaticReconnect()
            .build();

        Object.entries(handlers).forEach(([message, handler]) => hubConnection.on(message, wrapHandler(message, handler)));

        setConnection(hubConnection);
    }, [handlers, sticky]);

    useEffect(() => {
        if (isAuthed && connection) {
            connection.start().catch(console.error);
        }

        return () => {
            if(connection) {
                connection.stop().catch(console.error);
            }
        };
    }, [connection, isAuthed]);
}
import React, { useState, useEffect } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { FormikHelpers } from 'formik';
import withPage from '../../../hoc/withPage';
import { useParams } from 'react-router-dom';
import FetchResult from '../../../../models/fetchResult';
import { ConsumerShortNewsFull } from '@interfaces/consumer/shortNews';
import { EditShortNewsForm } from '../../../consumer/shortnews';
import { EditShortNewsFormValues } from '../../../consumer/shortnews/editShortNewsForm';
import { toastr } from 'react-redux-toastr';
import { useBreadcrumbs } from '@services/hooks';
import ConsumerShortNewsService from '../../../../services/api/consumer/consumerShortNewsService';

/*interface EditShortNewsForm {
    title: string;
    text: string;
}*/

const EditShortNewsPage: React.FC = () => {

    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState<FetchResult<ConsumerShortNewsFull>>();

    useEffect(() => {

        const loadNews = async () => {
            setLoading(true);
            const api = new ConsumerShortNewsService();
            const r = await api.fetchConsumerShortNews(id);
            setResult(r);
            setLoading(false);
        }

        loadNews();
    }, [id]);

    useBreadcrumbs('consumer.shortnews.current.name', true, result?.result?.title);

    const onSubmit = async (values: EditShortNewsFormValues, actions: FormikHelpers<EditShortNewsFormValues>) => {

        if (!result?.result) {
            return;
        }
        const { id } = result.result;
        const api = new ConsumerShortNewsService();
        const apiResult = await api.patchConsumerShortNews({
            ...values,
            id,
            beginTime: values.beginTime.toUTCString(),
            endTime: values.endTime.toUTCString()
        });

        if (apiResult.ok) {
            toastr.success('Редактирование новости', 'Новость успешно обновлена.');
            setResult(apiResult);
        }
        else {
            if (apiResult.status === 404) {
                toastr.error('Редактирование новости', 'Новость не найдена.');
            }
        }
    }

    if (loading || !result?.result) {
        return (
            <Card body className="text-center">
                {loading ? <Spinner /> : 'Новость не найдена'}
            </Card>
        )
    }

    return (
        <EditShortNewsForm
            news={result.result}
            onSubmit={onSubmit} />
    )
}

export default withPage(EditShortNewsPage);
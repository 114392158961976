import { SelectorsFormsValues } from "@components/constructor/components/core/forms/selectors";
import { Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useGameServers, useLoginServers } from "./selectors/game.selectors";
import { useAccounts, useCharacters } from "./selectors/user.selectors";

export function usePromisedDispatch() {
    return useDispatch() as <T>(action: ((dispatch: Dispatch, getState: any) => T) | PayloadAction<any>) => T;
}

export const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
        isMountRef.current = false;
    }, []);
    return isMountRef.current;
};

export function useDefaultSelectorFormValues(): SelectorsFormsValues {

    const loginServers = useLoginServers();
    const login = loginServers.length === 1 ? loginServers[0] : null;

    const gameServers = useGameServers(login?.id);
    const game = login && (gameServers.length === 1 ? gameServers[0] : null);

    const accounts = useAccounts(login?.id);
    const account = login && (accounts.length === 1 ? accounts[0] : null);

    const characters = useCharacters(account?.id || '', game?.id);
    const character = account && game && (characters.length === 1 ? characters[0] : null);

    return {
        selected_login_server: login?.id || '',
        selected_game_server: game?.id || '',
        selected_account: account?.id || '',
        selected_character: character?.id || ''
    }
}
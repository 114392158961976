import {FC, PropsWithChildren, useMemo} from "react";
import { LoginServerProvider } from "./context";
import { useLoginServerAuto } from "@hooks/selectors/game.selectors";
import { useAccounts } from "@hooks/selectors/user.selectors";
import { injectChild } from "@components/constructor";

interface PanelLoginServerContextProps {
    login_id?: number | string;
}

const PanelLoginServerContext: FC<PropsWithChildren<PanelLoginServerContextProps>> = ({ children, login_id }) => {

    const login = useLoginServerAuto(login_id);
    const accounts = useAccounts();

    const data = useMemo(() => {
        return {
            login_id: login?.id,
            login_accounts_max: login?.maxAccounts || 0,
            login_accounts_count: accounts.filter(a => a.loginServer === login?.id).length,
            login_can_charge: login?.canCharge || false
        }
    }, [accounts, login]);

    return (
        <LoginServerProvider value={login}>
            {injectChild(data, children)}
        </LoginServerProvider>
    )
}

export default PanelLoginServerContext;
import ConsumerItemsService from "@api/consumer/consumerItemsService";
import { EditItemForm } from "@components/consumer/items";
import { useBreadcrumbs, useStateLoader } from "@hooks/panelHooks";
import { IItemFull } from "@interfaces/consumer/items";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, Spinner, CardHeader, CardTitle } from "reactstrap";
import withPage from "@components/hoc/withPage";

const EditItemPage: React.FC = () => {

    const { item_id } = useParams<{ item_id: string }>();

    const loadItem = useCallback(() => {
        const api = new ConsumerItemsService();
        return api.getItem(item_id)
    }, [item_id]);
    const { data: item, loading } = useStateLoader<IItemFull>(loadItem);

    useBreadcrumbs('consumer.item.current.name', true, item?.name);

    if (!item) {
        return (
            <Card>
                <CardBody className="text-center">
                    {loading ? <Spinner className="mr-2" color="primary" /> : (
                        "Предмет не найден"
                    )}
                </CardBody>
            </Card>
        )
    }

    return (
        <EditItemForm item={item} />
    )
}

export default withPage(EditItemPage);
import {FC, useCallback, useState} from "react";
import {useFormikContext} from "formik";
import {useDropzone} from "react-dropzone";
import {useFile} from "@hooks/filesHooks";
import {Button, Card, Dropdown, Image, ProgressBar} from "react-bootstrap";
import cloudUpload from "../../../../assets/img/icons/cloud-upload.svg";
import {CardDropdown, Flex} from "@components/falcon/common";
import {getSize} from "../../../../helpers/utils";

interface FormIconBlockProps {
    title: string;
    iconId?: string;
    uploading?: boolean;
    uploadProgress?: number;
}

const FormIconBlock: FC<FormIconBlockProps> = ({
    title,
    iconId,
    uploading,
    uploadProgress
                                               }) => {

    const [imageUrl, setImageUrl] = useState<string>();

    const {
        values,
        setFieldValue
    } = useFormikContext<{icon: File | null}>();

    const selectFile = useCallback((file: File | null) => {
        setFieldValue("icon", file);

        if (imageUrl) {
            URL.revokeObjectURL(imageUrl);
        }

        if (file) {
            setImageUrl(URL.createObjectURL(file));
        }
        else {
            setImageUrl(undefined);
        }
    }, [imageUrl, setFieldValue]);

    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'image/*': ['.jpeg', '.png', '.jpg']
        },
        onDrop: acceptedFiles => {
            selectFile(acceptedFiles[0]);
        }
    });

    const file = useFile(iconId);

    const iconUrl = imageUrl || file.data?.url;
    const iconName = values.icon?.name || file.data?.fileName;
    const iconSize = values.icon?.size || file.data?.size;

    return (
        <Card className="mb-3">
            <Card.Header as="h6" className="bg-body-tertiary">
                {title}
            </Card.Header>
            <Card.Body>
                <div
                    {...getRootProps({
                        className: 'dropzone-area py-6',
                        acceptedfiles: 'image/*'
                    })}
                >
                    <input {...getInputProps({multiple: false})} />
                    <div className="fs-10">
                        <img src={cloudUpload} alt="" width={20} className="me-2"/>
                        <span className="d-none d-lg-inline">
                            Перетащите файл сюда
                            <br/>
                            или{' '}
                        </span>
                        <Button variant="link" size="sm" className="p-0 fs-10">
                            выберите
                        </Button>
                    </div>
                </div>
                {iconUrl && (
                    <div className="mt-3">
                        <Flex
                            alignItems="center"
                            className="btn-reveal-trigger"
                            key={iconUrl}
                        >
                            <Image
                                rounded
                                width={40}
                                height={40}
                                src={iconUrl}
                                alt={iconName || ''}
                            />
                            <Flex
                                justifyContent="between"
                                direction="column"
                                className="mx-2 flex-1 text-truncate"
                            >
                                <h6 className="text-truncate">{iconName}</h6>
                                <Flex className="position-relative" alignItems="center">
                                    <p className="mb-0 fs-10 text-400 line-height-1">
                                        <strong>{getSize(iconSize || 0)}</strong>
                                    </p>
                                </Flex>
                            </Flex>
                            <CardDropdown>
                                <div className="py-2">
                                    <Dropdown.Item
                                        className="text-danger"
                                        onClick={() => selectFile(null)}
                                    >
                                        Удалить
                                    </Dropdown.Item>
                                </div>
                            </CardDropdown>
                        </Flex>
                        {uploading && (
                            <ProgressBar
                                animated
                                className="mt-1"
                                now={uploadProgress || 0}
                            />
                        )}
                    </div>
                )}
            </Card.Body>
        </Card>
    )
}

export default FormIconBlock;
import { BonusList } from "@components/consumer/payments/bonuses";
import withPage from "@components/hoc/withPage";
import { FC } from "react";

const BonusListPage: FC = () => {

    return (
        <BonusList />
    )
}

export default withPage(BonusListPage);
import {FC, useCallback, useState} from "react";
import {Card, Col, Row} from "react-bootstrap";
import {useStateLoader} from "@hooks/panelHooks";
import ConsumerService from "@api/consumerService";
import {SubtleBadge} from "@components/falcon/common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CountUp from 'react-countup';

const OnlineWidget: FC = () => {

    const [api] = useState(() => new ConsumerService());
    const loadOnline = useCallback(() => api.fetchOnlineWidget(), [api]);

    const {
        loading,
        data
    } = useStateLoader(loadOnline);

    return (
        <Card className="h-100">
            <Card.Body>
                <Row className="flex-between-center g-0">
                    <Col className="d-md-flex d-lg-block flex-between-center">
                        <h6 className="mb-md-0 mb-lg-2">Текущий онлайн</h6>
                        <SubtleBadge bg="primary" pill>
                            <FontAwesomeIcon icon="caret-up" /> {data?.delta}%
                        </SubtleBadge>
                    </Col>
                    <Col xs="auto">
                        <h4 className="fs-6 fw-normal text-primary">
                            <CountUp
                                start={0}
                                end={data?.nowOnline || 0}
                                duration={2.75}
                            />
                        </h4>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default OnlineWidget;
import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { sidebarActions } from '../../../redux/sidebarStore';

const NavbarToggle: React.FC = () => {

  const dispatch = useDispatch();
  const toggleSidebar = useCallback(() => dispatch(sidebarActions.toggleSidebar()), [dispatch]);

  return (
    <span
      className="sidebar-toggle d-flex me-2"
      onClick={() => {
        toggleSidebar();
      }}
    >
      <i className="hamburger align-self-center" />
    </span>
  );
}

export default NavbarToggle;
import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";
import SupportMessageGroupList from "./messageGroupList";
import SupportMessageAvatar from './messageAvatar';
import SupportMessagesList from "./messagesList";
import SupportMessageText from "./messageText";
import SupportMessageTime from "./messageTime";
import SupportMessageTitle from "./messageTitle";
import SupportMessageSenderName from "./messageSenderName";
import SupportEditableMessageText from "./editableMessageText";

const SupportMessageAvatarInfo: ConstructionComponentInfo = {
    component: SupportMessageAvatar,
    requiredParent: [
        'SupportMessagesList'
    ]
}

const SupportMessagesListInfo: ConstructionComponentInfo = {
    component: SupportMessagesList,
    props: [
        'sort_order'
    ],
    addedChildProps: [
        'is_incoming_message',
        'is_first_message',
        'delete_message',
        'message_has_attachments'
    ]
}

const SupportMessagesGroupListInfo: ConstructionComponentInfo = {
    component: SupportMessageGroupList,
    props: [
        'split_delay',
        'sort_order'
    ],
    addedChildProps: [
        'is_incoming_group'
    ]
}

const SupportMessageSenderNameInfo: ConstructionComponentInfo = {
    component: SupportMessageSenderName,
    requiredParent: [
        'SupportMessagesList',
        'SupportMessageGroupList'
    ]
}

const SupportMessageTextInfo: ConstructionComponentInfo = {
    component: SupportMessageText,
    requiredParent: [
        'SupportMessagesList'
    ]
}

const SupportMessageTimeInfo: ConstructionComponentInfo = {
    component: SupportMessageTime,
    requiredParent: [
        'SupportMessagesList'
    ]
}

const SupportMessageTitleInfo: ConstructionComponentInfo = {
    component: SupportMessageTitle,
    requiredParent: [
        'SupportMessagesList'
    ]
}

const SupportEditableMessageTextInfo: ConstructionComponentInfo = {
    component: SupportEditableMessageText,
    requiredParent: [
        'SupportTopic'
    ],
    requiredProps: [
        'editable_message'
    ],
    props: [
        'editable_message'
    ],
    injectChild: [
        'editable_message'
    ]
}

export {
    SupportMessagesGroupListInfo,
    SupportMessageSenderNameInfo,
    SupportMessageAvatarInfo,
    SupportMessagesListInfo,
    SupportMessageTextInfo,
    SupportMessageTimeInfo,
    SupportMessageTitleInfo,
    SupportEditableMessageTextInfo
}
import { UserBonusCodesStore } from '../../interfaces/user/userStore';
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { IPersonalBonusCode, IUsedBonusCode } from '../../interfaces/user/bonusCodes';
import UserService from '../../services/api/userService';

const slice = createSlice({
    initialState: {
        usedCodes: {
            loaded: false,
            loading: false,
            error: null,
            data: []
        },
        personalCodes: {
            loaded: false,
            loading: false,
            error: null,
            data: []
        }
    } as UserBonusCodesStore,
    name: 'userBonusCodesStore',
    reducers: {
        setUsedLoading: (state) => {
            state.usedCodes = {
                ...state.usedCodes,
                loading: true,
                error: null
            }
        },
        setUsedError: (state, action) => {
            state.usedCodes = {
                ...state.usedCodes,
                loading: false,
                error: action.payload
            }
        },
        setUsedCodes: (state, action: PayloadAction<IUsedBonusCode[]>) => {
            state.usedCodes = {
                loaded: true,
                loading: false,
                error: null,
                data: action.payload
            }
        },
        setPersonalLoading: (state) => {
            state.personalCodes = {
                ...state.personalCodes,
                loading: true,
                error: null
            }
        },
        setPersonalError: (state, action) => {
            state.personalCodes = {
                ...state.personalCodes,
                loading: false,
                error: action.payload
            }
        },
        setPersonalCodes: (state, action: PayloadAction<IPersonalBonusCode[]>) => {
            state.personalCodes = {
                loaded: true,
                loading: false,
                error: null,
                data: action.payload
            }
        }
    }
});

export const { reducer: userBonusCodesReducer } = slice;

const {
    setPersonalCodes,
    setPersonalError,
    setPersonalLoading,
    setUsedCodes,
    setUsedError,
    setUsedLoading
} = slice.actions;

export const loadUsedBonusCodes = () => async (dispatch: Dispatch) => {
    dispatch(setUsedLoading());
    try {
        const api = new UserService();
        const codes = await api.fetchUserUsedBonusCodes();
        dispatch(setUsedCodes(codes));
        return codes;
    }
    catch(e) {
        dispatch(setUsedError(e));
    }
}

export const loadPersonalBonusCodes = () => async (dispatch: Dispatch) => {
    dispatch(setPersonalLoading());
    try {
        const api = new UserService();
        const codes = await api.fetchUserPersonalBonusCodes();
        dispatch(setPersonalCodes(codes));
        return codes;
    }
    catch(e) {
        dispatch(setPersonalError(e));
    }
}
import { ConsumerShortNews, ConsumerShortNewsFull } from '../../../interfaces/consumer/shortNews';
import { PanelTableData } from '../../../interfaces/panel';
import { PatchConsumerShortNewsModel, PutConsumerShortNewsModel } from '../../../models/consumer/shortNews';
import { PanelTableDataModel } from '../../../models/panel';
import IService from '../iService';

export default class ConsumerShortNewsService extends IService {

    fetchConsumerShortNewsList = async (model: PanelTableDataModel) => {
        return await this.postApi<PanelTableData<ConsumerShortNews[]>>('/consumer/shortnews/', model);
    }

    async fetchConsumerShortNews(id: string) {
        return await this.getApi<ConsumerShortNewsFull>(`/consumer/shortnews/full/${id}/`);
    }

    async putConsumerShortNews(news: PutConsumerShortNewsModel) {
        return await this.putApi<string>('/consumer/shortnews/', news);
    }

    async patchConsumerShortNews(news: PatchConsumerShortNewsModel) {
        return await this.patchApi('/consumer/shortnews/', news);
    }
    
}
import ConsumerRefService from "@api/consumer/consumerRefService";
import { IGoalShort } from "@interfaces/consumer/refs";
import { dateFormatter } from "@services/helpers";
import { FC, useState } from "react";
import {Link, useHistory} from "react-router-dom";
import {ColumnDef} from "@tanstack/react-table";
import {useRemoteTable} from "@hooks/tableHooks";
import {Card, Form} from "react-bootstrap";
import {Flex, IconButton} from "@components/falcon/common";
import {AdvanceTable, AdvanceTableFooter, AdvanceTableSearchBox} from "@components/falcon/common/tables";
import AdvanceTableProvider from "@components/falcon/common/tables/context";
import {useRole} from "@services/hooks";

const columns: ColumnDef<IGoalShort>[] = [
    {
        accessorKey: 'name',
        header: 'Название',
        enableSorting: true,
        meta: {
            headerProps: { className: 'pe-1 text-900' },
            cellProps: { className: 'py-2' }
        },
        cell: ({row: {original: {id, name}}}) => (
            <Link to={`/consumer/goals/${id}/`}>
                <Flex alignItems="center" className="flex-1">
                    <h5 className="mb-0 fs-10">{name}</h5>
                </Flex>
            </Link>
        )
    },
    {
        accessorKey: 'createTime',
        header: 'Дата создания',
        enableSorting: true,
        cell: ({row: {original: {createTime}}}) => dateFormatter(createTime),
    },
    {
        accessorKey: 'isActive',
        header: 'Активно',
        enableSorting: false,
        meta: {
            headerProps: { className: 'text-center text-900' },
            cellProps: { className: 'text-center' }
        },
        cell: ({row: {original: {isActive}}}) => (
            <Form.Check type="checkbox" defaultChecked={isActive} disabled />
        )
    }
]

const GoalsList: FC = () => {

    const [api] = useState(() => new ConsumerRefService());
    const history = useHistory();

    const table = useRemoteTable({
        name: 'consumer.goals',
        columns,
        defaultState: {
            sorting: [{id: 'name', desc: false}]
        }
    }, api.getGoals);

    const canEdit = useRole('consumer.goal.edit');

    return (
        <AdvanceTableProvider table={table}>
            <Card className="mb-3">
                <Card.Header>
                    <Flex className='align-items-center justify-content-between'>
                        <Flex alignItems="center" className="fs-10">
                            <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">Список достижений</h5>
                        </Flex>
                        <Flex>
                            {canEdit && (
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="plus"
                                    transform="shrink-3"
                                    className='me-2'
                                    onClick={() => history.push('/consumer/goals/add/')}
                                >
                                    <span className="d-none d-sm-inline-block ms-1">Создать</span>
                                </IconButton>
                            )}
                            <Flex><AdvanceTableSearchBox/></Flex>
                        </Flex>
                    </Flex>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowInfo
                        rowsPerPageSelection
                        paginationButtons
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableProvider>
    )
}

export default GoalsList;
import { FC } from "react";
import { useSupportMessageContext, useSupportMessageGroupContext } from "../context";

const SupportMessageAvatar: FC = (props) => {

    const group = useSupportMessageGroupContext();
    const message = useSupportMessageContext();

    if (!group && !message) {
        return null;
    }

    const avatar = group?.avatarUrl || message?.avatarUrl;
    const sender = group?.senderName || message?.senderName;

    return (
        <img src={avatar} alt={sender} {...props} />
    )
}

export default SupportMessageAvatar;
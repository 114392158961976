import IService from "@api/iService";
import { IGoal, IRefCode, IRefStat } from "@interfaces/user/refs";

export default class RefService extends IService {

    async getGoals() {
        return await this.getApi<IGoal[]>('/user/goal/');
    }

    async getGoal(goalId: string) {
        return await this.getApi<IGoal>(`/user/goal/${goalId}/`);
    }

    getRefCode() {
        return this.getApi<IRefCode>('/user/ref/');
    }

    createRefCode() {
        return this.postApi<IRefCode>('/user/ref/create/');
    }

    getRefStats() {
        return this.getApi<IRefStat[]>('/user/ref/stats/')
    }
}
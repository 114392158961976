
export interface IGoal {
    id: string;
    name: string;
    description?: string;
    isReached: boolean;
    rewards: IGoalReward[];
}

export interface IGoalReward {
    id: string;
    goalId: string;
    name: string;
    description?: string;
    type: GoalRewardType;
    order: number;
}

export interface IBalanceGoalReward extends IGoalReward {
    balance: number;
}

export interface IItemGoalReward extends IGoalReward {
    itemId: string;
    count: number;
}

export enum GoalRewardType {
    Balance = 'Balance',
    Item = 'Item'
}

export interface IRefCode {
    code: string;
    createTime: string;
    bindingsCount: number;
}

export interface IRefStat {
    id: string;
    rewardId: string;
    rewardName: string;
    receiveTime: string;
}
import * as React from 'react';
import NavbarToggle from './navbar-toggle';
import NavbarDropdowns from './navbar-dropdowns';
import { Navbar } from 'reactstrap';
import { ReduxStore } from '../../../interfaces/store';
import { useSelector } from 'react-redux';

const NavbarComponent: React.FC = () => {

  const isOnRight = useSelector((state: ReduxStore) => state.sidebar.isOnRight);

  return (
    <Navbar expand className="navbar-theme">
      {!isOnRight ? (
        <React.Fragment>
          <NavbarToggle />
          {/* <NavbarSearch /> */}
          <NavbarDropdowns />
        </React.Fragment>
      ) : (
          <React.Fragment>
            <NavbarDropdowns />
            {/* <NavbarSearch /> */}
            <NavbarToggle />
          </React.Fragment>
        )}
    </Navbar>
  );
};


export default NavbarComponent;
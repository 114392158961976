import ConsumerRefService from "@api/consumer/consumerRefService";
import { EditRefTargetForm } from "@components/consumer/refs";
import withPage from "@components/hoc/withPage";
import { useBreadcrumbs, useStateLoader } from "@hooks/panelHooks";
import { IRefTarget } from "@interfaces/consumer/refs";
import { FC, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Card, CardHeader, CardTitle } from "reactstrap";


const EditRefTargetPage: FC = () => {

    const { target_id, ref_id } = useParams<{ target_id: string, ref_id: string }>();

    const loadTarget = useCallback((ref_id: string, reward_id: string) => {
        const api = new ConsumerRefService();
        return api.getRefTarget(ref_id, reward_id);
    }, []);

    const { data: target } = useStateLoader<IRefTarget>(loadTarget, ref_id, target_id);

    useBreadcrumbs('consumer.refs.target.current.name', true, target?.goalName);
    useBreadcrumbs('consumer.refs.current.name', true, target?.refName, `/consumer/refs/${ref_id}/`);
    useBreadcrumbs('consumer.refs.targets', true, null, `/consumer/refs/${ref_id}/targets/`);

    if (!target) {
        return null;
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h5">
                    Редактирование цели {target?.goalName}
                </CardTitle>
            </CardHeader>
            <EditRefTargetForm target={target}/>
        </Card>
    )
}

export default withPage(EditRefTargetPage);
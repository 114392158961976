import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConsumerUserAccount } from "@interfaces/consumer/users";
import { KeyValueSet } from "@interfaces/helpers";
import {FC, Fragment, useState} from "react";
import ChargeGameAccountModal from "./chargeGameAccountModal";
import {useGameServers, useLoginServer} from "@hooks/selectors/consumer/project.selectors";
import {Dropdown} from "react-bootstrap";

interface ChargeGameAccountIconProps {
    account: ConsumerUserAccount;
}

const ChargeGameAccountIcon: FC<ChargeGameAccountIconProps> = ({ account }) => {

    const login = useLoginServer(account.loginId);
    const games = useGameServers(account.loginId);

    const id = `login-${login?.id}-account-${account.id}`;

    const gameModalsInit: KeyValueSet<boolean> = {};
    games.forEach(game => gameModalsInit[game.id] = false);

    const [loginModal, setLoginModal] = useState(false);
    const [gameModals, setGameModals] = useState(gameModalsInit);

    const toggleGameModal = (gameId: string) => {
        const state = {...gameModals};
        state[gameId] = !gameModals[gameId];
        setGameModals(state);
    }

    if (!login) {
        return null;
    }

    return (
        <Dropdown className="font-sans-serif btn-reveal-trigger"
                  align="end"
        >
            <Dropdown.Toggle
                variant="reveal"
                size="sm"
                data-boundary="viewport"
                className="text-600 btn-reveal"
            >
                <FontAwesomeIcon
                    id={id}
                    icon={faMoneyBill}
                    className="fs-11"/>
            </Dropdown.Toggle>
            <Dropdown.Menu className="border py-0">
                <div className="py-2">
                    {
                        (login.canCharge && login.isActive) &&
                        <>
                            <Dropdown.Item onClick={() => setLoginModal(true)}>
                                На логинсервер
                            </Dropdown.Item>
                            <ChargeGameAccountModal
                                id={`${login.id}-${account.id}`}
                                account={account}
                                login={login}
                                isOpened={loginModal}
                                toggle={() => setLoginModal(false)}/>
                        </>
                    }
                    {
                        games.filter(game => game.canCharge && game.isActive).map(game => (
                            <Fragment key={game.id}>
                                <Dropdown.Item onClick={() => toggleGameModal(game.id)}>
                                    На геймсервер {game.name}
                                </Dropdown.Item>
                                <ChargeGameAccountModal
                                    id={`${login.id}-${game.id}-${account.id}`}
                                    account={account}
                                    login={login}
                                    game={game}
                                    isOpened={gameModals[game.id]}
                                    toggle={() => toggleGameModal(game.id)}/>
                            </Fragment>
                        ))
                    }
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default ChargeGameAccountIcon;
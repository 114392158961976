import React, {FC} from "react";
import {SidebarCategoryLink} from "@interfaces/control-panel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Flex, SubtleBadge} from "@components/falcon/common";

interface NavbarVerticalMenuItemProps {
    route: SidebarCategoryLink;
}

const NavbarVerticalMenuItem: FC<NavbarVerticalMenuItemProps> = ({route}) => (
    <Flex alignItems="center">
        {route.icon && (
            <span className="nav-link-icon">
                <FontAwesomeIcon icon={route.icon} />
            </span>
        )}
        <span className="nav-link-text ps-1">{route.name}</span>
        {route.badgeText && (
            <SubtleBadge pill bg={route.badgeColor || 'primary'} className="ms-2">
                {route.badgeText}
            </SubtleBadge>
        )}
    </Flex>
)

export default NavbarVerticalMenuItem;
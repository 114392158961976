import React from 'react';
import { Card, CardHeader, CardTitle, CardBody, Col, Row, Button, Form, Input, FormGroup, Label } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxStore } from '@interfaces/store';
import { ConsumerUserFull } from '@interfaces/consumer/users';
import { useAuth, useLocalizedBlock, useRole } from '@services/hooks';
import { KeyValueSet } from '@interfaces/helpers';
import { useFormik } from 'formik';
import { toastr } from 'react-redux-toastr';
import ConsumerUsersService from '@api/consumer/consumerUsersService';
import { consumerActions } from '@store/consumer';

interface ConsumerUserRolesProps {
    user: ConsumerUserFull;
}

const UserRoles: React.FC<ConsumerUserRolesProps> = ({ user }) => {

    const dispatch = useDispatch();
    const allRoles = useSelector(({ consumer }: ReduxStore) => consumer.users.allowedRoles.data);
    const targetRoles = user.roles;

    const { user: self } = useAuth();
    const canEdit = useRole('consumer.users.roles.edit') && user.id !== self?.id;

    const roleNames = useLocalizedBlock('roles');
    const lang = useLocalizedBlock('consumer.user.roles');

    const values: KeyValueSet<boolean> = {};

    Object.entries(allRoles).forEach(([key, roles]) => {
        roles.forEach(role => values[role.replace(/\./g, ':')] = targetRoles.includes(role))
    })

    const onSubmit = async (values: KeyValueSet<boolean>) => {
        
        if(!canEdit){
            return;
        }

        const roles: KeyValueSet<boolean> = {};
        Object.entries(values).forEach(([role, value]) => roles[role.replace(/:/g, '.')] = value);
        const api = new ConsumerUsersService();
        const result = await api.changeConsumerUserRoles({
            user: user.id,
            roles
        });

        if(result.ok) {
            toastr.success('Изменение прав', 'Права пользователя успешно сохранены.');
            dispatch(consumerActions.loadUser(user.id, true));
        }
        else {
            if(result.status === 403) {
                toastr.error('Изменение прав', 'У вас нет прав для этой операции.');
            }
            else if(result.errorCode === 'NotFound') {
                toastr.error('Изменение прав', 'Пользователь не найден');
            }
            else if(result.errorCode === 'CantEditYourself') {
                toastr.error('Изменение прав', 'Нельзя редактировать свои права');
            }
            else {
                toastr.error('Изменение прав', 'Что-то пошло не так');
            }
        }
    }

    const formik = useFormik({
        initialValues: values,
        onSubmit
    });

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h5">
                    {lang['title']}
                </CardTitle>
            </CardHeader>
            <CardBody>
                <Form onSubmit={formik.handleSubmit} onBlur={formik.handleBlur}>
                    <Row>
                        {
                            Object.entries(allRoles).map(e => (
                                <Col key={`roles.${e[0]}`}>
                                    <Card>
                                        <CardHeader>
                                            <CardTitle tag="h5">
                                                {roleNames[`${e[0]}.title`]}
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            {
                                                e[1].map((role) => {
                                                    const fRole = role.replace(/\./g, ':');
                                                    return (
                                                        <FormGroup check key={`role.${role}`} >
                                                            <Input 
                                                            key={`role.${role}`} 
                                                            disabled={!canEdit} 
                                                            type="checkbox" 
                                                            id={role} name={fRole} 
                                                            onChange={formik.handleChange} 
                                                            checked={formik.values[fRole]} 
                                                            value={formik.values[fRole].toString()} />
                                                            <Label check>{roleNames[`${role}.name`]}</Label>
                                                        </FormGroup>
                                                    )
                                                })
                                            }
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
                    {
                        canEdit &&
                        <div className="text-center mt-3">
                            <Button type="submit" color="primary" disabled={formik.isSubmitting}>
                                {lang['save']}
                            </Button>
                        </div>
                    }
                </Form>
            </CardBody>
        </Card>
    )
}

export default UserRoles;
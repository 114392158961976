import { useCharacters } from "@hooks/selectors/user.selectors";
import classNames from "classnames";
import { Field, useFormikContext } from "formik";
import { FC } from "react";
import { SelectorsFormsValues } from ".";
import { FormFieldProps } from "..";

const CharacterSelector: FC<FormFieldProps> = ({ className, invalidClassName, ...props }) => {
    const { errors, touched, values: { selected_account, selected_game_server } } = useFormikContext<SelectorsFormsValues>();

    const characters = useCharacters(selected_account || '', selected_game_server);

    const invalid = Boolean(errors['selected_character'] && touched['selected_character']);

    const cn = classNames(className, invalidClassName && {
        [invalidClassName]: invalid
    });

    return (
        <Field
            id="selected_character"
            name="selected_character"
            as="select"
            className={cn}
            invalid={invalid || undefined}
            {...props}>
            <option value='' key={'empty'}>Выберите персонажа</option>
            {
                selected_game_server &&
                characters.map(character => <option value={character.id} key={character.id}>{character.name}</option>)
            }
        </Field>
    )
}

export default CharacterSelector;
import { useDispatch, useSelector } from "react-redux";
import { ReduxStore } from "../../../interfaces/store";
import { useEffect } from "react";
import { userActions } from '../../../redux/user';

export function useShortNews() {

    const dispatch = useDispatch();
    const state = useSelector(({user}: ReduxStore) => user.shortNews);

    useEffect(() => {
        dispatch(userActions.loadNews());
    }, [dispatch])
    return state;
}
import { Loadable } from '../../interfaces/helpers';
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { UserNotification } from '../../interfaces/user/users';
import UserService from '../../services/api/userService';

const slice = createSlice({
    initialState: {
        loaded: false,
        loading: false,
        error: null,
        data: []
    } as Loadable<UserNotification[]>,
    name: 'userNotificationsStore',
    reducers: {
        setLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        setNotifications: (state, action: PayloadAction<UserNotification[]>) => {
            state.data = action.payload;
            state.error = null;
            state.loaded = true;
            state.loading = false;
        },
        setReaded: (state, action: PayloadAction<string>) => {
            const n = state.data.find(n => n.id === action.payload);
            if (n) {
                n.isReaded = true;
            }
        },
        setAllReaded: (state) => {
            state.data.filter(x => !x.isReaded)
                .forEach(x => x.isReaded = true);
        },
        removeNotification: (state, action: PayloadAction<string>) => {
            state.data = state.data.filter(n => n.id !== action.payload);
        },
        setNotification: (state, action: PayloadAction<UserNotification>) => {
            const not = action.payload;

            const idx = state.data.findIndex(n => n.id === not.id);
            if (idx >= 0) {
                state.data[idx] = not;
            }
            else {
                state.data.push(not);
            }
        }
    }
})

export const { reducer: userNotificationsReducer } = slice;

const {
    setError,
    setLoading,
    setNotifications,
    setNotification,
    setAllReaded
} = slice.actions;

export const { removeNotification, setReaded: setNotificationReaded } = slice.actions;

export const notificationActions = {
    loadNotifications: () => async (dispatch: Dispatch) => {
        dispatch(setLoading());
        try {
            const api = new UserService();
            const nots = await api.fetchUserNotifications();
            dispatch(setNotifications(nots));
            return nots;
        }
        catch (e) {
            dispatch(setError(e));
        }
    },
    loadNotification: (id: string) => async (dispatch: Dispatch) => {

        try {
            const api = new UserService();
            const not = await api.fetchUserNotification(id);
            dispatch(setNotification(not));
            return not;
        }
        catch (e) {
            dispatch(setError(e));
        }
    },
    /**
     * Пометить уведомление прочитанным на сервере
     * Для изменения только на клиенте использовать setNotificationReaded
     * @param id 
     * @returns 
     */
    readNotification: (id: string) => async (dispatch: Dispatch) => {

        const api = new UserService();
        const result = await api.readUserNotification(id);

        if (result.ok) {
            dispatch(setNotificationReaded(id));
        }
        return result;
    },
    /**
     * Удаляет уведомление на сервере
     * Для удаления уведомления только на клиенте использовать removeNotification
     * @param id 
     * @returns 
     */
    deleteNotification: (id: string) => async (dispatch: Dispatch) => {
        const api = new UserService();
        const result = await api.deleteUserNotification(id);
    
        if (result.ok) {
            dispatch(removeNotification(id));
        }
        return result;
    },
    readAllNotifications: () => async (dispatch: Dispatch) => {
        const api = new UserService();
        const result = await api.readAllUserNotifications();

        if (result.ok) {
            dispatch(setAllReaded());
        }

        return result;
    },
    removeNotification,
    setNotificationReaded,
    setNotifications
}

export const loadNotifications = () => async (dispatch: Dispatch) => {
    dispatch(setLoading());
    try {
        const api = new UserService();
        const nots = await api.fetchUserNotifications();
        dispatch(setNotifications(nots));
        return nots;
    }
    catch (e) {
        dispatch(setError(e));
    }
}

export const loadNotification = (id: string) => async (dispatch: Dispatch) => {

    try {
        const api = new UserService();
        const not = await api.fetchUserNotification(id);
        dispatch(setNotification(not));
        return not;
    }
    catch (e) {
        dispatch(setError(e));
    }
}

/**
 * Пометить уведомление прочитанным на сервере
 * Для изменения только на клиенте использовать setNotificationReaded
 * @param id 
 * @returns 
 */
export const readNotification = (id: string) => async (dispatch: Dispatch) => {

    const api = new UserService();
    const result = await api.readUserNotification(id);

    if (result.ok) {
        dispatch(setNotificationReaded(id));
    }
    return result;
}

/**
 * Удаляет уведомление на сервере
 * Для удаления уведомления только на клиенте использовать removeNotification
 * @param id 
 * @returns 
 */
export const deleteNotification = (id: string) => async (dispatch: Dispatch) => {
    const api = new UserService();
    const result = await api.deleteUserNotification(id);

    if (result.ok) {
        dispatch(removeNotification(id));
    }
    return result;
}
import { injectChildArray } from "@components/constructor";
import { useAccounts } from "@hooks/selectors/user.selectors";
import { useFormikContext } from "formik";
import {FC, PropsWithChildren, useEffect, useMemo} from "react";
import { SelectorsFormsValues } from "../selectors";

interface AccountsListProps {
    clear_on_change?: boolean;
}

interface AccountsListChildProps {
    account_id: string;
    account_name: string;
}

const AccountsList: FC<PropsWithChildren<AccountsListProps>> = ({ clear_on_change, children }) => {

    const {
        values: { selected_login_server, selected_account },
        touched,
        setFieldValue,
        setFieldTouched
    } = useFormikContext<SelectorsFormsValues>();

    const accounts = useAccounts(selected_login_server || undefined);

    useEffect(() => {
        if (touched.selected_account && clear_on_change) {
            setFieldValue('selected_character', '');
            setFieldTouched('selected_character', false);
        }
    }, [clear_on_change, setFieldTouched, setFieldValue, touched.selected_account, selected_account]);

    const data = useMemo<AccountsListChildProps[]>(() => accounts.map(account => {
        return {
            account_id: account.id,
            account_name: account.name
        }
    }), [accounts]);

    if(!selected_login_server) {
        return null;
    }
    
    return injectChildArray(data, children, 'account_id');
}

export default AccountsList;
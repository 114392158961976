import {FC} from "react";
import {Card} from "react-bootstrap";
import classNames from "classnames";
import {SubtleBadge, Background} from "@components/falcon/common";
import CountUp from 'react-countup';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface StatisticsCardProps {
    title: string;
    value: number;
    decimal?: boolean;
    suffix?: string;
    prefix?: string;
    valueClassName?: string;
    linkText?: string;
    link?: string;
    badgeText?: string;
    badgeBg?: 'primary' |
        'secondary' |
        'success' |
        'info' |
        'warning' |
        'danger' |
        'light' |
        'dark';
    image?: string;
    className?: string;
}

const StatisticsCard: FC<StatisticsCardProps> = ({title,
                                                     value,
                                                     decimal,
                                                     suffix,
                                                     prefix,
                                                     valueClassName,
                                                     linkText,
                                                     link,
                                                     badgeText,
                                                     badgeBg = 'primary',
                                                     image,
                                                     className, ...rest}) => {

    return (
        <Card className={classNames(className, 'overflow-hidden')} {...rest}>
            {image && <Background image={image} className="bg-holder" />}
            <Card.Body className="position-relative">
                <h6>
                    {title}
                    {badgeText && (
                        <SubtleBadge bg={badgeBg} pill className="ms-2">
                            {badgeText}
                        </SubtleBadge>
                    )}
                </h6>
                <div
                    className={classNames(
                        valueClassName,
                        'display-4 fs-5 mb-2 fw-normal font-sans-serif'
                    )}
                >
                    <CountUp
                        start={0}
                        end={value}
                        duration={2.75}
                        suffix={suffix}
                        prefix={prefix}
                        separator=","
                        decimals={decimal ? 2 : 0}
                        decimal="."
                    />
                </div>
                {link && (
                    <Link to={link} className="fw-semibold fs-10 text-nowrap">
                        {linkText}
                        <FontAwesomeIcon
                            icon="angle-right"
                            className="ms-1"
                            transform="down-1"
                        />
                    </Link>
                )}
            </Card.Body>
        </Card>
    )
}

export default StatisticsCard;
import { useGameServer, useLoginServer } from "@hooks/selectors/game.selectors";
import { useFormikContext } from "formik";
import { FC } from "react";
import { GameIdProps } from "@constructor/components/core/servers/game";
import { ChargeAccountFormValues } from "./chargeAccountForm";
import { Text } from '@components/constructor/components';
import { LoginIdProps } from "../../loginServer";

const ChargeAccountCount: FC<LoginIdProps & GameIdProps> = ({ login_id, game_id, ...props }) => {

    const { 
        values: { 
            count,
            selected_login_server, 
            selected_game_server 
        }
    } = useFormikContext<ChargeAccountFormValues>();

    const login = useLoginServer(selected_login_server);
    const gameServer = useGameServer(selected_game_server);

    let rate: number = 0;

    if(gameServer?.canCharge) {
        rate = gameServer.chargePower;
    }
    else if(login?.canCharge) {
        rate = login.chargePower;
    }

    const value = count * rate;

    return (
        <Text text={value} {...props} />
    )
}

export default ChargeAccountCount;
import { createContext, RefObject, useContext } from "react";
import { ReactCropperElement } from "react-cropper";

export interface IAvatarContext {
    cropper_ref: RefObject<ReactCropperElement>;
    imageUrl: string | null;
}

const AvatarContext = createContext<IAvatarContext>(undefined as any);
export const AvatarProvider = AvatarContext.Provider;

export function useUploadAvatarContext(): IAvatarContext | undefined {
    return useContext(AvatarContext);
}
import React from 'react';
import { EditShortNewsForm } from '../../../consumer/shortnews';
import { EditShortNewsFormValues } from '../../../consumer/shortnews/editShortNewsForm';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import withPage from '../../../hoc/withPage';
import ConsumerShortNewsService from '../../../../services/api/consumer/consumerShortNewsService';

const AddShortNewsPage: React.FC = () => {

    const history = useHistory();

    const onSubmit = async (values: EditShortNewsFormValues) => {

        const {beginTime, endTime} = values;
        const api = new ConsumerShortNewsService();
        const result = await api.putConsumerShortNews({
            ...values,
            beginTime: beginTime.toUTCString(),
            endTime: endTime.toUTCString()
        });

        if(result.ok) {
            toastr.success('Добавление новости', 'Новость успешно добавлена.');
            history.replace(`/consumer/shortnews/${result.result}`);
        }
        else {
            if(result.errorCode === 'InvalidModel') {
                toastr.success('Добавление новости', 'Ошибка запроса. Обновите страницу и попробуйте еще раз.');
            }
        }
    };

    return (
        <EditShortNewsForm
            isNew
            onSubmit={onSubmit}/>
    )
}

export default withPage(AddShortNewsPage);
import {
    IConsumerMarketCategory,
    IConsumerMarketCategoryFull,
    IConsumerMarketItem,
    IConsumerMarketItemFull, ISearchCategory,
} from '@interfaces/consumer/market';
import { PanelTableData } from '@interfaces/panel';
import {
    ISearchCategoryModel,
    PatchConsumerMarketCategoryModel,
    PatchConsumerMarketItemModel,
    PutConsumerMarketCategoryModel,
    PutConsumerMarketItemModel
} from '@models/consumer/market';
import { PanelTableDataModel } from '@models/panel';
import IService from '../iService';
import {OperationResult} from "@interfaces/panel/files";

export default class ConsumerMarketService extends IService {
    async putConsumerMarketCategory(category: PutConsumerMarketCategoryModel) {
        return await this.putApi<OperationResult>('/consumer/market/category/', category);
    }

    async putConsumerMarketItem(item: PutConsumerMarketItemModel) {
        return await this.putApi<OperationResult>('/consumer/market/item/', item);
    }

    fetchConsumerMarketCategoriesList = async (model: PanelTableDataModel) => {
        return await this.postApi<PanelTableData<IConsumerMarketCategory[]>>('/consumer/market/category/', model);
    }

    async fetchConsumerMarketCategory(id: string) {
        return await this.getApi<IConsumerMarketCategoryFull>(`/consumer/market/category/${id}/`)
    }

    fetchConsumerMarketItemsList = async (model: PanelTableDataModel) => {
        return await this.postApi<PanelTableData<IConsumerMarketItem[]>>('/consumer/market/item/', model);
    }

    fetchConsumerMarketItem = async (id: string) => {
        return await this.getApi<IConsumerMarketItemFull>(`/consumer/market/item/${id}/`);
    }

    async patchConsumerMarketCategory(category: PatchConsumerMarketCategoryModel) {
        return await this.patchApi<OperationResult>('/consumer/market/category/', category);
    }

    async patchConsumerMarketItem(item: PatchConsumerMarketItemModel) {
        return await this.patchApi<OperationResult>('/consumer/market/item/', item);
    }

    async searchCategories(model: ISearchCategoryModel) {
        return await this.postApi<ISearchCategory[]>('/consumer/market/category/search/', model);
    }
}
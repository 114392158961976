import { Text } from "@components/constructor/components";
import { FC } from "react";
import { useSupportMessageContext, useSupportMessageGroupContext } from "../context";

const SupportMessageSenderName: FC = (props) => {

    const group = useSupportMessageGroupContext();
    const message = useSupportMessageContext();

    const name = group?.senderName || message?.senderName;
    if (!name) {
        return null;
    }

    return (
        <Text text={name} {...props} />
    )
}

export default SupportMessageSenderName;
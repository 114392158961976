import {useState, FC} from 'react';
import { Card } from 'react-bootstrap';
import withPage from '../../../hoc/withPage';
import { ConsumerUser } from '@interfaces/consumer/users';
import { dateFormatter } from '@services/helpers';
import {Link} from 'react-router-dom';
import ConsumerUsersService from '../../../../services/api/consumer/consumerUsersService';
import AdvanceTableProvider from "@components/falcon/common/tables/context";
import {
    AdvanceTable,
    AdvanceTableFooter,
    AdvanceTableSearchBox
} from "@components/falcon/common/tables";
import {useRemoteTable} from "@hooks/tableHooks";
import {ColumnDef} from "@tanstack/react-table";
import {Avatar, Flex} from "@components/falcon/common";

const columns: ColumnDef<ConsumerUser>[] = [
    {
        accessorKey: 'userName',
        header: 'Логин',
        enableSorting: true,
        meta: {
            headerProps: { className: 'pe-1 text-900' },
            cellProps: {
                className: 'py-2'
            }
        },
        cell: ({row: {original: {id, userName, avatarId}}}) => (
            <Link to={`/consumer/users/${id}/`}>
                <Flex alignItems="center">
                    <Avatar fileId={avatarId} name={userName} size="xl" className="me-2" rounded="circle"/>
                    <div className="flex-1">
                        <h5 className="mb-0 fs-10">{userName}</h5>
                    </div>
                </Flex>
            </Link>
        )
    },
    {
        accessorKey: 'email',
        header: 'Email',
        enableSorting: true,
        meta: {
            headerProps: { className: 'text-900' }
        }
    },
    {
        accessorKey: 'createTime',
        header: 'Дата создания',
        enableSorting: true,
        cell: ({row: {original: {createTime}}}) => dateFormatter(createTime),
    },
    {
        accessorKey: 'balance',
        header: 'Баланс',
        enableSorting: true,
        meta: {
            headerProps: { className: 'text-900' }
        }
    }
];

const ConsumerUserListPage: FC = () => {

    const [api] = useState(() => new ConsumerUsersService());
    const table = useRemoteTable({
        name: 'consumer.users.list',
        columns,
        defaultState: {
            sorting: [
                {
                    id: 'createTime',
                    desc: true
                }
            ]
        }
    }, api.fetchConsumerUsers);

    return (
        <AdvanceTableProvider table={table}>
            <Card className="mb-3">
                <Card.Header>
                    <Flex className='align-items-center justify-content-between'>
                        <Flex alignItems="center" className="fs-10">
                            <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">Пользователи</h5>
                        </Flex>
                        <Flex>
                            <AdvanceTableSearchBox/>
                        </Flex>
                    </Flex>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowInfo
                        rowsPerPageSelection
                        paginationButtons
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableProvider>
    )
}

export default withPage(ConsumerUserListPage);
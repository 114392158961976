import React, {PropsWithChildren} from 'react';
import {Form, Col, Row, InputGroup} from 'react-bootstrap';
import {FieldProps} from "formik";

interface FormikRowInputProps extends FieldProps {
    description?: string;
    label?: string;
    id?: string;
    placeholder?: string;
    labelSize?: number;
    prefix?: string;
    suffix?: string;
}

const FormikRowInput: React.FC<PropsWithChildren<FormikRowInputProps>> = (prop) => {
    const {
        field,
        form: { touched, errors },
        description,
        label,
        labelSize = 3,
        children,
        prefix,
        suffix,
        ...props
    } = prop;
    return (
        <Form.Group as={Row} className="mb-3">
            <Form.Label
                column
                htmlFor={props.id}
                sm={labelSize}>{label}</Form.Label>
            <Col sm={12 - labelSize}>
                { prefix || suffix ? (
                    <InputGroup>
                        {prefix && <InputGroup.Text>{prefix}</InputGroup.Text>}
                        <Form.Control {...props} {...field} isInvalid={Boolean(touched[field.name] && errors[field.name])} />
                        {suffix && <InputGroup.Text>{suffix}</InputGroup.Text>}
                    </InputGroup>
                ) : (
                    <Form.Control {...props} {...field} isInvalid={Boolean(touched[field.name] && errors[field.name])} />
                )}
                {touched[field.name] && errors[field.name] ? <Form.Control.Feedback type="invalid">{errors[field.name]?.toString()}</Form.Control.Feedback> : ''}
                {description && <Form.Text>{description}</Form.Text>}
            </Col>
        </Form.Group>
    );
}
export default FormikRowInput;
import { ReduxStore } from '@interfaces/store';
import { reducer as toastr } from "react-redux-toastr";
import thunk from 'redux-thunk';
import { panelReducer } from '../panelStore';
import { userReducer } from '../user';
import { consumerReducer } from '../consumer';
import { sidebarReducer } from '../sidebarStore';
import { queryReducer } from '../queryStore';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

const rootReducer = combineReducers<ReduxStore>({
    user: userReducer,
    sidebar: sidebarReducer,
    panel: panelReducer,
    toastr: toastr,
    consumer: consumerReducer,
    queries: queryReducer
});

//const store = createStore(rootReducer, devToolsEnhancer({}));
const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk]
});
export default store;
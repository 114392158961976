import { getComponent } from "@components/constructor";
import { useCurrentLanguage } from "@hooks/languageHooks";
import classNames from "classnames";
import { Field, useFormikContext } from "formik";
import { FC } from "react";
import { FormFieldProps } from ".";

const FormField: FC<FormFieldProps & any> = ({ children, className, name, invalidClassName, ...props }) => {

    const newProps = { ...props };

    const lang = useCurrentLanguage().data?.strings;
    const { errors, touched } = useFormikContext<any>();

    if (lang) {
        Object.entries(props).forEach(([key, value]) => {

            if (typeof value === 'string' && /^\{[A-Za-z.]+\}$/.test(value)) {
                const c: string = value.substring(1, value.length - 1);
                if (lang[c]) {
                    newProps[key] = lang[c];
                }
            }
        });
    }

    if (newProps.component && typeof newProps.component === 'string') {
        newProps.component = getComponent(newProps.component);
    }

    const invalid = Boolean(errors[name] && touched[name]);

    const cn = classNames(className, invalidClassName && {
        [invalidClassName]: invalid
    });

    return (
        <Field
            className={cn}
            name={name}
            invalid={invalid || undefined}
            {...newProps}>
            {children}
        </Field>
    )
}

export default FormField;
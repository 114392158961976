import { injectContextArray } from "@components/constructor";
import { useUsedCodes } from "@hooks/userHooks";
import { IUsedBonusCode } from "@interfaces/user/bonusCodes";
import {FC, PropsWithChildren, useMemo} from "react";
import { PaginationProps } from "@constructor/components/core/pagination";
import { BonusLogProvider } from "./context";
import {InjectContextType} from "@interfaces/constructor/constructor";

const BonusCodeLogsList: FC<PropsWithChildren<PaginationProps>> = ({ pagination_current_page = 0, pagination_per_page = 10, children }) => {
    const { data: logs } = useUsedCodes();

    const page = logs.slice(pagination_current_page * pagination_per_page, (pagination_current_page + 1) * pagination_per_page);

    const data = useMemo<InjectContextType<IUsedBonusCode>[]>(() => page.map((log, idx) => {
        return {
            key: log.code,
            value: log,
            childData: {
                bonus_code_log_index: pagination_current_page * pagination_per_page + 1 + idx
            }
        }
    }), [page, pagination_current_page, pagination_per_page]);

    return injectContextArray(data, BonusLogProvider, children);
}

export default BonusCodeLogsList;
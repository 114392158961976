import { useEmail } from "@hooks/selectors/user.selectors";
import { FC } from "react";
import { Text } from '@components/constructor/components';

const UserEmail: FC = (props) => {

    const email = useEmail();

    return (
        <Text text={email} {...props} />
    )
}

export default UserEmail;
import {ConsumerTicket, ConsumerTicketMessage} from "@interfaces/consumer/support";
import {UploadFileInfo} from "@interfaces/panel/files";
import {createContext, useContext} from "react";

interface UploadAttachmentState {
    id: string;
    uploading: boolean;
    uploadSuccess: boolean;
    uploadFailed: boolean;
    uploadProgress: number;
}

export interface ISupportTopicContext {
    topic?: ConsumerTicket;
    editableMessageId?: string;
    attachedImages?: {
        id: string;
        file: File;
        fileUrl: string;
        uploadProgress?: UploadAttachmentState
    }[];
    setAttachedImages: (files: File[]) => void;
    removeAttachedImage: (id: string) => void;
    uploadAttachedImages: (ufi: UploadFileInfo[]) => Promise<any>;
    setEditableMessageId: (id?: string) => void;
    deleteMessage: (topicId: string, messageId: string) => void;

    setCurrentTopic: (topic?: ConsumerTicket) => void;

    topicInfo: {
        isThreadInfoOpened: boolean;
        setThreadInfoOpened: (opened: boolean) => void;
    },
    topicActions: {
        closeTicket: () => void;
        openTicket: () => void;
        deleteTicket: () => void;
    },
    sidebar: {
        hideSidebar: boolean;
        setHideSidebar: (hide: boolean) => void;
    },
    scroll: {
        scrollToBottom: boolean;
        setScrollToBottom: (scroll: boolean) => void;
    }
}

export const SupportTopicContext = createContext<ISupportTopicContext>(undefined as any);
export const SupportTopicProvider = SupportTopicContext.Provider;

export function useSupportTopicContext(): ISupportTopicContext {
    return useContext(SupportTopicContext);
}

export const SupportMessageContext = createContext<ConsumerTicketMessage>(undefined as any);
export const SupportMessageProvider = SupportMessageContext.Provider;

export function useSupportMessageContext(): ConsumerTicketMessage {
    return useContext(SupportMessageContext);
}
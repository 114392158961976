import {FC, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {Card, Col, Row} from 'react-bootstrap';
import { IItem } from "@interfaces/consumer/items";
import ConsumerItemsService from "@services/api/consumer/consumerItemsService";
import withPage from "@components/hoc/withPage";
import {ColumnDef} from "@tanstack/react-table";
import {Avatar, Flex, IconButton} from "@components/falcon/common";
import {useRemoteTable} from "@hooks/tableHooks";
import AdvanceTableProvider from "@components/falcon/common/tables/context";
import {AdvanceTable, AdvanceTableFooter, AdvanceTableSearchBox} from "@components/falcon/common/tables";

const columns: ColumnDef<IItem>[] = [
    {
        accessorKey: 'name',
        header: 'Название',
        enableSorting: true,
        meta: {
            headerProps: { className: 'pe-1 text-900' },
            cellProps: {
                className: 'py-2'
            }
        },
        cell: ({row: {original: {id, iconId, name}}}) => (
            <Link to={`/consumer/item/${id}/`}>
                <Flex alignItems="center">
                    <Avatar fileId={iconId} name={name} size="xl" className="me-2"/>
                    <div className="flex-1">
                        <h5 className="mb-0 fs-10">{name}</h5>
                    </div>
                </Flex>
            </Link>
        )
    },
    {
        accessorKey: 'innerId',
        header: 'Внутренний ID',
        enableSorting: true,
        meta: {
            headerProps: { className: 'text-900' }
        }
    }
];

const ItemsList: FC = () => {

    const history = useHistory();
    const [api] = useState(() => new ConsumerItemsService());

    const table = useRemoteTable({
        name: 'consumer.items.list',
        columns,
        defaultState: {
            sorting: [
                {
                    id: 'createTime',
                    desc: true
                }
            ]
        }
    }, api.fetchItemsList);

    return (
        <AdvanceTableProvider table={table}>
            <Card className="mb-3">
                <Card.Header>
                    <Row className="flex-between-center">
                        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                            <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">Предметы</h5>
                        </Col>
                        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                                <div className="d-flex">
                                    <IconButton
                                        variant="falcon-default"
                                        size="sm"
                                        icon="plus"
                                        transform="shrink-3"
                                        className='me-2'
                                        onClick={() => history.push('/consumer/item/add/')}
                                    >
                                        <span className="d-none d-sm-inline-block ms-1">Создать</span>
                                    </IconButton>
                                    <Flex><AdvanceTableSearchBox/></Flex>
                                </div>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowInfo
                        rowsPerPageSelection
                        paginationButtons
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableProvider>
    )
}

export default withPage(ItemsList);
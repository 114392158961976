import {ElementType, FC, PropsWithChildren, ReactNode} from "react";
import classNames from "classnames";
import {Card, Col, Row} from "react-bootstrap";

interface TitleProps {
    breakPoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    className?: string;
    titleTag?: ElementType;
}

const Title: FC<PropsWithChildren<TitleProps>> = ({
    titleTag: TitleTag = 'h5',
    className,
    breakPoint,
    children
                                                  }) => (
    <TitleTag
        className={classNames(
            {
                'mb-0': !breakPoint,
                [`mb-${breakPoint}-0`]: !!breakPoint
            },
            className
        )}
    >
        {children}
    </TitleTag>
)

interface FalconCardHeaderProps {
    title: string;
    light?: boolean;
    breakPoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    endEl?: ReactNode;
    titleTag?: ElementType;
    titleClass?: string;
    className?: string;
}

const FalconCardHeader: FC<PropsWithChildren<FalconCardHeaderProps>> = ({
    title,
    light = true,
    breakPoint,
    endEl,
    titleTag,
    titleClass,
    className,
    children
                                                      }) => (
    <Card.Header className={classNames(className, { 'bg-body-tertiary': light })}>
        {endEl ? (
            <Row className="align-items-center g-2">
                <Col>
                    <Title
                        breakPoint={breakPoint}
                        titleTag={titleTag}
                        className={titleClass}
                    >
                        {title}
                    </Title>
                    {children}
                </Col>
                <Col
                    {...{ [breakPoint ? breakPoint : 'xs']: 'auto' }}
                    className={`text${breakPoint ? `-${breakPoint}` : ''}-right`}
                >
                    {endEl}
                </Col>
            </Row>
        ) : (
            <Title breakPoint={breakPoint} titleTag={titleTag} className={titleClass}>
                {title}
            </Title>
        )}
    </Card.Header>
)

export default FalconCardHeader;
import {FC} from "react";
import {useSupportTopicContext} from "@components/consumer/support/context";
import {Button, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {Flex} from "@components/falcon/common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {dateFormatter} from "@services/helpers";


const ChatHeader: FC = () => {

    const {
        topic,
        topicInfo: {
            isThreadInfoOpened, setThreadInfoOpened
        },
        topicActions: {
            closeTicket,
            openTicket,
            deleteTicket
        },
        sidebar: {
            setHideSidebar
        }
    } = useSupportTopicContext();

    return (
        <div className="chat-content-header">
            <Row className="flex-between-center">
                <Col xs={6} md={8} as={Flex} alignItems="center">
                    <div
                        className="pe-3 text-700 d-md-none contacts-list-show cursor-pointer"
                        onClick={() => setHideSidebar(true)}
                    >
                        <FontAwesomeIcon icon="chevron-left" />
                    </div>
                    <div className="min-w-0">
                        <h5 className="mb-0 text-truncate fs-9">{topic?.name}</h5>
                        <div className="fs-11 text-400">
                            {topic && dateFormatter(topic.updateTime)}
                        </div>
                    </div>
                </Col>
                <Col xs="auto">
                    {topic?.isClosed ? (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip style={{ position: 'fixed' }}>
                                    Переоткрыть
                                </Tooltip>
                            }
                        >
                            <Button
                                variant="falcon-primary"
                                className="me-2"
                                size="sm"
                                onClick={openTicket}
                            >
                                <FontAwesomeIcon icon="lock-open" />
                            </Button>
                        </OverlayTrigger>
                    ) : (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip style={{ position: 'fixed' }}>
                                    Закрыть
                                </Tooltip>
                            }
                        >
                            <Button
                                variant="falcon-primary"
                                className="me-2"
                                size="sm"
                                onClick={closeTicket}
                            >
                                <FontAwesomeIcon icon="lock" />
                            </Button>
                        </OverlayTrigger>
                    )}
                    {topic?.isClosed && (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip style={{ position: 'fixed' }}>
                                    Удалить
                                </Tooltip>
                            }
                        >
                            <Button
                                variant="falcon-primary"
                                className="me-2"
                                size="sm"
                                onClick={deleteTicket}
                            >
                                <FontAwesomeIcon icon="trash-alt" />
                            </Button>
                        </OverlayTrigger>
                    )}
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip style={{ position: 'fixed' }}>
                                Conversation Information
                            </Tooltip>
                        }
                    >
                        <Button
                            variant="falcon-primary"
                            size="sm"
                            onClick={() => setThreadInfoOpened(!isThreadInfoOpened)}
                        >
                            <FontAwesomeIcon icon="info" />
                        </Button>
                    </OverlayTrigger>
                </Col>
            </Row>
        </div>
    )
}

export default ChatHeader;
import { PaymentAgregator } from "@interfaces/consumer/payments";

export enum EPaymentBonusType {
    Fixed,
    Percent
}

export enum EThresholdType {
    Total,
    OneTime,
    Aggregator
}

export enum EModifyType {
    Add,
    Dec
}

export enum EPaymentCurrency {
    RUB = 'RUB',
    EUR = 'EUR',
    USD = 'USD',
    KZT = 'KZT',
    BYN = 'BYN',
    UAH = 'UAH'
}

export interface IDonateBonus {
    id: string;
    value: number;
    type: EPaymentBonusType;
    threshold: number;
    thresholdType: EThresholdType;
    startDate?: string;
    startTime?: string;
    endDate?: string;
    endTime?: string;
    daysOfWeek: number[];
    modifyType: EModifyType;
    aggregators: PaymentAgregator[];
}

export interface IPaymentSettings {
    defaultCurrency: EPaymentCurrency;
    currencies: IPaymentCurrency[];
    donateMin: number;
    donateMax: number;
}

export interface IPaymentCurrency {
    currency: EPaymentCurrency;
    value: number;
    donateMin?: number;
    donateMax?: number;
}
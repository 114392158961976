import React from 'react';
import { Row, Col, Card, Spinner } from 'reactstrap';

interface LoadingPageProps {
    className?: string;
    color?: string;
}

const LoadingPage: React.FC<LoadingPageProps> = ({color, className}) => {
    return (
        <Row>
            <Col>
                <Card body>
                    <Spinner color={color} className={className} style={{margin: 'auto', alignSelf: 'center', width: '8rem', height: '8rem'}}/>
                </Card>
            </Col>
        </Row>
    )
}

export default LoadingPage;
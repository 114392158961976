import {FC, PropsWithChildren, useCallback, useMemo, useState} from "react";
import {ISupportTopicContext, SupportTopicContext} from "@components/consumer/support/context";
import {useHistory, useParams} from "react-router-dom";
import {useTopic} from "@hooks/selectors/consumer/support.selectors";
import {ConsumerTicket} from "@interfaces/consumer/support";
import {randomString} from "@services/helpers";
import {DownloadFileInfo, UploadFileInfo} from "@interfaces/panel/files";
import {FileUploader} from "@services/files/fileUploader";
import {toastr} from "react-redux-toastr";
import {usePromisedDispatch} from "@hooks/helperHooks";
import {consumerActions} from "@store/consumer";
import FetchResult from "@models/fetchResult";
import {useSubscribe} from "@hooks/signalr";
import {ConsumerSupportMessageCreatedView} from "@interfaces/signalr";

interface UploadAttachmentState {
    id: string;
    uploading: boolean;
    uploadSuccess: boolean;
    uploadFailed: boolean;
    uploadProgress: number;
}

interface SupportTopicState {
    hideSidebar: boolean;
    scrollToBottom: boolean;
    isThreadInfoOpened: boolean;
    editableMessageId?: string;
    attachedImages: {
        id: string;
        file: File;
        fileUrl: string;
        uploadProgress?: UploadAttachmentState;
    }[];
}

const ChatProvider: FC<PropsWithChildren> = ({children}) => {

    const { id } = useParams<{id: string}>();
    const history = useHistory();
    const dispatch = usePromisedDispatch();

    const [state, setState] = useState<SupportTopicState>({
        hideSidebar: true,
        scrollToBottom: false,
        isThreadInfoOpened: false,
        attachedImages: []
    });

    const ticket = useTopic(id);

    const openTicket = useCallback(async () => {

        if(!ticket?.id) {
            return;
        }
        
        await dispatch(consumerActions.support.changeTopicStatus(ticket.id, false));

    }, [dispatch, ticket?.id]);

    const closeTicket = useCallback(async () => {
        
        if(!ticket?.id) {
            return;
        }
        
        await dispatch(consumerActions.support.changeTopicStatus(ticket.id, true));
        
    }, [dispatch, ticket?.id]);

    const deleteTicket = useCallback(async () => {

        if(!ticket?.id) {
            return;
        }
        
        const result = await dispatch(consumerActions.support.deleteTopic(ticket.id));
        if(result.ok) {
            history.replace("/consumer/support/");
        }
        
    }, [dispatch, history, ticket?.id]);

    const setCurrentTopic = useCallback((topic?: ConsumerTicket) => {
        if (topic) {
            history.push(`/consumer/support/${topic.id}`);
        }
        else {
            history.push(`/consumer/support/`);
        }
    }, [history]);

    const deleteMessage = useCallback((topicId: string, messageId: string) => {
        dispatch(consumerActions.support.deleteMessage(topicId, messageId));
    }, [dispatch]);

    const setAttachedImages = useCallback((images: File[]) => {
        setState((state) => {

            const removedImages = state.attachedImages.filter(
                file => !images.some(image => image === file.file)
            ) ?? [];
            const addedImages = images.filter(
                image => !state.attachedImages.some(file => file.file === image)
            ).map(file => ({
                id: randomString(),
                file,
                fileUrl: URL.createObjectURL(file)
            }));

            removedImages.forEach(file => URL.revokeObjectURL(file.fileUrl));

            return {
                ...state,
                attachedImages: state.attachedImages.filter(file =>
                    !removedImages.some(image => image.id === file.id)
                ).concat(addedImages)
            }
        })
    }, []);

    const setUploadState = useCallback((fileId: string, attachState: Partial<UploadAttachmentState>) => {
        setState(state => {

            const fileIdx = state.attachedImages.findIndex(file => file.id === fileId);
            if(fileIdx < 0) return state;

            const newAttachedImages = state.attachedImages.slice();
            newAttachedImages[fileIdx] = {
                ...newAttachedImages[fileIdx],
                uploadProgress: {
                    ...newAttachedImages[fileIdx].uploadProgress!,
                    ...attachState
                }
            }

            return {
                ...state,
                attachedImages: newAttachedImages
            }
        })
    }, []);

    const uploadAttachedImages = useCallback((ufi: UploadFileInfo[]) => {

        const promises: Promise<void>[] = [];

        setState(state => {

            const newAttaches = [];
            for(let idx = 0; idx < Math.min(ufi.length, state.attachedImages.length); idx++) {

                const upload = ufi[idx];
                const file = {
                    ...state.attachedImages[idx],
                    id: upload.id
                };

                const uploader = new FileUploader(upload);
                uploader.onProgress = event => {
                    setUploadState(file.id, {
                        uploadProgress: event.loaded / event.total * 100
                    });
                };
                uploader.onSuccess = event => setUploadState(file.id, {
                    uploadSuccess: true,
                    uploading: false,
                    uploadProgress: 100
                });
                uploader.onError = event => setUploadState(file.id, {
                    uploadFailed: true,
                    uploading: false,
                    uploadProgress: 0
                });

                file.uploadProgress = {
                    id: upload.id,
                    uploading: true,
                    uploadProgress: 0,
                    uploadFailed: false,
                    uploadSuccess: false
                };

                const promise = uploader.upload(file.file)
                    .then(e => {})
                    .catch((e: FetchResult<DownloadFileInfo>) => {
                        toastr.error("Поддержка", "Не удалось загрузить изображение");
                    });
                
                newAttaches.push(file);
                promises.push(promise);
            }

            return {
                ...state,
                attachedImages: newAttaches,
            };
        });

        return Promise.all(promises);
    }, [setUploadState]);

    const setScrollToBottom = useCallback((scroll: boolean) => {
        setState(state => ({...state, scrollToBottom: scroll}));
    }, []);

    const messageCreatedSubscribe = useCallback(() => setScrollToBottom(true), [setScrollToBottom]);
    const topicDeletedSubscribe = useCallback(() => {
        history.replace("/consumer/support/");
        toastr.warning("Поддержка", "Просматриваемый тикет был удален");
    }, [history]);
    const subscribeValidator = useCallback((e: {topicId: string}) => e.topicId === ticket?.id,
        [ticket?.id]);

    useSubscribe('ConsumerSupportMessageCreated', messageCreatedSubscribe, subscribeValidator);
    useSubscribe('ConsumerSupportTopicDeleted', topicDeletedSubscribe, subscribeValidator);

    const context: ISupportTopicContext = useMemo(() => (
        {
            topic: ticket,
            sidebar: {
                hideSidebar: state.hideSidebar,
                setHideSidebar: hide=> setState((state) => ({...state, hideSidebar: hide}))
            },
            topicActions: {
                openTicket,
                closeTicket,
                deleteTicket
            },
            scroll: {
                scrollToBottom: state.scrollToBottom,
                setScrollToBottom
            },
            topicInfo: {
                isThreadInfoOpened: state.isThreadInfoOpened,
                setThreadInfoOpened: opened => setState((state) => ({...state, isThreadInfoOpened: opened}))
            },
            setCurrentTopic,
            deleteMessage,
            editableMessageId: state.editableMessageId,
            setEditableMessageId: messageId => setState((state) => ({...state, editableMessageId: messageId})),
            attachedImages: state.attachedImages,
            setAttachedImages,
            uploadAttachedImages,
            removeAttachedImage: id => setState(state => ({
                ...state,
                attachedImages: state.attachedImages.filter(file => file.id !== id)
            }))
        }
    ), [closeTicket, deleteMessage, deleteTicket, openTicket, setAttachedImages, setCurrentTopic,
        state.attachedImages, state.editableMessageId, state.hideSidebar, state.isThreadInfoOpened,
        state.scrollToBottom, ticket, uploadAttachedImages, setScrollToBottom]);

    return (
        <SupportTopicContext.Provider value={context}>
            {children}
        </SupportTopicContext.Provider>
    )
}

export default ChatProvider;
import DailyService from "@api/user/dailyService";
import { ICheckGift, IDailyStat, IGift } from "@interfaces/user/daily";
import FetchResult from "@models/fetchResult";
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

export interface IDailyStore {
    gifts: IGift[];
    stats: IDailyStat[];
}

const slice = createSlice({
    name: 'dailyStore',
    initialState: {
        gifts: [],
        stats: []
    } as IDailyStore,
    reducers: {
        setGifts: (state, action: PayloadAction<IGift[]>) => {
            state.gifts = action.payload;
        },
        setStats: (state, action: PayloadAction<IDailyStat[]>) => {
            state.stats = action.payload;
        },
        updateGifts: (state, { payload: gifts }: PayloadAction<IGift[]>) => {
            gifts.forEach(gift => {
                const idx = state.gifts.findIndex(g => g.id === gift.id);
                if (idx >= 0) {
                    state.gifts[idx] = gift;
                }
                else {
                    state.gifts.push(gift);
                }
            })
        }
    }
});

const { setGifts, setStats, updateGifts } = slice.actions;

export const { reducer: dailyReducer } = slice;

export const dailyActions = {
    loadGifts: () => async (dispatch: Dispatch) => {
        try {
            const api = new DailyService();
            const result = await api.getGifts();

            if (result.success) {
                dispatch(setGifts(result.data));
            }
            return result;
        } catch (error) {
            return error as FetchResult<IGift[]>;
        }
    },
    loadStats: () => async (dispatch: Dispatch) => {
        try {
            const api = new DailyService();
            const result = await api.getStats();

            if (result.success) {
                dispatch(setStats(result.data));
            }
            return result;
        } catch (error) {
            return error as FetchResult<IDailyStat[]>;
        }
    },
    checkGitfs: () => async (dispatch: Dispatch) => {
        try {
            const api = new DailyService();

            const result = await api.checkDaily();
            if(result.success) {
                dispatch(updateGifts(result.data.newGifts));
                dispatch(updateGifts(result.data.updatedGifts));
            }
            return result;
        }
        catch (error) {
            return error as FetchResult<ICheckGift>;
        }
    },
    setGifts,
    setStats
}
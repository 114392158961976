import {CustomQuery, IQueryResult} from "@interfaces/query";
import React, {FC} from "react";
import {useLocalizedBlock} from "@services/hooks";

interface QueryExecuteBooleanResultProps {
    tag?: string | React.ElementType;
    query: CustomQuery;
    result: IQueryResult;
}

const QueryExecuteBooleanResult: FC<QueryExecuteBooleanResultProps> = ({tag: Tag = "div", query,  result }) => {

    const lang = useLocalizedBlock(`queries.${query.name.toLowerCase()}.result`);
    const defaultLang = useLocalizedBlock(`queries.default.result`);

    if(result.success === undefined) {
        return null;
    }

    const type = result.success ? "success" : "failure";
    const defaultMsg = result.success ? "Успешно" : "Ошибка";

    return (
        <Tag>
            {lang[type] || defaultLang[type] || defaultMsg}
        </Tag>
    )
}

export default QueryExecuteBooleanResult;
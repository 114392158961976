import { FC } from "react";
import { Text } from '@constructor/components';
import { useBonusLogContext } from "./context";
import { fromUtcDate } from "@services/helpers";

interface BonusCodeUseTimeProps {
    format?: string;
}
const BonusCodeUseTime: FC<BonusCodeUseTimeProps> = ({ format = 'lll', ...props }) => {

    const code = useBonusLogContext();
    if (!code) {
        return null;
    }

    return (
        <Text text={fromUtcDate(code.useTime).format(format)} {...props} />
    )
}

export default BonusCodeUseTime;
import IService from "@api/iService";
import { IInventoryItem } from "@interfaces/user/items";
import { SendInventoryItemModel } from "@models/user/inventory";

export default class InventoryService extends IService {

    async fetchUserInventory() {
        return this.getAndThrowApi<IInventoryItem[]>('/user/inventory/');
    }

    async sendInventoryItem(item: SendInventoryItemModel) {
        return await this.postApi('/user/inventory/send/', item)
    }

    async getInventoryItem(itemId: string) {
        return await this.getApi<IInventoryItem>(`/user/inventory/${itemId}/`);
    }
}
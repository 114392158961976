import { injectChild } from "@components/constructor";
import { usePagination } from "@hooks/components/constructor.hooks";
import { useInventory } from "@hooks/selectors/user.selectors";
import {FC, PropsWithChildren, useMemo} from "react";
import { PaginationProps } from "@constructor/components/core/pagination";

interface InventoryProps {
    inventory_item_per_page?: number;
}

interface InventoryChildProps {
    inventory_item_count: number;
}

const Inventory: FC<PropsWithChildren<InventoryProps>> = ({ inventory_item_per_page = 10, children }) => {

    const inventory = useInventory();
    const pagination = usePagination(inventory_item_per_page, inventory.length);

    const data = useMemo<InventoryChildProps & PaginationProps>(() => {
        return {
            inventory_item_count: inventory.length,
            ...pagination
        }
    }, [inventory.length, pagination]);

    return injectChild(data, children);
}

export default Inventory;
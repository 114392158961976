import React, { FC } from "react";
import { Provider } from "react-redux";
import store from "../../redux/store";
import ReduxToastr from "react-redux-toastr";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { PanelLoader } from "../loader";
import Routes from "../../routes/routes";
import { YMInitializer } from 'react-yandex-metrika';
import { usePanel } from "@hooks/loadingHooks";

import { Chart as ChartJS, registerables } from 'chart.js';
ChartJS.register(...registerables);

library.add(fas);

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <PanelLoader>
                <Routes />
            </PanelLoader>
            <ReduxToastr
                timeOut={5000}
                newestOnTop={true}
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick
            />
            <Metrics />
        </Provider>
    );
};

const Metrics: FC = () => {

    const { ymId } = usePanel();

    if(!ymId) {
        return null;
    }

    return (
        <YMInitializer accounts={[ymId]} options={{
            webvisor: true,
            trackLinks: true,
            //clickmap: true
        }}
        version="2"/>
    )
}

export default App;
import UserService from "@api/userService";
import { usePromisedDispatch } from "@hooks/helperHooks";
import { useBalance } from "@hooks/selectors/user.selectors";
import { ICodeTypeBalance } from "@interfaces/user/bonusCodes";
import { userActions } from "@store/user";
import { Form, Formik } from "formik";
import {FC, PropsWithChildren, useCallback, useMemo} from "react"
import { toastr } from "react-redux-toastr";
import * as Yup from 'yup';

interface BonusCodeBuyFormValues {
    sum: number;
}

const BonusCodeBuyForm: FC<PropsWithChildren> = ({ children, ...props }) => {

    const dispatch = usePromisedDispatch();
    const balance = useBalance();

    const validate = useMemo(() => Yup.object().shape({
        sum: Yup.number()
            .min(100, '')
            .max(balance, '')
            .required('Обязательно')
    }), [balance]);

    const onSubmit = useCallback(async (values: BonusCodeBuyFormValues) => {
        const api = new UserService();
        const result = await api.buyBonusCode({
            sum: values.sum
        });

        if(result.success) {
            toastr.success('Покупка бонусного кода', `Вы успешно приобрели код ${result.data.code} на сумму ${(result.data.valueInfo as ICodeTypeBalance).value} ₽.`)

            dispatch(userActions.loadBalance());
            dispatch(userActions.loadPersonalBonusCodes());
        }
        else {
            if(result.errorCode === 'SmallSum') {
                toastr.error('Покупка бонусного кода', 'Слишком маленькая сумма');
            }
            else if(result.errorCode === 'NotEnoughtMoney') {
                toastr.error('Покупка бонусного кода', 'Недостаточно средств');
            }
            else if(result.errorCode === 'BalanceError') {
                toastr.error('Покупка бонусного кода', 'Ошибка списания баланса');
            }
        }
    }, [dispatch]);

    const initial: BonusCodeBuyFormValues = {
        sum: Math.min(100, balance)
    };

    return (
        <Formik
            initialValues={initial}
            validationSchema={validate}
            onSubmit={onSubmit}>
            <Form {...props}>
                {children}
            </Form>
        </Formik>
    )
}

export default BonusCodeBuyForm;
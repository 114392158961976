import RefService from "@api/user/refService";
import { IGoal } from "@interfaces/user/refs";
import { GoalStore } from "@interfaces/user/userStore";
import FetchResult from "@models/fetchResult";
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

const slice = createSlice({
    initialState: {
        loading: false,
        loaded: false,
        error: null,
        data: []
    } as GoalStore,
    name: 'goalStore',
    reducers: {
        setGoals: (state, action: PayloadAction<IGoal[]>) => {
            state.error = null;
            state.loading = false;
            state.loaded = true;
            state.data = action.payload;
        },
        setLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        }
    }
});

export const { reducer: goalsReducer } = slice;

const {
    setError,
    setGoals,
    setLoading
} = slice.actions;

export const goalActions = {
    loadGoals: (silent: boolean = false) => async (dispatch: Dispatch) => {
        try {
            if(!silent) {
                dispatch(setLoading());
            }

            const api = new RefService();
            const result = await api.getGoals();

            if(result.success) {
                dispatch(setGoals(result.data));
            }
            else {
                dispatch(setError(result));
            }

            return result;
        } catch (error) {
            return error as FetchResult<IGoal[]>;
        }
    }
}
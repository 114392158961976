import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";
import Query from "./query";
import QueryMessage from "./queryMessage";
import QueryName from "./queryName";
import QueryPrice from "./queryPrice";
import QueryServerName from "./queryServerName";
import QueryTableBody from "./queryTableBody";
import QueryTableHead from "./queryTableHead";

const QueryInfo: ConstructionComponentInfo = {
    component: Query,
    addedChildProps: [
        'query_executing',
        'query_result_rows',
        'query_result_exists'
    ]
}

const QueryMessageInfo: ConstructionComponentInfo = {
    component: QueryMessage,
    requiredParent: [
        'Query'
    ]
}

const QueryNameInfo: ConstructionComponentInfo = {
    component: QueryName,
    props: [
        'format'
    ],
    requiredParent: [
        'Query'
    ]
}

const QueryPriceInfo: ConstructionComponentInfo = {
    component: QueryPrice,
    requiredParent: [
        'Query'
    ]
}

const QueryServerNameInfo: ConstructionComponentInfo = {
    component: QueryServerName,
    requiredParent: [
        'Query'
    ]
}

const QueryTableBodyInfo: ConstructionComponentInfo = {
    component: QueryTableBody,
    requiredParent: [
        'Query'
    ]
}

const QueryTableHeadInfo: ConstructionComponentInfo = {
    component: QueryTableHead,
    props: [
        'columns'
    ],
    requiredParent: [
        'Query'
    ]
}

export {
    QueryInfo,
    QueryMessageInfo,
    QueryNameInfo,
    QueryPriceInfo,
    QueryServerNameInfo,
    QueryTableBodyInfo,
    QueryTableHeadInfo
}
import { injectContextArray } from "@components/constructor";
import { useLoginServerAuto, useLoginServers } from "@hooks/selectors/game.selectors";
import { useAccounts } from "@hooks/selectors/user.selectors";
import { GameAccount } from "@interfaces/game";
import {FC, PropsWithChildren, useMemo} from "react";
import { LoginIdProps } from "../../loginServer";
import { AccountProvider } from "./context";
import {InjectContextType} from "@interfaces/constructor/constructor";

interface AccountListChildProps {
    account_id: string;
    account_index: number;
}

const AccountList: FC<PropsWithChildren<LoginIdProps>> = ({ login_id, children }) => {

    const selectedLogin = useLoginServerAuto(login_id);
    const loginServers = useLoginServers();

    const loginServer = selectedLogin || (loginServers.length === 1 ? loginServers[0] : null);
    const accounts = useAccounts(loginServer?.id);

    const data = useMemo<InjectContextType<GameAccount, AccountListChildProps>[]>(() => accounts.map((a, idx) => { //login context > router > props
        return {
            key: a.id,
            value: a,
            childData: {
                account_id: a.id,
                account_index: idx + 1
            }
        }
    }), [accounts]);

    //return injectChildArray(accounts, children, 'id', 'account');
    return injectContextArray<GameAccount>(data, AccountProvider, children);
}

export default AccountList;
import {FC, PropsWithChildren, useMemo} from "react";
import { injectChild } from "@components/constructor";
import { useUsedCodes } from "@hooks/userHooks";
import { PaginationProps } from "@constructor/components/core/pagination";
import { usePagination } from "@hooks/components/constructor.hooks";

interface BonusCodeLogsProps {
    bonus_code_logs_per_page?: number;
}

interface BonusCodeLogsChildProps {
    bonus_code_logs_count: number;
    bonus_code_logs_loading: boolean;
}

const BonusCodeLogs: FC<PropsWithChildren<BonusCodeLogsProps>> = ({ children, bonus_code_logs_per_page = 10 }) => {

    const { loading, data } = useUsedCodes();
    const pagination = usePagination(bonus_code_logs_per_page, data.length);

    const childData = useMemo<PaginationProps & BonusCodeLogsChildProps>(() => {
        return {
            bonus_code_logs_count: data.length,            
            bonus_code_logs_loading: loading,
            ...pagination
        }
    }, [data.length, loading, pagination]);

    return injectChild(childData, children);
}

export default BonusCodeLogs;
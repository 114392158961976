import {UploadFileInfo} from "@interfaces/panel/files";

export interface ConsumerTicket {
    id: string;
    name: string;
    isClosed: boolean;
    createTime: string;
    updateTime: string;
    lastUserName: string;
    ownerUserName: string;
    ownerId: string;
    lastMessage?: ConsumerTicketMessage;
}

export interface ConsumerTicketMessage {
    id: string;
    task: string;
    message: string;
    senderId: string;
    senderName: string;
    avatarUrl?: string;
    incoming: boolean;
    createTime: string;
    edited: boolean;
    attachments: IMessageAttachment[];
}

export interface IMessageAttachment {
    id: string;
    fileId: string;
    order: number;
    type: EMessageAttachmentType;
    state: EMessageAttachmentState;
    name: string;
    uploadFile?: UploadFileInfo;
}

export enum EMessageAttachmentType {
    Image = 'Image',
    File = 'File',
    Video = 'Video',
}

export enum EMessageAttachmentState {
    Uploading = "Uploading",
    Uploaded = "Uploaded",
    Failed = "Failed",
    Deleted = "Deleted"
}
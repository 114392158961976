import { FC } from "react";
import { useBalance } from "@hooks/selectors/user.selectors";
import { Text } from '@components/constructor/components';
import { usePaymentCurrency } from "@hooks/selectors/panel.selectors";

const UserBalance: FC = (props) => {

    const balance = useBalance();
    const currency = usePaymentCurrency();

    return (
        <Text text={`${balance} ${currency}`} {...props} />
    )
}

export default UserBalance;
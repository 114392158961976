import {ElementType, FC, PropsWithChildren} from "react";
import {Card, Col, ColProps, Row} from "react-bootstrap";
import Background from "./background";
import classNames from "classnames";
import corner4 from '../../../assets/img/illustrations/corner-4.png';

interface PageHeaderProps {
    title: string;
    preTitle?: string;
    titleTag: ElementType;
    description?: string;
    col?: ColProps;
    image?: string;
    className?: string;
}

const PageHeader: FC<PropsWithChildren<PageHeaderProps>> = ({
                                                                title,
                                                                preTitle,
                                                                titleTag: TitleTag = 'h3',
                                                                description,
                                                                image = corner4,
                                                                col = { lg: 8},
                                                                children,
                                                                ...rest
                                                            }) => (
    <Card {...rest}>
        <Background
            image={image}
            className="bg-card d-none d-sm-block"
            style={{
                borderTopRightRadius: '0.375rem',
                borderBottomRightRadius: '0.375rem'
            }}
        />
        <Card.Body className="position-relative">
            <Row>
                <Col {...col}>
                    {preTitle && <h6 className="text-600">{preTitle}</h6>}
                    <TitleTag className="mb-0">{title}</TitleTag>
                    {description && (
                        <p className={classNames('mt-2', { 'mb-0': !children })}>{description}</p>
                    )}
                    {children}
                </Col>
            </Row>
        </Card.Body>
    </Card>
);

export default PageHeader;
import {FC, PropsWithChildren, useState} from "react";
import {AvatarProps} from "@components/falcon/common/avatar";
import {UserNotification} from "@interfaces/user/users";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {Avatar} from "@components/falcon/common";
import {fromUtcDate} from "@services/helpers";
import {usePromisedDispatch} from "@hooks/helperHooks";
import {userActions} from "@store/user";

interface NotificationProps {
    avatar?: AvatarProps;
    className?: string;
    notification: UserNotification;
    emoji?: string;
    flush?: boolean;
}

const Notification: FC<PropsWithChildren<NotificationProps>> = ({
    avatar,
    notification,
    flush,
    emoji,
    className}) => {

    const dispatch = usePromisedDispatch();
    const [fetched, setFetched] = useState(false);

    const onMouseLeave = async () => {
        if(!notification.isReaded && !fetched) {
            setFetched(true);
            await dispatch(userActions.notifications.readNotification(notification.id));
        }
    }

    return (
        <Link
            className={classNames(
                'notification',
                {'notification-unread': !notification.isReaded, 'notification-flush': flush},
                className
            )}
            to="#!"
            onMouseLeave={onMouseLeave}
        >
            {avatar && (
                <div className="notification-avatar">
                    <Avatar {...avatar} className="me-3"/>
                </div>
            )}
            <div className="notification-body">
                <p className="mb-1">
                    {notification.message}
                </p>
                <span className="notification-time">
                    {emoji && (
                        <span className="me-2" role="img" aria-label="Emoji">
                            {emoji}
                        </span>
                    )}
                    {fromUtcDate(notification.createTime).calendar()}
                </span>
            </div>
        </Link>
    )
}

export default Notification;
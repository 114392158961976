import { Loadable } from '../../interfaces/helpers';
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { GameAccount } from '../../interfaces/game';
import UserService from '../../services/api/userService';
import { PatchGameAccountModel, PutGameAccountModel } from '../../models/user/data';

const slice = createSlice({
    initialState: {
        loaded: false,
        loading: false,
        error: null,
        data: []
    } as Loadable<GameAccount[]>,
    name: 'userAccountsStore',
    reducers: {
        setLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setError: (state, action) => {
            state.loaded = false;
            state.loading = false;
            state.error = action.payload;
            state.data = [];
        },
        setAccounts: (state, action: PayloadAction<GameAccount[]>) => {
            state.data = action.payload;
            state.loaded = true;
            state.error = null;
            state.loading = false;
        },
        setAccount: (state, action: PayloadAction<GameAccount>) => {
            const account = action.payload;
            const idx = state.data.findIndex(a => a.id === account.id);
            if (idx >= 0) {
                state.data[idx] = account;
            }
            else {
                state.data.push(account);
            }
        },
        removeAccount: (state, action: PayloadAction<string>) => {
            state.data = state.data.filter(a => a.id !== action.payload);
        }
    }
});

export const { reducer: userAccountsReducer } = slice;

const {
    setAccounts,
    setError,
    setLoading,
    setAccount,
} = slice.actions;

export const { removeAccount: removeGameAccount } = slice.actions;

export const accountActions = {
    loadGameAccounts: (silent: boolean = false) => async (dispatch: Dispatch) => {

        if (!silent) {
            dispatch(setLoading());
        }
    
        try {
            const api = new UserService();
            const accounts = await api.fetchAccounts();
            dispatch(setAccounts(accounts));
            return accounts;
        }
        catch (e) {
            dispatch(setError(e));
        }
    },
    loadGameAccount: (accountId: string, silent: boolean = true) => async (dispatch: Dispatch) => {

        if (!silent) {
            dispatch(setLoading());
        }
    
        try {
            const api = new UserService();
            const account = await api.fetchAccount(accountId);
            dispatch(setAccount(account));
            return account;
        }
        catch (e) {
            dispatch(setError(e));
        }
    },
    createAccount: (account: PutGameAccountModel) => async (dispatch: Dispatch) => {
        const api = new UserService();
        const result = await api.putAccount(account);
    
        if (result.ok && result.result) {
            dispatch(setAccount(result.result));
        }
        return result;
    },
    patchAccount: (account: PatchGameAccountModel) => async (dispatch: Dispatch) => {
        const api = new UserService();
        const result = await api.patchAccount(account);
    
        if (result.ok && result.result) {
            dispatch(setAccount(result.result));
        }
        return result;
    },
    removeGameAccount,
    setAccounts
}

export const loadGameAccounts = (silent: boolean = false) => async (dispatch: Dispatch) => {

    if (!silent) {
        dispatch(setLoading());
    }

    try {
        const api = new UserService();
        const accounts = await api.fetchAccounts();
        dispatch(setAccounts(accounts));
        return accounts;
    }
    catch (e) {
        dispatch(setError(e));
    }
}

export const loadGameAccount = (accountId: string, silent: boolean = true) => async (dispatch: Dispatch) => {

    if (!silent) {
        dispatch(setLoading());
    }

    try {
        const api = new UserService();
        const account = await api.fetchAccount(accountId);
        dispatch(setAccount(account));
        return account;
    }
    catch (e) {
        dispatch(setError(e));
    }
}

export const createAccount = (account: PutGameAccountModel) => async (dispatch: Dispatch) => {
    const api = new UserService();
    const result = await api.putAccount(account);

    if (result.ok && result.result) {
        dispatch(setAccount(result.result));
    }
    return result;
}

export const patchAccount = (account: PatchGameAccountModel) => async (dispatch: Dispatch) => {
    const api = new UserService();
    const result = await api.patchAccount(account);

    if (result.ok && result.result) {
        dispatch(setAccount(result.result));
    }
    return result;
}
import {useContext, useCallback, useMemo, createContext, FC, PropsWithChildren} from 'react';
import { SidebarItemLink, SidebarCategoryLink } from '@interfaces/control-panel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSidebar } from '@hooks/selectors/panel.selectors';
import { injectContextArray, injectChild } from '@components/constructor';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Badge } from 'reactstrap';
import {ConstructionComponentData, InjectContextType} from '@interfaces/constructor/constructor';
import Text from '../Text';
import { sidebarActions } from '@store/sidebarStore';

interface SidebarContextType {
    links: SidebarCategoryLink[];
    isOpen: boolean;
    isOnRight: boolean;
}

const SidebarContext = createContext<SidebarContextType>(undefined as any);
const SidebarProvider = SidebarContext.Provider;

export function useSidebarContext() {
    return useContext(SidebarContext);
}

const SidebarCategoryContext = createContext<SidebarCategoryLink>(undefined as any);
const SidebarCategoryProvider = SidebarCategoryContext.Provider;

export function useSidebarCategoryContext() {
    const context = useContext(SidebarCategoryContext);
    return context;
}

const SidebarItemContext = createContext<SidebarItemLink>(undefined as any);
const SidebarItemProvider = SidebarItemContext.Provider;

export function useSidebarItemContext() {
    const context = useContext(SidebarItemContext);
    return context;
}

export const Sidebar: FC<PropsWithChildren> = ({ children }) => {

    const sidebar = useSidebar();

    const data = {
        sidebar_opened: sidebar.isOpen,
        sidebar_right: sidebar.isOnRight
    }

    return (
        <SidebarProvider value={sidebar}>
            {
                injectChild(data, children)
            }
        </SidebarProvider>
    )
}

interface SidebarCategoryProps {
    itemComponent?: string | ConstructionComponentData;
}

export const SidebarCategoredItem: FC<PropsWithChildren<SidebarCategoryProps>> = ({ children }) => {

    const context = useSidebarCategoryContext();
    const { location } = useHistory();

    const item: SidebarItemLink = useMemo(() => {
        return { ...context, to: context.to }
    }, [context]);

    const data = {
        sidebar_item_active: location.pathname === item.to,
        sidebar_item_path: item.to
    };

    return (
        <SidebarItemProvider value={item}>
            {
                injectChild(data, children)
            }
        </SidebarItemProvider>
    )
}

export const SidebarCategoryItemList: FC<PropsWithChildren> = ({ children }) => {
    const context = useSidebarCategoryContext();
    const { location } = useHistory();

    const items: InjectContextType<SidebarItemLink>[] | undefined = useMemo(() => context?.children && context.children.map((item, idx) => {
        return {
            key: idx,
            value: item,
            childData: {
                sidebar_item_active: location.pathname === item.to,
                sidebar_item_path: item.to
            }
        }
    }), [context, location.pathname]);


    if (!items) {
        return null;
    }

    return injectContextArray(items, SidebarItemProvider, children);
}

export const SidebarCategoryList: FC<PropsWithChildren> = ({ children }) => {
    const { location: { pathname } } = useHistory();
    const context = useSidebarContext();
    const dispatch = useDispatch();

    const toggle = useCallback((idx: number) => dispatch(sidebarActions.toggleSidebarItem(idx)), [dispatch]);

    const links = useMemo<InjectContextType<SidebarCategoryLink>[] | undefined>(() => context?.links && context.links.map((link, idx) => {
        return {
            key: idx,
            value: link,
            childData: {
                sidebar_category_active: link.to === '/' ? pathname === '/' : pathname.indexOf(link.to) !== -1,
                sidebar_category_opened: link.isOpen,
                sidebar_category_toggle: () => toggle(idx),
                sidebar_category_item_count: link.children?.length || 0
            }
        }
    }), [context, pathname, toggle]);

    if (!links) {
        return null;
    }

    return injectContextArray(links, SidebarCategoryProvider, children);
}

/*const SidebarItem: React.FC = () => {

}*/

export const SidebarCategoryIcon: FC = () => {
    const context = useSidebarCategoryContext();
    if (!context?.icon) {
        return null;
    }

    return (
        <FontAwesomeIcon
            icon={context.icon}
            fixedWidth
            className="align-middle mr-2" />
    )
}

export const SidebarItemIcon: FC = () => {
    const context = useSidebarItemContext();
    if (!context?.icon) {
        return null;
    }

    return (
        <FontAwesomeIcon
            icon={context.icon}
            fixedWidth
            className="align-middle mr-2" />
    )
}

export const SidebarCategoryName: FC = (props) => {
    const context = useSidebarCategoryContext();
    if (!context?.name) {
        return null;
    }

    return (
        <Text text={context.name} {...props} />
    )
}

export const SidebarItemName: FC = (props) => {
    const context = useSidebarItemContext();
    if (!context?.name) {
        return null;
    }

    return (
        <Text text={context.name} {...props} />
    )
}

export const SidebarCategoryBadge: FC<PropsWithChildren> = ({ children, ...props }) => {
    const context = useSidebarCategoryContext();

    if (!context) {
        return null;
    }

    const { badgeColor, badgeText } = context;

    if (!badgeColor || !badgeText) {
        return null;
    }

    return (
        <Badge color={badgeColor} {...props}>
            {badgeText}
        </Badge>
    )
}

export const SidebarItemBadge: FC<PropsWithChildren> = ({ children, ...props }) => {
    const context = useSidebarItemContext();

    if (!context) {
        return null;
    }

    const { badgeColor, badgeText } = context;

    if (!badgeColor || !badgeText) {
        return null;
    }

    return (
        <Badge color={badgeColor} {...props}>
            {badgeText}
        </Badge>
    )
}
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Collapse } from 'reactstrap';
import classNames from "classnames";

interface SidebarCategoryProps {
    name: string;
    badgeColor?: string;
    badgeText?: string;
    icon?: IconProp;
    isOpen: boolean;
    children?: React.ReactNode
    onClick?: () => void;
    to: string;
}

const SidebarCategory: React.FC<SidebarCategoryProps> = (props: SidebarCategoryProps) => {

    const {
        name,
        badgeColor,
        badgeText,
        icon,
        isOpen,
        children,
        onClick,
        to
    } = props;

    const location = useLocation();

    const getSidebarItemClass = (path: string) => {
        return (location.pathname.indexOf(path) !== -1 ||
            (location.pathname === "/" && path === "/dashboard"))
            ? "active"
            : "";
    };

    return (
        <li className={"sidebar-item " + getSidebarItemClass(to)}>
            <span
                data-bs-toggle="collapse"
                className={classNames("sidebar-link ", { "collapsed": !isOpen })}
                onClick={onClick}
                aria-expanded={isOpen}
            >
                {icon && 
                <FontAwesomeIcon
                    icon={icon}
                    fixedWidth
                    className="align-middle mr-2"
                />}{" "}
                <span className="align-middle">{name}</span>
                {badgeColor && badgeText ? (
                    <Badge color={badgeColor} size={18} pill className="sidebar-badge">
                        {badgeText}
                    </Badge>
                ) : null}
            </span>
            <Collapse isOpen={isOpen}>
                <ul id="item" className={"sidebar-dropdown list-unstyled"}>
                    {children}
                </ul>
            </Collapse>
        </li>
    );
};

export default SidebarCategory;
import {FC, PropsWithChildren, useMemo} from "react";
import { useTaskMessages, useTopicAuto } from "@hooks/selectors/user/support.selectors";
import { IUserTaskMessageGroup } from "@interfaces/user/support";
import { injectContextArray } from "@components/constructor";
import { SupportMessageGroupProvider } from "../context";
import { sortByDate } from "@services/helpers";
import {InjectContextType} from "@interfaces/constructor/constructor";

interface SupportMessageGroupListChildProps {
    is_incoming_group: boolean;
}

interface SupportMessageGroupListProps {
    sort_order?: 'desc' | 'asc';
    split_delay?: number;
}

const SupportMessageGroupList: FC<PropsWithChildren<SupportMessageGroupListProps>> = ({ children, split_delay, sort_order = 'asc' }) => {

    const topic = useTopicAuto();

    const messages = useTaskMessages(topic?.id || '');

    const groups = useMemo<IUserTaskMessageGroup[]>(() => {

        const sortedMessages = [...messages].sort(sortByDate(m => m.createTime, 'asc'));

        let lastOwner: string | null = null;
        let i = 0;
        let group: IUserTaskMessageGroup | null = null;
        const groups: IUserTaskMessageGroup[] = [];
        let lastTime = 0;

        while (i < sortedMessages.length) {
            const m = sortedMessages[i];
            const createTime = Date.parse(m.createTime);
            if (lastOwner !== m.senderId 
                || (split_delay && createTime - lastTime > split_delay * 1000)) {
                group = {
                    senderId: m.senderId,
                    senderName: m.senderName,
                    task: m.task,
                    incoming: m.incoming,
                    avatarUrl: m.avatarUrl,
                    messages: []
                }
                lastOwner = m.senderId;
                groups.push(group);
            }
            lastTime = createTime;
            group?.messages.push(m);
            i++;
        }

        return groups.sort(sortByDate(g => g.messages[0].createTime, sort_order));
    }, [messages, sort_order, split_delay]);

    const data = useMemo<InjectContextType<IUserTaskMessageGroup, SupportMessageGroupListChildProps>[]>(() => groups.map((group, idx) => {
        return {
            key: idx,
            value: group,
            childData: {
                is_incoming_group: group.incoming
            }
        }
    }), [groups]);

    return injectContextArray(data, SupportMessageGroupProvider, children);
}

export default SupportMessageGroupList;
import {CustomQuery, IQueryResult} from "@interfaces/query";
import {FC} from "react";

interface QueryExecuteObjectResultProps {
    query: CustomQuery;
    result: IQueryResult;
}

const QueryExecuteObjectResult: FC<QueryExecuteObjectResultProps> = ({query, result}) => {
    return (
        <div>
            QueryExecuteObjectResult
        </div>
    )
}

export default QueryExecuteObjectResult;
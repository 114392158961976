import React, {FC, PropsWithChildren} from "react";
import {FieldProps} from "formik";
import {Col, Form, Row} from "react-bootstrap";

interface FormikRowSelectProps extends FieldProps {
    description?: string;
    label?: string;
    id?: string;
    placeholder?: string;
    labelSize?: number;
}
const FormikRowSelect: FC<PropsWithChildren<FormikRowSelectProps>> = (prop) => {
    const {
        field,
        form: { touched, errors },
        description,
        label,
        labelSize = 3,
        children,
        ...props
    } = prop;
    return (
        <Form.Group as={Row}>
            <Form.Label
                column
                for={props.id}
                className={"label-color"}
                sm={labelSize}>{label}</Form.Label>
            <Col sm={12 - labelSize}>
                <Form.Select {...props} {...field} isInvalid={Boolean(touched[field.name] && errors[field.name])}>
                    {children}
                </Form.Select>
                {touched[field.name] && errors[field.name] ? <Form.Control.Feedback type="invalid">{errors[field.name]?.toString()}</Form.Control.Feedback> : ''}
                {description && <Form.Text>{description}</Form.Text>}
            </Col>
        </Form.Group>
    );
}

export default FormikRowSelect;
import React, {FC} from "react";
import {CustomQuery, IQueryResult} from "@interfaces/query";
import {useLocalizedBlock} from "@services/hooks";

interface QueryExecuteMessageResultProps {
    tag?: string | React.ElementType;
    query: CustomQuery;
    result: IQueryResult;
}

const QueryExecuteMessageResult: FC<QueryExecuteMessageResultProps> = ({tag: Tag = "div", query, result}) => {

    const lang = useLocalizedBlock(`queries.${query.name.toLowerCase()}.result.message`);

    if(!result.message) {
        return null;
    }

    return (
        <Tag>
            {lang[result.message] || result.message}
        </Tag>
    )
}

export default QueryExecuteMessageResult;
import React, {FC, PropsWithChildren, useCallback} from "react";
import {ModalProps} from "@constructor/components/modals";
import {useBalance} from "@hooks/selectors/user.selectors";
import {useDispatch} from "react-redux";
import UserService from "@api/userService";
import {toastr} from "react-redux-toastr";
import {userActions} from "@store/user";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {useMarketItemContext} from "./items/context";
import ItemCountHelper from "./itemCountHelper";

interface MarketBuyItemFormValues {
    count: number;
}

const MarketBuyItemForm: FC<PropsWithChildren<ModalProps>> = ({ modalToggle, children, ...props }) => {
    const item = useMarketItemContext();
    const balance = useBalance();
    const dispatch = useDispatch();

    const maxCount = Math.min(item ? Math.floor(balance / item.discountPrice) : 0, 10000);

    const onSubmit = useCallback(async (values: MarketBuyItemFormValues) => {
        if (!item) {
            return;
        }

        const api = new UserService();
        const result = await api.buyMarketItem({
            items: [
                {
                    id: item.id,
                    count: values.count,
                    price: item.discountPrice
                }
            ]
        });

        if (result.ok) {
            toastr.success('Покупка предмета', 'Предмет куплен. Перейдите в инвентарь, чтобы отправить его в игру.');
            dispatch(userActions.loadBalance());
            dispatch(userActions.inventory.loadInventoryItems(true));
            if (modalToggle) {
                modalToggle();
            }
        }
        else {
            if (result.errorCode === 'PriceMismatch') {
                toastr.error('Покупка предмета', 'Цена на этот предмет обновилась. Попробуйте еще раз.');
                dispatch(userActions.loadMarket());
            }
            else if (result.errorCode === 'ItemNotFound') {
                toastr.error('Покупка предмета', 'Данный предмет не найден. Попробуйте еще раз.');
                dispatch(userActions.loadMarket());
            }
            else if (result.errorCode === 'NotEnoughMoney') {
                toastr.error('Покупка предмета', 'Недостаточно средств для покупки. Пополните счет и попробуйте еще раз.');
                dispatch(userActions.loadBalance());
            }
            else if (result.errorCode === 'ConcurrencyError') {
                toastr.error('Покупка предмета', 'Ошибка покупки. Попробуйте еще раз.');
            }
            else if(result.errorCode === "LimitExceeded") {
                const limit = Array.isArray(result.errorData) ? result.errorData?.find(x => x.shopItemId === item.id) : null;
                toastr.error('Покупка предмета', `Превышен лимит покупок. Этот предмет можно купить ${limit?.limit} раз. Вы уже приобрели его ${limit?.limitValue} раз.`);
            }
            else if(result.errorCode === "CountError") {
                toastr.error('Покупка предмета', `Неверное количество предметов. Попробуйте еще раз.`);
            }
            else {
                toastr.error('Покупка предмета', 'Что-то пошло не так.');
            }
        }
    }, [dispatch, item, modalToggle]);

    if (!item) {
        return null;
    }

    const initialValues: MarketBuyItemFormValues = {
        count: 1
    };

    const validate = Yup.object().shape<MarketBuyItemFormValues>({
        count: Yup.number()
            .min(1, "Не менее 1")
            .max(maxCount, `Не более ${maxCount}`)
            .required()
    });

    return (
        <Formik initialValues={initialValues} validationSchema={validate} onSubmit={onSubmit}>
            <Form {...props}>
                <ItemCountHelper>
                    {children}
                </ItemCountHelper>
            </Form>
        </Formik>
    )
}

export default MarketBuyItemForm;
import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";
import * as RS from 'reactstrap';
import { KeyValueSet } from "@interfaces/helpers";



const AlertInfo: ConstructionComponentInfo = {
    component: RS.Alert
}

const BadgeInfo: ConstructionComponentInfo = {
    component: RS.Badge
}

const BreadcrumbInfo: ConstructionComponentInfo = {
    component: RS.Breadcrumb
}

const BreadcrumbItemInfo: ConstructionComponentInfo = {
    component: RS.BreadcrumbItem
}

const ButtonInfo: ConstructionComponentInfo = {
    component: RS.Button
}

const ButtonDropdownInfo: ConstructionComponentInfo = {
    component: RS.ButtonDropdown
}

const ButtonGroupInfo: ConstructionComponentInfo = {
    component: RS.ButtonGroup
}

const ButtonToggleInfo: ConstructionComponentInfo = {
    component: RS.ButtonToggle
}

const ButtonToolbarInfo: ConstructionComponentInfo = {
    component: RS.ButtonToolbar
}

const CardInfo: ConstructionComponentInfo = {
    component: RS.Card
}

const CardBodyInfo: ConstructionComponentInfo = {
    component: RS.CardBody
}

const CardColumnsInfo: ConstructionComponentInfo = {
    component: RS.CardColumns
}

const CardDeckInfo: ConstructionComponentInfo = {
    component: RS.CardDeck
}

const CardFooterInfo: ConstructionComponentInfo = {
    component: RS.CardFooter
}

const CardGroupInfo: ConstructionComponentInfo = {
    component: RS.CardGroup
}

const CardHeaderInfo: ConstructionComponentInfo = {
    component: RS.CardHeader
}

const CardImgInfo: ConstructionComponentInfo = {
    component: RS.CardImg
}

const CardImgOverlayInfo: ConstructionComponentInfo = {
    component: RS.CardImgOverlay
}

const CardLinkInfo: ConstructionComponentInfo = {
    component: RS.CardLink
}

const CardSubtitleInfo: ConstructionComponentInfo = {
    component: RS.CardSubtitle
}

const CardTextInfo: ConstructionComponentInfo = {
    component: RS.CardText
}

const CardTitleInfo: ConstructionComponentInfo = {
    component: RS.CardTitle
}

const CarouselInfo: ConstructionComponentInfo = {
    component:  RS.Carousel
}

const CarouselItemInfo: ConstructionComponentInfo = {
    component: RS.CarouselItem
}

const CarouselControlInfo: ConstructionComponentInfo = {
    component: RS.CarouselControl
}

const CarouseIndicatorsInfo: ConstructionComponentInfo = {
    component: RS.CarouselIndicators
}

const CarouselCaptionInfo: ConstructionComponentInfo = {
    component: RS.CarouselCaption
}

const ColInfo: ConstructionComponentInfo = {
    component: RS.Col
}

const CollapseInfo: ConstructionComponentInfo = {
    component: RS.Collapse
}

const ContainerInfo: ConstructionComponentInfo = {
    component: RS.Container
}

const DropdownInfo: ConstructionComponentInfo = {
    component: RS.Dropdown
}

const DropdownItemInfo: ConstructionComponentInfo = {
    component: RS.DropdownItem
}

const DropdownMenuInfo: ConstructionComponentInfo = {
    component: RS.DropdownMenu
}

const DropdownToggleInfo: ConstructionComponentInfo = {
    component: RS.DropdownToggle
}

const FadeInfo: ConstructionComponentInfo = {
    component: RS.Fade
}

const FormInfo: ConstructionComponentInfo = {
    component: RS.Form
}

const FormFeedbackInfo: ConstructionComponentInfo = {
    component: RS.FormFeedback
}

const FormGroupInfo: ConstructionComponentInfo = {
    component: RS.FormGroup
}

const FormTextInfo: ConstructionComponentInfo = {
    component: RS.FormText
}

const InputInfo: ConstructionComponentInfo = {
    component: RS.Input
}

const InputGroupInfo: ConstructionComponentInfo = {
    component: RS.InputGroup
}

const InputGroupTextInfo: ConstructionComponentInfo = {
    component: RS.InputGroupText
}

const LabelInfo: ConstructionComponentInfo = {
    component: RS.Label
}

const ListGroupInfo: ConstructionComponentInfo = {
    component: RS.ListGroup
}

const ListGroupItemInfo: ConstructionComponentInfo = {
    component: RS.ListGroupItem
}

const ListGroupItemHeadingInfo: ConstructionComponentInfo = {
    component: RS.ListGroupItemHeading
}

const ListGroupItemTextInfo: ConstructionComponentInfo = {
    component: RS.ListGroupItemText
}

const MediaInfo: ConstructionComponentInfo = {
    component: RS.Media
}

const ModalInfo: ConstructionComponentInfo = {
    component: RS.Modal
}

const ModalBodyInfo: ConstructionComponentInfo = {
    component: RS.ModalBody
}

const ModalFooterInfo: ConstructionComponentInfo = {
    component: RS.ModalFooter
}

const ModalHeaderInfo: ConstructionComponentInfo = {
    component: RS.ModalHeader
}

const NavInfo: ConstructionComponentInfo = {
    component: RS.Nav
}

const NavbarInfo: ConstructionComponentInfo = {
    component: RS.Navbar
}

const NavbarBrandInfo: ConstructionComponentInfo = {
    component: RS.NavbarBrand
}

const NavbarTextInfo: ConstructionComponentInfo = {
    component: RS.NavbarText
}

const NavbarTogglerInfo: ConstructionComponentInfo = {
    component: RS.NavbarToggler
}

const NavItemInfo: ConstructionComponentInfo = {
    component: RS.NavItem
}

const NavLinkInfo: ConstructionComponentInfo = {
    component: RS.NavLink
}

const PaginationInfo: ConstructionComponentInfo = {
    component: RS.Pagination
}

const PaginationItemInfo: ConstructionComponentInfo = {
    component: RS.PaginationItem
}

const PaginationLinkInfo: ConstructionComponentInfo = {
    component: RS.PaginationLink
}

const PopoverInfo: ConstructionComponentInfo = {
    component: RS.Popover
}

const PopoverBodyInfo: ConstructionComponentInfo = {
    component: RS.PopoverBody
}

const PopoverHeaderInfo: ConstructionComponentInfo = {
    component: RS.PopoverHeader
}

const ProgressInfo: ConstructionComponentInfo = {
    component: RS.Progress
}

const RowInfo: ConstructionComponentInfo = {
    component: RS.Row
}

const SpinnerInfo: ConstructionComponentInfo = {
    component: RS.Spinner
}

const TabContentInfo: ConstructionComponentInfo = {
    component: RS.TabContent
}

const TableInfo: ConstructionComponentInfo = {
    component: RS.Table
}

const TabPaneInfo: ConstructionComponentInfo = {
    component: RS.TabPane
}

/*const TagInfo: ConstructionComponentinfo = {
    component: RS.Tag
} */

const ToastInfo: ConstructionComponentInfo = {
    component: RS.Toast
}

const ToastBodyInfo: ConstructionComponentInfo = {
    component: RS.ToastBody
}

const ToastHeaderInfo: ConstructionComponentInfo = {
    component: RS.ToastHeader
}

const TooltipInfo: ConstructionComponentInfo = {
    component: RS.Tooltip
}

const UncontrolledAlertInfo: ConstructionComponentInfo = {
    component: RS.UncontrolledAlert
}

const UncontrolledButtonDropdownInfo: ConstructionComponentInfo = {
    component: RS.UncontrolledButtonDropdown
}

const UncontrolledCarouselInfo: ConstructionComponentInfo = {
    component: RS.UncontrolledCarousel
}

const UncontrolledCollapseInfo: ConstructionComponentInfo = {
    component: RS.UncontrolledCollapse
}

const UncontrolledDropdownInfo: ConstructionComponentInfo = {
    component: RS.UncontrolledDropdown
}

const UncontrolledPopoverInfo: ConstructionComponentInfo = {
    component: RS.UncontrolledPopover
}

const UncontrolledTooltipInfo: ConstructionComponentInfo = {
    component: RS.UncontrolledTooltip
}

const infos: KeyValueSet<ConstructionComponentInfo> = {
    AlertInfo,
    BadgeInfo,
    BreadcrumbInfo,
    BreadcrumbItemInfo,
    ButtonInfo,
    ButtonDropdownInfo,
    ButtonGroupInfo,
    ButtonToggleInfo,
    ButtonToolbarInfo,
    CardInfo,
    CardBodyInfo,
    CardColumnsInfo,
    CardDeckInfo,
    CardFooterInfo,
    CardGroupInfo,
    CardHeaderInfo,
    CardImgInfo,
    CardImgOverlayInfo,
    CardLinkInfo,
    CardSubtitleInfo,
    CardTextInfo,
    CardTitleInfo,
    CarouselInfo,
    CarouselItemInfo,
    CarouselControlInfo,
    CarouseIndicatorsInfo,
    CarouselCaptionInfo,
    ColInfo,
    CollapseInfo,
    ContainerInfo,
    DropdownInfo,
    DropdownItemInfo,
    DropdownMenuInfo,
    DropdownToggleInfo,
    FadeInfo,
    FormInfo,
    FormFeedbackInfo,
    FormGroupInfo,
    FormTextInfo,
    InputInfo,
    InputGroupInfo,
    InputGroupTextInfo,
    LabelInfo,
    ListGroupInfo,
    ListGroupItemInfo,
    ListGroupItemHeadingInfo,
    ListGroupItemTextInfo,
    MediaInfo,
    ModalInfo,
    ModalBodyInfo,
    ModalFooterInfo,
    ModalHeaderInfo,
    NavInfo,
    NavbarInfo,
    NavbarBrandInfo,
    NavbarTextInfo,
    NavbarTogglerInfo,
    NavItemInfo,
    NavLinkInfo,
    PaginationInfo,
    PaginationItemInfo,
    PaginationLinkInfo,
    PopoverInfo,
    PopoverBodyInfo,
    PopoverHeaderInfo,
    ProgressInfo,
    RowInfo,
    SpinnerInfo,
    TabContentInfo,
    TableInfo,
    TabPaneInfo,
    //TagInfo,
    ToastInfo,
    ToastBodyInfo,
    ToastHeaderInfo,
    TooltipInfo,
    UncontrolledAlertInfo,
    UncontrolledButtonDropdownInfo,
    UncontrolledCarouselInfo,
    UncontrolledCollapseInfo,
    UncontrolledDropdownInfo,
    UncontrolledPopoverInfo,
    UncontrolledTooltipInfo
}

export default infos;
import { ConstructionComponentInfo } from "@interfaces/constructor/constructor"
import { ComponentType } from "react"
import NewsBlock from "./newsBlock"
import NewsBody from "./newsBody"
import NewsDate from "./newsDate"
import NewsList from "./newsList"
import NewsTitle from "./newsTitle"

const NewsBlockInfo: ConstructionComponentInfo = {
    component: NewsBlock,
    addedChildProps: [
        'news_count',
        'news_loading'
    ]
}

const NewsListInfo: ConstructionComponentInfo = {
    component: NewsList as ComponentType,
    injectChild: [
        'slider_ref'
    ]
}

const NewsTitleInfo: ConstructionComponentInfo = {
    component: NewsTitle,
    requiredParent: [
        'NewsList'
    ]
}

const NewsBodyInfo: ConstructionComponentInfo = {
    component: NewsBody,
    requiredParent: [
        'NewsList'
    ]
}

const NewsDateInfo: ConstructionComponentInfo = {
    component: NewsDate,
    requiredParent: [
        'NewsList'
    ]
}

export {
    NewsBlockInfo,
    NewsListInfo,
    NewsTitleInfo,
    NewsBodyInfo,
    NewsDateInfo
}
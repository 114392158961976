import { FC } from "react";
import { Text } from '@constructor/components';

interface DonateLogItemIndexProps {
    donate_log_item_index?: number;
    append?: number;
}

const DonateLogItemIndex: FC<DonateLogItemIndexProps> = ({ donate_log_item_index, append, ...props }) => {
    if (donate_log_item_index === undefined) {
        return null;
    }

    if (append) {
        donate_log_item_index += append;
    }

    return <Text text={donate_log_item_index} {...props} />
}

export default DonateLogItemIndex;
import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";
import PaginationPages from "./paginationPages";

export interface PaginationProps {
    pagination_page_name: number;
    pagination_current_page: number;
    pagination_total_pages: number;
    pagination_per_page: number;
    pagination_set_page: (page: number) => any;
    pagination_next_page: () => any;
    pagination_prev_page: () => any;
}

export interface PaginationItemProps {
    pagination_page: number;
    pagination_text_page: number;
    pagination_open_page: () => any;
}

const PaginationPagesInfo: ConstructionComponentInfo = {
    component: PaginationPages,
    addedChildProps: [
        'pagination_page',
        'pagination_text_page',
        'pagination_open_page'
    ],
    injectChild: [
        'pagination_total_pages',
        'pagination_set_page'
    ]
}

export {
    PaginationPagesInfo
}
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ReduxStore } from "@interfaces/store";
import { useEffect } from "react";
import { consumerActions } from '@store/consumer';

export function useConsumerProjectInfo() {
    const info = useSelector(({ consumer: { projectInfo } }: ReduxStore) => projectInfo, shallowEqual);
    const { loaded, loading, error } = info;
    const dispatch = useDispatch();

    useEffect(() => {
        if (!loaded && !loading && !error) {
            dispatch(consumerActions.loadProjectInfo());
        }
    }, [dispatch, error, loaded, loading]);

    return info;
}
import {FC, useCallback, useEffect, useMemo, useState} from "react";
import {Nav, Navbar} from 'react-bootstrap';
import {useSidebar} from "@hooks/selectors/panel.selectors";
import {Flex, Logo} from "@components/falcon/common";
import ToggleButton from "./toggleButton";
import {useAllRoles} from "@services/hooks";
import {consumerItems} from "./items";
import NavbarVerticalMenu from "@components/falcon/navbar/navbarVerticalMenu";
import {navbarBreakPoint} from "@services/config";

const NavbarVertical: FC = () => {

    const roles = useAllRoles();
    const links = useMemo(() => consumerItems.filter(x => !x.role || roles.includes(x.role)), [roles]);

    const [, setTimer] = useState<NodeJS.Timeout | null>(null);

    const {
        isOpen,
        isOnRight,
        showBurgerMenu
    } = useSidebar();
    const HTMLClassList = document.getElementsByTagName('html')[0].classList;

    useEffect(() => {
        if (!isOpen) {
            HTMLClassList.add('navbar-vertical-collapsed');
        } else {
            HTMLClassList.remove('navbar-vertical-collapsed');
        }
        return () => {
            HTMLClassList.remove('navbar-vertical-collapsed-hover');
        };
    }, [isOpen, HTMLClassList]);

    const handleMouseEnter = useCallback(() => {
        if (!isOpen) {
            setTimer(timer => {
                return setTimeout(() => {
                    HTMLClassList.add('navbar-vertical-collapsed-hover');
                }, 100);
            })
        }
    }, [HTMLClassList]);

    const handleMouseLeave = useCallback(() => {
        setTimer(timer => {
            if (timer) {
                clearTimeout(timer);
            }
            return null;
        });
        HTMLClassList.remove('navbar-vertical-collapsed-hover');
    }, [HTMLClassList]);

    return (
        <Navbar
            expand={navbarBreakPoint}
            variant='light'
            className='navbar-vertical navbar-card'>
            <Flex alignItems='center'>
                <ToggleButton/>
                <Logo at="navbar-vertical" textClass="text-primary" width={40} />
            </Flex>
            <Navbar.Collapse
                in={showBurgerMenu}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{backgroundImage: "none"}}
            >
                <div className="navbar-vertical-content scrollbar">
                    <Nav className="flex-column" as="ul">
                        <NavbarVerticalMenu routes={links}/>
                    </Nav>
                </div>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default NavbarVertical;
import { FieldProps } from "formik";
import React, {FC, PropsWithChildren} from "react";
import {Col, Form} from "react-bootstrap";

interface FormikSelectProps extends FieldProps {
    description?: string;
    label?: string;
}

const FormikSelect: FC<PropsWithChildren<FormikSelectProps>> = (prop) => {
    const {
        field,
        form: { touched, errors },
        label,
        description,
        children,
        ...props
    } = prop;

    return (
        <Col md={12}>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
            </Form.Group>
            <Form.Select {...props} {...field} isInvalid={Boolean(touched[field.name] && errors[field.name])}>
                {children}
            </Form.Select>
            <Form.Control.Feedback type="invalid">{errors[field.name]?.toString()}</Form.Control.Feedback>
            {description && <Form.Text>{description}</Form.Text>}
        </Col>
    )
}

export default FormikSelect;
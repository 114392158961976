import {FC, useEffect, useRef} from "react";
import {ConsumerTicket} from "@interfaces/consumer/support";
import TopicInfo from "./topicInfo";
import SimpleBarReact from 'simplebar-react';
import ChatContentIntro from "./chatContentIntro";
import ChatMessage from "./chatMessage";
import {useSortedChatMessages} from "@hooks/selectors/consumer/support.selectors";
import {SupportMessageProvider, useSupportTopicContext} from "@components/consumer/support/context";
import {fromUtcDate} from "@services/helpers";

interface ChatBodyProps {
    topic: ConsumerTicket;
}

const ChatBody: FC<ChatBodyProps> = ({topic}) => {

    let lastDate: string | null = null;
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const messages = useSortedChatMessages(topic.id, 'asc');

    const {
        scroll: {
            scrollToBottom,
            setScrollToBottom
        }
    } = useSupportTopicContext();

    useEffect(() => {
        if (scrollToBottom) {
            setTimeout(() => {
                messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 500);
            setScrollToBottom(false);
        }
    }, [scrollToBottom, setScrollToBottom]);

    return (
        <div className="chat-content-body" style={{display: 'inherit'}}>
            <TopicInfo/>
            <SimpleBarReact style={{height: '100%'}}>
                <div className="chat-content-scroll-area">
                    <ChatContentIntro/>
                    {messages.map(message => {
                        const date = new Date(message.createTime);
                        const day = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;

                        const showDate = lastDate !== day;
                        lastDate = day;
                        return (
                            <div key={message.id}>
                                {showDate && (
                                    <div
                                        className="text-center fs-11 text-500">{fromUtcDate(message.createTime).format("ll")}</div>
                                )}
                                <SupportMessageProvider value={message}>
                                    <ChatMessage/>
                                </SupportMessageProvider>
                            </div>
                        )
                    })}
                </div>
                <div ref={messagesEndRef}/>
            </SimpleBarReact>
        </div>
    )
}

export default ChatBody;
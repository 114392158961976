import {FC} from "react";
import classNames from "classnames";
import logo from '../../../assets/img/illustrations/falcon.png';
import {Link} from "react-router-dom";
import {useProjectName} from "@hooks/selectors/panel.selectors";

interface LogoProps {
    id?: string;
    at?: 'navbar-vertical' | 'navbar-top' | 'auth';
    width?: number;
    textClass?: string;
    className?: string;
}

const Logo: FC<LogoProps> = ({at = 'auth', width = 58, className, textClass, ...rest}) => {

    const name = useProjectName();

    return (
        <Link
            to="/"
            className={classNames(
                'text-decoration-none',
                { 'navbar-brand text-left': at === 'navbar-vertical' },
                { 'navbar-brand text-left': at === 'navbar-top' }
            )}
            {...rest}
        >
            <div
                className={classNames(
                    'd-flex',
                    {
                        'align-items-center py-3': at === 'navbar-vertical',
                        'align-items-center': at === 'navbar-top',
                        'flex-center fw-bolder fs-4 mb-4': at === 'auth'
                    },
                    className
                )}
            >
                <img className="me-2" src={logo} alt="Logo" width={width} />
                <span className={classNames('font-sans-serif', textClass)}>{name}</span>
            </div>
        </Link>
    )
}

export default Logo;
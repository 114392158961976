import { injectChildArray } from "@components/constructor";
import { useCharacters } from "@hooks/selectors/user.selectors";
import { useFormikContext } from "formik";
import {FC, PropsWithChildren, useEffect, useMemo} from "react";
import { SelectorsFormsValues } from "../selectors";

interface CharactersListProps {
    clear_on_change?: boolean;
}

interface CharactersListChildProps {
    character_id: string;
    character_name: string;
}

const CharactersList: FC<PropsWithChildren<CharactersListProps>> = ({ clear_on_change, children }) => {

    const {
        values: { selected_game_server, selected_account }, setFieldTouched, setFieldValue
    } = useFormikContext<SelectorsFormsValues>();

    const characters = useCharacters(selected_account || '', selected_game_server);
    const defaultChar = characters.length === 1 ? characters[0].id : '';

    const data = useMemo<CharactersListChildProps[]>(() => characters.map(character => {
        return {
            character_id: character.id,
            character_name: character.name
        }
    }), [characters]);

    useEffect(() => {
        if(clear_on_change) {
            setFieldTouched('selected_character', false, false);
            setFieldValue('selected_character', defaultChar, true);
        }
    }, [clear_on_change, characters, setFieldTouched, setFieldValue, defaultChar]);

    if (!selected_account && !selected_game_server) {
        return null;
    }

    return injectChildArray(data, children, 'character_id');
}

export default CharactersList;
import {FC} from "react";
import {ConsumerTicketMessage} from "@interfaces/consumer/support";

interface LastMessageProps {
    message: ConsumerTicketMessage;
}

const LastMessage: FC<LastMessageProps> = ({message}) => {

    const lastMassagePreview = message.attachments.length > 0
            ? `${message.senderName} sent files`
            : message?.message.split('<br>');

    return (
        <div className="chat-contact-content">
            {lastMassagePreview}
        </div>
    )
}

export default LastMessage;
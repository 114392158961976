import { injectContextArray } from "@components/constructor";
import { useLoginServers } from "@hooks/selectors/game.selectors";
import { useAccounts } from "@hooks/selectors/user.selectors";
import { LoginServer } from "@interfaces/game";
import {FC, PropsWithChildren, useMemo} from "react";
import { LoginServerProvider } from "./context";
import {InjectContextType} from "@interfaces/constructor/constructor";

interface LoginServersListChildProps {
    login_id: string;
    login_accounts_max: number;
    login_accounts_count: number;
    login_can_charge: boolean;
}

const LoginServersList: FC<PropsWithChildren> = ({ children }) => {

    const loginServers = useLoginServers();
    const accounts = useAccounts();

    const data = useMemo<InjectContextType<LoginServer, LoginServersListChildProps>[]>(() => loginServers.map(login => (
        {
            key: login.id,
            value: login,
            childData: {
                login_id: login.id,
                login_accounts_max: login.maxAccounts,
                login_accounts_count: accounts.filter(a => a.loginServer === login.id).length,
                login_can_charge: login.canCharge
            }
        }
    )), [accounts, loginServers]);

    return injectContextArray(data, LoginServerProvider, children);
}

export default LoginServersList;
import {FC, PropsWithChildren, useCallback, useMemo} from "react";
import {useFormikContext} from "formik";
import {useBalance} from "@hooks/selectors/user.selectors";
import {injectChild} from "@components/constructor";
import {useMarketItemContext} from "./items/context";

const ItemCountHelper: FC<PropsWithChildren> = ({ children }) => {
    const {
        setFieldValue,
        values
    } = useFormikContext<{count: number}>();

    const item = useMarketItemContext();
    const balance = useBalance();

    const inc_item_count = useCallback(() => {
        if(item && item.discountPrice * (values.count + 1) <= balance) {
            setFieldValue("count", values.count + 1);
        }

    }, [setFieldValue, values.count, balance, item]);

    const dec_item_count = useCallback(() => {
        if(values.count > 1) {
            setFieldValue("count", values.count - 1);
        }
    }, [setFieldValue, values.count]);

    const data = useMemo(() => {
        return {
            inc_item_count,
            dec_item_count,
            item_total_price: item ? item.discountPrice * values.count : 0
        }
    }, [dec_item_count, inc_item_count, item, values.count]);

    return injectChild(data, children);
}

export default ItemCountHelper;
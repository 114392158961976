import jwtDecode from 'jwt-decode';
import { AuthUser, AuthToken, IdToken, SignInModel, ForgotPasswordModel, ResetPasswordModel, SignUpModel } from '../models/auth/authModels';
import { shallowEqual } from 'react-redux';
import { apiUrl } from '..';
import FetchResult from '../models/fetchResult';
import { ISignUpResult } from '../interfaces/auth';

let a: AuthService | undefined;

type Unsubscribe = () => void;
type AuthCallback = (user: AuthUser | null) => void;

class AuthService {

    private token: AuthToken | null = null;
    //private apiUrl = '';
    private user: AuthUser | null = null;
    //public projectId = (window as any).ProjectID;

    private authCallback: AuthCallback | undefined;
    private refreshTimeout: NodeJS.Timeout | null = null;

    private refreshPromise: Promise<void> | null = null;

    constructor() {
        this.loadToken();
    }

    private loadToken() {
        const tokensString = localStorage.getItem('auth-tokens');
        const token = tokensString ? JSON.parse(tokensString) as AuthToken : undefined;

        if (token) {
            if (token.expiration_date - 300 * 1000 <= Date.now()) {
                this.token = token;
                this.refreshToken();
            }
            else {
                this.setToken(token);
            }
        }
    }

    private setUser(user: AuthUser | null) {
        if (user && shallowEqual(this.user, user)) {
            return;
        }
        this.user = user;

        if (this.authCallback) {
            this.authCallback(user);
        }
    }

    public get CurrentUser() {
        return this.user;
    }

    public onAuthStateChange(callback: AuthCallback): Unsubscribe {

        this.authCallback = callback;

        callback(this.CurrentUser);

        return () => {
            this.authCallback = undefined;
        }
    }

    private setToken(token: AuthToken | null) {

        this.token = token;

        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
            this.refreshTimeout = null;
        }

        if (token) {

            const delay = token.expiration_date - Date.now() - 180 * 1000;
            this.refreshTimeout = setTimeout(this.refreshToken, delay); //рефрешим токен за три минуты до конца
            localStorage.setItem('auth-tokens', JSON.stringify(token));

            const idToken = jwtDecode<IdToken>(token.id_token);

            const user: AuthUser = {
                id: idToken.sub,
                name: idToken.name,
                projectId: idToken.projectId,
                roles: idToken.role
            };

            this.setUser(user);
        }
        else {
            localStorage.removeItem('auth-tokens');
            this.setUser(null);
        }
    }

    private async refreshToken() {

        if (!this.token) {
            return;
        }

        if(this.refreshPromise) {
            return this.refreshPromise;
        }

        const searchParams = new URLSearchParams();
        searchParams.set('refresh_token', this.token.refresh_token);
        searchParams.set('grant_type', 'refresh_token');
        searchParams.set('scope', 'openid offline_access services roles profile');

        const request: RequestInit = {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            body: searchParams
        }

        /*const result = await this.fetchToken(request);
        if (result.ok && result.result) {
            this.setToken(result.result);
        }
        else {
            this.setToken(null);
        }*/

        this.refreshPromise = this.fetchToken(request)
            .then((result) => {
                if (result.ok && result.result) {
                    this.setToken(result.result);
                }
                else {
                    this.setToken(null);
                }

                this.refreshPromise = null;
            }).catch(e => {
                this.refreshPromise = null;
            });

        return this.refreshPromise;
    }

    public async getToken(forceRefresh?: boolean): Promise<string> {
        if (!this.token) {
            return '';
        }

        if (forceRefresh || this.token.expiration_date <= Date.now()) {
            await this.refreshToken();
        }

        return this.token?.access_token || '';
    }

    private async fetchToken(request: RequestInit): Promise<FetchResult<AuthToken>> {

        try {
            const response = await fetch(apiUrl + '/connect/token', {
                ...request,
                credentials: 'include'
            });
            const fr = await FetchResult.create<AuthToken>(response);
            if (fr.ok && fr.result) {
                const token = fr.result;
                token.expiration_date = Date.now() + token.expires_in * 1000;
            }
            return fr;
        }
        catch (e: any) {
            const fr = new FetchResult<AuthToken>();
            fr.ok = false;
            fr.errorCode = "FetchError";
            fr.message = e.message;
            return fr;
        }
    }

    public async signIn(model: SignInModel) {

        Object.assign(model, {
            grant_type: "password",
            scope: 'openid offline_access services roles profile',
            //projectId: this.projectId
        });

        const searchParams = new URLSearchParams();

        Object.entries(model).forEach(([key, value]) => searchParams.set(key, value));

        const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
        const ri: RequestInit = {
            method: 'POST',
            headers: headers,
            body: searchParams
        }

        const res = await this.fetchToken(ri);
        if (res.ok && res.result) {
            this.setToken(res.result);
        }

        return res;
    }

    public async forgotPassword(data: ForgotPasswordModel) {

        // data = Object.assign(data, {
        //     projectId: this.projectId
        // })

        const ri: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            credentials: 'include'
        }
        const res = await fetch(apiUrl + '/account/forgot', ri);

        return await FetchResult.create(res);
    }

    public async resetPassword(data: ResetPasswordModel) {

        // Object.assign(data, {
        //     projectId: this.projectId
        // });

        const ri: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            credentials: 'include'
        }
        const res = await fetch(apiUrl + '/account/reset', ri);

        return await FetchResult.create(res);
    }

    public async confirmEmail(code: string) {
        const data = {
            code,
            //projectId: this.projectId
        };

        const ri: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            credentials: 'include'
        }
        const res = await fetch(apiUrl + '/account/confirm/', ri);

        return await FetchResult.create(res);
    }

    public async signUp(data: SignUpModel) {

        // Object.assign(data, {
        //     projectId: this.projectId
        // });

        const ri: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            credentials: 'include'
        }
        const res = await fetch(apiUrl + '/account/signup/', ri);

        return await FetchResult.create<ISignUpResult>(res);
    }

    public async signOut() {

        const token = await auth().getToken();

        const headers = new Headers();
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }

        const request: RequestInit = {
            headers,
            credentials: 'include'
        };

        await fetch(apiUrl + '/connect/logout', request);

        this.setToken(null);
    }
}

export function auth() {
    if (!a) {
        a = new AuthService();
    }

    return a;
}
import { UserAuthLog, UserLogs, UserDonateLog } from '../../interfaces/user/users';
import UserService from '../../services/api/userService';
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

const slice = createSlice({
    initialState: {
        auth: {
            error: null,
            loaded: false,
            loading: false,
            data: []
        },
        donate: {
            loaded: false,
            loading: false,
            error: null,
            data: []
        }
    } as UserLogs,
    name: 'userLogsStore',
    reducers: {
        setAuthLoading: (state) => {
            state.auth = {
                ...state.auth,
                loading: true,
                error: null
            }
        },
        setAuthError: (state, action) => {
            state.auth = {
                ...state.auth,
                loading: false,
                error: action.payload
            }
        },
        setAuthLogs: (state, action: PayloadAction<UserAuthLog[]>) => {
            state.auth = {
                loaded: true,
                loading: false,
                error: null,
                data: action.payload
            }
        },
        setDonateLoading: (state) => {
            state.donate = {
                ...state.donate,
                loading: true,
                error: null
            }
        },
        setDonateError: (state, action) => {
            state.donate = {
                ...state.donate,
                loading: false,
                error: action.payload
            }
        },
        setDonateLogs: (state, action: PayloadAction<UserDonateLog[]>) => {
            state.donate = {
                loaded: true,
                loading: false,
                error: null,
                data: action.payload
            }
        }
    }
})

export const { reducer: userLogsReducer } = slice;

const {
    setAuthError,
    setAuthLoading,
    setAuthLogs,
    setDonateError,
    setDonateLoading,
    setDonateLogs
} = slice.actions;

export const loadDonateLogs = () => async (dispatch: Dispatch) => {
    dispatch(setDonateLoading());

    try {
        const api = new UserService();
        const logs = await api.fetchUserDonateLogs();
        dispatch(setDonateLogs(logs));
        return logs;
    }
    catch(e) {
        dispatch(setDonateError(e));
    }
}

export const loadAuthLogs = () => async (dispatch: Dispatch) => {
    dispatch(setAuthLoading());

    try {
        const api = new UserService();
        const logs = await api.fetchUserAuthLogs();
        dispatch(setAuthLogs(logs));
        return logs;
    }
    catch(e) {
        dispatch(setAuthError(e));
    }
} 
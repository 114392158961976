import {FC, PropsWithChildren, useEffect, useMemo, useState} from "react";
import {useMarketItemContext} from "./context";
import {useFile} from "@hooks/filesHooks";
import {injectChild} from "@components/constructor";

const MarketItemIcon: FC<PropsWithChildren> = ({children}) => {

    const item = useMarketItemContext();

    if(!item) {
        return null;
    }

    return (
        <ImageLoader fileId={item?.iconId}>
            {children}
        </ImageLoader>
    )
}

interface ImageLoaderProps {
    fileId?: string;
    loading?: boolean;
}

const ImageLoader: FC<PropsWithChildren<ImageLoaderProps>> = ({fileId, loading, children}) => {
    const file = useFile(fileId);    
    const [fileLoaded, setFileLoaded] = useState(false);
    
    useEffect(() => {
        if(file?.data?.url) {
            const loadImg = new Image();
            loadImg.onload = () => {
                setFileLoaded(true);
            }
            loadImg.src = file.data.url;
        }
    }, [file?.data?.url]);
    
    const data = useMemo(() => ({
        image_loading: loading || !fileLoaded,
        image_url: file?.data?.url || ''
    }), [file?.data?.url, fileLoaded, loading]);

    return injectChild(data, children);
}

export default MarketItemIcon;
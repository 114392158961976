import { Loadable } from '../../interfaces/helpers';
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { GameCharacter } from '../../interfaces/game';
import UserService from '../../services/api/userService';

const slice = createSlice({
    initialState: {
        loaded: false,
        loading: false,
        error: null,
        data: []
    } as Loadable<GameCharacter[]>,
    name: 'userCharactersStore',
    reducers: {
        setLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        setCharacters: (state, action: PayloadAction<GameCharacter[]>) => {
            state.data = action.payload;
            state.loaded = true;
            state.loading = false;
            state.error = null;
        }
    }
});

const {
    setCharacters,
    setError,
    setLoading
} = slice.actions;

export const { reducer: userCharactersReducer } = slice;

export const characterActions = {
    loadCharacters: () => async (dispatch: Dispatch) => {
        dispatch(setLoading());
        try {
            const api = new UserService();
            const chars = await api.fetchCharactes();
            dispatch(setCharacters(chars));
            return chars;
        }
        catch(e) {
            dispatch(setError(e));
        }
    },
    setCharacters
}

export const loadCharacters = () => async (dispatch: Dispatch) => {
    dispatch(setLoading());
    try {
        const api = new UserService();
        const chars = await api.fetchCharactes();
        dispatch(setCharacters(chars));
        return chars;
    }
    catch(e) {
        dispatch(setError(e));
    }
}
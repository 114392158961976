import { ConstructionComponentInfo } from "@interfaces/constructor/constructor"
import AccountSelector from "./accountSelector"
import CharacterSelector from "./characterSelector"
import GameServerSelector from "./gameServerSelector"
import LoginServerSelector from "./loginServerSelector"

export interface SelectorsFormsValues {
    selected_login_server: string;
    selected_game_server: string;
    selected_account: string;
    selected_character: string;
}


const LoginServerSelectorInfo: ConstructionComponentInfo = {
    component: LoginServerSelector
}

const GameServerSelectorInfo: ConstructionComponentInfo = {
    component: GameServerSelector
}

const AccountSelectorInfo: ConstructionComponentInfo = {
    component: AccountSelector
}

const CharacterSelectorInfo: ConstructionComponentInfo = {
    component: CharacterSelector
}

export {
    LoginServerSelectorInfo,
    GameServerSelectorInfo,
    AccountSelectorInfo,
    CharacterSelectorInfo
}
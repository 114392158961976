import {FC, PropsWithChildren, useMemo} from "react";
import {injectChildArray} from "@components/constructor";
import {useSupportTopicContext} from "@constructor/components/features/support/context";

interface SupportTopicInputAttachedImagesListChildProps {
    attached_image_id: string;
    attached_image_url: string;
    remove_attached_image: () => void;
}

const SupportTopicInputAttachedImagesList: FC<PropsWithChildren> = ({children}) => {

    const {
        attachedImages,
        removeAttachedImage
    } = useSupportTopicContext() ?? {};

    //console.log(attachedImages);

    const data = useMemo<SupportTopicInputAttachedImagesListChildProps[]>(() => attachedImages?.map(image => ({
        attached_image_id: image.id,
        attached_image_url: image.fileUrl,
        remove_attached_image: () => removeAttachedImage && removeAttachedImage(image.id)
    })) ?? [], [attachedImages, removeAttachedImage]);

    return injectChildArray(data, children, 'attached_image_id');
}

export default SupportTopicInputAttachedImagesList;
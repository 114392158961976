import { ConsumerShortNewsFull } from '@interfaces/consumer/shortNews';
import { fromUtcDate } from '../../../services';
import {FormikHelpers, Formik, Form} from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import {Col, Row} from "react-bootstrap";
import {FormPageFooter, FormPageHeader} from "@components/falcon/common/forms";
import {BaseInfoBlock, CreateInfoBlock, DatesBlock} from "./forms";
import {FC} from "react";
import * as Yup from 'yup';

interface EditShortNewsFormProps {
    news?: ConsumerShortNewsFull;
    isNew?: boolean;
    onSubmit: (values: EditShortNewsFormValues, actions: FormikHelpers<EditShortNewsFormValues>) => void;
}

export interface EditShortNewsFormValues {
    title: string;
    text: string;
    isActive: boolean;
    beginTime: Date;
    endTime: Date;
}

const validationSchema = Yup.object().shape<EditShortNewsFormValues>({
    title: Yup.string()
        .min(3)
        .max(256)
        .required(),
    text: Yup.string()
        .min(3)
        .max(10000)
        .required(),
    isActive: Yup.boolean(),
    beginTime: Yup.date()
        .required(),
    endTime: Yup.date()
        .required()
})

const EditShortNewsForm: FC<EditShortNewsFormProps> = ({ news, isNew, onSubmit }) => {

    const initialValues: EditShortNewsFormValues = {
        title: news?.title || '',
        text: news?.text || '',
        beginTime: (news && fromUtcDate(news.beginTime).toDate()) || new Date(),
        endTime: (news && fromUtcDate(news.endTime).toDate()) || new Date(),
        isActive: news?.isActive || false
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            <Form>
                <Row className="g-3">
                    <Col xs={12}>
                        <FormPageHeader title={isNew ? "Создание новости" : `Редактирование новости "${news?.title}"`} />
                    </Col>
                    <Col lg={8}>
                        <BaseInfoBlock />
                    </Col>
                    <Col lg={4}>
                        {!isNew && news && <CreateInfoBlock news={news} />}
                        <DatesBlock />
                    </Col>
                    <Col>
                        <FormPageFooter
                            cancelUrl={'/consumer/shortnews/'}
                        />
                    </Col>
                </Row>
            </Form>
        </Formik>
    )
}

export default EditShortNewsForm;
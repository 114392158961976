import ConsumerDailyService from "@api/consumer/consumerDailyService";
import withPage from "@components/hoc/withPage";
import { useRole } from "@hooks/userHooks";
import { IConsumerGift } from "@interfaces/consumer/daily";
import { FC, useState } from "react";
import {Link, useHistory} from "react-router-dom";
import {useRemoteTable} from "@hooks/tableHooks";
import {ColumnDef} from "@tanstack/react-table";
import AdvanceTableProvider from "@components/falcon/common/tables/context";
import {Flex, IconButton} from "@components/falcon/common";
import {AdvanceTable, AdvanceTableFooter, AdvanceTableSearchBox} from "@components/falcon/common/tables";
import {Card, Col, Form, Row} from "react-bootstrap";
import {fromUtcDate} from "@services/helpers";

const columns: ColumnDef<IConsumerGift>[] = [
    {
        accessorKey: 'name',
        header: 'Название',
        enableSorting: true,
        meta: {
            headerProps: { className: 'pe-1 text-900' },
            cellProps: {
                className: 'py-2'
            }
        },
        cell: ({row: {original: {id, name}}}) => (
            <Link to={`/consumer/daily/${id}/`}>
                <Flex alignItems="center" className="flex-1">
                    <h5 className="mb-0 fs-10">{name}</h5>
                </Flex>
            </Link>
        )
    },
    {
        accessorKey: 'days',
        header: 'Дни',
        enableSorting: true,
        meta: {
            headerProps: { className: 'text-900' },
            placeholderSize: 2
        }
    },
    {
        accessorKey: 'isActive',
        header: 'Активно',
        enableSorting: false,
        cell: ({row: {original: {isActive}}}) => (
            <Form.Check type="checkbox" defaultChecked={isActive} disabled />
        ),
        meta: {
            headerProps: { className: 'text-center text-900' },
            cellProps: { className: 'text-center' },
            placeholderSize: 1
        }
    },
    {
        accessorKey: 'createTime',
        header: 'Дата создания',
        enableSorting: true,
        cell: ({row: {original: {createTime}}}) => fromUtcDate(createTime).calendar(),
        meta: {
            headerProps: { className: 'text-900 text-end' },
            cellProps: { className: 'text-end' },
            placeholderSize: 2
        }
    }
];

const ConsumerDailyGiftListPage: FC = () => {

    const history = useHistory();
    const [api] = useState(() => new ConsumerDailyService());
    const canEdit = useRole('consumer.daily.edit');

    const table = useRemoteTable({
        name: 'consumer.daily.list',
        columns,
        defaultState: {
            sorting: [
                {
                    id: 'createTime',
                    desc: true
                }
            ]
        }
    }, api.fetchGiftsList);

    return (
        <AdvanceTableProvider table={table}>
            <Card className="mb-3">
                <Card.Header>
                    <Row className="flex-between-center">
                        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                            <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">Бонусы</h5>
                        </Col>
                        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                            <div className="d-flex">
                                {canEdit && (
                                    <IconButton
                                        variant="falcon-default"
                                        size="sm"
                                        icon="plus"
                                        transform="shrink-3"
                                        className='me-2'
                                        onClick={() => history.push('/consumer/daily/add/')}
                                    >
                                        <span className="d-none d-sm-inline-block ms-1">Создать</span>
                                    </IconButton>
                                )}
                                <Flex><AdvanceTableSearchBox/></Flex>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowInfo
                        rowsPerPageSelection
                        paginationButtons
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableProvider>
    )
}

export default withPage(ConsumerDailyGiftListPage);
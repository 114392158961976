import { createContext, useContext } from "react";

export interface ConfirmEmailState {
    done: boolean;
    success: boolean;
    fetching: boolean;
    error: boolean;
}

const ConfirmEmailContext = createContext<ConfirmEmailState>(undefined as any);
export const ConfirmEmailProvider = ConfirmEmailContext.Provider;

export function useConfirmEmailContext(): ConfirmEmailState | undefined {
    return useContext(ConfirmEmailContext);
}
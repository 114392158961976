import { useAccounts } from "@hooks/selectors/user.selectors";
import classNames from "classnames";
import { Field, useFormikContext } from "formik";
import { FC, useCallback } from "react";
import { SelectorsFormsValues } from ".";
import { FormFieldProps } from "..";

const AccountSelector: FC<FormFieldProps> = ({ className, invalidClassName, ...props }) => {

    const {
        errors,
        touched,
        values: { selected_login_server, selected_character },
        setFieldValue,
        handleChange,
        setFieldTouched
    } = useFormikContext<SelectorsFormsValues>();
    const accounts = useAccounts(selected_login_server || undefined);

    const onChange = useCallback((e: any) => {
        handleChange(e);
        if (selected_character) {
            setFieldValue('selected_character', '');
            setFieldTouched('selected_character', false);
        }
    }, [handleChange, selected_character, setFieldTouched, setFieldValue]);

    const invalid = Boolean(errors['selected_account'] && touched['selected_account']);

    const cn = classNames(className, invalidClassName && {
        [invalidClassName]: invalid
    });

    return (
        <Field
            id="selected_account"
            name="selected_account"
            as="select"
            onChange={onChange}
            className={cn}
            invalid={invalid || undefined}
            {...props}>
            <option value='' key={'empty'}>Выберите аккаунт</option>
            {
                selected_login_server &&
                accounts.map(account => <option value={account.id} key={account.id}>{account.name}</option>)
            }
        </Field>
    )
}

export default AccountSelector;
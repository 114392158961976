import { injectChild } from "@components/constructor";
import { useFormikContext } from "formik";
import {FC, PropsWithChildren, useCallback, useMemo} from "react";

interface CountHelperProps {
    min: number;
    max: number;
    step?: number;
    name: string;
}

interface CountHelperChildProps {
    inc_field_count: () => void;
    dec_field_count: () => void;
}

const CountHelper: FC<PropsWithChildren<CountHelperProps>> = ({ min, max, name= 'count', step = 1, children }) => {
    const {
        setFieldValue,
        values
    } = useFormikContext<any>();

    const count: number = values[name];

    const inc_field_count = useCallback(() => {
        if (count < max) {
            setFieldValue(name, count + step);
        }

    }, [count, max, name, setFieldValue, step]);

    const dec_field_count = useCallback(() => {
        if (count > min) {
            setFieldValue(name, count - step);
        }
    }, [count, min, name, setFieldValue, step]);

    const data = useMemo<CountHelperChildProps>(() => ({
        inc_field_count,
        dec_field_count
    }), [dec_field_count, inc_field_count]);

    return injectChild(data, children);
}

export default CountHelper;
import {FC, PropsWithChildren} from "react";
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import {useFilesUrls} from "@hooks/filesHooks";

interface LightBoxGalleryProps {
    index: number | null;
    images?: string[];
    imageIds?: string[];
    setIndex: (index: number | null) => void;
}

const LightBoxGallery: FC<PropsWithChildren<LightBoxGalleryProps>> = ({
                                                                          index,
                                                                          setIndex,
                                                                          images = [],
                                                                          imageIds = [],
                                                                          children
}) => {

    const srcs = useFilesUrls(imageIds, images);

    return (
        <div>
            {children}
            {index !== null && (
                <Lightbox
                    mainSrc={srcs[index]}
                    nextSrc={index + 1 > srcs.length ? undefined : srcs[index + 1]}
                    prevSrc={index - 1 < 0 ? undefined : srcs[index - 1]}
                    onCloseRequest={() => setIndex(null)}
                    onImageLoad={() => window.dispatchEvent(new Event('resize'))}
                    onMovePrevRequest={() => setIndex((index + srcs.length - 1) % srcs.length)}
                    onMoveNextRequest={() => setIndex((index + 1) % srcs.length)}
                    reactModalStyle={{ overlay: { zIndex: 999999 } }}
                />
            )}
        </div>
    )
}

export default LightBoxGallery;
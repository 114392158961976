import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { ConsumerUsersStore } from "../../interfaces/consumer/store";
import { ConsumerUserFull, ConsumerUserList } from "../../interfaces/consumer/users";
import { KeyValueSet } from "../../interfaces/helpers";
import ConsumerUsersService from "../../services/api/consumer/consumerUsersService";

const slice = createSlice({
    initialState: {
        count: 0,
        page: 0,
        current: {
            loaded: false,
            loading: false,
            error: null,
            data: undefined
        },
        users: {
            loaded: false,
            loading: false,
            error: null,
            data: []
        },
        allowedRoles: {
            loaded: false,
            loading: false,
            error: null,
            data: {}
        }
    } as ConsumerUsersStore,
    name: 'consumerUsersStore',
    reducers: {
        setUserLoading: (state) => {
            state.current = {
                ...state.current,
                loading: true,
                error: null,
                loaded: false,
                data: undefined
            }
        },
        setUsersLoading: (state) => {
            state.users = {
                ...state.users,
                loading: true,
                error: null
            }
        },
        setRolesLoading: (state) => {
            state.allowedRoles = {
                loading: true,
                loaded: false,
                error: null,
                data: {}
            }
        },

        setUserError: (state, action) => {
            state.current = {
                loaded: false,
                loading: false,
                error: action.payload,
                data: undefined
            }
        },
        setUsersError: (state, action) => {
            state.users = {
                loaded: false,
                loading: false,
                error: action.payload,
                data: []
            }
        },
        setRolesError: (state, action) => {
            state.allowedRoles = {
                ...state.allowedRoles,
                loaded: false,
                loading: false,
                error: action.payload
            }
        },

        setUser: (state, action: PayloadAction<ConsumerUserFull>) => {
            state.current = {
                loaded: true,
                loading: false,
                error: null,
                data: action.payload
            }
        },
        setUsers: (state, action: PayloadAction<ConsumerUserList>) => {
            const { count, page, users } = action.payload;

            state.count = count;
            state.page = page;
            state.users = {
                loaded: true,
                loading: false,
                error: null,
                data: users
            }
        },
        setRoles: (state, action: PayloadAction<KeyValueSet<string[]>>) => {
            state.allowedRoles = {
                loaded: true,
                loading: false,
                error: null,
                data: action.payload
            }
        }
    }
})

export const { reducer: consumerUsersReducer } = slice;

const {
    setRoles,
    setRolesError,
    setRolesLoading,
    setUser,
    setUserError,
    setUserLoading,
    //setUsers,
    //setUsersError,
    //setUsersLoading
} = slice.actions;

export const loadUser = (id: string, silent: boolean = false) => async (dispatch: Dispatch) => {
    if (!silent) {
        dispatch(setUserLoading());
    }
    try {
        const api = new ConsumerUsersService();
        const [user, accounts, donateLogs, logs] = await Promise.all([
            api.fetchUser(id),
            api.fetchUserAccounts(id),
            api.fetchUserPayments(id),
            api.fetchUserLogs(id)
        ]);
        dispatch(setUser({
            ...user,
            ...logs,
            accounts,
            donateLogs
        }));
        return user;
    }
    catch (e) {
        dispatch(setUserError(e));
    }
}

/*export const loadUsers = () => async (dispatch: Dispatch) => {
    dispatch(setUsersLoading());
    try {
        const api = new ConsumerUsersService();
        const user = await api.fetchConsumerUsers();
        dispatch(setUser(user));
        return user;
    }
    catch(e) {
        dispatch(setUserError(e));
    }
}*/

export const loadRoles = () => async (dispatch: Dispatch) => {
    dispatch(setRolesLoading());
    try {
        const api = new ConsumerUsersService();
        const roles = await api.fetchConsumerRoles();
        dispatch(setRoles(roles));
        return roles;
    }
    catch (e) {
        dispatch(setRolesError(e));
    }
}
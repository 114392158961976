import { injectContextAsArray } from "@components/constructor";
import { useUserShortNews } from "@hooks/selectors/user.selectors";
import { IShortNews } from "@interfaces/user/shortNews";
import { ReactNode, RefObject } from "react";
import { useMemo } from "react";
import { NewsProvider } from "./context";
import Slick, { Settings } from "react-slick";
import {InjectContextType} from "@interfaces/constructor/constructor";

interface NewsListProps {
    children: ReactNode;
    slider?: Settings;
    slider_ref?: RefObject<Slick>;
}

const NewsList = ({ children, slider, slider_ref }: NewsListProps) => {

    const news = useUserShortNews().data;

    const data = useMemo<InjectContextType<IShortNews>[]>(() => news.map((i) => {
        return {
            key: i.id,
            value: i
        }
    }), [news]);

    if (slider || slider_ref) {
        return (
            <Slick {...slider} ref={slider_ref}>
                {injectContextAsArray(data, NewsProvider, children)}
            </Slick>
        )
    }
    return injectContextAsArray(data, NewsProvider, children);
}

export default NewsList;
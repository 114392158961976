import {FC, useCallback, useRef, useState} from "react";
import {Card, Col, Form, Row} from "react-bootstrap";
import {Flex, LineChart} from "@components/falcon/common";
import ConsumerService from "@api/consumerService";
import {useStateLoader} from "@hooks/panelHooks";

interface ChartData {
    all: number[];
    successful: number[];
    failed: number[];
}

type PaymentStatus = keyof ChartData;

const PaymentsChartWidget: FC = () => {

    const chartRef = useRef(null);
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>('successful');

    const [api] = useState(() => new ConsumerService());
    const loadWidget = useCallback(() => api.fetchPaymentChartsWidget(), [api]);

    const {
        loading,
        data
    } = useStateLoader(loadWidget);

    const chartData: ChartData = {
        all: data?.allPayments ?? [1],
        successful: data?.successfulPayments ?? [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
        failed: data?.failedPayments ?? [1]
    }

    return (
        <Card className="rounded-3 overflow-hidden h-100 shadow-none">
            <Card.Body
                className="bg-line-chart-gradient"
                as={Flex}
                justifyContent="between"
                direction="column">
                <Row className="align-items-center g-0">
                    <Col>
                        <h4 className="text-white mb-0">Сегодня {data?.todayProfit} ₽</h4>
                        <p className="fs-10 fw-semibold text-white">
                            Вчера <span className="opacity-50">{data?.yesterdayProfit} ₽</span>
                        </p>
                    </Col>
                    <Col xs="auto" className="d-none d-sm-block">
                        <Form.Select
                            size="sm"
                            className="mb-3"
                            value={paymentStatus}
                            onChange={e => setPaymentStatus(e.target.value as PaymentStatus)}
                        >
                            <option value="all">Все платежи</option>
                            <option value="successful">Успешные платежи</option>
                            <option value="failed">Отмененные</option>
                        </Form.Select>
                    </Col>
                </Row>
                <LineChart
                    data={chartData}
                    status={paymentStatus}
                    ref={chartRef} />
            </Card.Body>
        </Card>
    )
}

export default PaymentsChartWidget;

import { loadGameAccounts, loadGameAccount, removeGameAccount, createAccount, patchAccount, userAccountsReducer, accountActions } from './userAccountsStore';
import { setUser, setAuthState, userAuthStateReducer } from './userAuthStore';
import { loadPersonalBonusCodes, loadUsedBonusCodes, userBonusCodesReducer } from './userBonusCodesStore';
import { characterActions, userCharactersReducer } from './userCharactersStore';
import { clearUserData, loadBalance, loadUserData, userDataReducer, setBalance, dataActions } from './userDataStore';
import { inventoryActions, userInventoryReducer } from './userInventoryStore';
import { loadAuthLogs, loadDonateLogs, userLogsReducer } from './userLogsStore';
import { loadMarket, userMarketReducer } from './userMarketStore';
import { 
    loadNotifications, 
    loadNotification,
    readNotification,
    deleteNotification, 
    removeNotification, 
    setNotificationReaded,
    userNotificationsReducer,
    notificationActions
} from './userNotificationsStore';
import { projectActions, userProjectReducer } from './userProjectStore';
import { loadNews, userShortNewsReducer } from './userShortnewsStore';
import {
    loadTaskMessages,
    addTaskMessage,
    userTaskMessagesReducer,
    editTaskMessage,
    deleteTaskMessage
} from './userTaskMessagesStore';
import { loadTask, loadTasks, createTask, userTaskReducer } from './userTasksStore';

import { combineReducers } from 'redux';
import { UserStore } from '@interfaces/store';
import { dailyActions, dailyReducer } from './userDailyStore';
import { donateBonusActions, donateBonusReducer } from './userDonateBonus';
import { itemsReducer } from './userItemsStore';
import { goalActions, goalsReducer } from './userGoalStore';
import { refActions, refReducer } from './userRefStore';

export const userReducer = combineReducers<UserStore>({
    accounts: userAccountsReducer,
    bonusCodes: userBonusCodesReducer,
    user: userDataReducer,
    characters: userCharactersReducer,
    inventory: userInventoryReducer,
    logs: userLogsReducer,
    notifications: userNotificationsReducer,
    project: userProjectReducer,
    market: userMarketReducer,
    shortNews: userShortNewsReducer,
    taskList: userTaskReducer,
    taskMessages: userTaskMessagesReducer,
    authState: userAuthStateReducer,
    daily: dailyReducer,
    donateBonuses: donateBonusReducer,
    items: itemsReducer,
    goals: goalsReducer,
    refs: refReducer
});

export const userActions = {
    data: dataActions,
    project: projectActions,
    accounts: accountActions,
    characters: characterActions,
    inventory: inventoryActions,
    notifications: notificationActions,
    donateBonus: donateBonusActions,
    
    clearUserData,
    loadBalance,
    setBalance,
    loadUserData,
    loadGameAccounts,
    loadGameAccount,
    removeGameAccount,
    loadPersonalBonusCodes,
    loadUsedBonusCodes,
    loadAuthLogs,
    loadDonateLogs,

    loadNotifications,
    loadNotification,
    readNotification,
    removeNotification,
    setNotificationReaded,

    loadMarket,
    loadNews,
    loadTask,
    loadTasks,
    loadTaskMessages,
    editTaskMessage,
    deleteTaskMessage,

    setUser,
    setAuthState,
    createTask,
    createAccount,
    patchAccount,
    addTaskMessage,
    deleteNotification,

    daily: dailyActions,
    goals: goalActions,
    refs: refActions
}
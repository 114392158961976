import { useQueryById } from "@hooks/selectors/user/query.selectors";
import { FC } from "react";
import { useQueryResultContext } from "./context";

const QueryTableBody: FC = (props) => {

    const result = useQueryResultContext();
    const query = useQueryById(result?.queryId || '');

    if (!query?.columns || !result?.rows) {
        return null;
    }

    const key = query.columns.find(c => c.key)?.name;

    if (!key) {
        return null;
    }

    return (
        <tbody {...props}>
            {
                result.rows.map(row => (
                    <tr key={row[key]}>
                        {
                            query.columns.map(column => {
                                if (column.hidden) {
                                    return null;
                                }

                                return (
                                    <td key={column.name}>{row[column.name]}</td>
                                )
                            })
                        }
                    </tr>
                ))
            }
        </tbody>
    )
}

export default QueryTableBody;
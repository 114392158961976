import { useSupportTopicContext } from "@components/constructor/components/features/support/context";
import { useDataLoading } from "@hooks/loadingHooks";
import { ReduxStore } from "@interfaces/store";
import { loadTasks } from "@store/user/userTasksStore";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export function useTasks() {
    const data = useSelector(({ user: { taskList } }: ReduxStore) => taskList);

    useDataLoading(loadTasks, data);

    return data;
}

export function useTask(id: string) {
    return useSelector(({ user: { taskList } }: ReduxStore) => taskList.data.find(t => t.id === id));
}

export function useTaskMessages(taskId: string) {
    return useSelector(({ user: { taskMessages } }: ReduxStore) => taskMessages.data.filter(m => m.task === taskId), shallowEqual);
}

export function useTaskMessage(taskId?: string, messageId?: string) {
    return useSelector(({ user: { taskMessages } }: ReduxStore) => taskMessages.data.find(m => m.task === taskId && m.id === messageId));
}

export function useTopicAuto(id?: string) {
    const context = useSupportTopicContext();
    const { topic_id } = useParams<{ topic_id: string }>();

    return useTask(context?.topic?.id || topic_id || id || '');
}

export function useSupportLoading() {
    return useSelector(({ user }: ReduxStore) => user.taskList.loading || user.taskMessages.loading);
}
import React from 'react';
import { Modal, ModalHeader, ModalBody, Table, ModalFooter, Button } from 'reactstrap';
import {ConsumerBonusCode} from "@interfaces/consumer/bonusCodes";

interface ConsumerCreatedBonusCodesListModalProps {
    codes: ConsumerBonusCode[];
    isOpened: boolean;
    toggle(): void;
}

const ConsumerCreatedBonusCodesListModal: React.FC<ConsumerCreatedBonusCodesListModalProps> = ({ codes, isOpened, toggle }) => {
    return (
        <Modal
            isOpen={isOpened}
            toggle={toggle}
            centered>
            <ModalHeader toggle={toggle}>
                Созданные бонусные коды
            </ModalHeader>
            <ModalBody>
                <Table striped>
                    <thead>
                        <tr>
                            <th>
                                Код
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            codes.length === 0 ? <tr><td align='center'>Список пуст</td></tr> :
                                codes.map(code => (
                                    <tr key={code.id}>
                                        <td align='center'>{code.code.toUpperCase()}</td>
                                    </tr>
                                ))
                        }
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" type="button" onClick={toggle}>
                    Закрыть
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ConsumerCreatedBonusCodesListModal;
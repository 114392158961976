import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { ICPaymentBonus } from "@interfaces/consumer/payments";
import { Loadable } from "@interfaces/helpers";
import CPaymentsService from "@api/consumer/consumerPaymentsService";
import { PatchPaymentBonusModel, PutPaymentBonusModel } from "@models/consumer/payments";
import FetchResult from "@models/fetchResult";

export interface ICPaymentsStore {
    bonuses: Loadable<ICPaymentBonus[]>;
}

const slice = createSlice({
    name: 'CPaymentsStore',
    initialState: {
        bonuses: {
            loaded: false,
            loading: false,
            error: null,
            data: []
        }
    } as ICPaymentsStore,
    reducers: {
        setBonuses: (state, { payload: bonuses }: PayloadAction<ICPaymentBonus[]>) => {
            state.bonuses = {
                loaded: true,
                loading: false,
                error: null,
                data: bonuses
            }
        },
        setBonus: (state, { payload: bonus }: PayloadAction<ICPaymentBonus>) => {
            state.bonuses.data = state.bonuses.data.filter(b => b.id !== bonus.id).concat(bonus);
        },
        deleteBonus: (state, {payload: id}: PayloadAction<string>) => {
            state.bonuses.data = state.bonuses.data.filter(b => b.id !== id);
        },
        setBonusesLoading: (state) => {
            state.bonuses.loading = true;
        },
        setBonusesError: (state, { payload: error }: PayloadAction<any>) => {
            state.bonuses = {
                ...state.bonuses,
                loading: false,
                error
            }
        }
    }
});

const {
    setBonuses,
    setBonus,
    deleteBonus,
    setBonusesError,
    setBonusesLoading
} = slice.actions;

export const { reducer: cPaymentsReducer } = slice;

export const cPaymentsActions = {
    loadBonuses: (silent: boolean = false) => async (dispatch: Dispatch) => {
        try {
            if (!silent) {
                dispatch(setBonusesLoading());
            }

            const api = new CPaymentsService();
            const result = await api.loadBonuses();

            if (result.success) {
                dispatch(setBonuses(result.data));
            }
            else {
                dispatch(setBonusesError(result));
            }

            return result;
        } catch (error) {
            return error as FetchResult<ICPaymentBonus[]>;
        }
    },
    loadBonus: (id: string) => async (dispatch: Dispatch) => {
        try {
            const api = new CPaymentsService();
            const result = await api.getBonus(id);

            if(result.success) {
                dispatch(setBonus(result.data));
            }
            else if(result.errorCode === 'BonusNotFound') {
                dispatch(deleteBonus(id));
            }

            return result;
        } catch (error) {
            return error as FetchResult<ICPaymentBonus>;
        }
    },
    deleteBonus: (id: string) => async (dispatch: Dispatch) => {
        try {
            const api = new CPaymentsService();
            const result = await api.deleteBonus(id);

            if(result.ok) {
                dispatch(deleteBonus(id));
            }

            return result;
        } catch (error) {
            return error as FetchResult;
        }
    },
    createBonus: (model: PutPaymentBonusModel) => async (dispatch: Dispatch) => {
        try {
            const api = new CPaymentsService();
            const result = await api.putBonus(model);

            if(result.success) {
                dispatch(setBonus(result.data));
            }

            return result;
        } catch (error) {
            return error as FetchResult<ICPaymentBonus>;
        }
    },
    updateBonus: (model: PatchPaymentBonusModel) => async (dispatch: Dispatch) => {
        try {
            const api = new CPaymentsService();
            const result = await api.patchBonus(model);

            if(result.success) {
                dispatch(setBonus(result.data));
            }

            return result;
        } catch (error) {
            return error as FetchResult<ICPaymentBonus>;
        }
    }
}
import { createContext, ReactText, useContext } from "react";

export interface DropdownContextState {
    value: string;
    setValue: (value: ReactText) => void
}

export const DropdownContext = createContext<DropdownContextState>(undefined as any);
export const DropdownProvider = DropdownContext.Provider;

export function useDropdownContext(): DropdownContextState | undefined {
    return useContext(DropdownContext);
}
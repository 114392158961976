import { FC } from "react";
import { useNewsContext } from "./context";
import { Text } from '@components/constructor/components'

const NewsTitle: FC = (props) => {
    const news = useNewsContext();

    if (!news) {
        return null;
    }

    return (
        <Text text={news.title} {...props} />
    )
}

export default NewsTitle;
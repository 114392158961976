import ConsumerRefService from "@api/consumer/consumerRefService";
import { EditConditionForm } from "@components/consumer/goals";
import withPage from "@components/hoc/withPage";
import { useBreadcrumbs, useStateLoader } from "@hooks/panelHooks";
import { IGoalCondition } from "@interfaces/consumer/refs";
import { FC, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Card, CardHeader, CardTitle } from "reactstrap";

const EditGoalConditionPage: FC = () => {
    const { goal_id, condition_id } = useParams<{ goal_id: string, condition_id: string }>();

    const loadGoal = useCallback((goal_id: string, conditionId: string) => {
        const api = new ConsumerRefService();
        return api.getGoalCondition(goal_id, conditionId);
    }, []);

    const { data: cond } = useStateLoader<IGoalCondition>(loadGoal, goal_id, condition_id);

    useBreadcrumbs('consumer.goals.condition.current.name', true, cond?.name);
    useBreadcrumbs('consumer.goals.current.name', true, cond?.goalName, `/consumer/goals/${cond?.goalId}/`);
    useBreadcrumbs('consumer.goals.conditions', true, null, `/consumer/goals/${cond?.goalId}/conditions/`);

    if (!cond) {
        return null;
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h5">
                    Редактирование условия {cond?.name}
                </CardTitle>
            </CardHeader>
            <EditConditionForm cond={cond}/>
        </Card>
    )
}

export default withPage(EditGoalConditionPage);
import { FC } from "react";
import { Text } from '@constructor/components';
import { useRefStatContext } from "./context";

const RefStatName: FC = (props) => {

    const stat = useRefStatContext();
    if (!stat?.rewardName) {
        return null;
    }

    return (
        <Text text={stat.rewardName} {...props} />
    )
}

export default RefStatName;
import React, {FC} from "react";
import {Card, Col, Form, Row} from "react-bootstrap";
import {Field, useFormikContext} from "formik";
import {FormikRowCheckbox, FormikRowInput} from "@components/panel/formik";
import ReactQuill from "@components/panel/reactQuill";

const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'align'];
const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link']
    ]
};

interface BaseInfoBlockProps {
    editForm?: boolean;
}

const BaseInfoBlock: FC<BaseInfoBlockProps> = ({editForm}) => {

    const {
        values,
        setFieldValue,
        errors
    } = useFormikContext<{description: string}>();

    return (
        <Card className="mb-3">
            <Card.Header as="h6" className="bg-body-tertiary">
                Основное
            </Card.Header>
            <Card.Body>
                <Field
                    label='Название'
                    type='text'
                    name='name'
                    placeholder='Название категории'
                    component={FormikRowInput}
                />
                <Field
                    label='Адрес'
                    name='shortName'
                    type='text'
                    description='Этот адрес может быть виден пользователям'
                    placeholder='category-name'
                    component={FormikRowInput}
                />
                <Form.Group as={Row} className="mb-3">
                    <Form.Label
                        column
                        htmlFor='description'
                        sm={3}>Описание</Form.Label>
                    <Col sm={9}>
                        <ReactQuill
                            defaultValue={values.description}
                            onChange={(value) => setFieldValue('description', value)}/>
                        <Form.Control.Feedback>{errors.description}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                {editForm && (
                    <Field
                        label='Активно'
                        id='new-bonus-active'
                        name='isActive'
                        type='switch'
                        component={FormikRowCheckbox}
                    />
                )}
            </Card.Body>
        </Card>
    )
}

export default BaseInfoBlock;
import {FC} from "react";
import {useSupportTopicContext} from "@components/consumer/support/context";
import classNames from "classnames";
import SimpleBarReact from 'simplebar-react';
import {Avatar, Flex} from "@components/falcon/common";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const TopicInfo: FC = () => {
    const {
        topic,
        topicInfo: {
            isThreadInfoOpened,
            setThreadInfoOpened
        }
    } =  useSupportTopicContext();

    return (
        <div className={classNames('conversation-info', { show: isThreadInfoOpened })}>
            <div className="h-100 overflow-auto">
                <SimpleBarReact
                    style={{
                        height: '100%',
                        minWidth: '75px'
                    }}
                >
                    <Flex
                        alignItems="center"
                        className="position-relative p-3 border-bottom hover-actions-trigger"
                    >
                        <Avatar
                            size="xl"
                            fileId={topic?.ownerId}
                            name={topic?.ownerUserName}
                        />
                        <Flex className="ms-2 flex-between-center flex-1">
                            <h6 className="mb-0">
                                <Link
                                    to={`/consumer/users/${topic?.ownerId}`}
                                    className="text-decoration-none stretched-link text-700"
                                >
                                    {topic?.ownerUserName}
                                </Link>
                            </h6>
                            <Button
                                variant="link"
                                size="sm"
                                className="text-400 z-1 p-0"
                                onClick={() => setThreadInfoOpened(false)}
                            >
                                <FontAwesomeIcon
                                    icon="times"
                                    className="fs-8"
                                    transform="shrink-3"
                                />
                            </Button>
                        </Flex>
                    </Flex>
                </SimpleBarReact>
            </div>
        </div>
    )
}

export default TopicInfo;
import {FC, PropsWithChildren, useMemo} from "react";
import { injectContextArray } from "@components/constructor";
import { useChargingLoginServers } from "@hooks/selectors/game.selectors";
import { LoginServer } from "@interfaces/game";
import { LoginServerProvider } from "@constructor/components/loginServer/context";
import {InjectContextType} from "@interfaces/constructor/constructor";

const DonateRateLogins: FC<PropsWithChildren> = ({ children }) => {

    const logins = useChargingLoginServers();

    const data = useMemo<InjectContextType<LoginServer>[]>(() => logins.map(ls => {
        return {
            key: ls.id,
            value: ls
        }
    }), [logins])

    return injectContextArray(data, LoginServerProvider, children);
}

export default DonateRateLogins;
import { EditBonusForm } from "@components/consumer/payments/bonuses";
import withPage from "@components/hoc/withPage";
import { useBreadcrumbs } from "@hooks/panelHooks";
import { usePaymentBonus } from "@hooks/selectors/consumer/payments.selectors";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, Spinner } from "reactstrap";
import {cPaymentsActions} from "@store/consumer/consumerPaymentsStore";
import {toastr} from "react-redux-toastr";
import {EditBonusFormValues} from "@components/consumer/payments/bonuses/editBonusForm";
import {usePromisedDispatch} from "@hooks/helperHooks";

const EditPaymentBonusPage: FC = () => {

    const dispatch = usePromisedDispatch();
    const { bonus_id } = useParams<{ bonus_id: string }>();

    const {
        bonus,
        loading
    } = usePaymentBonus(bonus_id);

    useBreadcrumbs('consumer.payments.bonuses.current.name', true, bonus?.name);
    
    if (!bonus) {
        return (
            <Card>
                <CardBody className="text-center">
                    {loading ? <Spinner className="mr-2" color="primary" /> : (
                        "Бонус не найден"
                    )}
                </CardBody>
            </Card>
        )
    }

    const onSubmit = async (values: EditBonusFormValues) => {

        const result = await dispatch(cPaymentsActions.updateBonus({
            ...values,
            id: bonus.id
        }));

        if (result.success) {
            toastr.success('Бонусы за пополнение', 'Бонус успешно обновлен');
        } else {
            if (result.errorCode === 'BonusNotFound') {
                toastr.error('Бонусы за пополнение', 'Бонус не найден');
            } else {
                toastr.error('Бонусы за пополнение', 'Что-то пошло не так');
            }
        }
    };

    return (
        <EditBonusForm
            title={`Редактирование бонуса "${bonus.name}"`}
            bonus={bonus}
            onSubmit={onSubmit}
        />
    )
}

export default withPage(EditPaymentBonusPage);
import {createContext, FC, PropsWithChildren, useCallback, useContext, useMemo, useState} from "react";
import {CustomQuery, IQueryResult} from "@interfaces/query";
import {queryActions} from "@store/queryStore";
import {toastr} from "react-redux-toastr";
import {usePromisedDispatch} from "@hooks/helperHooks";

interface CurrentQueryInfo {
    query?: CustomQuery;
    executing?: boolean;
    result?: IQueryResult;
}

interface QueriesContextType {
    queries: CustomQuery[];
    current: CurrentQueryInfo;

    executeQuery: (query: CustomQuery, queryParam?: string) => Promise<void>;
    clearCurrent: () => void;
}

const QueriesContext = createContext<QueriesContextType>({} as QueriesContextType);

interface QueriesProviderProps {
    queries: CustomQuery[];
    queryParam?: string;
}

export const QueriesProvider: FC<PropsWithChildren<QueriesProviderProps>> = ({queries, children}) => {

    const dispatch = usePromisedDispatch();
    const [current, setCurrent] = useState<CurrentQueryInfo>({});

    const executeQuery = useCallback(async (query: CustomQuery, queryParam?: string) => {

        setCurrent({
            query,
            executing: true,
            result: undefined
        });

        const result = await dispatch(queryActions.executeQuery(query.id, queryParam));

        if (result.success) {
            setCurrent({
                query,
                executing: false,
                result: result.data
            });
        } else {
            if (result.errorCode === 'QueryNotFound') {
                toastr.error("Ошибка выполнения запроса", "Запрос не найден");
            } else if (result.errorCode === 'AccessDenied') {
                toastr.error("Ошибка выполнения запроса", "Недостаточно прав для выполнения этого запроса");
            } else if (result.errorCode === 'InvalidParameter') {
                toastr.error("Ошибка выполнения запроса", "Неверный параметр запроса");
            } else if (result.errorCode === 'InvalidServer') {
                toastr.error("Ошибка выполнения запроса", "Сервер не найден");
            } else if (result.errorCode === 'AccountNotFound') {
                toastr.error("Ошибка выполнения запроса", "Аккаунт не найден");
            } else if (result.errorCode === 'CharacterNotFound') {
                toastr.error("Ошибка выполнения запроса", "Персонаж не найден");
            } else if (result.errorCode === 'QueryError') {
                toastr.error("Ошибка выполнения запроса", "Ошибка выполнения запроса");
            } else {
                toastr.error("Ошибка выполнения запроса", "Неизвестная ошибка");
            }
        }

        setCurrent(state => ({
            ...state,
            executing: false
        }));
    }, []);

    const clearCurrent = useCallback(() => setCurrent({}), []);

    const value = useMemo<QueriesContextType>(() => ({
        queries,
        current,
        executeQuery,
        clearCurrent
    }), [queries, current, executeQuery, clearCurrent]);

    return (
        <QueriesContext.Provider value={value}>
            {children}
        </QueriesContext.Provider>
    )
}

export const useQueriesContext = () => useContext(QueriesContext);
import { FC } from "react";
import { useQueryResultContext } from "./context";
import { Text } from '@components/constructor/components';

const QueryMessage: FC = (props) => {

    const result = useQueryResultContext();
    
    if(!result?.message) {
        return null;
    }

    return (
        <Text text={result.message} {...props} />
    )
}

export default QueryMessage;
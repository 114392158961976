import {FC, PropsWithChildren, useCallback, useRef, useState} from 'react';
import * as Yup from 'yup';
import { FormikHelpers, FormikValues, Formik, Form } from 'formik';
import { toastr } from 'react-redux-toastr';
import ReCAPTCHA from 'react-google-recaptcha';
import {useLocalizedBlock, usePanel} from '@services/hooks';
import { injectChild } from '@components/constructor';
import { auth } from '@services/auth';
import { fromUtcDate } from '@services/helpers';
import {useThemeGoal} from "@hooks/panelHooks";
import ym from "react-yandex-metrika";

interface SingInFormValues extends FormikValues {
    username: string;
    password: string;
}

interface SignInFormProps {
    formProps?: any;
}

const initialValues: SingInFormValues = {
    username: '',
    password: '',
}

const SignInForm: FC<PropsWithChildren<SignInFormProps>> = ({ children, ...props }) => {

    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [recaptcha, setRecaptcha] = useState<string | null>(null);

    const locale = useLocalizedBlock('guest.auth');

    const usernameErrors = useLocalizedBlock('yup.message.errors', {
        min: 5,
        max: 32
    });

    const passwordErrors = useLocalizedBlock('yup.message.errors', {
        min: 6,
        max: 64
    });

    const { ymId } = usePanel();
    const signInGoal = useThemeGoal('signIn');

    const validation = Yup.object().shape({
        username: Yup.string()
            .min(5, usernameErrors['notless'])
            .max(32, usernameErrors['notmore'])
            .required(usernameErrors['required']),
        password: Yup.string()
            .min(6, passwordErrors['notless'])
            .max(64, passwordErrors['notmore'])
            .required(passwordErrors['required'])
    });

    const onSubmit = useCallback(async (values: SingInFormValues, actions: FormikHelpers<SingInFormValues>) => {

        if (!recaptcha) {
            actions.setFieldError("username", locale['errors.RequiredRecaptcha']);
            return;
        }
        const token = await auth().signIn({ username: values.username, password: values.password, recaptcha: recaptcha });
        if(token.ok && ymId && signInGoal) {
            ym('reachGoal', signInGoal);
        }

        if (!token.ok && token.errorCode) {
            if (token.errorCode === 'invalid_grant')
                actions.setFieldError("username", locale['errors.invalid_grant']);
            else if (token.errorCode === "unknown_project")
                actions.setFieldError("username", locale['errors.unknown_project']);
            else if (token.errorCode === 'EmailUnconfirmed') {
                toastr.error(locale['header'], locale['errors.EmailUnconfirmed']);
            }
            else if (token.errorCode === 'InvalidRecaptcha') {
                toastr.error(locale['header'], locale['errors.recaptcha']);
            }
            else if (token.errorCode === 'LockedOut') {
                if(token.message) {
                    toastr.error(locale['header'], `Аккаунт заблокирован до ${fromUtcDate(token.message).format('lll')}`);
                }
                else {
                    toastr.error(locale['header'], "Аккаунт заблокирован");
                }

            }
            else {
                toastr.error(locale['header'], locale['errors.InternalError']);
            }
            recaptchaRef.current?.reset();
        }
    }, [locale, recaptcha, signInGoal, ymId]);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validation}>
            <Form {...props}>
                {
                    injectChild({
                        recaptcha: {
                            ref: recaptchaRef,
                            onChange: setRecaptcha
                        }
                    }, children)
                }
            </Form>
        </Formik>
    )
}

export default SignInForm;
import { useReCAPTCHAKey } from "@hooks/selectors/panel.selectors";
import { FC, RefObject } from "react";
import ReCAPTCHA from "react-google-recaptcha";

interface FormReCAPTCHAProps {
    recaptcha: ReCAPTCHAProps;
}

export interface ReCAPTCHAProps {
    ref: RefObject<ReCAPTCHA>;
    onChange: (value: string | null) => void;
}

const FormReCAPTCHA: FC<FormReCAPTCHAProps> = ({ recaptcha, ...props }) => {

    const key = useReCAPTCHAKey();

    return (
        <ReCAPTCHA
            ref={recaptcha.ref as any}
            sitekey={key}
            onChange={recaptcha.onChange}
            {...props}
        />
    )
}

export default FormReCAPTCHA;
import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";
import AuthLogDate from "./authLogDate";
import AuthLogIP from "./authLogIp";
import AuthLogs from "./authLogs";
import AuthLogsList from "./authLogsList";

const AuthLogDateInfo: ConstructionComponentInfo = {
    component: AuthLogDate,
    requiredParent: [
        'AuthLogsList'
    ],
    props: [
        'format'
    ]
}

const AuthLogIPInfo: ConstructionComponentInfo = {
    component: AuthLogIP,
    requiredParent: [
        'AuthLogsList'
    ]
}

const AuthLogsInfo: ConstructionComponentInfo = {
    component: AuthLogs,
    props: [
        'auth_logs_per_page'
    ],
    addedChildProps: [
        'pagination_current_page',
        'pagination_total_pages',
        'pagination_per_page',
        'pagination_set_page',
        'pagination_next_page',
        'pagination_prev_page',
        'auth_logs_count',
        'auth_logs_loading'
    ]
}

const AuthLogsListInfo: ConstructionComponentInfo = {
    component: AuthLogsList,
    addedChildProps: [
        'auth_log_index'
    ],
    props: [
        'pagination_per_page'
    ],
    requiredParent: [
        'AuthLogs'
    ],
    injectChild: [
        'pagination_current_page',
        'pagination_per_page'
    ]
}

export {
    AuthLogDateInfo,
    AuthLogIPInfo,
    AuthLogsInfo,
    AuthLogsListInfo
}
import ConsumerBonusService from "@api/consumer/consumerBonusService";
import { ConsumerBonusCodeInfo } from "@components/consumer/bonuses";
import withPage from "@components/hoc/withPage";
import { useBreadcrumbs } from "@hooks/panelHooks";
import { ConsumerBonusCodeFull } from "@interfaces/consumer/bonusCodes";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, CardText, CardTitle, Spinner } from "reactstrap";

const BonusCodePage: FC = () => {

    const { code_id } = useParams<{ code_id: string }>();
    const [bonus, setBonus] = useState<ConsumerBonusCodeFull>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const load = async () => {
            setLoading(true);
            const api = new ConsumerBonusService();
            const result = await api.getBonusCode(code_id);

            if (result.success) {
                setBonus(result.data);
            }
            setLoading(false);
        }

        load();
    }, [code_id]);

    useBreadcrumbs('consumer.bonus.current.name', true, bonus?.code);

    if(!bonus) {
        return (
            <Card>
                <CardHeader>
                    <CardTitle>
                        Редактирование бонусного кода
                    </CardTitle>
                </CardHeader>
                <CardBody className="text-center">
                    {
                        loading ? <Spinner className="mr-2" color="primary" /> : (
                            <CardText>
                                "Подарок не найден"
                            </CardText>
                        )
                    }
                </CardBody>
            </Card>
        )
    }
    
    return (
        <ConsumerBonusCodeInfo  code={bonus} />
    )
}

export default withPage(BonusCodePage);
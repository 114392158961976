import { usePromisedDispatch } from "@hooks/helperHooks";
import { PatchGameAccountModel } from "@models/user/data";
import { userActions } from "@store/user";
import { Form, Formik, FormikHelpers } from "formik";
import {FC, PropsWithChildren, useCallback, useMemo} from "react";
import { BasicToastrOptions, toastr } from "react-redux-toastr";
import { AccountIdProps } from ".";
import { ModalProps } from "../../modals";
import { useAccountContext } from "./context";
import * as Yup from 'yup';

interface ChangeAccountPasswordFormValues {
    accountId: string;
    newPassword: string;
    repeatPassword: string;
}

const ChangeAccountPasswordForm: FC<PropsWithChildren<AccountIdProps & ModalProps>> = ({ account_id, children, modalToggle, ...props }) => {

    const context = useAccountContext();
    const dispatch = usePromisedDispatch();

    const validate = useMemo(() => Yup.object().shape({
        accountId: Yup.string()
            .required("Выберите аккаунт"),
        newPassword: Yup.string()
            .min(6, "Не менее 6 символов")
            .max(16, "Не более 16 символов")
            .required("Обязательно"),
        repeatPassword: Yup.string()
            .min(6, "Не менее 6 символов")
            .max(16, "Не более 16 символов")
            .oneOf([Yup.ref("newPassword")], "Пароли не совпадают")
            .required("Обязательно"),
    }), []);

    const onSubmit = useCallback(async (values: ChangeAccountPasswordFormValues, actions: FormikHelpers<ChangeAccountPasswordFormValues>) => {
        const account: PatchGameAccountModel = {
            id: values.accountId,
            password: values.newPassword
        };

        const result = await dispatch(userActions.patchAccount(account));

        if (result.ok) {
            const options: BasicToastrOptions = {
                timeOut: 10000,
                showCloseButton: true,
                progressBar: true,

            }
            toastr.success("Изменение пароля", "Пароль игрового аккаунта успешно изменен", options);
            if (modalToggle) {
                modalToggle();
            }
        }
        else {
            actions.setFieldError('newPassword', 'Ошибка изменения пароля. Обратитесь к администратору');
        }
    }, [dispatch, modalToggle]);

    if (!context && !account_id) {
        console.error('account context and id is null');
        return null;
    }

    return (
        <Formik
            validationSchema={validate}
            onSubmit={onSubmit}
            initialValues={{
                newPassword: '',
                repeatPassword: '',
                accountId: context?.id || account_id || ''
            } as ChangeAccountPasswordFormValues}>
            <Form {...props}>
                {children}
            </Form>
        </Formik>
    )
}

export default ChangeAccountPasswordForm;
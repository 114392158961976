import {FC, memo} from "react";
import {useItem} from "@hooks/selectors/user/item.selectors";
import {Link} from "react-router-dom";
import {Placeholder} from "react-bootstrap";
import {randomInteger} from "@services/helpers";

interface ItemNameProps {
    itemId: string;
}
const ItemName: FC<ItemNameProps> = ({itemId}) => {
    const {
        name,
        loading
    } = useItem(itemId);

    if(loading) {
        return (
            <Placeholder as="div" animation="glow">
                <Placeholder xs={randomInteger(6, 9)}/>
            </Placeholder>
        )
    }

    return (
        <Link to={`/consumer/item/${itemId}/`}>{name}</Link>
    )
}

export default memo(ItemName);
import { injectContextArray } from "@components/constructor";
import { useTaskMessages, useTopicAuto } from "@hooks/selectors/user/support.selectors";
import { IUserTaskMessage } from "@interfaces/user/support";
import { sortByDate } from "@services/helpers";
import {FC, PropsWithChildren, useCallback, useMemo} from "react";
import { SupportMessageProvider, useSupportMessageGroupContext } from "../context";
import {userActions} from "@store/user";
import {usePromisedDispatch} from "@hooks/helperHooks";
import {toastr} from "react-redux-toastr";
import {InjectContextType} from "@interfaces/constructor/constructor";

interface SupportMessagesListChildProps {
    message_id: string;
    is_incoming_message: boolean;
    is_first_message: boolean;
    delete_message: () => void;
    message_has_attachments: boolean;
    message_has_text: boolean;
}

interface SupportMessagesListProps {
    sort_order?: 'desc' | 'asc';
}

const SupportMessagesList: FC<PropsWithChildren<SupportMessagesListProps>> = ({ children, sort_order = 'asc' }) => {

    const dispatch = usePromisedDispatch();
    const topic = useTopicAuto();
    const group = useSupportMessageGroupContext();

    const messages = useTaskMessages(topic?.id || '');
    const deleteMessage = useCallback(async (messageId: string) => {
        if(!topic?.id) {
            return;
        }

        const result = await dispatch(userActions.deleteTaskMessage(topic.id, messageId));
        if(result.ok) {
            dispatch(userActions.loadTask(topic.id));
        }
        else {
            if(result.errorCode === 'TopicNotFound') {
                toastr.error("Поддержка", "Заявка не найдена");
                dispatch(userActions.loadTask(topic.id));
            }
            else if(result.errorCode === 'ClosedTopic') {
                toastr.error("Поддержка", "Нельзя удалить сообщение в закрытой заявке");
                dispatch(userActions.loadTask(topic.id));
            }
            else if(result.errorCode === 'MessageNotFound') {
                toastr.error("Поддержка", "Сообщение не найдено");
                dispatch(userActions.loadTask(topic.id));
            }
            else if(result.errorCode === 'NotOwner') {
                toastr.error("Поддержка", "Вы не можете удалить это сообщение");
                dispatch(userActions.loadTask(topic.id));
            }
            else if(result.errorCode === 'TooLate') {
                toastr.error("Поддержка", "Нельзя удалять сообщения старше 5 минут");
            }
            else if(result.errorCode === 'LastMessage') {
                toastr.error("Поддержка", "Нельзя удалить последнее сообщение");
            }
            else {
                toastr.error("Поддержка", "Что-то пошло не так");
            }
        }
    }, [dispatch, topic?.id]);

    const msg = group?.messages || messages;    

    const data = useMemo<InjectContextType<IUserTaskMessage, SupportMessagesListChildProps>[]>(() => {
        const sortedMessages = [...msg].sort(sortByDate(m => m.createTime, sort_order));
        return sortedMessages.map((message, idx) => {

            const first_message = idx === 0 || sortedMessages[idx - 1].senderName !== message.senderName;
            const delete_message = !message.incoming ? () => deleteMessage(message.id) : () => {};
    
            return {
                key: message.id,
                value: message,
                childData: {
                    message_id: message.id,
                    is_incoming_message: message.incoming,
                    is_first_message: first_message,
                    message_has_attachments: message.attachments.length > 0,
                    message_has_text: message.message.length > 0,
                    delete_message
                }
            }
        })
    }, [deleteMessage, msg, sort_order]);

    if (msg.length === 0) {
        return null;
    }

    return injectContextArray(data, SupportMessageProvider, children);
}

export default SupportMessagesList;
import ConsumerRefService from "@api/consumer/consumerRefService";
import { IGoal } from "@interfaces/consumer/refs";
import { FC, useCallback } from "react";
import { toastr } from "react-redux-toastr";
import { CardBody } from "reactstrap";

import GoalForm, { ConditionType, GoalFormValues } from "./goalForm";

interface EditGoalFormProps {
    goal: IGoal;
}

const EditGoalForm: FC<EditGoalFormProps> = ({ goal }) => {

    const initialValues: GoalFormValues = {
        name: goal.name,
        description: goal.description || '',
        isActive: goal.isActive,
        order: goal.order,
        conditionType: goal.allConditions ? ConditionType.All : ConditionType.One,
        hidden: goal.hidden
    }

    const onSubmit = useCallback(async (values: GoalFormValues) => {
        const api = new ConsumerRefService();
        const result = await api.patchGoal({
            id: goal.id,
            name: values.name,
            description: values.description,
            isActive: values.isActive,
            order: values.order,
            allConditions: values.conditionType === ConditionType.All,
            hidden: values.hidden
        });

        if (result.success) {
            toastr.success("Изменение достижения", "Достижение успешно обновлено");
        }
        else {
            if (result.errorCode === 'GoalNotFound') {
                toastr.error('Изменение достижения', 'Достижение не найдено');
            }
            else {
                toastr.error('Изменение достижения', 'Неизвестная ошибка');
            }
        }
    }, [goal.id]);

    return (
        <CardBody>
            <GoalForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                editForm />
        </CardBody>
    )
}

export default EditGoalForm;
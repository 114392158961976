import RefService from "@api/user/refService";
import { IRefCode, IRefStat } from "@interfaces/user/refs";
import { RefStore } from "@interfaces/user/userStore";
import FetchResult from "@models/fetchResult";
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

const slice = createSlice({
    name: 'userRefStore',
    initialState: {
        code: {
            loaded: false,
            loading: false,
            error: null,
            data: null
        },
        stats: {
            loaded: false,
            loading: false,
            error: null,
            data: []
        }
    } as RefStore,
    reducers: {
        setCode: (state, { payload: code }: PayloadAction<IRefCode>) => {
            state.code = {
                loaded: true,
                loading: false,
                error: null,
                data: code
            }
        },
        setCodeLoading: (state) => {
            state.code = {
                ...state.code,
                loading: true,
                error: null
            }
        },
        setCodeError: (state, action) => {
            state.code = {
                ...state.code,
                loaded: false,
                loading: false,
                error: action.payload,
                data: null
            }
        },

        setStats: (state, action: PayloadAction<IRefStat[]>) => {
            state.stats = {
                loaded: true,
                loading: false,
                error: null,
                data: action.payload
            }
        },
        setStatsLoading: (state) => {
            state.stats = {
                ...state.stats,
                loading: true,
                error: null
            }
        },
        setStatsError: (state, action) => {
            state.stats = {
                loaded: false,
                loading: false,
                error: action.payload,
                data: []
            }
        }
    }
});

const {
    setCode,
    setCodeError,
    setCodeLoading,

    setStats,
    setStatsError,
    setStatsLoading
} = slice.actions;

export const { reducer: refReducer } = slice;

export const refActions = {
    loadCode: (silent: boolean = false) => async (dispatch: Dispatch) => {
        try {

            if (!silent) {
                dispatch(setCodeLoading());
            }

            const api = new RefService();
            const result = await api.getRefCode();

            if (result.success) {
                dispatch(setCode(result.data));
            }
            else {
                dispatch(setCodeError(result));
            }

            return result;

        } catch (error) {
            dispatch(setCodeError(error));
            return error as FetchResult<IRefCode>;
        }
    },
    createCode: () => async (dispatch: Dispatch) => {
        try {
            const api = new RefService();
            const result = await api.createRefCode();

            if (result.success) {
                dispatch(setCode(result.data));
            }
            else {
                dispatch(setCodeError(result));
            }

            return result;
        } catch (error) {
            dispatch(setCodeError(error));
            return error as FetchResult<IRefCode>;
        }
    },

    loadStats: (silent: boolean = false) => async (dispatch: Dispatch) => {
        try {
            if(!silent) {
                dispatch(setStatsLoading());
            }

            const api = new RefService();
            const result = await api.getRefStats();

            if(result.success) {
                dispatch(setStats(result.data));
            }
            else {
                dispatch(setStatsError(result));
            }

            return result;
        } catch (error) {
            dispatch(setCodeError(error));
            return error as FetchResult<IRefStat[]>;
        }
    }
}
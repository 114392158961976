import ConsumerRefService from "@api/consumer/consumerRefService";
import { FC, useCallback, useEffect, useState } from "react";
import AsyncSelect from "react-select/async";

interface GoalInputProps {
    name: string;
    value: any;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const GoalInput: FC<GoalInputProps> = ({ name, value, setFieldValue }) => {

    const [selectValue, setValue] = useState<any>({
        value
    });

    const searchItems = useCallback(async (text: string) => {
        const api = new ConsumerRefService();

        const result = await api.searchGoal(text);

        if (result.success) {
            return result.data.map(goal => {
                return {
                    value: goal.id,
                    label: goal.name
                }
            })
        }
        return [];
    }, []);

    useEffect(() => {
        const load = async () => {
            const api = new ConsumerRefService();
            const result = await api.getGoal(value);

            if (result.success) {
                setValue({
                    value: result.data.id,
                    label: result.data.name
                })
            }
        }

        if (value) {
            load();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AsyncSelect
            name={name}
            className="react-select-container"
            classNamePrefix="react-select"
            cacheOptions
            defaultOptions
            loadOptions={searchItems}
            onChange={(option) => {
                setFieldValue(name, option?.value);
                setValue(option);
            }}
            value={selectValue}
        />
    )
}

export default GoalInput;
import React, {FC, useCallback, useMemo, useState} from 'react';
import {Card, CardBody, Col, Row, Spinner} from 'react-bootstrap';
import {Link, useParams} from 'react-router-dom';
import withPage from '../../hoc/withPage';
import {dateFormatter} from '@services/helpers';
import {ConsumerPaymentFull, PaymentAgregator, PaymentState} from '@interfaces/consumer/payments';
import {useBreadcrumbs, useStateLoader} from '../../../services/hooks/panelHooks';
import {PageHeader} from "@components/falcon/common";
import {PaymentLogs, PaymentStateBadge} from "@components/consumer/payments/index";
import CPaymentsService from "@api/consumer/consumerPaymentsService";
import {usePaymentCurrency} from "@hooks/selectors/panel.selectors";

const stateNames = [
    'Создан',
    'Завершен',
    'Ошибка'
]

const agreagatorNames = {
    [PaymentAgregator.Prime]: 'Prime',
    [PaymentAgregator.Unitpay]: 'Unitpay',
    [PaymentAgregator.Enot]: 'Enot',
    [PaymentAgregator.PayPalych]: 'PayPalych',
    [PaymentAgregator.Antilopay]: 'Antilopay',
    [PaymentAgregator.StreamPay]: 'StreamPay'
}

const ConsumerPayment: FC = () => {
    const { payment_id } = useParams<{ payment_id: string }>();
    const loadPayment = useCallback((payment_id: string) => {
        const api = new CPaymentsService();
        return api.fetchConsumerPayment(payment_id);
    }, []);

    const currency = usePaymentCurrency();
    const { data: payment, loading } = useStateLoader<ConsumerPaymentFull>(loadPayment, payment_id);
    useBreadcrumbs('consumer.payments.current.name', true, payment && `Платеж № ${payment.id}`);

    if (!payment) {
        return (
            <Card>
                <CardBody className="text-center">
                    {loading ? <Spinner className="mr-2" color="primary" /> : (
                        "Платеж не найден"
                    )}
                </CardBody>
            </Card>
        )
    }

    return (
        <Row className="g-3 mb-3">
            <Col xxl={9}>
                <PageHeader title={`Детали заказа № ${payment.id}`} titleTag="h5" className="mb-3">
                    <p className="fs-10 mt-1">{dateFormatter(payment.createTime)}</p>
                    <div>
                        <strong className="me-2">Статус: </strong>
                        <PaymentStateBadge state={payment.state} className="fs-11" />
                    </div>
                    {payment.state === PaymentState.Finished && (
                        <p className="fs-10 mt-1">Завершен {dateFormatter(payment.finishTime!)}</p>
                    )}
                </PageHeader>
                <Card className="mb-3">
                    <Card.Body>
                        <Row>
                            <Col lg xxl={5}>
                                <h6 className="fw-semibold ls mb-3 text-uppercase">
                                    Информация о заказе
                                </h6>
                                <Row>
                                    <Col xs={5} sm={4}>
                                        <p className="fw-semibold mb-1">Сумма</p>
                                    </Col>
                                    <Col>{payment.sum} {payment.currency}</Col>
                                </Row>
                                {(payment.sum !== payment.paidSum || payment.paidSum !== payment.normalizedPaidSum) && (
                                    <Row>
                                        <Col xs={5} sm={4}>
                                            <p className="fw-semibold mb-1">К оплате</p>
                                        </Col>
                                        <Col>
                                            {payment.paidSum} {payment.currency}{' '}
                                            {payment.paidSum !== payment.normalizedPaidSum && `(${payment.normalizedPaidSum} ${currency})`}
                                        </Col>
                                    </Row>
                                )}
                                {payment.state === PaymentState.Finished && (
                                    <>
                                        <Row>
                                            <Col xs={5} sm={4}>
                                                <p className="fw-semibold mb-1">Прибыль</p>
                                            </Col>
                                            <Col>
                                                {payment.profit} {payment.currency}{' '}
                                                {payment.normalizedProfit !== payment.profit && `(${payment.normalizedProfit} ${currency})`}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={5} sm={4}>
                                                <p className="fw-semibold mb-1">Начислено</p>
                                            </Col>
                                            <Col>{payment.replenishmentSum} {currency}</Col>
                                        </Row>
                                    </>
                                )}
                                <Row>
                                    <Col xs={5} sm={4}>
                                        <p className="fw-semibold mb-1">Cистема</p>
                                    </Col>
                                    <Col>{agreagatorNames[payment.aggregator] ?? 'Неизвестно'}</Col>
                                </Row>
                                <Row>
                                    <Col xs={5} sm={4}>
                                        <p className="fw-semibold mb-1">Пользователь</p>
                                    </Col>
                                    <Col><Link to={`/consumer/users/${payment.userId}/`}>{payment.userName}</Link></Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col xxl={3}>
                <PaymentLogs userId={payment.userId} />
            </Col>
        </Row>
    )
}

export default withPage(ConsumerPayment);
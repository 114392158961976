import {FC, PropsWithChildren, useMemo} from "react";
import { PaginationProps } from "@constructor/components/core/pagination";
import { useAuthLogs } from "@hooks/userHooks";
import { UserAuthLog } from "@interfaces/user/users";
import { injectContextArray } from "@components/constructor";
import { AuthLogProvider } from "./context";
import {InjectContextType} from "@interfaces/constructor/constructor";

interface AuthLogsListChildProps {
    auth_log_index: number;
}

const AuthLogsList: FC<PropsWithChildren<PaginationProps>> = ({ pagination_current_page = 0, pagination_per_page = 10, children }) => {

    const { data: logs } = useAuthLogs();
    const page = logs.slice(pagination_current_page * pagination_per_page, (pagination_current_page + 1) * pagination_per_page);

    const data = useMemo<InjectContextType<UserAuthLog, AuthLogsListChildProps>[]>(() => page.map((log, idx) => {
        return {
            key: log.id,
            value: log,
            childData: {
                auth_log_index: pagination_current_page * pagination_per_page + 1 + idx
            }
        }
    }), [page, pagination_current_page, pagination_per_page]);

    return injectContextArray(data, AuthLogProvider, children);
}

export default AuthLogsList;
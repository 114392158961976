import { useSelector, shallowEqual } from "react-redux";
import { ReduxStore } from "../../interfaces/store";
import {useDataLoading} from './loadingHooks';
import { userActions } from '../../redux/user';

export function useRole(role: string): boolean {
    return useSelector(({ user }: ReduxStore) => user.user.data.roles.includes(role));
}

export function useAllRoles(): string[] {
    return useSelector(({ user }: ReduxStore) => user.user.data.roles);
}

export function useUsedCodes() {

    const data = useSelector(({ user }: ReduxStore) => user.bonusCodes.usedCodes, shallowEqual);
    return useDataLoading(userActions.loadUsedBonusCodes, data);
}

export function usePersonalCodes() {

    const data = useSelector(({ user }: ReduxStore) => user.bonusCodes.personalCodes, shallowEqual);
    return useDataLoading(userActions.loadPersonalBonusCodes, data);
}

export function useUserInfo() {
    return useSelector((state: ReduxStore) => state.user.user.data);
}

export function useAuthLogs() {
    return useSelector((state: ReduxStore) => state.user.logs.auth, shallowEqual);
    //return useDataLoading(userActions.loadAuthLogs, data);
}
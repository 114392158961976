import {FC, useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {useQueriesContext} from "./context";
import {useLocalizedBlock} from "@services/hooks";
import QueryExecuteResult from "@components/consumer/queries/queryExecuteResult";
import {Flex} from "@components/falcon/common";

const QueryExecuteResultModal: FC = () => {

    const {
        clearCurrent,
        current: {
            query,
            result
        }
    } = useQueriesContext();

    const [show, setShow] = useState(false);
    const queryName = useLocalizedBlock(`queries.${query?.name.toLowerCase()}`);

    useEffect(() => {
        setShow(!!result);
    }, [result]);

    return (
        <Modal
            show={show}
            size="xl"
            centered
            onHide={() => setShow(false)}
            onExited={clearCurrent}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {queryName['name'] ?? query?.displayName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Flex justifyContent="center">
                    {(query && result) && (
                        <QueryExecuteResult query={query} result={result} />
                    )}
                </Flex>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="submit"
                    color="primary"
                    onClick={() => setShow(false)}
                >
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default QueryExecuteResultModal;
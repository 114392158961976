import {FC, Fragment, PropsWithChildren, useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { Card, CardHeader, CardTitle, CardBody, CardText } from 'reactstrap';
import GuestLayout from '../../layouts/Auth';
import { Helmet } from 'react-helmet';
import { usePanel, useUser, useAuth } from '@services/hooks';
import { ReduxStore } from '@interfaces/store';
import { loadLanguages } from '@services/localization';
import TagManager from 'react-gtm-module';
import { auth } from '@services/auth';
import { useSignalr } from '@services/hooks/signalr';
import UserService from '@api/userService';
import { itemActions } from '@store/user/userItemsStore';
import { userActions } from '@store/user';
import { sidebarActions } from '@store/sidebarStore';
import { queryActions } from '@store/queryStore';
import { panelActions } from '@store/panelStore';
import { usePromisedDispatch } from '@hooks/helperHooks';

const PanelLoader: FC<PropsWithChildren> = ({ children }) => {

    const { panelLoading, panelLoaded, panelError, panelActive, panelTitle, gtmId } = usePanel();
    const { userDataLoading } = useUser();
    const { isAuthed, tokenLoaded, user } = useAuth();
    const dispatch = usePromisedDispatch();

    const avLangs = useSelector(({ panel }: ReduxStore) => panel.settings.data.languages);
    const curLangs = useSelector(({ panel }: ReduxStore) => panel.languages);
    const [loaded, setLoaded] = useState(false);
    const [requested, setRequested] = useState(false);
    const fetched = useSelector(({ panel }: ReduxStore) => /*panel.settings.loaded && */panel.languages[panel.settings.data.defaultLang]?.loaded || false);

    const [userLoading, setUserLoading] = useState(false);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        return auth().onAuthStateChange(user => {
            dispatch(userActions.setUser(user));
        });
    }, [dispatch]);

    useEffect(() => {

        const loadData = async () => {
            setUserLoading(true);

            try {
                const api = new UserService();
                const result = await api.loadData();

                if (!result.success) {
                    setError(result);
                    return;
                }

                const data = result.data;

                if (data.Info) {
                    dispatch(userActions.data.setUserData(data.Info));
                }
                if (data.Project) {
                    dispatch(userActions.project.setProject(data.Project));
                }
                if (data.Accounts) {
                    dispatch(userActions.accounts.setAccounts(data.Accounts));
                }
                if (data.Characters) {
                    dispatch(userActions.characters.setCharacters(data.Characters));
                }
                if (data.Inventory) {
                    dispatch(userActions.inventory.setItems(data.Inventory));
                }
                if (data.Notifications) {
                    dispatch(userActions.notifications.setNotifications(data.Notifications));
                }
                if (data.DonateBonuses) {
                    dispatch(userActions.donateBonus.setBonuses(data.DonateBonuses));
                }
                if (data.Queries) {
                    dispatch(queryActions.setQueries(data.Queries));
                }
                if (data.DailyGifts) {
                    dispatch(userActions.daily.setGifts(data.DailyGifts));
                }
                if (data.DailyStats) {
                    dispatch(userActions.daily.setStats(data.DailyStats));
                }
                if (data.Sidebar) {
                    dispatch(sidebarActions.setLinks(data.Sidebar));
                }
                if (data.Items) {
                    dispatch(itemActions.setItems(data.Items));
                    dispatch(panelActions.loadFiles(data.Items.filter(i => i.iconId).map(i => i.iconId as string)));
                }
                if(data.PaymentSettings) {
                    dispatch(panelActions.setPaymentSettings(data.PaymentSettings));
                }
                dispatch(panelActions.loadVariables());

            } catch (error) {
                setError(error);
            }

            setUserLoading(false);
        }

        if (tokenLoaded && isAuthed) {
            loadData();
        }
    }, [dispatch, isAuthed, tokenLoaded, user?.id]);

    useEffect(() => {
        const load = async () => {
            dispatch(panelActions.loadTheme((window as any).DefaultTheme));
            const info = await dispatch(panelActions.loadPanelSettings());
            if(info?.Queries) {
                dispatch(queryActions.concatQueries(info.Queries));
            }     
        }

        load();
    }, [dispatch]);

    useEffect(() => { //Загружаем сохраненные в стораж данные
        if (!loaded) {
            const langs = loadLanguages();
            if (langs != null) {
                dispatch(panelActions.setLanguages(langs));
            }
            setLoaded(true);
        }
    }, [loaded, dispatch]);

    useEffect(() => {
        if (!requested && loaded && !panelError && !panelLoading && panelLoaded) { //Когда загрузилась панель и достали данные из стоража
            //console.log(`av langs: ${avLangs}`);
            avLangs.forEach((lang) => {
                if (!curLangs[lang.code] || curLangs[lang.code].data?.hashCode !== lang.hash) {
                    dispatch(panelActions.loadLanguage(lang.code));
                }
            });
            setRequested(true);

            if (gtmId) {
                TagManager.initialize({
                    gtmId
                });
            }
        }
    }, [avLangs, curLangs, dispatch, loaded, panelError, panelLoaded, panelLoading, requested, gtmId]);

    const loadScreen = !fetched || !requested || userDataLoading || !tokenLoaded || panelLoading || userLoading;
    const loadError = !!panelError || !!error;

    if(loadError)
    {
        console.log(panelError);
        console.log(error);
    }

    useSignalr();
    const loaderActive = loadScreen && !loadError;

    useEffect(() => {
        const loader = document.getElementById("loadScreen");
        const active = loaderActive;

        if(active) {
            loader?.classList.add("active");
        }
        else {
            loader?.classList.remove("active");
        }
    }, [loaderActive]);

    return (
        <Fragment>
            <Helmet
                defaultTitle={panelTitle}
                titleTemplate={`${panelTitle} - %s`}
            />
            {/* <div className={classNames('splash', { 'active': loadScreen && !loadError })}>
                <div className="splash-icon">
                    {
                        kostyl && (
                            <>
                                <img src='/themes/immortals/images/logo.png' alt='logo' />
                                <div className='lds-ellipsis'>
                                    <div /><div /><div /><div />
                                </div>
                            </>
                        )
                    }
                </div>
            </div> */}
            {
                loadError ?
                    <ErrorLayout
                        title="Ошибка загрузки"
                        body="Не удалось загрузить данные личного кабинета. Попробуйте позднее или обратитесь к администрации" /> :

                    loadScreen || (
                        panelLoaded && (
                            panelActive ? children :
                                <ErrorLayout
                                    title="Личный кабинет отключен"
                                    body="К сожалению, в данный момент администрация ограничила доступ к личному кабинету. Попробуйте позднее." />
                        )

                    )}
        </Fragment>
    )
}

interface ErrorLayoutProps {
    title: string;
    body: string;
}
const ErrorLayout: FC<ErrorLayoutProps> = ({ title, body }) => (
    <GuestLayout>
        <Card className="text-center">
            <CardHeader>
                <CardTitle tag="h5">
                    {title}
                </CardTitle>
                <CardBody>
                    <CardText>
                        {body}
                    </CardText>
                </CardBody>
            </CardHeader>
        </Card>
    </GuestLayout>
)

export default PanelLoader;
import IService from "@api/iService";
import { IUserTask, IUserTaskMessage } from "@interfaces/user/support";
import {PatchUserTaskMessageModel, UserTaskMessageModel, UserTaskModel} from "@models/user/support";

export default class SupportService extends IService {
    async fetchUserTasks() {
        return await this.getAndThrowApi<IUserTask[]>('/user/support/');
    }

    async getTopics() {
        return await this.getApi<IUserTask[]>('/user/support/');
    }

    async fetchUserTask(task: string) {
        return await this.getAndThrowApi<IUserTask>(`/user/support/${task}/`);
    }

    async getTopic(task: string) {
        return await this.getApi<IUserTask>(`/user/support/${task}/`);
    }

    async fetchUserTaskMessages(taskId: string) {
        return await this.getAndThrowApi<IUserTaskMessage[]>(`/user/support/${taskId}/messages/`);
    }

    async getTopicMessages(taskId: string) {
        return await this.getApi<IUserTaskMessage[]>(`/user/support/${taskId}/messages/`);
    }

    async putUserTask(task: UserTaskModel) {
        return await this.putApi<IUserTask>('/user/support/', task);
    }

    async putUserTaskMessage(message: UserTaskMessageModel) {
        return await this.putApi<IUserTaskMessage>(`/user/support/${message.task}/messages/`, message);
    }

    async patchUserTaskMessage(taskId: string, messageId: string, message: PatchUserTaskMessageModel) {
        return await this.patchApi<IUserTaskMessage>(`/user/support/${taskId}/messages/${messageId}/`, message);
    }

    async deleteUserTaskMessage(taskId: string, messageId: string) {
        return await this.deleteApi(`/user/support/${taskId}/messages/${messageId}/`);
    }
}
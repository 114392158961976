import React, {FC} from "react";
import {Button, Card, Col, Form, Table} from "react-bootstrap";
import {Field, FieldArray, useFormikContext} from "formik";
import SimpleBarReact from "simplebar-react";
import {FormikInput} from "@components/panel/formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconButton} from "@components/falcon/common";
import ReactDatePicker from "react-datepicker";
import {EditItemFormValues} from "../.";

const LimitsBlock: FC = () => {

    const {
        values: {limits},
        setFieldValue
    } = useFormikContext<EditItemFormValues>();

    return (
        <Card className="mb-3">
            <Card.Header as="h6" className="bg-body-tertiary">
                Ограничения
            </Card.Header>
            <Card.Body className="bg-body-tertiary">
                <FieldArray name="limits" render={arrayHelpers => (
                    <>
                        <Table
                            className="mb-2 border-200 mt-3 bg-body-emphasis table-responsive"
                            bordered
                        >
                            <thead>
                            <tr className="fs-10">
                                <th scope="col">Ограничение</th>
                                <th scope="col">Дата начала</th>
                                <th scope="col">Дата окончания</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody className="event-ticket-body">
                            {limits && limits.length > 0 ? limits.map((limit, index) => (
                                <tr key={limit.id || index}>
                                    <td>
                                        <Field
                                            name={`limits.${index}.limit`}
                                            type="number"
                                            placeholder="Ограничение"
                                            as={Form.Control}
                                        />
                                    </td>
                                    <td>
                                        <ReactDatePicker
                                            selected={limit.startDate}
                                            id="startDate"
                                            name="startDate"
                                            onChange={date => setFieldValue(`limits.${index}.startDate`, date)}
                                            showTimeInput
                                            timeInputLabel="Время:"
                                            dateFormat="dd.MM.yyyy HH:mm"
                                            className="form-control"
                                            placeholderText="Введите дату старта" />
                                    </td>
                                    <td>
                                        <ReactDatePicker
                                            selected={limit.endDate}
                                            id="endDate"
                                            name="endDate"
                                            onChange={date => setFieldValue(`limits.${index}.endDate`, date)}
                                            showTimeInput
                                            timeInputLabel="Время:"
                                            dateFormat="dd.MM.yyyy HH:mm"
                                            className="form-control"
                                            placeholderText="Введите дату завершения" />
                                    </td>
                                    <td className="text-center align-middle">
                                        <Button variant="link" size="sm" onClick={() => arrayHelpers.remove(index)}>
                                            <FontAwesomeIcon className="text-danger" icon="times-circle" />
                                        </Button>
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan={4} align="center">Нет ограничений на продажу</td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                        <IconButton
                            onClick={() => arrayHelpers.push({limit: 0})}
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            transform="shrink-3"
                        >
                            Add Item
                        </IconButton>
                    </>
                )}/>
            </Card.Body>
        </Card>
    )
}

export default LimitsBlock;
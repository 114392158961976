import {FC, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Flex} from "@components/falcon/common/index";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {useFile} from "@hooks/filesHooks";
import {Spinner} from "react-bootstrap";

export interface AvatarProps {
    size?: 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
    rounded?: string;
    src?: string | string[];
    userId?: string;
    fileId?: string;
    name?: string;
    emoji?: string;
    className?: string;
    mediaClassName?: string;
    isExact?: boolean;
    icon?: IconProp;
}

const Avatar: FC<AvatarProps> = ({
    size = 'xl',
    rounded,
    src,
    userId,
    fileId,
    name,
    emoji = '😊',
    className,
    mediaClassName,
    isExact = false,
    icon
                                 }) => {

    const [imageError, setImageError] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);

    const classNames = ['avatar', `avatar-${size}`, className].join(' ');
    const mediaClasses = [
        rounded ? `rounded-${rounded}` : 'rounded',
        mediaClassName
    ].join(' ');

    const file = useFile(fileId);
    const loading = Boolean(imageLoading || (fileId && file?.loading));
    if(file?.loaded) {
        src = file.data?.url || src;
    }

    useEffect(() => {
        if(!src) {
            return;
        }

        setImageLoading(true);
        const img = new Image();
        img.src = src as string;
        img.onload = () => setImageLoading(false);
        img.onerror = () => {
            setImageError(true);
            setImageLoading(false);
        }
    }, [src]);

    const getAvatar = () => {

        if(loading) {
            return (
                <Flex alignItems="center" justifyContent="center" className={`avatar-name ${mediaClasses}`}>
                    <Spinner animation="grow" />
                </Flex>
            );
        }

        if (src && !imageError) {
            if (Array.isArray(src)) {
                return (
                    <div className={`${mediaClasses} overflow-hidden h-100 d-flex`}>
                        <div className="w-50 border-right">
                            <img src={src[0]} alt="" onError={() => setImageError(true)}/>
                        </div>
                        <div className="w-50 d-flex flex-column">
                            <img src={src[1]} alt="" className="h-50 border-bottom" />
                            <img src={src[2]} alt="" className="h-50" />
                        </div>
                    </div>
                );
            } else {
                return <img className={mediaClasses} src={src} alt="" onError={() => setImageError(true)}/>;
            }
        }

        if (name) {
            return (
                <div className={`avatar-name ${mediaClasses}`}>
                    <span>{isExact ? name : name.split(' ').map((word) => word[0]).join('').slice(0, 2)}</span>
                </div>
            );
        }

        if (icon) {
            return (
                <Flex className={`avatar-name ${mediaClasses}`}>
                    <FontAwesomeIcon icon={icon} />
                </Flex>
            );
        }

        return (
            <div className={`avatar-emoji ${mediaClasses}`}>
                <span role="img" aria-label="Emoji">
                  {emoji}
                </span>
            </div>
        );
    };

    return (
        <div className={classNames}>
            {getAvatar()}
        </div>
    )
}

export default Avatar;
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { MarketStore } from "../../interfaces/user/userStore";
import { MarketItem, MarketCategory } from '../../interfaces/user/market';
import UserService from '../../services/api/userService';

interface MarketData {
    items: MarketItem[];
    categories: MarketCategory[];
}

const slice = createSlice({
    initialState: {
        loaded: false,
        loading: false,
        error: null,
        data: {
            items: [],
            categories: []
        }
    } as MarketStore,
    name: 'userMarketStore',
    reducers: {
        setLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        setMarket: (state, action: PayloadAction<MarketData>) => {
            state.data = action.payload;
            state.error = null;
            state.loaded = true;
            state.loading = false;
        }
    }
})

export const { reducer: userMarketReducer } = slice;

const { 
    setError,
    setLoading,
    setMarket
} = slice.actions;

export const loadMarket = () => async (dispatch: Dispatch) => {
    dispatch(setLoading());
    try {
        const api = new UserService();
        const [items, categories] = await Promise.all([
            api.fetchUserMarketItems(),
            api.fetchUserMarketCategories()
        ]);

        dispatch(setMarket({
            items,
            categories
        }));
    }
    catch(e) {
        dispatch(setError(e));
    }
}
import React, {PropsWithChildren} from 'react';
import classNames from 'classnames';

interface MainProps {
    className?: string;
}

const Main: React.FC<PropsWithChildren<MainProps>> = ({ className, children }) => (
  <div className={classNames("main", className)}>{children}</div>
);

export default Main;

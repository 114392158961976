import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";
import RefCode from "./refCode";
import RefCodeLink from "./refCodeLink";

const RefCodeInfo: ConstructionComponentInfo = {
    component: RefCode,
    addedChildProps: [
        'ref_code_loading',
        'ref_code_loaded',
        'ref_code_error',
        'ref_code_exists',
        'ref_code_creating',
        'create_ref_code'
    ]
}

const RefCodeLinkInfo: ConstructionComponentInfo = {
    component: RefCodeLink,
    requiredParent: [
        'RefCode'
    ],
    props: [
        'tag'
    ]
}

export {
    RefCodeInfo,
    RefCodeLinkInfo
}
import {FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormikHelpers, Formik, Form } from 'formik';
import { toastr } from 'react-redux-toastr';
import * as Yup from 'yup';
import { injectChild } from '@components/constructor';
import {useLocalizedBlock, usePanel} from '@services/hooks';
import { auth } from '@services/auth';
import {useThemeGoal, useThemeUrl} from '@hooks/panelHooks';
import { useHistory, useLocation } from 'react-router-dom';
import ym from "react-yandex-metrika";

interface SignUpFormValues {
    username: string
    email: string,
    password: string,
    repeatPassword: string,
    rules: boolean;
    promoCode: string;
}

const SignUpSchema = Yup.object().shape({
    username: Yup.string()
        .min(5, "Не менее 5 символов")
        .max(16, "Не более 16 символов")
        .required("Обязательно"),
    email: Yup.string()
        .email("Введите корректный e-mail")
        .required("Обязательно"),
    password: Yup.string()
        .min(6, "Не менее 6 символов")
        .max(16, "Не более 16 символов")
        .required("Обязательно")
        .matches(/[A-Z]/, "Должен содержать заглавные буквы")
        .matches(/[a-z]/, "Должен содержать прописные буквы")
        .matches(/[0-9]/, "Должен содержать цифры"),
    repeatPassword: Yup.string()
        .required("Обязательно")
        .oneOf([Yup.ref('password')], "Пароли не совпадают"),
    promoCode: Yup.string()
        .max(36, "Не более 36 символов")
});

function useRefCode() {

    const { search } = useLocation();
    const history = useHistory();

    const code = useMemo(() => {
        const params = new URLSearchParams(search);
        const code = params.get("r");
        const storedCode = localStorage.getItem("refCode");

        if (code && storedCode !== code) {
            localStorage.setItem("refCode", code);
        }

        if(code) {
            params.delete('r');
            history.replace({
                search: params.toString()
            });
        }

        return code || storedCode;
    }, [history, search]);

    return code;
}

const SignUpForm: FC<PropsWithChildren> = ({ children, ...props }) => {

    const history = useHistory();
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [recaptcha, setRecaptcha] = useState<string | null>(null);
    const locale = useLocalizedBlock('guest.signup');
    const redirectUrl = useThemeUrl('signup.success');

    const { ymId } = usePanel();
    const signUpGoal = useThemeGoal('signUp');

    const refCode = useRefCode();

    const onSubmit = useCallback(async (values: SignUpFormValues, actions: FormikHelpers<SignUpFormValues>) => {

        if (!recaptcha) {
            actions.setFieldError("username", locale['errors.RequiredRecaptcha']);
            return;
        }

        const res = await auth().signUp({
            userName: values.username,
            email: values.email,
            password: values.password,
            confirmPassword: values.repeatPassword,
            recaptcha,
            promoCode: values.promoCode || undefined,
            refCode: refCode || undefined
        });

        if(ymId && signUpGoal) {
            ym('reachGoal', signUpGoal);

        }

        if (res.success) {
            const result = res.data.result;

            if (result === 'CheckMail') {
                toastr.success("Регистрация", "Аккаунт успешно зарегистрирован. Проверьте почту и подтвердите ваш email");
            }
            else if (result === 'SmtpError') {
                toastr.warning("Регистрация", "Аккаунт успешно разрегистрирован, но не удалось отправить сообщение на почту. Повторите попытку позднее.");
            }
            else {
                toastr.success("Регистрация", "Аккаунт успешно зарегистрирован.");
            }

            if (redirectUrl) {
                history.push(redirectUrl);
            }

            localStorage.removeItem('r');
        }
        else {
            let handled = false;
            if (res.errorCode === 'DuplicateUserName') {
                actions.setFieldError("username", locale['errors.DuplicateUserName']);
                handled = true;
            }
            if (res.errorCode === 'DuplicateEmail') {
                actions.setFieldError("email", locale['errors.DuplicateEmail']);
                handled = true;
            }
            if (res.errorCode === 'InvalidUserName') {
                actions.setFieldError("username", locale['errors.InvalidUserName']);
                handled = true;
            }
            if (res.errorCode === 'InvalidRecaptcha') {
                toastr.error(locale['header'], locale['errors.recaptcha']);
                handled = true;
            }
            if (res.errorCode === 'ForbiddenEmail') {
                actions.setFieldError("email", "Этот почтовый домен запрещен");
                handled = true;
            }
            if (res.errorCode === 'CodeNotFound') {
                actions.setFieldError("promoCode", "Промокод не найден");
                handled = true;
            }
            if (res.errorCode === 'CodeExpired') {
                actions.setFieldError("promoCode", "Промокод истек");
                handled = true;
            }
            if (res.errorCode === 'CodeUsed') {
                actions.setFieldError("promoCode", "Промокод уже использован");
                handled = true;
            }
            if (!handled) {
                toastr.error("Регистрация", "Что-то пошло не так");
            }
            recaptchaRef.current?.reset();
        }
    }, [history, locale, recaptcha, redirectUrl, refCode, signUpGoal, ymId]);

    const initialValues: SignUpFormValues = {
        username: '',
        email: '',
        password: '',
        repeatPassword: '',
        rules: false,
        promoCode: ''
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={SignUpSchema}>
            <Form {...props}>
                {
                    injectChild({
                        recaptcha: {
                            ref: recaptchaRef,
                            onChange: setRecaptcha
                        }
                    }, children)
                }
            </Form>
        </Formik>
    )
}

export default SignUpForm;
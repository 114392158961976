import ConsumerRefService from "@api/consumer/consumerRefService";
import { RefRewardType } from "@interfaces/consumer/refs";
import { PutRefRewardModel } from "@models/consumer/refs";
import { FormikHelpers } from "formik";
import { FC, useCallback } from "react";
import { toastr } from "react-redux-toastr";
import { useHistory, useParams } from "react-router-dom";
import { CardBody } from "reactstrap";
import RefRewardForm, { RefRewardFormValues } from "./refRewardForm";

const AddRefRewardForm: FC = () => {

    const { ref_id } = useParams<{ ref_id: string }>();
    const history = useHistory();

    const initialValues: RefRewardFormValues = {
        name: '',
        isActive: false,
        order: 0,
        type: RefRewardType.Balance,

        balance: 0,
    
        itemId: '',
        count: 0,

        percent: 0,
        minSum: 0,
        maxSum: 15000
    }

    const onSubmit = useCallback(async (values: RefRewardFormValues, actions: FormikHelpers<RefRewardFormValues>) => {
        const api = new ConsumerRefService();

        const model: PutRefRewardModel = {
            name: values.name,
            type: values.type
        }
        //Чтоб не гонять мусор
        if(values.type === RefRewardType.Balance) {
            model.balance = values.balance
        }
        else if(values.type === RefRewardType.Item) {
            model.itemId = values.itemId;
            model.count = values.count;
        }
        else {
            model.percent = values.percent;
            model.minSum = values.minSum;
            model.maxSum = values.maxSum;
        }

        const result = await api.putRefReward(ref_id, model);

        if (result.success) {
            toastr.success("Изменение реферального бонуса", "Награда реферального бонуса успешно добавлена");
            history.replace(`/consumer/refs/${ref_id}/rewards/${result.data.id}/`);
        }
        else {
            if (result.errorCode === 'RefNotFound') {
                toastr.error('Изменение реферального бонуса', 'Бонус не найден');
            }
            else if (result.errorCode === 'InvalidRequest') {
                toastr.error('Изменение реферального бонуса', 'Некорректные параметры награды');
            }
            else if (result.errorCode === 'DisabledItem') {
                actions.setFieldError('itemId', 'Предмет отключен');
            }
            else if (result.errorCode === 'InvalidType') {
                toastr.error('Изменение реферального бонуса', 'Некорректный тип награды');
            }
            else {
                toastr.error('Изменение реферального бонуса', 'Неизвестная ошибка');
            }
        }
    }, [history, ref_id]);

    return (
        <CardBody>
            <RefRewardForm
                initialValues={initialValues}
                onSubmit={onSubmit} />
        </CardBody>
    )
}

export default AddRefRewardForm;
import React, {FC, PropsWithChildren} from "react";
import classNames from "classnames";

interface FlexProps {
    justifyContent?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly';
    inline?: boolean;
    alignItems?: 'start' | 'center' | 'end' | 'baseline' | 'stretch';
    alignContent?: 'start' | 'center' | 'end' | 'between' | 'around' | 'stretch';
    wrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
    className?: string;
    tag?: string | React.ElementType;
    breakpoint?: string;
    direction?: 'column' | 'column-reverse';
}

const Flex: FC<PropsWithChildren<FlexProps>> = ({
                                 tag: Tag = 'div',
                                 justifyContent,
                                 inline,
                                 alignItems,
                                 alignContent,
                                 wrap, className,
                                 breakpoint,
                                 direction,
                             children,
                             ...rest}
) => (
    <Tag
        className={classNames(
            {
                [`d-${breakpoint ? breakpoint + '-' : ''}flex`]: !inline,
                [`d-${breakpoint ? breakpoint + '-' : ''}inline-flex`]: inline,
                [`flex-${direction}`]: direction,
                [`justify-content-${justifyContent}`]: justifyContent,
                [`align-items-${alignItems}`]: alignItems,
                [`align-content-${alignContent}`]: alignContent,
                [`flex-${wrap}`]: wrap
            },
            className
        )}
        {...rest}
    >
        {children}
    </Tag>
)

export default Flex;
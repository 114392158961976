import ConsumerRefService from "@api/consumer/consumerRefService";
import { AnimatedFontAwesomeIcon, TableSpinner } from "@components/panel";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useStateLoader } from "@hooks/panelHooks";
import { useRole } from "@hooks/userHooks";
import { useItem } from "@hooks/selectors/user/item.selectors"
import { GoalRewardType, IGoalBalanceReward, IGoalItemReward, IGoalReward } from "@interfaces/consumer/refs";
import { FC, memo, useCallback } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { CardBody, CardHeader, CardTitle, Input, Table } from "reactstrap";

const GoalRewardsList: FC = () => {

    const history = useHistory();
    const { goal_id } = useParams<{ goal_id: string }>();
    const loadGoal = useCallback((goal_id: string) => {
        const api = new ConsumerRefService();
        return api.getGoalRewards(goal_id);
    }, []);

    const canEdit = useRole('consumer.goal.edit');
    const { loading, data: rewards = [] } = useStateLoader<IGoalReward[]>(loadGoal, goal_id);

    const toggleModal = () => {
        history.push(`/consumer/goals/${goal_id}/rewards/add/`);
    };

    return (
        <>
            <CardHeader>
                {
                    canEdit && (
                        <div className="card-actions float-end">
                            <AnimatedFontAwesomeIcon
                                icon={faPlus}
                                fixedWidth
                                className="align-middle"
                                onClick={toggleModal}
                                animate="spin"
                            />
                        </div>
                    )
                }
                <CardTitle tag="h5">
                    Список наград за достижение{' '}
                </CardTitle>
            </CardHeader>
            <CardBody>
                <Table striped>
                    <thead>
                        <tr>
                            <th>Название</th>
                            <th>Тип</th>
                            <th>Количество</th>
                            <th>Активно</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ? (
                                <TableSpinner colSpan={4} />
                            ) : rewards.length === 0 ? (
                                <tr><td colSpan={5} align='center'>Нет наград</td></tr>
                            ) : rewards.map(r => (
                                <GoalReward key={r.id} reward={r} />
                            ))
                        }
                    </tbody>
                </Table>
            </CardBody>
        </>
    )
}

interface GoalRewardProps {
    reward: IGoalReward;
}

const GoalReward: FC<GoalRewardProps> = memo(({ reward }) => {
    
    const balance = (reward as IGoalBalanceReward).balance;
    const itemId = (reward as IGoalItemReward).itemId;
    const count = (reward as IGoalItemReward).count;

    const item = useItem(itemId || '');

    const info = reward.type === GoalRewardType.Balance ? balance : `${item.name} x${count}`;

    return (
        <tr>
            <td><Link to={`/consumer/goals/${reward.goalId}/rewards/${reward.id}/`}>{reward.name}</Link></td>
            <td>{reward.type}</td>
            <td>{info}</td>
            <td>
                <Input
                    id={`rw-${reward.id}`}
                    type="checkbox"
                    disabled
                    defaultChecked={reward.isActive} />
            </td>
        </tr>
    )
});

export default GoalRewardsList;
import {FC, useCallback, useState} from 'react';
import { useParams } from 'react-router-dom';
import { FileUploader } from '../../../../services';
import { useStateLoader, useBreadcrumbs } from '@hooks/panelHooks';
import { IConsumerMarketCategoryFull } from '@interfaces/consumer/market';
import {Form, Formik, FormikHelpers} from 'formik';
import { UploadFileInfo } from '@interfaces/panel/files';
import { toastr } from 'react-redux-toastr';
import withPage from '../../../hoc/withPage';
import ConsumerMarketService from '../../../../services/api/consumer/consumerMarketService';
import {Card, Col, Row, Spinner} from "react-bootstrap";
import {FormIconBlock, FormPageFooter, FormPageHeader} from "@components/falcon/common/forms";
import {BaseInfoBlock} from "./forms";
import * as Yup from 'yup';

const EditCategoryPage: FC = () => {

    const { category_id } = useParams<{ category_id: string }>();
    const loadCategory = useCallback(() => {
        const api = new ConsumerMarketService();
        return api.fetchConsumerMarketCategory(category_id);
    }, [category_id]);
    const { data: category, loading } = useStateLoader<IConsumerMarketCategoryFull>(loadCategory);

    useBreadcrumbs('consumer.market.category.current.name', true, category?.name);

    if (!category) {
        return (
            <Card>
                <Card.Body className="text-center">
                    {loading ? <Spinner className="mr-2" color="primary" /> : (
                        "Категория не найдена"
                    )}
                </Card.Body>
            </Card>
        )
    }

    return (
        <EditCategoryForm category={category} />
    )
}

interface EditCategoryFormProps {
    category: IConsumerMarketCategoryFull;
}

interface EditCategoryFormValues {
    name: string;
    shortName: string;
    description: string;
    icon?: File | null;
    isActive: boolean;
}

const EditCategoryForm: FC<EditCategoryFormProps> = ({ category }) => {

    const [uploading, setUploading] = useState(false);
    const [uploadState, setUploadState] = useState(0);

    const onProgress = useCallback((event: ProgressEvent<EventTarget>) => {
        setUploadState(event.loaded / event.total * 100);
    }, [setUploadState]);

    const initialValues: EditCategoryFormValues = {
        name: category.name,
        shortName: category.shortName,
        description: category.description,
        icon: null,
        isActive: category.isActive
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(),
        shortName: Yup.string()
            .min(3)
            .max(64)
            .required(),
        description: Yup.string(),
        icon: Yup.mixed(),
        isActive: Yup.boolean().required()
    });

    const onSubmit = async (values: EditCategoryFormValues, actions: FormikHelpers<EditCategoryFormValues>) => {
        const api = new ConsumerMarketService();
        const result = await api.patchConsumerMarketCategory({
            id: category.id,
            name: values.name,
            shortName: values.shortName,
            description: values.description,
            isActive: values.isActive,
            uploadIcon: Boolean(values.icon)
        });

        if (result.ok) {
            if (result.result?.upload && values.icon) {
                const upload: UploadFileInfo = result.result?.upload;
                const file = new FileUploader(upload);
                file.onProgress = onProgress;
                try {
                    setUploading(true);
                    await file.upload(values.icon);
                    toastr.success("Редактирование категории", "Категория успешно обновлена");
                }
                catch (e) {
                    toastr.warning("Редактирование категории", "Категория обновлена, но не удалось загрузить изображение.");
                    console.log(e);
                }
                finally {
                    setUploading(false);
                    setUploadState(0);
                }
            }
            else {
                toastr.success("Редактирование категории", "Категория успешно обновлена");
            }
        }
        else {
            if (result.errorCode === 'CategoryNotFound') {
                toastr.error("Редактирование категории", "Категория не найдена. Обновите страницу и попробуйте еще раз.");
            }
            if (result.errorCode === 'ShortNameExists') {
                actions.setFieldError('shortName', "Это имя уже занято");
            }
            else if (result.errorCode === 'BucketNotFound') {
                toastr.error("Редактирование категории", "Отсутствует хранилище файлов. Создайте его из панели администратора и повторите попытку.");
            }
            else {
                toastr.error("Редактирование категории", "Внутренняя ошибка");
            }
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            <Form>
                <Row className="g-3">
                    <Col xs={12}>
                        <FormPageHeader title={`Редактирование категории "${category.name}"`} />
                    </Col>
                    <Col lg={8}>
                        <BaseInfoBlock editForm />
                    </Col>
                    <Col lg={4}>
                        <FormIconBlock
                            iconId={category.iconId}
                            title="Иконка категории"
                            uploading={uploading}
                            uploadProgress={uploadState}
                        />
                    </Col>
                    <Col>
                        <FormPageFooter
                            cancelUrl={'/consumer/market/category/'}
                        />
                    </Col>
                </Row>
            </Form>
        </Formik>
    )
}

export default withPage(EditCategoryPage);
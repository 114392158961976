import React, {FC} from "react";
import {Col, Row} from "reactstrap";
import {useSelector} from "react-redux";
import {ReduxStore} from "@interfaces/store";
import {Link} from "react-router-dom";

const Footer: FC = () => {

    const projectName = useSelector((state: ReduxStore) => state.panel.settings.data.projectName);

    return (
        <footer className="footer">
            <Row className="justify-content-between text-center fs-10 mt-4 mb-3">
                <Col sm="auto">
                    <p className="mb-0 text-600">
                        Thank you for creating with Falcon{' '}
                        <span className="d-none d-sm-inline-block">| </span>
                        <br className="d-sm-none"/> {new Date().getFullYear()} &copy;{' '}
                        <Link to="/" className="text-muted">
                            {projectName}
                        </Link>
                    </p>
                </Col>
                <Col sm="auto">
                    <p className="mb-0 text-600">v2</p>
                </Col>
            </Row>
        </footer>
    )
}

export default Footer;
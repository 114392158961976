import { FC } from "react";
import { useAccountContext } from "./context";
import { Text } from '@constructor/components';

const AccountName: FC = (props) => {

    const { name } = useAccountContext();

    return (
        <Text text={name} {...props} />
    )
}

export default AccountName;
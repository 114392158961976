import { useAvatar, useUserName } from "@hooks/selectors/user.selectors";
import { FC } from "react";

const UserAvatar: FC = (props) => {
    const name = useUserName();
    const avatar = useAvatar();

    return (
        <img src={avatar} alt={name} {...props} />
    )
}

export default UserAvatar;
import { injectChild } from "@components/constructor";
import { useTopicAuto, useTaskMessages, useSupportLoading } from "@hooks/selectors/user/support.selectors";
import { userActions } from "@store/user";
import {FC, PropsWithChildren, useCallback, useEffect, useMemo, useState} from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {useSupportTopicContext, SupportTopicProvider, ISupportTopicContext} from "../context";
import {UploadFileInfo} from "@interfaces/panel/files";
import {FileUploader} from "@services/files/fileUploader";
import {toastr} from "react-redux-toastr";
import {randomString} from "@services/helpers";

interface SupportTopicChildProps {
    support_loading: boolean;
    topic_messages_count: number;
    topic_closed: boolean;

    editable_message?: string;
    editing_message: boolean;
    edit_message: (message: string) => void;
    cancel_edit_message: () => void;
}

interface UploadAttachmentState {
    id: string;
    uploading: boolean;
    uploadSuccess: boolean;
    uploadFailed: boolean;
    uploadProgress: number;
}

interface SupportTopicState {
    editableMessageId?: string;
    attachedImages: {
        id: string;
        file: File;
        fileUrl: string;
    }[];
    uploadProgress: UploadAttachmentState[];
}

const SupportTopic: FC<PropsWithChildren> = ({ children }) => {

    const { topic_id } = useParams<{ topic_id: string }>();
    const dispatch = useDispatch();
    const topic = useTopicAuto();
    const headContext = useSupportTopicContext();
    const messages = useTaskMessages(topic?.id || '');
    const isLoading = useSupportLoading();

    const [state, setState] = useState<SupportTopicState>({
        attachedImages: [],
        uploadProgress: []
    })
    
    const setEditableMessage = useCallback((message?: string) => {
        setState((state) => ({
            ...state,
            editableMessageId: message
        }));
    }, []);

    const setAttachedImages = useCallback((images: File[]) => {
        setState((state) => {

            const removedImages = state.attachedImages.filter(
                file => !images.some(image => image === file.file)
            ) ?? [];
            const addedImages = images.filter(
                image => !state.attachedImages.some(file => file.file === image)
            ).map(file => ({
                id: randomString(),
                file,
                fileUrl: URL.createObjectURL(file)
            }));

            removedImages.forEach(file => URL.revokeObjectURL(file.fileUrl));

            return {
                ...state,
                attachedImages: state.attachedImages.filter(file =>
                        !removedImages.some(image => image.id === file.id)
                ).concat(addedImages),
                uploadProgress: state.uploadProgress.filter(
                    p => !removedImages.some(x => x.id === p.id)
                ).concat(addedImages.map(file => ({
                    id: file.id,
                    uploading: false,
                    uploadSuccess: false,
                    uploadFailed: false,
                    uploadProgress: 0
                })))
            }
        })
    }, []);

    const removeAttachedImage = useCallback((id: string) => {
        setState(state => ({
            ...state,
            attachedImages: state.attachedImages.filter(file => file.id !== id),
            uploadProgress: state.uploadProgress?.filter(file => file.id !== id)
        }));
    }, []);

    const setUploadState = useCallback((index: number, attachState: Partial<UploadAttachmentState>) => {
        setState(state => {
            const uploadProgress = state.uploadProgress?.slice() ?? [];
            uploadProgress[index] = {
                ...uploadProgress[index],
                ...attachState
            };

            return {
                ...state,
                uploadProgress
            };
        })
    }, []);

    const uploadImages = useCallback(async (ufi: UploadFileInfo[]) => {
        for(let idx = 0; idx < Math.min(ufi.length, state.attachedImages?.length ?? 0); idx++) {
            const file = state.attachedImages?.[idx];
            if(!file) {
                continue;
            }

            const upload = ufi[idx];
            const uploader = new FileUploader(upload);
            uploader.onProgress = event => {
                setUploadState(idx, {
                    uploadProgress: event.loaded / event.total * 100
                });
            };
            uploader.onSuccess = event => setUploadState(idx, {
                uploadSuccess: true,
                uploading: false,
                uploadProgress: 100
            });
            uploader.onError = event => setUploadState(idx, {
                uploadFailed: true,
                uploading: false,
                uploadProgress: 0
            });
            
            try {
                setUploadState(idx, {
                    uploading: true,
                    uploadProgress: 0,
                    uploadFailed: false,
                    uploadSuccess: false
                });
                await uploader.upload(file.file);
            }
            catch (e) {
                toastr.error("Поддержка", "Не удалось загрузить изображение");
            }
        }
    }, [setUploadState, state.attachedImages]);

    useEffect(() => {
        if (topic_id) {
            dispatch(userActions.loadTask(topic_id));
            dispatch(userActions.loadTaskMessages(topic_id));
        }
    }, [dispatch, topic_id]);

    const data = useMemo<SupportTopicChildProps>(() => ({
        support_loading: isLoading,
        topic_messages_count: messages.length,
        topic_closed: topic?.isClosed || false,

        editable_message: state.editableMessageId,
        editing_message: Boolean(state.editableMessageId),
        edit_message: setEditableMessage,
        cancel_edit_message: () => setEditableMessage(undefined)
    }), [state.editableMessageId, isLoading, messages.length, setEditableMessage, topic?.isClosed]);

    const context = useMemo<ISupportTopicContext>(() => ({
        topic,
        attachedImages: (state.attachedImages ?? []).map((file, idx) => {
            const uploadState = state.uploadProgress?.[idx];
            return {
                id: file.id,
                file: file.file,
                fileUrl: file.fileUrl,
                uploading: uploadState?.uploading ?? false,
                uploadSuccess: uploadState?.uploadSuccess ?? false,
                uploadFailed: uploadState?.uploadFailed ?? false,
                uploadProgress: uploadState?.uploadProgress ?? 0
            }
        }),
        setAttachedImages: setAttachedImages,
        uploadAttachedImages: uploadImages,
        editableMessageId: state.editableMessageId,
        removeAttachedImage
    }), [setAttachedImages, state.attachedImages, state.editableMessageId, state.uploadProgress, topic, uploadImages, removeAttachedImage]);
    
    if (!topic) {
        return null;
    }

    if (headContext) {
        return injectChild(data, children);
    }
    else {
        return (
            <SupportTopicProvider value={context}>
                {injectChild(data, children)}
            </SupportTopicProvider>
        )
    }
}

export default SupportTopic;
import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";
import BonusCode from "./bonusCode";
import BonusCodeLogs from "./bonusCodeLogs";
import BonusCodeLogsList from "./bonusCodeLogsList";
import BonusCodeUseForm from "./bonusCodeUseForm";
import BonusCodeUseTime from "./bonusCodeUseTime";
import BonusCodeValue from "./bonusCodeValue";

export interface BonusCodeLogsPaginationProps {
    bonus_code_logs_page: number;
    bonus_code_logs_per_page: number;
}

const BonusCodeLogsInfo: ConstructionComponentInfo = {
    component: BonusCodeLogs,
    props: [
        'bonus_code_logs_per_page'
    ],
    addedChildProps: [
        'pagination_current_page',
        'pagination_total_pages',
        'pagination_per_page',
        'pagination_set_page',
        'pagination_next_page',
        'pagination_prev_page',
        'bonus_code_logs_count',
        'bonus_code_logs_loading'
    ]
}

const BonusCodeInfo: ConstructionComponentInfo = {
    component: BonusCode
}

const BonusCodeLogsListInfo: ConstructionComponentInfo = {
    component: BonusCodeLogsList,
    props: [
        'pagination_per_page'
    ],
    addedChildProps: [
        'bonus_code_log_index'
    ],
    injectChild: [
        'pagination_current_page',
        'pagination_per_page'
    ]
}

const BonusCodeUseTimeInfo: ConstructionComponentInfo = {
    component: BonusCodeUseTime,
    props: [
        'format'
    ],
    requiredParent: [
        'BonusCodeLogsList'
    ]
}

const BonusCodeValueInfo: ConstructionComponentInfo = {
    component: BonusCodeValue,
    requiredParent: [
        'BonusCodeLogsList'
    ]
}

const BonusCodeUseFormInfo: ConstructionComponentInfo = {
    component: BonusCodeUseForm
}

export {
    BonusCodeInfo,
    BonusCodeLogsInfo,
    BonusCodeLogsListInfo,
    BonusCodeUseTimeInfo,
    BonusCodeValueInfo,
    BonusCodeUseFormInfo
}
import UserService from "@api/userService";
import {usePaymentSettings} from "@hooks/selectors/panel.selectors";
import {PaymentAgregator} from "@interfaces/consumer/payments";
import {Form, Formik, FormikHelpers} from "formik";
import {toastr} from "react-redux-toastr";
import * as Yup from 'yup';
import {FC, PropsWithChildren, useCallback, useMemo} from "react";
import {FormHelper} from "@constructor/components/core/forms";
import {EPaymentCurrency} from "@interfaces/user/payments";
import {usePanel} from "@services/hooks";
import {useThemeGoal} from "@hooks/panelHooks";
import ym from "react-yandex-metrika";

interface DonateFormValues {
    count: number;
    aggregator: PaymentAgregator;
    tag: string;
    currency: EPaymentCurrency;
}

interface DonateFormProps {
    initialValues?: Partial<DonateFormValues>;
}

const DonateForm: FC<PropsWithChildren<DonateFormProps>> = ({ children, initialValues, ...props }) => {

    const { donateMin, donateMax, currencies, defaultCurrency } = usePaymentSettings();
    const aggregators: PaymentAgregator[] = [PaymentAgregator.PayPalych, PaymentAgregator.Enot, PaymentAgregator.Prime, PaymentAgregator.Antilopay];//костыль1!!!!
    const { ymId } = usePanel();
    const donateGoal = useThemeGoal('donate');

    const validate = useMemo(() =>Yup.object().shape({
        count: Yup.number()
            .when('currency', (currency, field) =>{
                const currencyData = currencies.find(x => x.currency === currency);
                const min = currencyData?.donateMin || donateMin;
                const max = currencyData?.donateMax || donateMax;

                return field
                    .min(min, `Не менее ${min}`)
                    .max(max, `Не более ${max}`);
            })
            .integer('Введите число')
            .required('Обязательно')
    }), [currencies, donateMax, donateMin]);

    const onSubmit = useCallback(async (values: DonateFormValues, actions: FormikHelpers<DonateFormValues>) => {

        const page = window.open('about:blank', '_blank');

        const api = new UserService();
        const result = await api.donateRequest({
            agregator: values.aggregator,
            sum: values.count,
            tag: values.tag || undefined
        });

        if (result.success && result.data.redirect) {
            page?.location.replace(result.data.redirect);

            if(ymId && donateGoal) {
                ym('reachGoal', donateGoal);
            }
        }
        else {
            if (result.errorCode === 'NotEnabled') {
                toastr.error("Пожертвования", "Выбранный способ пополнения недоступен.");
            }
            else if (result.errorCode === 'PaymentError') {
                toastr.error("Пожертвования", "Ошибка платежа. Обратитесь к администрации.");
            }
            else if (result.errorCode === 'WrongSum') {
                if(result.errorData) {
                    actions.setFieldError("count", `Допустимая сумма от ${result.errorData.min} до ${result.errorData.max}`);
                }
                else {
                    actions.setFieldError("count", "Некорректная сумма");
                }
            }
            else {
                toastr.error("Пожертвования", "Неизвестная ошибка. Обратитесь к администрации.");
            }
            page?.close();
        }
    }, [donateGoal, ymId]);

    const initial: DonateFormValues = {
        count: 100,
        aggregator: aggregators[0],
        tag: '',
        currency: defaultCurrency,
        ...initialValues
    }

    return (
        <Formik
            initialValues={initial}
            validationSchema={validate}
            onSubmit={onSubmit}>
            <Form {...props}>
                <FormHelper>
                    {children}
                </FormHelper>
            </Form>
        </Formik>
    )
}

export default DonateForm;
import ConsumerRefService from "@api/consumer/consumerRefService";
import { FC, useCallback } from "react";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import RefForm, { ConditionType, RefFormValues } from "./refForm";

const AddRefForm: FC = () => {

    const history = useHistory();
    const initialValues: RefFormValues = {
        name: '',
        description: '',
        conditionType: ConditionType.All,
        hidden: false,
        isActive: false,
        order: 0
    }

    const onSubmit = useCallback(async (values: RefFormValues) => {
        const api = new ConsumerRefService();
        const result = await api.putRef({
            name: values.name,
            description: values.description,
            hidden: values.hidden
        });

        if(result.success) {
            toastr.success('Реферальная система', 'Достижение создано');
            history.replace(`/consumer/refs/${result.data.id}/`);
        }
        else {
            toastr.error('Реферальная система', 'Неизвестная ошибка');
        }
    }, [history]);

    return (
        <RefForm
            initialValues={initialValues}
            onSubmit={onSubmit} />
    )
}

export default AddRefForm;
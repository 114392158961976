import { ConstructionComponentInfo } from "@interfaces/constructor/constructor"
import NotificationIcon from "./notificationIcon"
import NotificationMessage from "./notificationMessage"
import Notifications from "./notifications"
import NotificationsList from "./notificationsList"
import NotificationTime from "./notificationTime"
import NotificantionTitle from "./notificationTitle"

const NotificationsInfo: ConstructionComponentInfo = {
    component: Notifications,
    addedChildProps: [
        'notifications_count',
        'notifications_unreaded_count',
        'notifications_has_unreaded'
    ]
}

const NotificationsListInfo: ConstructionComponentInfo = {
    component: NotificationsList,
    addedChildProps: [
        'notification_unreaded',
        'notification_set_readed'
    ]
}

const NotificantionTitleInfo: ConstructionComponentInfo ={
    component: NotificantionTitle,
    requiredParent: [
        'NotificationsList'
    ]
}

const NotificationMessageInfo: ConstructionComponentInfo = {
    component: NotificationMessage,
    requiredParent: [
        'NotificationsList'
    ]
}

const NotificationIconInfo: ConstructionComponentInfo = {
    component: NotificationIcon,
    props: [
        'image'
    ],
    requiredParent: [
        'NotificationsList'
    ]
}

const NotificationTimeInfo: ConstructionComponentInfo = {
    component: NotificationTime,
    requiredParent: [
        'NotificationsList'
    ]
}

export {
    NotificantionTitleInfo,
    NotificationIconInfo,
    NotificationMessageInfo,
    NotificationTimeInfo,
    NotificationsInfo,
    NotificationsListInfo
}

export interface IUsedBonusCode {
    code: string;
    useTime: string;
    value: number;
    type: BonusCodeType;
    valueInfo: ICodeTypeItem | ICodeTypeBalance;
}

export interface IPersonalBonusCode {
    id: string;
    code: string;
    createTime: string;
    isUsed: boolean;
    useTime?: string;
    type: BonusCodeType;
    valueInfo: ICodeTypeItem | ICodeTypeBalance;
}

export interface ICodeTypeItem {
    itemId: string;
    count: number;
}

export interface ICodeTypeBalance {
    value: number;
}

export enum BonusCodeType {
    Balance = 'Balance',
    Item = 'Item'
}

export interface IUseBonusCodeResult {
    value: number;
    message?: string;
    type: BonusCodeType;
    valueInfo: ICodeTypeItem | ICodeTypeBalance;
}
import {FC, PropsWithChildren, useCallback, useMemo} from "react";
import {useFormikContext} from "formik";
import {injectChild} from "@components/constructor";

interface FormHelperChildProps {
    set_form_fields: (fields: object) => void;
}

const FormHelper: FC<PropsWithChildren> = ({children}) => {

    const {
        setValues,
    } = useFormikContext();

    const setFormFields = useCallback((fields: object) => {
        setValues((values: any) => ({...values, ...fields}));
    }, [setValues]);
    
    const data = useMemo<FormHelperChildProps>(() => ({
        set_form_fields: setFormFields
    }), [setFormFields]);

    return injectChild(data, children);
}

export default FormHelper;
import React, {FC} from "react";
import {Card, Col, Form, Form as BForm} from "react-bootstrap";
import {Field, useFormikContext} from "formik";
import {BonusCodeFormValues} from ".";
import {EModifyType} from "@interfaces/user/payments";
import {BonusCodeType} from "@interfaces/consumer/bonusCodes";
import {FormikInput} from "@components/panel/formik";
import {useRole} from "@services/hooks";
import {FormikItemInput} from "@components/consumer/helpers";

interface RewardBlockProps {
    editForm: boolean;
}

const RewardBlock: FC<RewardBlockProps> = ({editForm}) => {

    const {
        values,
        setFieldValue,
    } = useFormikContext<BonusCodeFormValues>();

    const canEdit = useRole('consumer.bonus.edit');

    return (
        <Card className="mb-3">
            <Card.Header as="h6" className="bg-body-tertiary">
                Награда
            </Card.Header>
            <Card.Body>
                <BForm.Check
                    type='radio'
                    name='type'
                    id='type-balance'
                    inline
                    disabled={editForm}
                    value={EModifyType.Add}
                    checked={values.type === BonusCodeType.Balance}
                    onChange={() => setFieldValue('type', BonusCodeType.Balance)}
                    label='Баланс'
                />
                <BForm.Check
                    type='radio'
                    name='type'
                    id='type-item'
                    inline
                    disabled={editForm}
                    value={EModifyType.Dec}
                    checked={values.type === BonusCodeType.Item}
                    onChange={() => setFieldValue('type', BonusCodeType.Item)}
                    label='Предмет' />
                {values.type === BonusCodeType.Balance ? (
                    <Field
                        label="Сумма"
                        type="number"
                        name="money"
                        min={1}
                        max={10000}
                        placeholder="1-10000"
                        description="Сумма, которую будет давать каждый код"
                        //disabled={!canEdit}
                        disabled={editForm}
                        component={FormikInput}
                    />
                ) : (
                    <>
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>Предмет</Form.Label>
                        </Form.Group>
                        <Field
                            name='itemId'
                            component={FormikItemInput}
                            disabled={editForm}
                        />
                    </Col>
                        <Field
                            label="Количество"
                            type="number"
                            name="itemCount"
                            min={1}
                            max={100}
                            placeholder="1-100"
                            description="Количество предметов"
                            //disabled={!canEdit}
                            disabled={editForm}
                            component={FormikInput}
                        />
                    </>
                )}
            </Card.Body>
        </Card>
    )
}

export default RewardBlock;
import { GoalRewardType, IItemGoalReward } from "@interfaces/user/refs";
import { FC } from "react";
import { useGoalRewardContext } from "./context";
import { Text } from '@constructor/components';

const GoalRewardItemCount: FC = (props) => {
    const reward = useGoalRewardContext();
    if (reward?.type !== GoalRewardType.Item) {
        return null;
    }

    return (
        <Text text={(reward as IItemGoalReward).count} {...props} />
    )
}

export default GoalRewardItemCount;
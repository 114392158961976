import {FC} from "react";
import {useGameServers, useLoginServer} from "@hooks/selectors/consumer/project.selectors";
import {useQueries} from "@hooks/selectors/user/query.selectors";
import {EQueryAccess, EQueryTarget} from "@interfaces/query";
import {QueriesDropdown2, QueriesProvider, QueryExecuteResultModal} from "@components/consumer/queries";
import {Flex} from "@components/falcon/common";
import { ConsumerUserAccount } from "@interfaces/consumer/users";
import {ChargeGameAccountIcon} from "@components/consumer/users/details/index";

interface AccountActionsProps {
    account: ConsumerUserAccount;
}

const AccountActions: FC<AccountActionsProps> = ({account}) => {

    const login = useLoginServer(account.loginId);
    const games = useGameServers(account.loginId);

    const serverIds = games.map(x => x.id).concat(login!.id);

    const queries = useQueries(q => q.access === EQueryAccess.Admin
        && q.target === EQueryTarget.Account && serverIds.includes(q.serverId));

    return (
        <QueriesProvider queries={queries} queryParam={account.id}>
            <Flex justifyContent="end">
                <ChargeGameAccountIcon account={account} />
                <QueriesDropdown2 queryParam={account.id} />
            </Flex>
            <QueryExecuteResultModal />
        </QueriesProvider>
    )
}

export default AccountActions;
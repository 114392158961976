import ConsumerRefService from "@api/consumer/consumerRefService";
import { AnimatedFontAwesomeIcon, TableSpinner } from "@components/panel";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useStateLoader } from "@hooks/panelHooks";
import { useRole } from "@hooks/userHooks";
import { useItem } from "@hooks/selectors/user/item.selectors"
import { IBalanceRefReward, IItemRefReward, IPercentRefReward, IRefReward, RefRewardType } from "@interfaces/consumer/refs";
import { FC, memo, useCallback } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { CardBody, CardHeader, CardTitle, Input, Table } from "reactstrap";

const RefRewardsList: FC = () => {

    const history = useHistory();
    const { ref_id } = useParams<{ ref_id: string }>();
    const loadGoal = useCallback((goal_id: string) => {
        const api = new ConsumerRefService();
        return api.getRefRewards(goal_id);
    }, []);

    const canEdit = useRole('consumer.ref.edit');
    const { loading, data: rewards = [] } = useStateLoader<IRefReward[]>(loadGoal, ref_id);

    const toggleModal = () => {
        history.push(`/consumer/refs/${ref_id}/rewards/add/`);
    };

    return (
        <>
            <CardHeader>
                {
                    canEdit && (
                        <div className="card-actions float-end">
                            <AnimatedFontAwesomeIcon
                                icon={faPlus}
                                fixedWidth
                                className="align-middle"
                                onClick={toggleModal}
                                animate="spin"
                            />
                        </div>
                    )
                }
                <CardTitle tag="h5">
                    Список условий для реферального бонуса{' '}
                </CardTitle>
            </CardHeader>
            <CardBody>
                <Table striped>
                    <thead>
                        <tr>
                            <th>Название</th>
                            <th>Тип</th>
                            <th>Количество</th>
                            <th>Активно</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ? (
                                <TableSpinner colSpan={4} />
                            ) : rewards.length === 0 ? (
                                <tr><td colSpan={4} align='center'>Нет наград</td></tr>
                            ) : rewards.map(r => (
                                <RefReward key={r.id} reward={r} />
                            ))
                        }
                    </tbody>
                </Table>
            </CardBody>
        </>
    )
}

interface RefRewardProps {
    reward: IRefReward;
}

const RefReward: FC<RefRewardProps> = memo(({ reward }) => {

    const balance = (reward as IBalanceRefReward).balance;
    const itemId = (reward as IItemRefReward).itemId;
    const count = (reward as IItemRefReward).count;
    const value = (reward as IPercentRefReward).percent;

    const item = useItem(itemId || '');

    const info = reward.type === RefRewardType.Item ? `${item.name} x${count}` : balance || value;

    return (
        <tr>
            <td><Link to={`/consumer/refs/${reward.refId}/rewards/${reward.id}/`}>{reward.name}</Link></td>
            <td>{reward.type}</td>
            <td>{info}</td>
            <td>
                <Input
                    id={`rw-${reward.id}`}
                    type="checkbox"
                    disabled
                    defaultChecked={reward.isActive} />
            </td>
        </tr>
    )
});

export default RefRewardsList;
import React from 'react';
import ReactDOM from 'react-dom';

const canUseDOM = !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
);

interface PortalProps {
    children: React.ReactNode;
    node?: Element;
}

class Portal extends React.Component<PortalProps> {

    defaultNode: Element | null = null;

    componentWillUnmount() {
        if (this.defaultNode) {
            document.body.removeChild(this.defaultNode);
        }
        this.defaultNode = null;
    }

    render() {
        if (!canUseDOM) {
            return null;
        }

        if(this.props.node){
            return ReactDOM.createPortal(
                this.props.children,
                this.props.node
            );
        }
        
        if (!this.defaultNode) {
            this.defaultNode = document.createElement('div');
            document.body.appendChild(this.defaultNode);
        }

        return ReactDOM.createPortal(
            this.props.children,
            this.props.node || this.defaultNode
        );
    }
}

export default Portal;
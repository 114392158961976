import ConsumerUsersService from "@api/consumer/consumerUsersService";
import { FormikRowInput } from "@components/panel/formik";
import { ConsumerGameInfo, ConsumerLoginInfo } from "@interfaces/consumer/project";
import { ConsumerUserAccount } from "@interfaces/consumer/users";
import { ConsumerUserChargeAccountModel } from "@models/consumer/users";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { toastr } from "react-redux-toastr";
import { Button, Modal } from "react-bootstrap";
import * as Yup from 'yup';
import {FC} from "react";

interface ChargeGameAccountModalProps {
    id: string;
    account: ConsumerUserAccount;
    login: ConsumerLoginInfo;
    game?: ConsumerGameInfo;
    toggle(): void;
    isOpened: boolean;
}

interface ChargeGameAccountModalForm {
    count: number;
}

const ChargeGameAccountModal: FC<ChargeGameAccountModalProps> = ({ id, account, login, game, toggle, isOpened }) => {

    const targetName = game ? `геймсервере ${game.name}` : `логинсервере ${login.name}`;

    const initial: ChargeGameAccountModalForm = {
        count: 0
    };

    const valid = Yup.object().shape({
        count: Yup.number()
            .min(1)
            .max(2000000000)
            .required()
    });

    const onSubmit = async (values: ChargeGameAccountModalForm, actions: FormikHelpers<ChargeGameAccountModalForm>) => {

        const request: ConsumerUserChargeAccountModel = {
            accountId: account.id,
            serverId: game ? game.id : login.id,
            count: values.count
        };
        const api = new ConsumerUsersService();
        const result = await api.chargeConsumerUserAccount(request);

        if (result.ok) {
            toastr.success('Пополнение аккаунта', `Аккаунт ${account.name} успешно пополнен.`);
            toggle();
        }
        else {
            if(result.errorCode === 'InvaidCount') {
                actions.setFieldError('count', 'Некорректное количество');
            }
            else if(result.errorCode === 'AccountNotFound') {
                toastr.error('Пополнение аккаунта', 'Аккаунт не найден');
            }
            else if(result.errorCode === 'ServerNotFound') {
                toastr.error('Пополнение аккаунта', 'Сервер не найден');
            }
            else if(result.errorCode === 'ServerCantCharge') {
                toastr.error('Пополнение аккаунта', 'Нельзя пополнить аккаунт на этом сервере');
            }
            else if(result.errorCode === 'ChargeError') {
                toastr.error('Пополнение аккаунта', 'Не удалось выполнить запрос на пополнение');
            }
            else {
                toastr.error('Пополнение аккаунта', 'Что-то пошло не так');
            }
        }
    }

    return (
        <Modal
            key={id}
            show={isOpened}
            onHide={toggle}
            centered>
            <Modal.Header closeButton>
                Пополнение игрового аккаунта {account.name} на {targetName}
            </Modal.Header>
            <Formik
                onSubmit={onSubmit}
                initialValues={initial}
                validationSchema={valid}>
                {
                    (({ isSubmitting }) => (
                        <Form>
                            <Modal.Body>
                                <Field
                                    label="Сумма"
                                    type="number"
                                    name="count"
                                    min="0"
                                    max={2000000000}
                                    placeholder="Введите сумму для начисления"
                                    description="Введенная сумма будет зачислена на игровой аккаунт 1 к 1"
                                    component={FormikRowInput} />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    type="button"
                                    variant="secondary"
                                    onClick={toggle}
                                >
                                    Закрыть
                                </Button>{" "}
                                <Button
                                    type="submit"
                                    variant="primary"
                                    disabled={isSubmitting}
                                >
                                    Пополнить
                                </Button>
                            </Modal.Footer>
                        </Form>
                    ))
                }
            </Formik>
        </Modal>
    )
}

export default ChargeGameAccountModal;
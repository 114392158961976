import {FC, PropsWithChildren, useMemo} from "react";
import { PaginationProps } from "@constructor/components/core/pagination";
import { useGoals } from "@hooks/selectors/user/goals.selectors";
import { IGoal } from "@interfaces/user/refs";
import { injectContextArray } from "@components/constructor";
import { GoalProvider } from "./context";
import {InjectContextType} from "@interfaces/constructor/constructor";

interface GoalsListChildProps {
    goal_id: string;
    goal_index: number;
    goal_reached: boolean;
}

const GoalsList: FC<PropsWithChildren<PaginationProps>> = ({ pagination_current_page = 0, pagination_per_page = 10, children }) => {

    const { data: goals } = useGoals();
    const page = useMemo(() => goals.slice(pagination_current_page * pagination_per_page, (pagination_current_page + 1) * pagination_per_page), [goals, pagination_current_page, pagination_per_page]);

    const data = useMemo<InjectContextType<IGoal, GoalsListChildProps>[]>(() => page.map((item, idx) => {
        return {
            key: item.id,
            value: item,
            childData: {
                goal_id: item.id,
                goal_index: pagination_current_page * pagination_per_page + 1 + idx,
                goal_reached: item.isReached
            }
        }
    }), [page, pagination_current_page, pagination_per_page]);

    return injectContextArray(data, GoalProvider, children);
}

export default GoalsList;
import {FC, PropsWithChildren, useMemo} from 'react';
import { Parser } from 'expr-eval';
import { useAllRoles } from '@hooks/userHooks';

interface LogicCondProps {
    condition?: any;
    roles?: string[];
}

const Condition: FC<PropsWithChildren<LogicCondProps>> = ({ children, condition, roles }) => {

    const userRoles = useAllRoles();

    const result = useMemo(() => {
        if(condition === undefined) {
            return true;
        }
        if (typeof condition === 'string') {
            try {
                return Boolean(Parser.evaluate(condition));
            }
            catch (e) {
                return false;
            }
        }

        return !!condition;
    }, [condition]);

    const roleResult = useMemo(() => {
        if(!Array.isArray(roles)) {
            return true;
        }

        return roles.reduce((result, role) => result || userRoles.includes(role), false);
    }, [roles, userRoles]);

    if (result && roleResult) {
        return <>{children}</>;
    }
    return null;
}

interface RolesCondProps {
    role?: string | string[];
}

const RolesCondition: FC<PropsWithChildren<RolesCondProps>> = ({ children, role }) => {
    const userRoles = useAllRoles();

    if (!role
        || (typeof role === 'string' && userRoles.includes(role))
        || (Array.isArray(role) && userRoles.findIndex(r => role.includes(r)) >= 0)) {
        return <>{children}</>
    }

    return null;
}

export {
    Condition,
    RolesCondition
}
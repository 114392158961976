import {FC, PropsWithChildren, useMemo} from "react";
import {useParams} from "react-router-dom";
import {useMarketCategories} from "@hooks/selectors/services.selectors";
import {injectChild} from "@components/constructor";
import {MarketCategoryProvider} from "@constructor/components/features/market/categories/context";

interface MarketCategoryProps {
    category_id?: string;
    default_category?: string;
}

interface MarketCategoryChildProps {
    market_category_loading: boolean;
    market_category_loaded: boolean;
    market_category_error: boolean;
    market_category_items_count: number;
}

const MarketCategory: FC<PropsWithChildren<MarketCategoryProps>> = ({ category_id, default_category, children }) => {

    const {category_url} = useParams<{category_url?: string}>();
    const { loading, loaded, error, categories = []} = useMarketCategories();
    const category = categories.find(x => x.id === category_id || x.shortName === category_url)
        || categories.find(x => x.shortName === default_category);
    
    const data = useMemo<MarketCategoryChildProps>(() => ({
        market_category_error: Boolean(error),
        market_category_loaded: loaded,
        market_category_loading: loading,
        market_category_items_count: category?.items.length || 0
    }), [category?.items.length, error, loaded, loading]);

    if(category) {
        return (
            <MarketCategoryProvider value={category}>
                {injectChild(data, children)}
            </MarketCategoryProvider>
        )
    }

    return injectChild(data, children);
}

export default MarketCategory;
//import { useDispatch } from 'react-redux';
import { SidebarCategoryLink, SidebarItemLink } from '@interfaces/control-panel';
///import IosPulse from 'react-ionicons/lib/IosPulse';
import classNames from 'classnames';
import { SidebarCategory, SidebarItem } from '.';
import { useSidebar, useProjectName } from '@hooks/selectors/panel.selectors';
import {
    usePublicName,
    useAvatar,
    useBalance
} from '@hooks/selectors/user.selectors';
import { FC, Fragment, useMemo, useState } from 'react';
import { useCallback } from 'react';
import { consumerItems } from './items';
import { useAllRoles } from '@hooks/userHooks';

const Sidebar: FC = () => {

    //const dispatch = useDispatch();
    const { /*links,*/ isOnRight, isOpen } = useSidebar();
    const username = usePublicName();
    const avatar = useAvatar();
    const projectName = useProjectName();
    const balance = useBalance();
    const roles = useAllRoles();

    const [categoryStatuses, setCategoryStatus] = useState(() => consumerItems.map(x => x.isOpen));

    //const toggleSidebarItem = useCallback((idx: number) => dispatch(sidebarActions.toggleSidebarItem(idx)), [dispatch]);
    const toggleSidebarItem = useCallback((idx: number) => setCategoryStatus(statuses => {
        const state = [...statuses];
        state[idx] = !state[idx];
        return state;
    }), []);

    const links = useMemo(() => consumerItems.filter(x => !x.role || roles.includes(x.role)), [roles]);

    return (
        <nav
            className={classNames(
                "sidebar",
                isOpen || "toggled",
                !isOnRight || "sidebar-right")}
        >
            <div className="sidebar-content">
                <a
                    className={classNames(
                        "sidebar-brand",
                        !isOnRight || "text-right"
                    )}
                    href="/"
                >
                    {/* {isOnRight || <IosPulse />}{" "} */}
                    <span className="align-middle">{projectName}</span>{" "}
                    {/* {!isOnRight || <IosPulse />} */}
                </a>

                <div className="sidebar-user">
                    <img
                        src={avatar}
                        className="img-fluid rounded-circle mb-2"
                        alt={username}
                    />
                    <div className="font-weight-bold">{username}</div>
                    <small>Баланс: {balance}</small>
                </div>
                <ul className="sidebar-nav">
                    {links.map((category: SidebarCategoryLink, index) => {
                        return (
                            <Fragment key={index}>
                                {category.header ? (
                                    <li className="sidebar-header">{category.header}</li>
                                ) : null}

                                {(category.children && category.children.length > 0) ? (
                                    <SidebarCategory
                                        name={category.name}
                                        badgeColor={category.badgeColor}
                                        badgeText={category.badgeText}
                                        icon={category.icon}
                                        to={category.to}
                                        isOpen={categoryStatuses[index] || false}
                                        onClick={() => toggleSidebarItem(index)}
                                    >
                                        {category.children.map((route: SidebarItemLink, index) => (
                                            <SidebarItem
                                                key={index}
                                                name={route.name}
                                                to={route.to}
                                                badgeColor={route.badgeColor}
                                                badgeText={route.badgeText}
                                                icon={route.icon}
                                            />
                                        ))}
                                    </SidebarCategory>
                                ) : (
                                    <SidebarItem
                                        name={category.name}
                                        to={category.to}
                                        icon={category.icon}
                                        badgeColor={category.badgeColor}
                                        badgeText={category.badgeText}
                                        exact={category.exact}
                                    />
                                )}
                            </Fragment>
                        );
                    })}
                </ul>
            </div>
        </nav>
    );
}


export default Sidebar;
import FilesService from "@api/filesService";
import { UploadFileInfo, DownloadFileInfo } from "@interfaces/panel/files";
import FetchResult from "@models/fetchResult";

export class FileUploader {

    private info: UploadFileInfo;

    constructor(info: UploadFileInfo) {
        this.info = info;
    }

    public onProgress: ((event: ProgressEvent<EventTarget>) => any) | null = null;
    public onEnd: ((event: ProgressEvent<EventTarget>) => any) | null = null;
    public onSuccess: ((event: ProgressEvent<EventTarget>) => any) | null = null;
    public onError: ((event: ProgressEvent<EventTarget>) => any) | null = null;

    public upload(file: File) {
        return this.uploadBlob(file, file.name);
    }

    public uploadBlob(blob: Blob, fileName: string) {
        return new Promise<DownloadFileInfo>((resolve, reject) => {
            const xhr = new XMLHttpRequest();

            xhr.onloadend = async () => {
                const api = new FilesService();
                if (xhr.status === 200) {
                    const result = await api.finishFileUploading({
                        ...this.info,
                        fileName
                    })
                    
                    if(result.success) {
                        resolve(result.data);
                    }
                    else {
                        reject(result);
                    }
                } 
                else {
                    const error = new FetchResult<DownloadFileInfo>();
                    error.ok = false;
                    error.message = xhr.statusText;
                    error.status = xhr.status;
                    reject(error);
                    await api.failFileUploading(this.info);
                }
            }

            xhr.upload.onprogress = this.onProgress;
            xhr.upload.onload = this.onSuccess;
            xhr.upload.onloadend = this.onEnd;
            xhr.upload.onerror = this.onError;

            xhr.open('PUT', this.info.url, true);
            xhr.send(blob);
        });
    }
}
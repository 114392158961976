import {FC, useState, MouseEvent} from "react";
import {useNotifications} from "@hooks/selectors/user.selectors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Card, Dropdown, ListGroup } from 'react-bootstrap';
import {FalconCardHeader} from "@components/falcon/common";
import Notification from "./notification";
import {usePromisedDispatch} from "@hooks/helperHooks";
import {userActions} from "@store/user";

const NotificationDropdown: FC = () => {

    const dispatch = usePromisedDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const [fetching, setFetching] = useState(false);

    const notifications = useNotifications();
    const newNotifications = notifications.filter(n => !n.isReaded);
    const readNotifications = notifications.filter(n => n.isReaded);

    const isAllRead = newNotifications.length === 0;

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const markAsRead = async (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        if(isAllRead || fetching)
            return;

        setFetching(true);
        await dispatch(userActions.notifications.readAllNotifications());
        setFetching(false);
    };

    return (
        <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
            <Dropdown.Toggle
                bsPrefix="toggle"
                as={Link}
                to="#!"
                className={classNames('px-0 nav-link', {
                    'notification-indicator notification-indicator-primary': !isAllRead
                })}
            >
                <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-5" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg">
                <Card
                    className="dropdown-menu-notification dropdown-menu-end shadow-none"
                    style={{ maxWidth: '20rem' }}
                >
                    <FalconCardHeader
                        className="card-header"
                        title="Уведомления"
                        titleTag="h6"
                        light={false}
                        endEl={readNotifications.length > 0 && (
                            <Link
                                className="card-link fw-normal"
                                to="#!"
                                onClick={markAsRead}
                            >
                                Отметить все прочитанным
                            </Link>
                        )}
                    />
                    <ListGroup
                        variant="flush"
                        className="fw-normal fs-10 scrollbar"
                        style={{maxHeight: '19rem'}}
                    >
                        {
                            newNotifications.length > 0 && (
                                <>
                                    <div className="list-group-title">Новые</div>{' '}
                                    {newNotifications.map(notification => (
                                        <ListGroup.Item key={notification.id} onClick={handleToggle}>
                                            <Notification notification={notification} flush/>
                                        </ListGroup.Item>
                                    ))}
                                </>
                            )
                        }
                        {
                            readNotifications.length > 0 && (
                                <>
                                    <div className="list-group-title">Прочитанные</div>{' '}
                                    {readNotifications.map(notification => (
                                        <ListGroup.Item key={notification.id} onClick={handleToggle}>
                                            <Notification notification={notification} flush/>
                                        </ListGroup.Item>
                                    ))}
                                </>
                            )
                        }
                        {
                            notifications.length === 0 && (
                                <div className="list-group-title">Уведомлений нет</div>
                            )
                        }
                    </ListGroup>
                </Card>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default NotificationDropdown;
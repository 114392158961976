import {CustomQuery, EQueryTarget} from "@interfaces/query";
import { ReduxStore } from "@interfaces/store";
import {shallowEqual, useSelector} from "react-redux";

export function useQueryByName(name: string, serverId?: string) {
    return useSelector(({ queries }: ReduxStore) => queries.queries.data.find(q => q.name === name && (!serverId || q.serverId === serverId)));
}

export function useQueriesByName(name: string) {
    return useSelector(({ queries }: ReduxStore) => queries.queries.data.filter(q => q.name === name), shallowEqual);
}

export function useQueries(predicate: (query: CustomQuery) => boolean) {
    return useSelector(({ queries }: ReduxStore) => queries.queries.data.filter(predicate), shallowEqual);
}

export function useQueryById(queryId: string) {
    return useSelector(({ queries }: ReduxStore) => queries.queries.data.find(q => q.id === queryId));
}

export function useQueriesByServer(serverId: string) {
    return useSelector(({ queries }: ReduxStore) => queries.queries.data.filter(q => q.serverId === serverId), shallowEqual);
}

export function useQueryResult(queryId: string, parameter?: string) {

    return useSelector(({ queries: { queries: { data: queries }, results } }: ReduxStore) => {

        const query = queries.find(q => q.id === queryId);

        if (!query) {
            return null;
        }

        let resultId = query.id;
        if (query.target !== EQueryTarget.Server && parameter) {
            resultId += ":" + parameter;
        }

        const storedResult = results[resultId];
        if (storedResult?.cacheTime && Date.parse(storedResult.cacheTime) > Date.now()) {
            return storedResult;
        }
        return null;
    });
}
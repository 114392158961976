import {FC, useEffect, useState} from "react";
import {useAdvanceTableContext} from "@components/falcon/common/tables/context";
import classNames from 'classnames';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDebounce from "@services/helpers";

interface AdvanceTableSearchBoxProps {
    placeholder?: string;
    className?: string;
}

const AdvanceTableSearchBox: FC<AdvanceTableSearchBoxProps> = ({className, placeholder = 'Поиск...'}) => {
    const { getState, setGlobalFilter } = useAdvanceTableContext();
    const [value, setValue] = useState(getState().globalFilter);

    const debounced = useDebounce(value, 500)

    useEffect(() => {
        setGlobalFilter(debounced || undefined);
    }, [debounced]);

    return (
        <InputGroup className={classNames(className, 'position-relative')}>
            <FormControl
                value={value || ''}
                onChange={({ target: { value } }) => {
                    setValue(value);
                }}
                size="sm"
                id="search"
                placeholder={placeholder}
                type="search"
                className="shadow-none"
            />
            <Button
                size="sm"
                variant="outline-secondary"
                className="border-300 hover-border-secondary"
            >
                <FontAwesomeIcon icon="search" className="fs-10" />
            </Button>
        </InputGroup>
    );
}

export default AdvanceTableSearchBox;
import {FC} from "react";
import {Nav} from "react-bootstrap";
import {ThemeControlDropdown, NotificationDropdown, ProfileDropdown} from "./items";

const TopNavRightSideNavItem: FC = () => {
    return (
        <Nav
            navbar
            className="navbar-nav-icons ms-auto flex-row align-items-center"
            as="ul"
        >
            <ThemeControlDropdown />
            <NotificationDropdown />
            <ProfileDropdown />
        </Nav>
    )
}

export default TopNavRightSideNavItem;